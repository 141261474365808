import { LightAuthUser } from "models/AuthUser";
import { Bookmark } from "models/Bookmark";
import { Organization } from "../../models/Organization";
import { toUser, User } from "../../models/User";
import { FairtrailHttpClient } from "../FairtrailHttpClient";

export class AccountsAPIClient extends FairtrailHttpClient {
  async getOrganizations(): Promise<Organization[]> {
    const response = await this.get({
      path: "/accounts/organizations?size=100", // there are not very many ortganizations so we can just get them all
      authenticated: true,
    });

    return response.data;
  }

  async getUserProfile(id: string): Promise<User> {
    const response = await this.get({
      path: `/accounts/users/${id}`,
      authenticated: true,
    });

    return toUser(response.data);
  }

  async queryForUserProfiles(q: string): Promise<User[]> {
    const response = await this.get({
      path: `/accounts/users`,
      authenticated: true,
      query: {
        q: q,
        status: "active",
      },
    });

    return response.data.map(toUser);
  }

  async getAuthUser(): Promise<LightAuthUser> {
    const response = await this.get({
      path: "/accounts/me",
      authenticated: true,
    });

    return response.data;
  }

  async getBookmarks(page = 0, size = 100): Promise<{ count: number; items: Bookmark[] }> {
    const response = await this.get({
      path: "/accounts/bookmarks",
      authenticated: true,
      query: {
        size,
        page,
      },
    });

    return {
      count: parseInt(response.headers["totalcount"]!),
      items: response.data,
    };
  }

  async getUsersByQuery({ userId }: { userId: string | string[] }): Promise<{
    users: User[];
    totalCount: number;
  }> {
    const response = await this.get({
      path: "/accounts/users",
      query: {
        userId,
      },
      authenticated: true,
    });

    return {
      users: response.data,
      totalCount: response.headers["totalcount"],
    };
  }
}
