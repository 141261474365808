import { UnitsType } from "models/widgets/WeatherWidget";
import { OPEN_WEATHER_MAP_KEY } from "../../config/config";
import { HttpClient } from "../HttpClient";

class OpenWeatherAPIClient extends HttpClient {
  private readonly apiKey: string;

  constructor(apiKey?: string) {
    super("https://api.openweathermap.org/data/3.0");
    this.apiKey = apiKey || OPEN_WEATHER_MAP_KEY;
  }

  async getWeatherByLatAndLong(lat: string, lon: string, unit: UnitsType) {
    const response = await this.get({
      path: `/onecall`,
      query: {
        lat: lat,
        lon: lon,
        units: unit,
        appid: this.apiKey,
      },
    });

    return response.data;
  }
}

export default OpenWeatherAPIClient;
