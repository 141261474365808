import type { PropsWithChildren } from "react";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { serializeCSSObj } from "theme/utils";

import { themeColorHelpers } from "./helpers/colors";
import { typography } from "./helpers/typography";
import { themeSpacingHelpers } from "./helpers/spacing";
import { BaseTheme, Palette, ThemeConfig, TypographyConfig, TypographyKey } from "./types";

type ThemeProviderProps<TTheme extends ThemeConfig> = {
  theme: TTheme;
};

export function ThemeProvider<TTheme extends ThemeConfig>({
  children,
  theme,
}: PropsWithChildren<ThemeProviderProps<TTheme>>) {
  return <EmotionThemeProvider theme={makeTheme(theme) as unknown as BaseTheme}>{children}</EmotionThemeProvider>;
}

/**
 * Transforms the theme config into a theme object that can be used by emotion
 * Makes spacing and typography keys available inside of the theme as `spacingValue` and `typographyValue`
 * and provides a `spacing` function that can be used to calculate spacing values
 * and a `typography` function that can be used to calculate typography values
 */
export const makeTheme = <TTheme extends ThemeConfig>(config: TTheme) => {
  const currentTheme = {
    ...config,
    color: themeColorHelpers(config.palette as Palette),
    spacing: themeSpacingHelpers(config.spacing),
    borderRadius: config.borderRadius,
    spacingValue: config.spacing,
    typography: setupTypographyGetters(config.typography),
    typographyValue: config.typography,
    shadow: config.shadow,
  };

  return currentTheme;
};

// TODO: Rework theme transformations completely to make it simpler
function setupTypographyGetters(themeTypographyConfig: any) {
  const returnObj: any = {};

  Object.keys(themeTypographyConfig).forEach((key) => {
    if (key === "default") {
      returnObj.default = serializeCSSObj(typography("default", themeTypographyConfig));
      return;
    }

    Object.keys(themeTypographyConfig[key]).forEach((variant) => {
      addVariantGetter(returnObj, key, variant, () => {
        const paramKey = `${key}.${variant}`;
        return serializeCSSObj(typography(paramKey as TypographyKey, themeTypographyConfig as TypographyConfig));
      });
    });
  });

  return returnObj;
}

function addVariantGetter<T>(obj: T, key: string, variant: string, fn: any) {
  Object.defineProperty(obj, key + variant, { get: fn });
}
