/** User as in response from auth/passwordless/:token login */
export type AuthUser = {
  userId: string;
  name: string;
  token: string;
  created: Date;
  expires: Date;
  activities: string[];
  existingUser: boolean;
  organizationId: string;
  url: string;
};

export function toAuthUser(input: any): AuthUser {
  return {
    ...input,
    created: new Date(input.created),
    expires: new Date(input.expires),
  };
}

export type LightAuthUser = {
  userId: string;
  status: string;
  organizationId: string;
  activities: string[];
  url: string;
};
