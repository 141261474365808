import { Theme } from "@emotion/react";
import { GaugeType } from "constants/widgetConstants";
import { GaugeWidgetViewModel } from "models/widgets/GaugeWidget";
import { Threshold, ThresholdColor } from "models/widgets/common/common";

type Stops = [number, string][];

export type AxisOptions = {
  threshold: Threshold;
  stops: Stops | undefined;
};

export function getYAxisConfigFromWidget(widget: GaugeWidgetViewModel, theme: Theme, data: number): AxisOptions {
  const thresholds = widget.settings.thresholds ?? [];

  if (!thresholds.length) {
    return {
      threshold: {
        range: {
          min: 0,
          max: 100,
        },
      },
      stops: undefined,
    };
  }

  const min = Math.min(...thresholds.map((threshold) => threshold.range.min));
  const max = Math.max(...thresholds.map((threshold) => threshold.range.max));
  const rangeSize = max - min;

  const getThresholdColor = (color: ThresholdColor) => {
    switch (color) {
      case ThresholdColor.Green:
        return theme.color.success;
      case ThresholdColor.Yellow:
        return theme.color.warning;
      case ThresholdColor.Red:
        return theme.color.error;
      default:
        return theme.color.primary200;
    }
  };

  const thresholdData = thresholds.map((threshold) => ({
    color: threshold.color ? getThresholdColor(threshold.color) : theme.color.primary200,
    range: threshold.range,
  }));

  /** Used for the Solid Gauge */
  const stops: Stops = thresholdData.map((threshold) => [(threshold.range.max - min) / rangeSize, threshold.color]);

  const currentThreshold = thresholds.find((threshold) => data >= threshold.range.min && data <= threshold.range.max);

  const currentColor = currentThreshold
    ? getThresholdColor(currentThreshold.color || (theme.color.primary200 as ThresholdColor))
    : theme.color.success;

  return {
    threshold: {
      color: currentColor as ThresholdColor,
      range: {
        min,
        max,
      },
    },
    stops: widget.settings.type === GaugeType.Solid ? stops : undefined,
  };
}
