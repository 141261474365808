import { useTheme } from "@emotion/react";
import GridLayout, { Layout } from "components/common/GridLayout";
import AddDevWidget from "components/dev/AddDevWidget";
import WidgetFrame from "components/widgets/WidgetFrame";
import { LoadingState } from "components/widgets/loading/LoadingState";
import { DashboardActivity } from "constants/permissionConstants";
import { DASHBOARD_ROUTE } from "constants/routes";
import { WidgetType } from "constants/widgetConstants";
import { getWidgetData } from "data/widgetData";
import Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import highchartsExportData from "highcharts/modules/export-data";
import highchartsExporting from "highcharts/modules/exporting";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { hasData } from "models/Loading";
import { WidgetViewModel } from "models/widgets/Widget";
import { useEffect, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import { useStore } from "stores/RootStore";
import { useStoreItem } from "stores/hooks";
import { hasActivity } from "utils/permissionUtils";
import { getWidgetHeight, getWidgetWidth } from "utils/widgetUtils";

highchartsAccessibility(Highcharts);
highchartsExporting(Highcharts);
highchartsExportData(Highcharts);

interface Props {
  dashboardId: string;
  isEditable: boolean;
}

const Dashboard = ({ dashboardId, isEditable }: Props) => {
  const windowSize = useWindowSize();
  const theme = useTheme();
  const { dashboardStore, authStore, themeStore, appStore } = useStore();
  const dashboard = useStoreItem(dashboardStore, dashboardId);

  useEffect(() => {
    dashboardStore.loadWidgetsByDashboardId(dashboard.dashboardId);

    return () => themeStore.resetTheme();
  }, [dashboard.dashboardId, dashboardStore, themeStore]);

  useEffect(() => {
    const dashboard = dashboardStore.getDashboardById(dashboardId!);

    if (dashboard) {
      if (appStore.isFullScreen) {
        themeStore.setThemeFromDashboard(dashboard);
      } else {
        themeStore.resetTheme();
      }
    }
  }, [dashboardId, dashboardStore, themeStore, appStore.isFullScreen]);

  const widgets = dashboardStore.getWidgetsByDashboardId(dashboard.dashboardId);
  const dashboardMetadata = hasData(dashboard) ? dashboard.metadata : undefined;

  const { layouts, items } = useMemo(() => {
    if (!dashboardMetadata) {
      return { layouts: [], items: [] };
    }

    return widgets
      .map((widget): { layout: Layout; item: JSX.Element } => {
        const widgetData = getWidgetData(widget);

        return {
          layout: {
            i: widget.widgetId,
            x: widget.settings.position.x,
            y: widget.settings.position.y,
            ...widget.dimensions,
            w: getWidgetWidth(widget),
            h: getWidgetHeight(widget),
            maxH: widget.dimensions.maxHeight,
            maxW: widget.dimensions.maxWidth,
            minH: widget.dimensions.minHeight,
            minW: widget.dimensions.minWidth,
            static: !isEditable,
          },
          item: (
            <WidgetFrame
              widgetId={widget.widgetId}
              title={widget?.settings?.title || widgetData?.name}
              hideTitle={widgetData?.hideTitle}
              hideable={widgetData?.hideBackground}
              readonly={!isEditable}
            >
              {widgetData?.component({ widget, readonly: !isEditable, dashboardMetadata })}
            </WidgetFrame>
          ),
        };
      })
      .reduce(
        (acc: { layouts: Layout[]; items: JSX.Element[] }, { layout, item }) => ({
          layouts: [...acc.layouts, layout],
          items: [...acc.items, <div key={layout.i}>{item}</div>],
        }),
        { layouts: [], items: [] }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgets.length, isEditable, dashboardMetadata]);

  const redrawHighcharts = () => {
    Highcharts.charts.forEach((chart) => {
      chart?.reflow();
    });
  };

  const saveLayout = (layout: Layout[]) => {
    redrawHighcharts();

    const updatedWidgets: WidgetViewModel[] = [];

    widgets.forEach((widget) => {
      const newLayout = layout.find((l) => l.i === widget.widgetId);

      const xPositionHasChanged = widget.settings?.position.x !== newLayout?.x;
      const yPositionHasChanged = widget.settings?.position.y !== newLayout?.y;
      const widthHasChanged = widget.settings?.size?.width !== newLayout?.w;
      const heightHasChanged = widget.settings?.size?.height !== newLayout?.h;

      if (xPositionHasChanged || yPositionHasChanged || widthHasChanged || heightHasChanged) {
        if (newLayout) {
          const updatedWidget = toJS(widget);

          updatedWidget.settings.position = {
            x: newLayout.x,
            y: newLayout.y,
          };
          updatedWidget.settings.size = {
            width: newLayout.w,
            height: newLayout.h,
          };

          updatedWidgets.push(updatedWidget);
        }
      }
    });

    if (updatedWidgets.length > 0) {
      dashboardStore.updateWidgetLayouts(dashboardId, updatedWidgets);
    }
  };

  if (!hasData(dashboard)) {
    return <LoadingState />;
  }

  if (isEditable && authStore.authUser && !hasActivity(DashboardActivity.UpdateDashboard)) {
    return <Navigate to={DASHBOARD_ROUTE.replace(":dashboardId", dashboardId)} />;
  }

  return (
    <>
      <AddDevWidget />
      <GridLayout
        className="layout"
        layout={layouts}
        cols={12}
        rowHeight={Math.round(Math.max(windowSize.height! / 22, 48))}
        width={windowSize.width - theme.spacingValue * 6}
        onLayoutChange={isEditable ? saveLayout : undefined}
        draggableHandle=".dragHandle"
        isResizable={isEditable}
        resizeHandles={isEditable ? ["se"] : []}
      >
        {items}
      </GridLayout>
    </>
  );
};

export default observer(Dashboard);
