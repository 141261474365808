import { SettingsType } from "constants/constants";
import { IncidentType } from "constants/widgetConstants";
import { titleSetting } from "data/commonSettings";
import { SettingsDescriptor } from "data/widgetData";
import {
  MultipleAlarmsValueSourceTypes,
  MultiplePropertyValueSourceTypes,
  MultipleThingsValueSourceTypes,
  ThingMetricThreshold,
  ThingMetricThresholdOperator,
  MultipleStacksValueSourceTypes,
} from "models/widgets/TableWidget";
import { rootStore } from "stores/RootStore";
import language from "translations/language";

const colorPalette = [
  { value: "Default", key: null },
  { value: language.colors.RED, key: "#d98380" },
  { value: language.colors.YELLOW, key: "#f0ad77" },
];

const thingMetricThresholdSettings: SettingsDescriptor = {
  metric: {
    type: SettingsType.Select,
    label: language.widgets.settings.THING_PROPERTY,
    options: Object.entries(ThingMetricThreshold).map(([_key, value]) => {
      return { key: value, value: language.thingMetricThreshold[value] };
    }),
    hidden: () => !!rootStore.configStore.deptId,
  },

  operator: {
    type: SettingsType.Select,
    label: language.widgets.settings.OPERATOR,
    options: Object.entries(ThingMetricThresholdOperator).map(([_key, value]) => {
      return { key: value, value: language.thingMetricThresholdOperator[value] };
    }),
    hidden: () => !!rootStore.configStore.deptId,
  },

  value: {
    type: SettingsType.Text,
    label: language.widgets.settings.VALUE,
    hidden: () => !!rootStore.configStore.deptId,
  },
};

const thingSettings: SettingsDescriptor = {
  thingId: {
    type: SettingsType.Thing,
    label: language.widgets.settings.THINGID,
    multiple: false,
  },

  thingProperty: {
    type: SettingsType.ThingMetric,
    label: language.widgets.settings.THING_PROPERTY,
    options: "LiveThingProperty",
    multiple: true,
    thingsIdField: "thingId",
  },
};

const incidentStatsSettings: SettingsDescriptor = {
  incidentType: {
    type: SettingsType.Select,
    label: language.widgets.settings.INCIDENT_TYPE,
    default: null,
    multiple: true,
    options: Object.entries(IncidentType).map(([_key, value]) => {
      return { key: value, value: language.incidentTypes[value] };
    }),
    hidden: () => !!rootStore.configStore.deptId,
  },

  dayRange: {
    type: SettingsType.DayRange,
    label: language.widgets.settings.INCIDENT_DURATION,
    hidden: () => !!rootStore.configStore.deptId,
  },
};

const alarmStatsSettings: SettingsDescriptor = {
  incidentType: {
    type: SettingsType.Hidden,
    hidden: () => !!rootStore.configStore.deptId,
  },
};

const projectDurationSettings: SettingsDescriptor = {
  hidden: {
    type: SettingsType.Hidden,
  },
};

export const tableWidgetSettings: SettingsDescriptor = {
  title: titleSetting,
  dataSource: {
    label: language.widgets.settings.DATA_SOURCE,
    type: SettingsType.Switch,
    switchField: {
      type: SettingsType.Select,
      label: language.widgets.settings.DATA_SOURCE_TYPE,
      default: null,
      multiple: true,
      options: [
        ...Object.entries(MultiplePropertyValueSourceTypes),
        ...Object.entries(MultipleThingsValueSourceTypes),
        ...Object.entries(MultipleAlarmsValueSourceTypes),
        ...Object.entries(MultipleStacksValueSourceTypes),
      ].map(([_key, value]) => {
        return { key: value, value: language.sourceTypes[value] };
      }),
    },
    switchKey: "sourceType",
    options: {
      [MultiplePropertyValueSourceTypes.Thing]: thingSettings,
      [MultiplePropertyValueSourceTypes.IncidentStats]: incidentStatsSettings,
      [MultipleThingsValueSourceTypes.ThingMetricThreshold]: thingMetricThresholdSettings,
      [MultipleAlarmsValueSourceTypes.Alarms]: alarmStatsSettings,
      [MultipleStacksValueSourceTypes.StackCreated]: projectDurationSettings,
    },
  },
  color: {
    type: SettingsType.Select,
    label: language.COLOR,
    default: null,
    options: colorPalette,
  },
};
