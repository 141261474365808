import { TimeInterval, TimeRangeType } from "constants/widgetConstants";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { DateRangeOrInterval } from "models/widgets/ChartWidget";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const FORMATTED_TIMESTAMP = "YYYY-MM-DD - HH:mm";

export const formatDate = (date: any, format: string = FORMATTED_TIMESTAMP, tz?: string) => {
  const dayjsDate = tz ? dayjs(date).tz(tz) : dayjs(date);
  return dayjsDate.format(format);
};

export function toISOString(date: Date): string;
export function toISOString(date?: null): null;
export function toISOString(date?: Date | null): string | null;
export function toISOString(date?: Date | null) {
  if (!(date instanceof Date)) {
    return null;
  }
  return dayjs(date).toISOString();
}

export const fromISOString = (date?: string | null) => {
  if (!date) {
    return null;
  }

  return new Date(date);
};

export const toUnixTime = (date: Date) => {
  return dayjs(date).unix();
};

export const fromUnixTime = (date: number | string, format: string = "YYYY-MM-DD HH:mm", tz?: string) => {
  const num = Number(date); //?
  const dayjsDate = tz ? dayjs.unix(num).tz(tz) : dayjs.unix(num);
  const formatted = dayjsDate.format(format);

  if (num < 0 || formatted === "Invalid Date") {
    return;
  }

  return formatted;
};

export const withinRange = (date: Date, daterangeOrInterval: DateRangeOrInterval, now: Dayjs): boolean => {
  const dayjsDate = dayjs(date);

  if (daterangeOrInterval.type === TimeRangeType.DateRange) {
    const { from, to } = daterangeOrInterval.dateRange;
    return dayjsDate.isBetween(from, to, null, "[]");
  } else if (daterangeOrInterval.type === TimeRangeType.TimeInterval) {
    const { interval } = daterangeOrInterval;
    return dayjsDate >= now.subtract(intervalToDays(interval!), "days");
  } else {
    throw new Error("Invalid date range or interval");
  }
};

export const intervalToDateFrom = (dateRangeOrInterval: DateRangeOrInterval, now: Dayjs = dayjs()): Date => {
  switch (dateRangeOrInterval.type) {
    case TimeRangeType.DateRange:
      return dayjs(dateRangeOrInterval.dateRange.from).toDate();
    case TimeRangeType.TimeInterval:
      return now
        .subtract(intervalToDays(dateRangeOrInterval.interval!), "days")
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0)
        .toDate();
  }
};

const intervalToDays = (interval: TimeInterval) => {
  switch (interval) {
    case TimeInterval.TwentyFourHours:
      return 1;
    case TimeInterval.ThreeDays:
      return 3;
    case TimeInterval.SevenDays:
      return 7;
    case TimeInterval.FourteenDays:
      return 14;
    case TimeInterval.ThirtyDays:
      return 30;
    case TimeInterval.SixtyDays:
      return 60;
    case TimeInterval.NinetyDays:
      return 90;
    case TimeInterval.OneHundredEightyDays:
      return 180;
    case TimeInterval.ThreeHundredSixtyFiveDays:
      return 365;
  }
};

/**
 * Converts a float representing a time to a string in the format HH:mm
 * So 12.5 becomes 12:30
 * @param floatTime
 */
export function floatToTimeString(floatTime: number | undefined): string {
  if (!floatTime) {
    return "";
  }
  const hours = Math.floor(floatTime);
  const minutes = Math.round((floatTime % 1) * 60);
  return `${hours > 10 ? hours : "0" + hours}:${minutes > 10 ? minutes : "0" + minutes}`;
}

/**
 * @param date The date to format, should be used with useTimezoneDate()
 * @param language The language to use for the date format (useLanguage instance)
 */
export const daysFromNow = (date: Date) => {
  return `${dayjs(new Date()).diff(date, "day")}`;
};
