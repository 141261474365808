import { AlarmActions, AlarmTypes, Thing, ThingAlarmType, ThingStatus } from "models/Thing";
import language from "translations/language";
import { formatDate } from "utils/datetimeUtils";

export const messageForThingStatus = (thing: Pick<Thing, "status" | "alarms">, tz?: string): string => {
  switch (thing.status) {
    case ThingStatus.Healthy:
    case ThingStatus.Offline:
    case ThingStatus.ConfigFailed:
    case ThingStatus.ConfigReceived:
    case ThingStatus.Preregistered:
      return language.thingStatus[thing.status];
    case ThingStatus.HasAlarm:
      return messageForThingAlarm(thing, tz);
    default:
      return "";
  }
};

const messageForThingAlarm = ({ alarms }: Pick<Thing, "alarms">, tz?: string) => {
  if (Object.keys(alarms).length === 0) {
    return language.thingStatus[ThingStatus.HasAlarm];
  }
  const alarmSpecifics = Object.entries(alarms)
    .map(([alarmType, alarm]) => {
      let additionalAlarmInfo = "";

      if (!!alarm.alarmType) {
        additionalAlarmInfo = ` (${language.alarmTypes[alarm.alarmType as AlarmTypes]})`;
      }

      if (
        (alarm.name === ThingAlarmType.LongWaterFlowAlarm || alarm.name === ThingAlarmType.LargeWaterVolumeAlarm) &&
        alarm.action === AlarmActions.NotifyUser
      )
        additionalAlarmInfo = ` (${language.WARNING})`;

      return `${language.thingAlarms[alarmType as ThingAlarmType]}${additionalAlarmInfo} @ ${formatDate(
        alarm.occurred,
        "YYYY-MM-DD HH:mm",
        tz
      )}`;
    })
    .join(", ");
  return `${language.thingStatus[ThingStatus.HasAlarm]}: ${alarmSpecifics}`;
};
