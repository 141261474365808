import { LoadingStatus } from "constants/constants";
import { hasData, Loadable } from "models/Loading";
import { SignedInstallationStatsDataSource } from "models/widgets/common/value";
import { RootStore } from "stores/RootStore";
import language from "translations/language";
import { CategorisedDataSource, CategorisedValues } from "../interfaces";

export class SignedInstallationStatsCategoryDataAdapter
  implements CategorisedDataSource<SignedInstallationStatsDataSource>
{
  constructor(private stores: RootStore) {}

  getData(config: SignedInstallationStatsDataSource): Loadable<CategorisedValues> {
    const stats = this.stores.signedInstallationStatsStore.get(config.organizationId);
    if (!hasData(stats)) {
      return stats;
    }

    return {
      loadingStatus: LoadingStatus.Loaded,
      units: "days",
      total: {
        value: stats.totalCompletedInstallations,
        label: language.signedInstallations.TOTAL_COMPLETED_INSTALLATIONS,
      },
      categories: {
        customerCreatedToComplete: {
          value: stats.customerCreatedToComplete,
          label: language.signedInstallations.CUSTOMER_CREATED_TO_COMPLETE,
        },
        customerCreatedToFirstSuggestedInstallation: {
          value: stats.customerCreatedToFirstSuggestedInstallation,
          label: language.signedInstallations.CUSTOMER_CREATED_TO_FIRST_SUGGESTED_INSTALLATION,
        },
        schedulingInstallation: {
          value: stats.schedulingInstallation,
          label: language.signedInstallations.SCHEDULING_INSTALLATION,
        },
        timeReviewingImages: {
          value: stats.timeReviewingImages,
          label: language.signedInstallations.REVIEWING_IMAGES,
        },
      },
    };
  }

  async loadData(config: SignedInstallationStatsDataSource) {
    await this.stores.signedInstallationStatsStore.load(config.organizationId);
  }
}
