import { CAPTION_DATA_REFRESH_TIMEOUT } from "constants/captionDataConstants";
import { DEFAULT_REFRESH_TIMEOUT, LoadingStatus } from "constants/constants";
import {
  FAIRTRAIL_INCIDENTS_ROUTE,
  FAIRTRAIL_REBORN_INCIDENTS_ROUTE,
  FAIRTRAIL_REBORN_STACK_ROUTE,
  FAIRTRAIL_STACK_ROUTE,
} from "constants/fairtrailRoutes";
import { IncidentAllTypes } from "constants/widgetConstants";
import { Loadable, hasData } from "models/Loading";
import { MultipleAlarmsDataSource } from "models/widgets/TableWidget";
import { RootStore, rootStore } from "stores/RootStore";
import language from "translations/language";
import { TableValues, TableValuesDataSource } from "../interfaces";
import { AlarmBellIcon } from "components/icons";
import { formatDate } from "utils/datetimeUtils";
import { Incident, IncidentStatus } from "models/IncidentStats";

export class AlarmStatsMultipleValueAdapter implements TableValuesDataSource<MultipleAlarmsDataSource> {
  refreshTimeout: number | undefined;

  constructor(private stores: RootStore) {
    /* If deptId is used, refreshes every CAPTION_DATA_REFRESH_TIMEOUT, otherwise we rely on the incoming websocket messages */
    this.refreshTimeout = this.stores.configStore.deptId ? CAPTION_DATA_REFRESH_TIMEOUT : DEFAULT_REFRESH_TIMEOUT;
  }

  getData(config: MultipleAlarmsDataSource): Loadable<TableValues> {
    const useFairtrailRebornLinks = this.stores.configStore.useFairtrailRebornLinks;

    const alarmStats = rootStore.incidentStore.loadedIncidents;

    if (!alarmStats.every(hasData<Incident>)) {
      return { loadingStatus: LoadingStatus.Loading };
    }

    const rows = alarmStats.map((incident) => {
      // todo: there should be a get method in the store?
      const stack = rootStore.stackStore.allStacks.find((stack) => {
        return stack.things.find((thing) => {
          return thing.thingId === incident.thing.thingId;
        });
      });

      return {
        loadingStatus: LoadingStatus.Loaded,
        columns: [
          {
            label: formatDate(incident.created),
          },
          {
            // @ts-ignore need to fix Incident model
            label: language.incidentAllTypes[incident.incidentType as IncidentAllTypes],
          },
          {
            label: stack?.name ?? language.tableWidget.UNKNOWN_ADDRESS,
            href: stack
              ? useFairtrailRebornLinks
                ? FAIRTRAIL_REBORN_STACK_ROUTE.replace(":stackId", stack.stackId)
                : FAIRTRAIL_STACK_ROUTE.replace(":stackId", stack.stackId)
              : undefined,
          },
        ],
      };
    });

    return {
      loadingStatus: LoadingStatus.Loaded,
      rows,
      title: language.tableWidget.ALARM_TABLE_TITLE,
      icon: <AlarmBellIcon />,
      showMoreLink: useFairtrailRebornLinks ? FAIRTRAIL_REBORN_INCIDENTS_ROUTE : FAIRTRAIL_INCIDENTS_ROUTE,
    };
  }

  async loadData(config: MultipleAlarmsDataSource) {
    await this.stores.stackStore.loadAllStacks();
    await this.stores.incidentStore.loadIncidents({
      status: [IncidentStatus.Registered, IncidentStatus.InProgress, IncidentStatus.Contacted],
      incidentType: [
        IncidentAllTypes.LargeWaterVolumeAlarm,
        IncidentAllTypes.LargeWaterFlowAlarm,
        IncidentAllTypes.LeakAlarm,
      ].join(","),
    });
  }
}
