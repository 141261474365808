import styled from "@emotion/styled/macro";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { useFullscreen } from "react-use";
import { ThemeProvider } from "theme/provider";
import Routes from "./Routes";
import { rootStore, StoreProvider, useStore } from "./stores/RootStore";
import GlobalStyles from "./styles/global";
import HighchartsStyles from "./styles/highchart";

/*
 * This is the only css imports in the entire project
 * Everything else must be done with emotion and its global styles
 */
import "react-datepicker/dist/react-datepicker.css";
import "./styles/font-faces.css";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

const FullScreenableApp = observer(({ children, ...props }: any) => {
  const { appStore } = useStore();

  const ref = useRef(null);
  useFullscreen(ref, appStore.isFullScreen, { onClose: () => appStore.exitFullScreen() });

  return (
    <StyledAppWrapper {...props} ref={ref} fullscreen={appStore.isFullScreen}>
      {children}
    </StyledAppWrapper>
  );
});

function App() {
  return (
    <StoreProvider store={rootStore}>
      <InnerApp />
    </StoreProvider>
  );
}

export default App;

const InnerApp = observer(() => {
  const { themeStore } = useStore();
  const { theme: currentTheme } = themeStore.themeObject;

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyles />
      <HighchartsStyles />
      <FullScreenableApp>
        <Routes />
      </FullScreenableApp>
    </ThemeProvider>
  );
});

// prettier-ignore
const StyledAppWrapper = styled.div<{ fullscreen: boolean}>(({ theme, fullscreen }) => `
  ${theme.color.bg.neutral}
  ${theme.spacing.p(4)}
  ${fullscreen && `overflow-y: scroll;`}
`);
