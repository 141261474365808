import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import { observer } from "mobx-react-lite";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import language from "translations/language";
import { fromISOString, toISOString } from "utils/datetimeUtils";
import { Select } from "components/common";
import { TimeInterval, TimeRangeType } from "constants/widgetConstants";
import { DateRangeOrInterval } from "models/widgets/ChartWidget";

type Option = { key: string; value: string };

interface Props {
  onChange?: (value: DateRangeOrInterval) => void;
  value: DateRangeOrInterval;
  options: Option[];
}

const DateRangePicker = ({ options, onChange, value }: Props) => {
  const { interval, dateRange } = value || ({} as DateRangeOrInterval);
  const { to, from } = dateRange ?? {};

  const intervalOptions = useMemo(
    () => [
      ...options.map((option) => ({ value: option.key, label: option.value })),
      { value: "custom", label: language.timeIntervals.custom },
    ],
    [options]
  );

  const [intervalValue, setTimeIntervalValue] = useState<DateRangeOrInterval["interval"] | "custom">(
    interval === null ? "custom" : interval || TimeInterval.SevenDays
  );
  const weekAgo = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
  const [fromValue, setFromValue] = useState<Date | null>(fromISOString(from) || weekAgo);

  const today = new Date();
  const [toValue, setToValue] = useState<Date | null>(fromISOString(to) || today);

  const selectedIntervalOption = useMemo(() => {
    if (intervalValue === "custom") return intervalOptions.find((option) => option.value === "custom");
    return intervalOptions.find((option) => option.value === intervalValue);
  }, [intervalValue, intervalOptions]);

  useEffect(() => {
    const updateParentState = () => {
      onChange?.({
        ...value,
        interval: intervalValue === "custom" ? null : intervalValue,
        type: intervalValue === "custom" ? TimeRangeType.DateRange : TimeRangeType.TimeInterval,
        dateRange: {
          from: toISOString(fromValue),
          to: toISOString(toValue),
        },
      });
    };

    updateParentState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalValue, fromValue, toValue]);

  const handleIntervalChange = (selected: any) => {
    selected?.value && setTimeIntervalValue(selected.value as TimeInterval | "custom" | null);
  };

  const handleFromChange: ReactDatePickerProps<string>["onChange"] = (date) => {
    date && setFromValue(date);
  };

  const handleToChange: ReactDatePickerProps<string>["onChange"] = (date) => {
    date && setToValue(date);
  };

  const isDatesDisabled = useMemo(() => {
    return intervalValue !== "custom";
  }, [intervalValue]);

  return (
    <S.DateRangeRow>
      <S.DateRangeCell>
        <Select options={intervalOptions} onChange={handleIntervalChange} value={selectedIntervalOption} />
      </S.DateRangeCell>
      <S.DateRangeCell disabled={isDatesDisabled}>
        <label htmlFor="date-from">{language.widgets.settings.DAY_RANGE_FROM}</label>
        <S.DatePicker
          id="date-from"
          shouldCloseOnSelect={true}
          disabled={isDatesDisabled}
          selected={fromValue}
          onChange={handleFromChange}
          showTimeSelect
          timeFormat="HH:mm"
          dateFormat="MM/dd/yyyy HH:mm"
        />
      </S.DateRangeCell>
      <S.DateRangeCell disabled={isDatesDisabled}>
        <label htmlFor="date-from">{language.widgets.settings.DAY_RANGE_TO}</label>
        <S.DatePicker
          id="date-to"
          disabled={isDatesDisabled}
          selected={toValue}
          onChange={handleToChange}
          showTimeSelect
          timeFormat="HH:mm"
          dateFormat="MM/dd/yyyy HH:mm"
        />
      </S.DateRangeCell>
    </S.DateRangeRow>
  );
};

export default observer(DateRangePicker);

//prettier-ignore
const S: Record<string, any> = {
  DateRangeRow: styled.div(({ theme }) => `
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    gap: ${theme.spacing(8)};

    ${theme.typography.body1}

    label {
      font-weight: bold;
      ${theme.spacing.mr(2)}
    }
  `),

  DateRangeCell: styled.div<{disabled: boolean}>(({ disabled })=>`
    display: flex;
    align-items: center;
    ${disabled && `opacity: 0.5;` }
  `),

  DatePicker: styled(DatePicker)(({ theme }) => `
    ${theme.typography.body2};
    ${theme.spacing.p(2)}
    max-height: ${theme.spacing(10)};

    border: 1px solid ${theme.color.neutral700};
    border-radius: ${theme.borderRadius.md};
    width: 100%;
    color: currentColor;
    background-color: transparent;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  `),
};
