import { FairtrailHttpClient } from "../FairtrailHttpClient";
import { KnownEventType, TimeLineEventCollection, Timeline, toTimeline } from "../../models/Timeline";
import dayjs from "dayjs";
import { TimelineKey } from "stores/TimelineStore";

class TimeLineAPIClient extends FairtrailHttpClient {
  async getTimelineEvents<T extends KnownEventType>({
    thingId,
    eventType,
    eventSubType,
    dateFrom,
    moreInfo,
  }: TimelineKey): Promise<TimeLineEventCollection<T>> {
    const { data } = await this.get({
      path: "timelines",
      authenticated: true,
      query: {
        thingId: thingId,
        size: 1000,
        page: 0,
        dateFrom: dateFrom.toISOString(),
        eventType,
      },
    });

    const events = data.items.map(toTimeline).filter((t: Timeline) => {
      const isCorrectSubType = eventSubType.includes(t.message);
      const hasCorrectMoreInfo = moreInfo ? t.moreInfo?.level === moreInfo.level : true;

      return isCorrectSubType && hasCorrectMoreInfo;
    });

    return {
      events: events,
      earliestDate: events[0]?.created ?? dayjs().toDate(), // For empty sets we might as well have now as the date
    };
  }
}

export default TimeLineAPIClient;
