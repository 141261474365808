import DashboardMenu from "components/dashboard/DashboardMenu";
import { observer } from "mobx-react-lite";
import { useLocation, useParams } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import { EDIT_DASHBOARD_ROUTE } from "../constants/routes";
import { getParamRoute } from "../utils/routeUtils";

const DashboardPage = () => {
  const location = useLocation();
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const isEditable = location.pathname === getParamRoute(EDIT_DASHBOARD_ROUTE, { dashboardId: dashboardId! });

  return (
    <>
      <DashboardMenu dashboardId={dashboardId!} />

      <Dashboard dashboardId={dashboardId!} isEditable={isEditable} />
    </>
  );
};

export default observer(DashboardPage);
