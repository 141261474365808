import { CAPTION_DATA_REFRESH_TIMEOUT } from "constants/captionDataConstants";
import { ALL_METRICS, LoadingStatus } from "constants/constants";
import {
  DateRangeConfig,
  TimeChartSeries,
  TimeChartValues,
  TimeChartValuesDataSource,
} from "datasources/timeChartValuesData/interfaces";
import { allMapItemsHaveData, getErrorsFromMap, hasData, Loadable } from "models/Loading";
import { Thing } from "models/Thing";
import { Timeseries } from "models/Timeseries";
import { AggregationType, ThingsTimeSeriesDataSource, TimeseriesType } from "models/widgets/ChartWidget";
import { RootStore, rootStore } from "stores/RootStore";
import language from "translations/language";
import { zip } from "utils/arrayUtils";

/* For this we need to know when to load the data for as well */
type Source = ThingsTimeSeriesDataSource & DateRangeConfig;

export class ThingTimeSeriesTimeChartValuesAdapter implements TimeChartValuesDataSource<Source> {
  refreshTimeout: number | undefined;

  constructor(private stores: RootStore) {
    /* If deptId is used, refreshes every CAPTION_DATA_REFRESH_TIMEOUT, otherwise we rely on the incoming websocket messages */
    this.refreshTimeout = this.stores.configStore.deptId ? CAPTION_DATA_REFRESH_TIMEOUT : undefined;
  }

  getData(config: Source): Loadable<TimeChartValues> {
    const data = this.stores.timeseriesStore.getTimeseriesForThings(
      config.thingIds,
      config.dateRangeOrInterval,
      config.metrics,
      config.aggregationType || AggregationType.Aggregated
    );
    const errors = getErrorsFromMap(data);

    if (errors.length > 0) {
      return {
        loadingStatus: LoadingStatus.Error,
        error: errors.join(", "),
      };
    }

    if (!allMapItemsHaveData<Timeseries>(data)) {
      return {
        loadingStatus: LoadingStatus.Loading,
      };
    }

    const series = this.seriesFromData(data, config);

    return {
      series: series,
      loadingStatus: LoadingStatus.Loaded,
    };
  }

  async loadData(config: Source) {
    await this.stores.thingStore.loadMany(config.thingIds);
    await this.stores.timeseriesStore.loadMany(
      config.thingIds.map((thingId) => ({
        thingId,
        dateRangeOrInterval: config.dateRangeOrInterval,
        metrics: config.metrics,
        aggregationType: config.aggregationType || AggregationType.Aggregated,
      }))
    );
  }

  private seriesFromData(data: Record<string, Timeseries>, config: Source): TimeChartSeries[] {
    return Object.entries(data).flatMap(([thingId, timeseries]) => {
      let metrics = config.metrics;

      if (metrics.includes(ALL_METRICS)) {
        metrics = Object.keys(timeseries.series).sort();
      }

      return metrics.flatMap((metric) => {
        if (!timeseries.series[metric as TimeseriesType]) {
          console.error(`Tried to load unsupported metric ${metric}`);
          return [];
        }

        return [
          {
            values: zip(timeseries.times, timeseries.series[metric as TimeseriesType] ?? []),
            label: this.buildLabel(thingId, metric as TimeseriesType),
            plotType: "continuous",
            valueType: "number",
          },
        ];
      });
    });
  }

  private buildLabel(thingId: string, property: TimeseriesType) {
    const thing = this.stores.thingStore.get(thingId);
    const thingWithData = hasData<Thing>(thing) ? thing : undefined;

    const propertyFromDetectedCapability = thingWithData?.productType?.detectedCapabilities?.find(
      (capability) => capability.name === property
    )?.label;

    const propertyName = propertyFromDetectedCapability ?? language.widgets.timeseriesTypes[property] ?? property;

    if (rootStore.configStore.showOnlyProperties) {
      return propertyName;
    }

    const thingName = thingWithData ? thingWithData.name : language.UNKNOWN_DEVICE;
    const name = thingName ?? language.UNKNOWN_DEVICE;

    return `${name} (${propertyName})`;
  }
}
