import styled from "@emotion/styled/macro";
import LastUpdated from "components/common/LastUpdated";
import WidgetMessage from "components/WidgetMessage";
import { LoadingState } from "components/widgets/loading/LoadingState";
import { LoadingStatus } from "constants/constants";
import { FontSize, FontSizeMultiplier } from "constants/themeConstants";
import { useSingleValueFromDataSource } from "datasources/hooks";
import { observer } from "mobx-react-lite";
import { ValueWidgetViewModel } from "models/widgets/ValueWidget";
import { fallbackResponsiveUnit, responsiveUnit } from "utils/styleUtils";
import language from "../../translations/language";
import ConfigureMessage from "../WidgetConfigureMessage";

interface Props {
  widget: ValueWidgetViewModel;
  timeZone?: string;
}

const ValueWidget = ({ widget, timeZone }: Props) => {
  const data = useSingleValueFromDataSource(widget.settings.dataSource);

  if (!widget.settings.dataSource) {
    return <ConfigureMessage widgetName={language.widgets.VALUE} />;
  }

  if (data.loadingStatus === LoadingStatus.Loading) {
    return <LoadingState />;
  }

  if (data.loadingStatus === LoadingStatus.Error) {
    return <WidgetMessage>{language.errors.ERROR_LOADING_DATA}</WidgetMessage>;
  }

  if (data.value === undefined) {
    return <WidgetMessage>{language.errors.NO_DATA_TO_DISPLAY}</WidgetMessage>;
  }

  const text = data.units ? `${data.value} ${data.units}` : `${data.value}`;

  return (
    <S.Container>
      <S.Value>{text}</S.Value>
      {data.updatedAt && <LastUpdated datetime={data.updatedAt} timeZone={timeZone} />}
    </S.Container>
  );
};

export default observer(ValueWidget);

const S = {
  Container: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(min(var(--widget-width), var(--widget-height)) * 0.8 * 1px);
    height: calc(min(var(--widget-width), var(--widget-height)) * 0.8 * 1px);
    aspect-ratio: 1/1;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${"container-type"}: size;
    @supports (container-type: size) {
      width: 80cqmin;
    }

    p {
      margin: 0;
      word-wrap: break-word;
    }
  `,
  Value: styled.p`
    font-weight: bold;
    font-size: ${fallbackResponsiveUnit(FontSize.Large)};
    line-height: 1;

    @supports (container-type: size) {
      font-size: ${responsiveUnit(FontSize.ExtraLarge, FontSizeMultiplier.Large)};
    }
  `,
};
