import { hasData } from "models/Loading";
import { mergeTimelineEventCollections, Timeline, TimeLineEventCollection } from "models/Timeline";
import TimelineAPIClient from "services/api/TimelineAPIClient";
import { SimpleStore } from "./SimpleStore";

export type TimelineKey = {
  eventType: string;
  eventSubType: string[];
  thingId: string;
  dateFrom: Date;
  moreInfo?: {
    level?: string;
  };
};

export class TimelineStore extends SimpleStore<TimelineKey, TimeLineEventCollection, "key"> {
  private client = new TimelineAPIClient();

  protected async fetchFromClientForKey(timelineKey: TimelineKey) {
    return await this.client.getTimelineEvents(timelineKey);
  }

  protected getRegistryIndex({ eventType, thingId, eventSubType = [""], moreInfo = {} }: TimelineKey) {
    // dateFrom is deliberately not included in the index as we want to be able to
    // update the timeline with new data as it comes in. So we keep data for all date
    // in one index then build it up.
    const metadataJson = JSON.stringify({
      eventSubType,
      moreInfo,
    });

    return `${eventType}-${thingId}-${metadataJson}`;
  }

  public readonly keyFieldName = "key" as const;

  timelineUpdated(timeline: Timeline) {
    const { thingId, eventType, created } = timeline;
    const key: TimelineKey = {
      eventType,
      thingId,
      dateFrom: created,
      eventSubType: [timeline.message],
    };
    this.updateEntry(key, { events: [timeline], earliestDate: created });
  }

  protected updateEntry(key: TimelineKey, data: TimeLineEventCollection) {
    const existingTimeline = this.get(key);

    // If there's no existing data we just use the normal updateEntry. If there is new data
    // then we want to merge the two sets of data together as newer or later data might have been fetched.
    if (!hasData(existingTimeline)) {
      super.updateEntry(key, data);
    } else {
      const mergedTimeline = mergeTimelineEventCollections(existingTimeline, data);
      super.updateEntry(key, mergedTimeline);
    }
  }
}
