import { DataInterval } from "constants/constants";
import { Thing, toThing } from "models/Thing";
import { toTimeseries } from "models/Timeseries";
import { FairtrailHttpClient } from "services/FairtrailHttpClient";
import { rootStore } from "stores/RootStore";

const CAPTION_DATA_TIMEOUT = 60000;

type GetThingsQuery = {
  page?: number;
  size?: number;
  q?: string;
  thingIds?: string[];
  thresholdMetric?: string;
  thresholdValue?: number;
  thresholdOperator?: string;
  category?: string;
  expandAll?: boolean;
};

export class VisualisationsProxyAPIClient extends FairtrailHttpClient {
  async getThings({
    page = 0,
    size = 100,
    q,
    thingIds,
    thresholdMetric,
    thresholdValue,
    thresholdOperator,
    category,
    expandAll,
  }: GetThingsQuery): Promise<{
    things: Thing[];
    totalCount: number;
  }> {
    if (rootStore.configStore.deptId) {
      this.setTimeout(CAPTION_DATA_TIMEOUT);
    }

    const response = await this.get({
      path: `/visualisations/proxy/things`,
      query: {
        q,
        size,
        page,
        thingIds,
        deptId: rootStore.configStore.deptId,
        thresholdMetric,
        thresholdValue,
        thresholdOperator,
        category,
        expandAll,
      },
      authenticated: true,
    });

    return {
      things: response.data.map((thing: any) => toThing(thing)),
      totalCount: response.headers["totalcount"],
    };
  }

  async getThingById(thingId: string) {
    if (rootStore.configStore.deptId) {
      this.setTimeout(CAPTION_DATA_TIMEOUT);
    }

    const response = await this.get({
      path: `/visualisations/proxy/things/${thingId}`,
      authenticated: true,
      query: {
        deptId: rootStore.configStore.deptId,
      },
    });

    return toThing(response.data);
  }

  /**
   * @param thingId The thing id
   * @param from The start of the time range, in Unix time (seconds since 1970-01-01T00:00:00Z)
   * @param to The end of the time range, in Unix time (seconds since 1970-01-01T00:00:00Z)
   * @param interval The interval of how often to aggregate data poinrts, e.g. once every 1h, 2h, 1d, 1w, 1m, 1y
   * @param metrics only used for caption data atm, could be implemented in fairtrail in the future
   */
  async aggregationQuery(thingId: string, from: number, to: number, interval: DataInterval, metrics: string[]) {
    if (rootStore.configStore.deptId) {
      this.setTimeout(CAPTION_DATA_TIMEOUT);
    }

    const response = await this.get({
      path: `/visualisations/proxy/timeseries/query/${thingId}/aggregate`,
      query: { from, to, interval, metrics, deptId: rootStore.configStore.deptId },
      authenticated: true,
    });

    return toTimeseries(response.data);
  }

  /**
   * @param thingId The thing id
   * @param from The start of the time range, in Unix time (seconds since 1970-01-01T00:00:00Z)
   * @param to The end of the time range, in Unix time (seconds since 1970-01-01T00:00:00Z)
   * @param interval The interval of how often to aggregate data points, e.g. once every 1h, 2h, 1d, 1w, 1m, 1y
   * @param metrics only used for caption data atm, could be implemented in fairtrail in the future
   */
  async nonAggregationQuery(thingId: string, from: number, to: number, interval: DataInterval, metrics: string[]) {
    if (rootStore.configStore.deptId) {
      this.setTimeout(CAPTION_DATA_TIMEOUT);
    }

    const response = await this.get({
      path: `/visualisations/proxy/timeseries/query/${thingId}`,
      query: {
        from,
        to,
        ...(interval !== DataInterval.NoInterval ? { interval } : {}),
        metrics,
        deptId: rootStore.configStore.deptId,
      },
      authenticated: true,
    });

    return toTimeseries(response.data);
  }
}
