import { useModal } from "@ebay/nice-modal-react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { Button } from "components/common";
import { AddCircleIcon, EyeIcon, MaximizeIcon, MinimizeIcon, SettingsIcon, TuningIcon } from "components/icons";
import DashboardSettingsModal from "components/modals/DashboardSettingsModal";
import { WidgetActivity } from "constants/permissionConstants";
import { DASHBOARD_ROUTE, DASHBOARD_VIEW_ROUTE, EDIT_DASHBOARD_ROUTE } from "constants/routes";
import { useIsVisible } from "hooks/useIsVisible";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStore } from "stores/RootStore";
import language from "translations/language";
import { canUpdateDashboard, hasActivity } from "utils/permissionUtils";
import AddWidgetSelector from "./AddWidgetSelector";
import { DashboardTheme } from "constants/dashboardConstants";

type Props = {
  dashboardId: string;
};

const DashboardMenu = ({ dashboardId }: Props) => {
  const { name: currentThemeName } = useTheme();
  const { dashboardStore, appStore } = useStore();
  const isVisible = useIsVisible();

  const [isWidgetSelectorVisible, setIsWidgetSelectorVisible] = useState(false);

  const modal = useModal(DashboardSettingsModal);

  const dashboard = dashboardStore.getDashboardById(dashboardId);

  const addWidgetButtonProps = currentThemeName === DashboardTheme.Polygon ? { secondary: true } : { success: true };

  if (!dashboard) {
    return <S.HeaderContainer />;
  }

  const openDashboardSettings = () => {
    modal.show({ dashboardId });
  };

  return (
    <>
      <AddWidgetSelector
        isOpen={isWidgetSelectorVisible}
        dashboardId={dashboardId}
        closeSidebar={() => setIsWidgetSelectorVisible(false)}
        openSidebar={() => setIsWidgetSelectorVisible(true)}
      />
      <S.HeaderContainer>
        <S.Row>
          <S.Actions>
            {hasActivity(WidgetActivity.CreateWidget) &&
              canUpdateDashboard(dashboard) &&
              isVisible([EDIT_DASHBOARD_ROUTE]) &&
              !isWidgetSelectorVisible && (
                <Button
                  {...addWidgetButtonProps}
                  rounded
                  onClick={() => setIsWidgetSelectorVisible((current) => !current)}
                >
                  <S.ButtonIconContainer>
                    <AddCircleIcon />
                  </S.ButtonIconContainer>
                  {language.dashboards.ADD_WIDGET}
                </Button>
              )}
            {canUpdateDashboard(dashboard) && isVisible([EDIT_DASHBOARD_ROUTE]) && (
              <Button ghost onClick={openDashboardSettings}>
                <S.ButtonIconContainer>
                  <SettingsIcon />
                </S.ButtonIconContainer>
                {language.SETTINGS}
              </Button>
            )}
            {canUpdateDashboard(dashboard) && isVisible([DASHBOARD_ROUTE]) && (
              <Button rounded ghost link to={EDIT_DASHBOARD_ROUTE.replace(":dashboardId", dashboardId)}>
                <S.ButtonIconContainer>
                  <TuningIcon />
                </S.ButtonIconContainer>
                {language.EDIT}
              </Button>
            )}
            {(isVisible([DASHBOARD_ROUTE]) || isVisible([DASHBOARD_VIEW_ROUTE])) && (
              <Button rounded ghost onClick={() => appStore.toggleFullScreen()}>
                <S.ButtonIconContainer>
                  {appStore.isFullScreen ? <MinimizeIcon /> : <MaximizeIcon />}
                </S.ButtonIconContainer>
                {language.FULLSCREEN}
              </Button>
            )}
            {isVisible([EDIT_DASHBOARD_ROUTE]) && (
              <Button rounded ghost link to={DASHBOARD_ROUTE.replace(":dashboardId", dashboardId)}>
                <S.ButtonIconContainer>
                  <EyeIcon />
                </S.ButtonIconContainer>
                {language.VIEW}
              </Button>
            )}
          </S.Actions>
        </S.Row>
      </S.HeaderContainer>
    </>
  );
};

export default observer(DashboardMenu);

// prettier-ignore
const S = {
  HeaderContainer: styled.div(({ theme }) => `
    ${theme.spacing.pb(2)}
    display: flex;
    justify-content: flex-end;
  `),
  
  Row: styled.div(({ theme }) => `
    ${theme.spacing.h(10)}
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  `),
  
  ButtonIconContainer: styled.div(({ theme }) => `
    display: inline-block;
    ${theme.spacing.w(6)}
    ${theme.spacing.h(6)}
    ${theme.spacing.mr(2)}
    
  `),
  
  Actions: styled.div(({ theme }) => `
    display: flex;
    gap: ${theme.spacing(4)};
    ${theme.spacing.px(3)}
  `),
  
  SelectContainer: styled.div(({ theme }) => `
    min-width: ${theme.spacing(50)};
  `),
  
  SelectContentWrapper: styled.div(({ theme }) => `
    display: flex; 
    align-items: center; 
    gap: ${theme.spacing(2)};
  `),
  
  SelectIconContainer: styled.div(({ theme }) => `
    width: calc(${theme.spacing(1)} * 4.5);
    height: calc(${theme.spacing(1)} * 4.5);
    flex-shrink: 0;
    margin-top: -1px;
  `),
  
  SelectText: styled.p`
    white-space: nowrap;
  `,
  
};
