import { makeAutoObservable, runInAction } from "mobx";
import { Bookmark, BookmarkId } from "models/Bookmark";
import { AccountsAPIClient } from "services/api/AccountsAPIClient";
import { LoadingStatus } from "../constants/constants";

export class BookmarkStore {
  private accountsClient = new AccountsAPIClient();

  private registry = new Map<BookmarkId, Bookmark>();

  private totalCount: number = 0;

  private loadingStatus: LoadingStatus = LoadingStatus.Loading;

  constructor() {
    makeAutoObservable(this);
  }

  async loadTop3(): Promise<void> {
    this.loadingStatus = LoadingStatus.Loading;

    const result = await this.accountsClient.getBookmarks(0, 3);

    this.totalCount = result.count;
    const bookmarks: Bookmark[] = result.items;

    runInAction(() => bookmarks.forEach((bookmark: Bookmark) => this.registry.set(bookmark.bookmarkId, bookmark)));

    this.loadingStatus = LoadingStatus.Loaded;
  }

  get bookmarks() {
    return Array.from(this.registry.values());
  }

  get count() {
    return this.totalCount;
  }

  get loadingState() {
    return this.loadingStatus;
  }
}
