import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useStore } from "stores/RootStore";
import { SelectSetting } from "./SelectSetting";

interface Props {
  value: string | string[] | null;
  settingsKey: string;
  onChange: (key: string, value: any) => void;
  multiple?: boolean;
}

const OrganizationPicker = ({ value, onChange, settingsKey, multiple }: Props) => {
  const { organizationStore } = useStore();

  useEffect(() => {
    organizationStore.loadAll();
  }, [organizationStore]);

  const options = useMemo(
    () =>
      organizationStore.organizations
        .map((organization) => ({ key: organization.organizationId as string, value: organization.name }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [organizationStore.organizations]
  );

  return (
    <>
      <SelectSetting
        onChange={onChange}
        settingsKey={settingsKey}
        value={value}
        multiple={multiple}
        options={options}
      />
    </>
  );
};

export default observer(OrganizationPicker);
