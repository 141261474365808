/** @jsxImportSource @emotion/react */
import { MouseEventHandler, PropsWithChildren, useMemo } from "react";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled/macro";
import FairtrailLink from "components/FairtrailLink";
import type { SpacingIndex } from "theme/types";

// TODO: Button props API should be like ant design's Button:
// Reference: https://ant.design/components/button#api

type ButtonProps = Partial<{
  full: boolean;
  size: "sm" | "md" | "lg";
  type: "default" | "text";
  link: boolean;
  to: string;
  href?: string;
  disabled: boolean;
  rounded: boolean;
  secondary: boolean;
  success: boolean;
  danger: boolean;
  ghost: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  // TODO: implement the remaining props
  // warning?: boolean; ?? not sure if we need this
  // loading?: boolean;
}>;

export const Button = ({
  full,
  type = "default",
  size = "md",
  link,
  ghost,
  to,
  href,
  disabled,
  rounded,
  danger,
  secondary,
  success,
  onClick,
  children,
}: PropsWithChildren<ButtonProps>) => {
  const theme = useTheme();
  const { color } = theme;

  let key = type;
  if (ghost) key += "_ghost";
  if (secondary) key += "_secondary";
  if (success) key += "_success";
  if (danger) key += "_danger";

  // TODO: must be described based on the theme
  type ColorsDescriptor = {
    bg: string;
    bgHover?: string;
    color: string;
    colorHover?: string;
    border?: string;
  };

  // This map must support theme overrides
  // Now it looks very theme specific and might be better described in the theme itself
  const colorMap = useMemo(
    () =>
      new Map<string, ColorsDescriptor>([
        ["default", { bg: color.neutral700, color: color.neutral700n }],
        ["default_success", { bg: color.success, color: color.neutral100 }],
        ["default_danger", { bg: color.success, color: color.neutral100 }],
        [
          "default_ghost",
          {
            bg: "transparent",
            bgHover: color.neutral700,
            color: color.neutral700,
            colorHover: color.neutral100,
            border: color.neutral700,
          },
        ],
        [
          "default_ghost_secondary",
          {
            bg: "transparent",
            bgHover: color.secondary,
            color: color.secondary,
            colorHover: color.neutral100,
            border: color.secondary,
          },
        ],
        [
          "default_ghost_success",
          {
            bg: "transparent",
            bgHover: color.success,
            color: color.success,
            colorHover: color.neutral100,
            border: color.success,
          },
        ],
        ["default_ghost_danger", { bg: "transparent", color: color.error, border: color.error }],
        ["text", { bg: "transparent", color: color.neutral700 }],
        ["text_success", { bg: "transparent", color: color.success }],
        ["text_danger", { bg: "transparent", color: color.error }],
        ["text_ghost", { bg: "transparent", color: color.primary400 }],
      ]),
    [color]
  );

  // Theme continued for the button sizes
  const sizeMap = useMemo(
    () =>
      new Map<string, SpacingIndex>([
        ["default", 9],
        ["sm", 7],
        ["md", 9],
        ["lg", 10],
      ]),
    []
  );

  /******************************* 
    Composable button CSS parts 
  *******************************/
  const baseCSS = useMemo(() => {
    return css`
      display: inline-flex;
      align-items: center;

      width: ${full ? "100%" : "fit-content"};

      border: none;
      cursor: pointer;
      ${theme.color.text.neutral700n}
      ${theme.typography.body2}
      ${theme.spacing.px(3)}
      font-weight: bold;

      border-radius: ${theme.borderRadius.md};

      transition: all 0.2s ease;

      user-select: none;
    `;
  }, [theme, full]);

  const linkCSS = css`
    text-decoration: none;
  `;

  const sizeCSS = useMemo(() => {
    const index = sizeMap.get(size) || sizeMap.get("default") || 9;
    return css`
      height: ${theme.spacing(index)};
      ${size === "sm" ? theme.typography.body4 : theme.typography.body2}
      font-weight: bold;
    `;
  }, [size, sizeMap, theme]);

  const borderCSS = useMemo(() => {
    const color = colorMap.get(key)?.border;
    const width = "1px";

    return css`
      border: ${color ? `${width} solid ${color}` : "none"};

      &:hover {
        border: ${color ? `${width} solid ${color}` : "none"};
      }
    `;
  }, [key, colorMap]);

  const bgCSS = useMemo(() => {
    const base = css`
      background-color: ${colorMap.get(key)?.bg || theme.color.neutral700};
    `;
    const hover = colorMap.get(key)?.bgHover
      ? css`
          &:hover {
            background-color: ${colorMap.get(key)?.bgHover || theme.color.neutral700};
          }
        `
      : css`
          &:hover {
            opacity: 0.8;
          }
        `;
    return [base, hover];
  }, [key, colorMap, theme]);

  const colorCSS = useMemo(() => {
    return css`
      color: ${colorMap.get(key)?.color || theme.color.neutral700n};

      &:hover {
        color: ${colorMap.get(key)?.colorHover || colorMap.get(key)?.color || theme.color.neutral700n};
      }
    `;
  }, [key, colorMap, theme]);

  const disabledCSS = useMemo(() => {
    return (
      disabled &&
      (ghost
        ? css`
            opacity: 0.5;
            cursor: not-allowed;

            &:hover {
              opacity: 0.5;
              background-color: transparent;
              color: ${colorMap.get(key)?.color || theme.color.neutral700n};
            }
          `
        : css`
            opacity: 0.5;
            cursor: not-allowed;

            &:hover {
              opacity: 0.5;
            }
          `)
    );
  }, [disabled, ghost, colorMap, key, theme]);

  const debugCSS = useMemo(() => {
    return css`
      --color-map-key: ${key};
    `;
  }, [key]);

  /***********************************************************
    Actual button component
    All the css is built from the above composable CSS parts
  ************************************************************/
  if (link && to) {
    return (
      <FairtrailLink css={[baseCSS, linkCSS, sizeCSS, borderCSS, bgCSS, colorCSS, debugCSS]} to={to} href={href}>
        {children}
      </FairtrailLink>
    );
  }
  return (
    <button
      disabled={disabled}
      css={[baseCSS, sizeCSS, borderCSS, bgCSS, colorCSS, disabledCSS, debugCSS]}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

// prettier-ignore
export const ActionButton = styled.button(({ theme }) => `
  ${theme.color.bg.neutral700}
  ${theme.color.text.neutral700n}
  border: none;
  padding: 5px;
  height: 26px;
  border-radius: 4px;
  margin: 1px 0 0 0;
  cursor: pointer;
`);

// prettier-ignore
export const EditButton = styled.button(({ theme }) => `
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-left: 2px;
  margin-top: 2px;
  margin-right: 6px;
  border: none;
  ${theme.color.bg.neutral700}
  ${theme.color.text.neutral100}
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    ${theme.color.bg.neutral400}
    transition: all 0.3s ease;
  }
`);

// prettier-ignore
export const RemoveButton = styled(EditButton)(({ theme }) => `
  &:hover {
    ${theme.color.bg.error}
  }
`
);

// prettier-ignore
export const IconButton = styled.button(({ theme }) => `
  user-select: none;
  cursor: pointer;
  ${theme.typography.body1};
  ${theme.spacing.h(8)}
  ${theme.spacing.w(8)}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: none;
  padding: 0;
  background-color: transparent;
  ${theme.color.text.primary200}
  transition: all 0.3s ease;

  &:hover {
    ${theme.color.text.primary}
    background-color: transparent;
    transition: all 0.3s ease;
    animation-iteration-count: 1;
  }

  &:focus {
    box-shadow: none;
  }
`);
