import { observer } from "mobx-react-lite";
import { FormEvent, useState } from "react";
import * as Form from "components/common/form";
import { Input, Button, PageContainer, ContentContainer } from "components/common";
import { useStore } from "stores/RootStore";
import language from "translations/language";

const LoginPage = () => {
  const { authStore } = useStore();

  const [requestCodeStep, setRequestCodeStep] = useState(true);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const requestCode = async (e: FormEvent) => {
    e.preventDefault();
    await authStore.requestLoginCode(email);
    setRequestCodeStep(false);
  };

  const validateCode = async (e: FormEvent) => {
    e.preventDefault();
    await authStore.validateCodeSentToUser(code);
  };

  return (
    <PageContainer>
      <ContentContainer style={{ width: "40%", marginRight: "auto" }}>
        {requestCodeStep ? (
          <>
            <Form.Form onSubmit={requestCode}>
              <Form.Row>
                <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder={language.EMAIL} />
              </Form.Row>
              <Form.Row>
                <Button>{language.LOGIN}</Button>
              </Form.Row>
            </Form.Form>
          </>
        ) : (
          <>
            <Form.Form onSubmit={validateCode}>
              <Form.Row>
                <Input value={code} onChange={(e) => setCode(e.target.value)} placeholder={language.LOGIN_CODE} />
              </Form.Row>
              <Form.Row>
                <Button>{language.SEND}</Button>
              </Form.Row>
            </Form.Form>
          </>
        )}
      </ContentContainer>
    </PageContainer>
  );
};

export default observer(LoginPage);
