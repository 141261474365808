import { fallbackResponsiveUnit, responsiveUnit } from "../../../utils/styleUtils";
import { FontSize, FontSizeMultiplier } from "../../../constants/themeConstants";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";
import { GaugeWidgetViewModel } from "../../../models/widgets/GaugeWidget";
import { Theme } from "@emotion/react";
import { ThresholdColor } from "models/widgets/common/common";
import { AxisOptions } from "utils/gaugeWidgetUtils";
import { GaugeWidgetOptions } from "./GaugeWidget";

export interface GaugeWidgetProps {
  chartComponentRef: React.RefObject<HighchartsReact.RefObject>;
  widget: GaugeWidgetViewModel;
  data: {
    label: string;
    value: number;
    units?: string;
  };
  yAxisConfig: AxisOptions;
  theme: Theme;
}

export const ClassicGaugeWidge = ({ chartComponentRef, widget, data, yAxisConfig, theme }: GaugeWidgetProps) => {
  const thresholdsData = widget.settings.thresholds;

  const plotBands =
    !thresholdsData || !thresholdsData.length
      ? [
          {
            from: 0,
            to: 100,
            color: theme.color.primary200,
            thickness: 30,
          },
        ]
      : thresholdsData.map((threshold) => {
          return {
            from: threshold.range.min,
            to: threshold.range.max,
            color:
              threshold.color === ThresholdColor.Green
                ? theme.color.success
                : threshold.color === ThresholdColor.Yellow
                ? theme.color.warning
                : theme.color.error,
            thickness: 30,
          };
        });

  const dataLabelColor =
    plotBands.find((band) => band.from <= data.value && band.to >= data.value)?.color ?? theme.color.text;

  const classicGaugeOptions: GaugeWidgetOptions = {
    title: null,
    chart: {
      animation: { duration: 0 },
      type: "solidgauge",
    },
    yAxis: {
      minorTickWidth: 1.2,
      minorTickLength: 6,
      minorTickPosition: "inside",
      minorTickColor: "#000000",
      tickPixelInterval: 50,
      tickWidth: 2,
      tickPosition: "inside",
      tickLength: 10,
      tickColor: "#000000",
    },
    pane: {
      center: ["50%", "50%"],
      size: "90%",
      startAngle: -120,
      endAngle: 120,
      background: [
        {
          backgroundColor: "transparent",
          borderWidth: 2,
          innerRadius: "65%",
          outerRadius: "102%",
          shape: "arc",
        },
      ],
    },

    credits: { enabled: false },

    plotOptions: {
      gauge: {
        dataLabels: {
          enabled: true,
        },
        dial: {
          baseLength: "5%",
          baseWidth: 3,
          radius: "70%",
          rearLength: "10%",
          topWidth: 1,
          borderWidth: 0.7,
          borderColor: "#000000",
        },
        pivot: {
          radius: 6,
          borderWidth: 0.3,
          borderColor: "#000000",
        },
      },
    },

    tooltip: {
      enabled: false,
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            pane: {
              center: ["50%", "50%"],
              size: "90%",
            },
          },
        },
      ],
    },
  };

  const chartOptions = {
    ...classicGaugeOptions,
    series: [
      {
        type: "gauge",
        name: data.label,
        data: [Math.max(Math.min(data.value, yAxisConfig.threshold.range.max), yAxisConfig.threshold.range.min)],
        tooltip: {
          valueSuffix: data.units != null ? ` ${data.units}` : "",
        },
        dataLabels: {
          backgroundColor: "none",
          borderWidth: 0,
          borderColor: "none",
          fontSize: fallbackResponsiveUnit(FontSize.Medium),
          y: 120,
          color: dataLabelColor,
          style: {
            fontSize: responsiveUnit(FontSize.Base, FontSizeMultiplier.Small, FontSize.Medium),
            fontWeight: "900",
            textOutline: "none",
          },
          formatter: function () {
            return `${data.value} ${data.units ?? ""}`;
          },
        },
      },
    ],
    yAxis: {
      ...yAxisConfig,
      ...classicGaugeOptions.yAxis,
      min: thresholdsData ? yAxisConfig.threshold.range.min : 0,
      max: thresholdsData ? yAxisConfig.threshold.range.max : 100,
      title: {
        style: {
          fontWeight: "900",
          fontSize: responsiveUnit(FontSize.Smallest, FontSizeMultiplier.Small, FontSize.ExtraExtraSmall),
        },
      },
      labels: {
        step: 1,
        rotation: "auto",
        distance: "10",
        style: {
          fontSize: responsiveUnit(FontSize.ExtremelySmall, FontSizeMultiplier.ExtremelySmall, FontSize.ExtremelySmall),
          fontWeight: "900",
        },
      },
      plotBands: plotBands,
    },
    exporting: {
      enabled: false,
      title: widget.settings.title,
    },
    pane: {
      ...classicGaugeOptions.pane,
      background: [
        {
          ...(classicGaugeOptions.pane?.background?.[0] ?? {}),
          borderColor: "none",
        },
      ],
    },
    plotOptions: {
      ...classicGaugeOptions.plotOptions,
      gauge: {
        dial: {
          ...classicGaugeOptions.plotOptions?.gauge?.dial,
          backgroundColor: "#000000",
        },
        pivot: {
          ...classicGaugeOptions.plotOptions?.gauge?.pivot,
          backgroundColor: "#000000",
        },
      },
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      ref={chartComponentRef}
      containerProps={{ style: { height: "100%" } }}
    />
  );
};
