import { WidgetType } from "./widgetConstants";

export const SUPPORTED_CAPTION_DATA_WIDGETS = [
  WidgetType.ChartWidget,
  WidgetType.ClockWidget,
  WidgetType.GaugeWidget,
  WidgetType.TextWidget,
  WidgetType.ValueWidget,
  WidgetType.WeatherWidget,
  WidgetType.MapWidget,
  WidgetType.TableWidget,
];

export const CAPTION_DATA_REFRESH_TIMEOUT = 1000 * 60 * 2;
