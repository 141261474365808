import { VISUALISATION_SERVICE_HOST } from "config/config";
import { SignedInstallationStats } from "models/SignedInstallationStats";
import { FairtrailHttpClient } from "../FairtrailHttpClient";

export class SignedInstallationStatsAPIClient extends FairtrailHttpClient {
  constructor() {
    super(VISUALISATION_SERVICE_HOST);
  }

  async getData(organizationId: string): Promise<SignedInstallationStats> {
    const response = await this.get({
      path: `/visualisations/organization/${organizationId}/signed-installation-stats`,
      authenticated: true,
    });

    return response.data;
  }
}
