import { GeoTaggedDataPoint, MetaDataLine } from "datasources/geoTaggedData/interfaces";
import FairtrailLink from "components/FairtrailLink";
import { observer } from "mobx-react-lite";

type MetaDataProps = { point: Pick<GeoTaggedDataPoint, "title" | "metaData"> };

export const MetaData = observer(({ point }: MetaDataProps) => {
  return (
    <>
      <h2>{point.title}</h2>
      {Object.entries(point.metaData).map(([key, data]) => (
        <span key={key}>
          <h3>{data.title}</h3>
          <span>
            {Array.isArray(data.value) ? (
              <NestedListItem value={data.value} />
            ) : (
              <ItemValue value={data.value} href={data.href} />
            )}
          </span>
        </span>
      ))}
    </>
  );
});

const NestedListItem = ({ value }: { value: MetaDataLine[] }) => {
  if (value.length === 0) {
    return <span>Empty</span>;
  }

  return (
    <ul>
      {value.map((item) => (
        <li key={item.title}>
          <span>
            <ItemValue value={item.title} href={item.href} />:{" "}
          </span>

          <span>{Array.isArray(item.value) ? "[]" : item.value}</span>
        </li>
      ))}
    </ul>
  );
};

const ItemValue = ({ value, href }: { value: string; href?: string }) => {
  if (!href) return <span>{value}</span>;
  return <FairtrailLink href={href}>{value}</FairtrailLink>;
};
