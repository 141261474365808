import { DayRange } from "components/edit/DayRangePicker";
import { IncidentType } from "constants/widgetConstants";
import { IncidentStats } from "../models/IncidentStats";
import IncidentsAPIClient from "../services/api/IncidentsAPIClient";
import { SimpleStore } from "./SimpleStore";

type Key<T extends IncidentType> = { type: T; range: DayRange };

export class IncidentStatsStore extends SimpleStore<Key<any>, IncidentStats<any>, "key"> {
  private client = new IncidentsAPIClient();

  protected async fetchFromClientForKey({ range, type }: Key<any>) {
    return await this.client.getIncidentStats(type, range);
  }

  protected getRegistryIndex<T extends IncidentType>({ range, type }: Key<T>) {
    return `${range.from}-${range.to}-${type}`;
  }

  readonly keyFieldName = "key" as const;
}
