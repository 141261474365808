import { LiveThingProperty } from "constants/widgetConstants";
import { observer } from "mobx-react-lite";
import { hasData, Loadable } from "models/Loading";
import { Thing } from "models/Thing";
import { getMetricCapabilities } from "models/ThingDataExtraction";
import { TimeseriesType } from "models/widgets/ChartWidget";
import { useMemo } from "react";
import { useManyStoreItems } from "stores/hooks";
import language from "translations/language";
import { intersection, unique } from "utils/arrayUtils";
import { areCaptionDataThings, getCaptionDataMetricOptions } from "utils/captionDataUtils";
import { LooseEqualityMap } from "utils/common";
import { useStore } from "../../stores/RootStore";
import { SelectSetting } from "./SelectSetting";

interface Props {
  defaultValue: string | string[] | null;
  options: "LiveThingProperty" | "TimeseriesType";
  settingsKey: string;
  onChange: (key: string, value: any) => void;
  multiple?: boolean;
  getSelectedThingIds: () => string[];
}

const ThingMetricPicker = ({ defaultValue, options, onChange, settingsKey, multiple, getSelectedThingIds }: Props) => {
  const { thingStore } = useStore();
  const things = useManyStoreItems(thingStore, getSelectedThingIds());

  const allowedOptions = useMemo(() => {
    if (areCaptionDataThings(things.values().filter(hasData<Thing>))) {
      return getCaptionDataMetricOptions(things, multiple);
    }

    const metricsSupportedByThings = supportedMetrics(things, multiple);

    /*
     * BatteryLevel is being excluded because we can't get it as a percentage for all devices that have a
     * battery level.
     */
    return Object.entries(options === "LiveThingProperty" ? LiveThingProperty : TimeseriesType)
      .filter(([_key, value]) => value !== LiveThingProperty.BatteryLevel)
      .filter(([_key, value]) => metricsSupportedByThings.includes(value))
      .map(([_key, value]) => {
        return {
          key: value,
          value:
            language.thingProperties[value as LiveThingProperty] ??
            language.widgets.timeseriesTypes[value as TimeseriesType],
        };
      });
  }, [things, options, multiple]);

  return (
    <SelectSetting
      onChange={onChange}
      settingsKey={settingsKey}
      value={defaultValue}
      multiple={multiple}
      options={allowedOptions}
      noOptionsMessage={() => language.errors.NO_METRICS_FOR_SELECTED_THINGS}
    />
  );
};

export default observer(ThingMetricPicker);

const supportedMetrics = (things: LooseEqualityMap<string, Loadable<Thing>>, multiple: boolean | undefined) => {
  const loadedThings = things.values().filter(hasData<Thing>);
  const capabilitiesForThings = loadedThings.map(getMetricCapabilities);

  if (capabilitiesForThings.length === 0) {
    return [];
  }
  if (multiple) {
    /* For many metrics we let them be selected if any thing supports them */
    return capabilitiesForThings.reduce(unique);
  } else {
    /* If we're only picking one metric it should be supported by all the things */
    return capabilitiesForThings.reduce(intersection);
  }
};
