import { useTheme } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { GaugeType } from "constants/widgetConstants";
import dayjs from "dayjs";
import HighchartsReact from "highcharts-react-official";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useWindowSize } from "react-use";
import { getYAxisConfigFromWidget } from "utils/gaugeWidgetUtils";
import { LoadingStatus } from "../../../constants/constants";
import { useSingleValueFromDataSource } from "../../../datasources/hooks";
import { GaugeWidgetViewModel } from "../../../models/widgets/GaugeWidget";
import { useStore } from "../../../stores/RootStore";
import language from "../../../translations/language";
import ConfigureMessage from "../../WidgetConfigureMessage";
import LastUpdated from "../../common/LastUpdated";
import { ErrorState } from "../loading/ErrorState";
import { LoadingState } from "../loading/LoadingState";
import { ClassicGaugeWidge } from "./ClassicGauge";
import { SolidGaugeWidget } from "./SolidGauge";
import type { TitleOptions } from "highcharts";

interface Props {
  widget: GaugeWidgetViewModel;
}

export type GaugeWidgetOptions = Omit<Highcharts.Options, "title"> & { title: TitleOptions | undefined | null };

const GaugeWidget = ({ widget }: Props) => {
  const { dashboardStore } = useStore();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const windowSize = useWindowSize();
  const data = useSingleValueFromDataSource(widget.settings.dataSource);
  const currentTheme = useTheme();

  useEffect(() => chartComponentRef.current?.chart?.reflow(), [windowSize]);

  useEffect(() => {
    if (chartComponentRef !== null) {
      dashboardStore.setWidgetChartRef(widget.widgetId, chartComponentRef);
    }
  });

  if (!widget.settings.dataSource) {
    return <ConfigureMessage widgetName={language.widgets.GAUGE} />;
  }

  if (data.loadingStatus === LoadingStatus.Loading) {
    return <LoadingState />;
  }

  if (data.loadingStatus === LoadingStatus.Error) {
    return <ErrorState />;
  }

  const yAxisConfig = getYAxisConfigFromWidget(widget, currentTheme, data.value);

  const GaugeComponent = widget.settings.type === GaugeType.Classic ? ClassicGaugeWidge : SolidGaugeWidget;

  return (
    <S.Container>
      <S.GaugeContainer>
        <GaugeComponent
          chartComponentRef={chartComponentRef}
          widget={widget}
          data={data}
          yAxisConfig={yAxisConfig}
          theme={currentTheme}
        />
      </S.GaugeContainer>
      <S.LastUpdatedContainer>
        {data.updatedAt && <LastUpdated datetime={data.updatedAt} timeZone={dayjs.tz.guess()} />}
      </S.LastUpdatedContainer>
    </S.Container>
  );
};

export default observer(GaugeWidget);

// prettier-ignore
const S= {
  Container: styled.div(({ theme }) => `
    ${theme.spacing.p([0, 6, 8, 4])}
    height: 90%;
    fill: ${theme.color.primary};
  `),

  GaugeContainer: styled.div`
    height: 90%;
    width: 100%;
  `,

  LastUpdatedContainer: styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
  `,
};
