import styled from "@emotion/styled/macro";
import Select from "components/common/select";
import { LoadingStatus } from "constants/constants";
import { observer } from "mobx-react-lite";
import { hasData, Loadable, LoadedEntity } from "models/Loading";
import { addressForStack, Stack } from "models/Stack";
import { User } from "models/User";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import { useStore } from "stores/RootStore";
import language from "translations/language";

type StackAndUser = { stack?: Loadable<Stack>; user: Loadable<User> };
type StackAndLoadedUser = { stack?: LoadedEntity<Stack>; user: LoadedEntity<User> };

type OptionType = {
  value: string;
  label: string;
  user: User;
  stack?: Stack;
};

const userPartHasData = (stackAndUser: StackAndUser): stackAndUser is StackAndLoadedUser => {
  return hasData(stackAndUser.user);
};

type Props = {
  onCustomerChange?: (customer: User | null) => unknown;
  onStackChange?: (stack: Stack) => unknown;
  initialCustomerId?: string;
  initialStackId?: string;
  requireStack?: boolean;
};

export const CustomerPicker = observer(
  ({ onCustomerChange, onStackChange, initialCustomerId, initialStackId, requireStack }: Props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [selectedOption, setSelectedOption] = useState<OptionType>();
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

    useDebounce(() => setDebouncedSearchTerm(searchTerm ?? ""), 300, [searchTerm]);

    const matchedByStack = useSearchByStack(debouncedSearchTerm);
    const matchesByUser = useSearchByUser(debouncedSearchTerm, initialCustomerId);

    const options = useMemo<OptionType[]>(() => {
      let allPairs = [...matchedByStack, ...matchesByUser].filter(userPartHasData);

      if (requireStack) {
        allPairs = allPairs.filter((pair) => pair.stack);
      }

      const uniqueOptions: OptionType[] = uniquePairs(allPairs).map(({ user, stack }: StackAndLoadedUser) => ({
        value: `${user.userId}-${stack ? stack.stackId : "NO-STACK"}`,
        label: `${stack ? addressForStack(stack) : ""} ${user.name} ${user.cellPhones[0]}`,
        user,
        stack,
      }));

      setIsSearching(false);

      return uniqueOptions;
    }, [matchedByStack, matchesByUser, requireStack]);

    const handleInputChange = (value: string) => {
      setSearchTerm(value);

      if (value) {
        setIsSearching(true);
      }
    };

    const handleChange = (option: OptionType) => {
      if (option === null) {
        setSelectedOption(undefined);
        onCustomerChange?.(null);
        return;
      }

      const { user, stack } = option;

      setSelectedOption(option);
      onCustomerChange?.(user);

      if (stack) {
        onStackChange?.(stack);
      }
    };

    return (
      <S.Container>
        <Select
          value={selectedOption as OptionType}
          onChange={(option) => handleChange(option as OptionType)}
          options={options}
          formatOptionLabel={(value) => formatOptionLabel(value as OptionType)}
          inputValue={searchTerm}
          onInputChange={handleInputChange}
          placeholder={initialCustomerId ? options[0]?.user.name : language.customerPicker.PLACEHOLDER}
          isSearchable={true}
          loadingMessage={() => null}
          isLoading={isSearching}
          noOptionsMessage={({ inputValue }) =>
            inputValue ? `${language.customerPicker.NO_RESULTS} '${inputValue}'` : null
          }
          isClearable
        />
      </S.Container>
    );
  }
);

const formatOptionLabel = ({ value, label, user, stack }: OptionType) => (
  <div style={{ display: "flex", justifyContent: "space-between", gap: "0 10px" }}>
    <div style={{ flex: 1 }}>{stack ? addressForStack(stack) : ""}</div>
    <div style={{ flexBasis: "35%" }}>{user.name}</div>
    <div style={{ flexBasis: "115px" }}>{user.cellPhones[0]}</div>
  </div>
);

// The StackPicker is the customer picker with a few options stripped out and requireStack set to true
export const StackPicker = (props: Pick<Props, "onStackChange" | "onCustomerChange" | "initialStackId">) => (
  <CustomerPicker {...props} requireStack={true} />
);

const useSearchByStack = (searchTerm: string, initialStackId?: string) => {
  const { userStore, stackStore } = useStore();

  const [stacksMatchedByStack, setStacksMatchedByStack] = useState<StackAndUser[]>([]);
  const [searchResultStackIds, setSearchResultStackIds] = useState<string[]>(initialStackId ? [initialStackId] : []);

  useEffect(() => {
    if (!initialStackId) {
      return;
    }

    stackStore.load(initialStackId);
  }, [stackStore, initialStackId]);

  useEffect(() => {
    if (searchTerm === "") {
      setSearchResultStackIds([]);
      return;
    }

    stackStore.loadForQuery(searchTerm).then((stackIds) => setSearchResultStackIds(stackIds));
  }, [stackStore, searchTerm]);

  /* Load up any user information for each stack matched by querying stacks */
  useEffect(() => {
    const stacksAndUsers = searchResultStackIds.map((stackId) => {
      const stack = stackStore.get(stackId);

      if (hasData(stack)) {
        const userId = stack.owner.userId;
        const user = userStore.get(userId);

        if (!hasData(user)) {
          userStore.load(userId);
        }

        return { stack, user };
      }

      return { stack, user: { loadingStatus: LoadingStatus.Loading } as Loadable<User> };
    });
    setStacksMatchedByStack(stacksAndUsers);
  }, [stackStore, userStore, searchResultStackIds]);

  return stacksMatchedByStack;
};

const useSearchByUser = (searchTerm: string, initialCustomerId?: string) => {
  const { userStore, stackStore } = useStore();

  const [searchResultUserIds, setSearchResultUserIds] = useState<string[]>(
    initialCustomerId ? [initialCustomerId] : []
  );

  const [stacksMatchedByUser, setStacksMatchedByUser] = useState<StackAndUser[]>([]);

  useEffect(() => {
    if (!initialCustomerId) {
      return;
    }

    userStore.load(initialCustomerId);
  }, [userStore, initialCustomerId]);

  useEffect(() => {
    if (searchTerm === "") {
      setSearchResultUserIds([]);
      return;
    }

    userStore.loadForQuery(searchTerm).then((userIds) => setSearchResultUserIds(userIds));
  }, [userStore, searchTerm]);

  useEffect(() => {
    const loadingUsersAndTheirStackIds = searchResultUserIds.map(async (userId) => {
      const stackIds = await stackStore.loadAllForUser(userId);
      return { user: userStore.get(userId), stackIds };
    });

    Promise.all(loadingUsersAndTheirStackIds).then((usersAndTheirStackIds) => {
      setStacksMatchedByUser(
        usersAndTheirStackIds.flatMap(({ user, stackIds }) => {
          if (stackIds.length === 0) {
            return [{ user }];
          }

          return stackIds.map((stackId) => ({ user, stack: stackStore.get(stackId) }));
        })
      );
    });
  }, [stackStore, userStore, searchResultUserIds]);

  return stacksMatchedByUser;
};

const uniquePairs = (stacksAndUsers: StackAndLoadedUser[]): StackAndLoadedUser[] => {
  const map = new Map();

  stacksAndUsers.forEach((stackAndUser) => {
    const uniqueKey = `${stackAndUser.stack ? stackAndUser.stack.stackId : "NO-STACK"}+${stackAndUser.user.userId}`;
    map.set(uniqueKey, stackAndUser);
  });

  return Array.from(map.values());
};

const S = {
  //prettier-ignore
  Container: styled.div`
    max-width: 800px;
  `,
};
