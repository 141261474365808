import { rootStore } from "stores/RootStore";
import { VISUALISATION_SERVICE_HOST } from "../../config/config";
import { Dashboard, DashboardMetadata, DashboardSettings, toDashboard } from "../../models/Dashboard";
import { toWidget, WidgetSettings, WidgetViewModel } from "../../models/widgets/Widget";
import { FairtrailHttpClient } from "../FairtrailHttpClient";

export class VisualisationAPIClient extends FairtrailHttpClient {
  constructor() {
    super(VISUALISATION_SERVICE_HOST);
  }

  async createDashboard(
    name: string,
    metadata: DashboardMetadata,
    settings?: DashboardSettings,
    cloneDashboardId?: string
  ) {
    const response = await this.post({
      path: `/visualisations/dashboards`,
      body: { name, metadata, settings, cloneDashboardId },
      authenticated: true,
    });

    return toDashboard(response.data);
  }

  async getDashboard(id: string) {
    if (rootStore.authStore.isSharingMode()) {
      return this.getPublicDashboard(id);
    }

    const response = await this.get({
      path: `/visualisations/dashboards/${id}`,
      authenticated: true,
    });

    return toDashboard(response.data);
  }

  async getPublicDashboard(id: string) {
    const response = await this.get({
      path: `/visualisations/publicDashboards/${id}`,
      authenticated: true,
    });

    return toDashboard(response.data);
  }

  async getDashboards(
    page = 0,
    size = 100,
    sortBy?: "name" | "createdAt",
    sortOrder?: 1 | -1,
    q?: string
  ): Promise<Dashboard[]> {
    const response = await this.get({
      path: `/visualisations/dashboards`,
      query: {
        page,
        size,
        q,
        sortBy,
        sortOrder,
      },
      authenticated: true,
    });

    return response.data.map((dashboard: any) => toDashboard(dashboard));
  }

  async updateDashboard(id: string, name: string, settings?: DashboardSettings) {
    const response = await this.put({
      path: `/visualisations/dashboards/${id}`,
      body: {
        name,
        settings,
      },
      authenticated: true,
    });

    return toDashboard(response.data);
  }

  async deleteDashboard(id: string) {
    await this.delete({
      path: `/visualisations/dashboards/${id}`,
      authenticated: true,
    });
  }

  async createWidget(dashboardId: string, widget: Pick<WidgetViewModel, "type" | "settings">) {
    const response = await this.post({
      path: `/visualisations/dashboards/${dashboardId}/widgets`,
      body: widget,
      authenticated: true,
    });

    return toWidget(response.data);
  }

  async getWidgetsByDashboardId(dashboardId: string): Promise<WidgetViewModel[]> {
    if (rootStore.authStore.isSharingMode()) {
      return this.getWidgetsByPublicDashboardId(dashboardId);
    }

    const response = await this.get({
      path: `/visualisations/dashboards/${dashboardId}/widgets`,
      authenticated: true,
    });

    return response.data.map((widget: any) => toWidget(widget));
  }

  async getWidgetsByPublicDashboardId(dashboardId: string): Promise<WidgetViewModel[]> {
    const response = await this.get({
      path: `/visualisations/publicDashboards/${dashboardId}/widgets`,
      authenticated: true,
    });

    return response.data.map((widget: any) => toWidget(widget));
  }

  async getWidgetById(dashboardId: string, widgetId: string): Promise<WidgetViewModel> {
    const response = await this.get({
      path: `/visualisations/dashboards/${dashboardId}/widgets/${widgetId}`,
      authenticated: true,
    });

    return toWidget(response.data);
  }

  async updateWidget(dashboardId: string, widgetId: string, widget: Pick<WidgetViewModel, "settings">) {
    const response = await this.put({
      path: `/visualisations/dashboards/${dashboardId}/widgets/${widgetId}`,
      body: widget,
      authenticated: true,
    });

    return toWidget(response.data);
  }

  async batchUpdateWidgetLayouts(
    dashboardId: string,
    updates: { widgetId: string; position: WidgetSettings["position"]; size: WidgetSettings["size"] }[]
  ): Promise<WidgetViewModel[]> {
    const response = await this.put({
      path: `/visualisations/dashboards/${dashboardId}/widgets/layout`,
      body: { updates },
      authenticated: true,
    });

    return response.data.map((widget: any) => toWidget(widget));
  }

  async deleteWidget(dashboardId: string, widgetId: string) {
    await this.delete({
      path: `/visualisations/dashboards/${dashboardId}/widgets/${widgetId}`,
      authenticated: true,
    });
  }
}
