import { ProtectedHouseholds } from "models/ProtectedHouseholds";
import { FairtrailHttpClient } from "../FairtrailHttpClient";

export class ProtectedHouseholdsAPIClient extends FairtrailHttpClient {
  async getData(organizationId: string = "organizationId"): Promise<ProtectedHouseholds> {
    const response = await this.get({
      path: `/visualisations/organization/${organizationId}/protected-stacks`,
      authenticated: true,
    });

    return response.data;
  }
}
