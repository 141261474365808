import { DashboardTheme } from "constants/dashboardConstants";

type Matcher = RegExp | string;
type Replacer = (match: string) => string;

function createParser(matcher: Matcher, replacer: Replacer): (string: string) => string {
  const regex = typeof matcher === "string" ? RegExp(matcher, "g") : matcher;
  return (string: string): string => {
    // * throw an error if not a string
    if (typeof string !== "string") {
      throw new TypeError(`expected an argument of type string, but got ${typeof string}`);
    }

    // * if no match between string and matcher
    if (!string.match(regex)) {
      return string;
    }

    // * executes the replacer function for each match
    // ? replacer can take any arguments valid for String.prototype.replace
    return string.replace(regex, replacer);
  };
}

const camelToKebab = createParser(/[A-Z]/, (match: string) => `-${match.toLowerCase()}`);
const kebabToCamel = createParser(/-[a-z]/, (match: string) => match[1].toUpperCase());

export const serializeCSSObj = (obj: any, parser = camelToKebab): string => {
  if (!obj || typeof obj !== "object" || Array.isArray(obj)) {
    throw new TypeError(`expected an argument of type object, but got ${typeof obj}`);
  }
  const lines = Object.keys(obj).map((property) => `${parser(property)}: ${obj[property]};`);
  return lines.join("\n");
};

export const deserializeThemeCSS = (str: string, parser = kebabToCamel) => {
  if (!str || typeof str !== "string") {
    throw new TypeError(`expected an argument of type string, but got ${typeof str}`);
  }
  const lines = str.split("\n");
  const obj: any = {};
  lines.forEach((line) => {
    const [key, value] = line.split(":");
    obj[parser(key.trim())] = value.trim();
  });
  return obj;
};

/**
 * Returns the deeply nested object value
 * @param key dot-based path
 * @param object reference to the main object that should be used to find the value
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const getValueFromKey = <TValue, TKey extends string = string, TObject = {}>(
  key: TKey | undefined,
  object: TObject
): TValue | undefined => {
  if (!key) {
    return undefined;
  }

  const paths = key.split(".");

  return paths.reduce((accumulator, path) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return accumulator ? accumulator[path] : (object as any)[path];
  }, undefined);
};

/**
 * Returns the value in pixels or the raw value if it is a string.
 */
export const pxOrRaw = (value: string | number, numberTransform: (value: number) => number = (v) => v) =>
  typeof value === "number" ? (value !== 0 ? `${numberTransform(value)}px` : "0") : value;

/**
 * Allows to override CSS value based on the current theme name.
 * Get the current theme name from the `useThemeSwitch` hook.
 * 
 * @example
* border: themeOverride(
    new Map([
      ["dark", "yellow"],
      ["darkPale", "cyan"],
    ]),
    color.neutral700
  )(currentThemeName),
*/
export const themeOverride = (overrides: Map<DashboardTheme, any>, defaultValue: any) => {
  // return overrides.get(themeName) || defaultValue;
  return (themeName: DashboardTheme) => overrides.get(themeName) || defaultValue;
};
