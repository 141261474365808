import { DASHBOARD_VIEW_ROUTE } from "constants/routes";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable, stopPersisting } from "mobx-persist-store";
import { AccountsAPIClient } from "services/api/AccountsAPIClient";
import { TOKEN_QUERY_PARAM } from "../constants/constants";
import { AuthUser, LightAuthUser } from "../models/AuthUser";
import { AuthAPIClient } from "../services/api/AuthAPIClient";
import { initializeWebSocketsChannels } from "../services/WebSocketService";
import { PUBLIC_TOKEN } from "config/config";

export class AuthStore {
  private authAPIClient = new AuthAPIClient();
  private accountsAPIClient = new AccountsAPIClient();

  _token: string | null = null;

  _authUser: LightAuthUser | null = null;

  private requestCode = "";

  constructor() {
    makeAutoObservable(this);

    /** So that refreshing during development doesn't cause memory leak */
    stopPersisting("AuthStore");

    makePersistable(this, {
      name: "AuthStore",
      properties: ["_token"],
      stringify: true,
    });
  }

  get hasToken() {
    return this.token != null;
  }

  get token() {
    if (this.isSharingMode()) {
      return PUBLIC_TOKEN;
    }

    return this.tokenFromUri ?? this._token;
  }

  get authUser() {
    return this._authUser;
  }

  clearToken() {
    runInAction(() => {
      // If we're using a token from the URI then clearing out the store won't help
      if (!this.tokenFromUri) {
        this._token = null;
      }
    });
  }

  async requestLoginCode(email: string) {
    const { requestCode } = await this.authAPIClient.requestLoginCode(email.toLowerCase());

    runInAction(() => (this.requestCode = requestCode));
  }

  async validateCodeSentToUser(loginCode: string) {
    try {
      const user: AuthUser = await this.authAPIClient.validateCodeSentToUser(loginCode, this.requestCode);

      runInAction(() => this.setToken(user.token));

      initializeWebSocketsChannels();
      this.loadAuthUser();
    } catch (err: any) {
      throw err;
    }
  }

  async loadAuthUser() {
    // @ts-ignore
    if (window.CI) {
      return;
    }

    const user = await this.accountsAPIClient.getAuthUser();
    runInAction(() => (this._authUser = user));
  }

  private setToken(token: string) {
    runInAction(() => (this._token = token));
  }

  private get tokenFromUri() {
    const queryParams = new URLSearchParams(window.location.search);
    const rawToken = queryParams.get(TOKEN_QUERY_PARAM)!;
    return rawToken !== null ? decodeURI(rawToken) : null;
  }

  isSharingMode() {
    return new RegExp(DASHBOARD_VIEW_ROUTE.replace(":dashboardId", ".*")).test(window.location.pathname);
  }
}
