// @ts-nocheck
/** If any new configs are added, they also need to be added to /run.sh */
window.env = window.env ?? {};

export const FAIRTRAIL_HOST = window.env.FAIRTRAIL_HOST ?? "https://api.fairtrail.biz/v1";
export const FAIRTRAIL_WEB_HOST = window.env.FAIRTRAIL_WEB_HOST ?? "https://login.fairtrail.biz";
export const FAIRTRAIL_REBORN_WEB_HOST = window.env.FAIRTRAIL_REBORN_WEB_HOST ?? "https://login-reborn.fairtrail.biz";
export const FAIRTRAIL_WEB_SOCKET_HOST = window.env.FAIRTRAIL_WEB_SOCKET_HOST ?? "wss://stream.fairtrail.biz/";
export const FAIRTRAIL_API_KEY = "c44509f686284515b48ed0e11deb9017";
export const OPEN_WEATHER_MAP_KEY = `ac52da247dcb5104569e5bcc6c6847e8`;
export const GOOGLE_MAPS_KEY = "AIzaSyAbtLHfc9ymiQMxdpBRtIBJuMA33GrqXwY";

export const DEFAULT_ORG = "2d0d3b40-e784-11e6-9346-cfab01408778";

// Local dev overrides - Grabbing from process.env. so that create react app can build them in
export const VISUALISATION_SERVICE_HOST = process.env.REACT_APP_VISUALISATION_SERVICE_HOST;

/**
 * it should be used only for the public dashboards page which is opened in sharing mode
 * it was added for hacking auth
 * token never invalidated and has right only for viewing public dashboard
 */
export const PUBLIC_TOKEN =
  window.env.PUBLIC_TOKEN ??
  "acdc4e5a1ce33294d9dcaeeb4afc778e13f5dc422b231705a40d51b660ce55a1b67df2fd99708190d6a68a520242941146c54bb41f88d5ec41925376baf641ca";
