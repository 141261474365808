import { DataSource } from "datasources/core";
import { Stack } from "models/Stack";

export type MetaDataLine = {
  title: string;
  value: string | MetaDataLine[];
  href?: string;
};

export type GeoTaggedDataPoint = {
  lat: number;
  lng: number;
  title: string;
  uniqueId: string;
  metaData: Record<string, MetaDataLine>;
  status?: GeoStatus;
  stack?: Stack;
  things?: any[];
};

export enum GeoStatus {
  Error = "ERROR",
  Warning = "WARNING",
  Ok = "OK",
}

export type GeoTaggedData = {
  points: GeoTaggedDataPoint[];
};

export interface GeoTaggedDataSource<TConfig> extends DataSource<GeoTaggedData, TConfig> {}
