import {
  CAPTION_DATA_DEPARTMENT_ID_QUERY_PARAM,
  SHOW_ONLY_PROPERTIES,
  THEME_QUERY_PARAM,
  USE_FAIRTRAIL_LINKS_QUERY_PARAM,
  USE_REBORN_LINKSQUERY_PARAM,
} from "constants/constants";
import { DashboardTheme } from "constants/dashboardConstants";
import { makeAutoObservable, runInAction } from "mobx";

/** Stores configs from query */
export class ConfigStore {
  private _useFairtrailLinks = false;

  /** Caption data department id */
  private _deptId: string | undefined = undefined;

  /** Hide things' names from graphs, showing only properties. I.e. for when using graph page */
  private _showOnlyProperties = false;

  private _useFairtrailRebornLinks = false;

  /** Used to grab the theme when going between pages */
  private _theme: DashboardTheme = DashboardTheme.Default;

  constructor() {
    makeAutoObservable(this);

    this.getConfigsFromQuery();
  }

  private getConfigsFromQuery() {
    const queryParams = new URLSearchParams(window.location.search);
    const useFairtrailLinks = queryParams.get(USE_FAIRTRAIL_LINKS_QUERY_PARAM)!;
    const useFairtrailRebornLinks = queryParams.get(USE_REBORN_LINKSQUERY_PARAM)!;
    const deptId = queryParams.get(CAPTION_DATA_DEPARTMENT_ID_QUERY_PARAM)!;
    const showOnlyProperties = queryParams.get(SHOW_ONLY_PROPERTIES)!;
    const theme = queryParams.get(THEME_QUERY_PARAM)!;

    runInAction(() => {
      if (useFairtrailLinks !== null) {
        this._useFairtrailLinks = useFairtrailLinks === "true";
      }

      if (deptId !== null) {
        this._deptId = deptId;
      }

      if (showOnlyProperties !== null) {
        this._showOnlyProperties = showOnlyProperties === "true";
      }

      if (useFairtrailRebornLinks !== null) {
        this._useFairtrailRebornLinks = useFairtrailRebornLinks === "true";
      }

      if (theme !== null) {
        this._theme = theme as DashboardTheme;
      }
    });
  }

  get useFairtrailLinks() {
    return this._useFairtrailLinks;
  }

  get deptId() {
    return this._deptId;
  }

  get showOnlyProperties() {
    return this._showOnlyProperties;
  }

  get useFairtrailRebornLinks() {
    return this._useFairtrailRebornLinks;
  }

  get theme() {
    return this._theme;
  }
}
