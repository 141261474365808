import styled from "@emotion/styled/macro";
import { observer } from "mobx-react-lite";
import { ImageWidgetViewModel } from "models/widgets/ImageWidget";

interface Props {
  widget: ImageWidgetViewModel;
  readonly: boolean;
}

const ImageWidget = ({ widget }: Props) => {
  const url = widget.settings?.url;

  return (
    <SContainer>
      <SImage src={url} />
    </SContainer>
  );
};

// prettier-ignore
const SImage =  styled.img`
object-fit: contain;
width: 100%;
height: 100%;
`;

const SContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2%;
  ${"container-type"}: size;
`;

export default observer(ImageWidget);
