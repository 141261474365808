import { LiveThingProperty } from "constants/widgetConstants";
import dayjs from "dayjs";
import { ProductCode, ProductName } from "./ProductType";
import { Thing } from "./Thing";
import { TimeseriesType } from "./widgets/ChartWidget";

export const stateModifiedAt = (thing: Thing): Date => {
  if (!thing.state || !thing.state.timestamp) {
    /*
     * As a fallback if the state doesn't have a timestamp we use when the thing was last modified.
     * This might be wrong though because other changes to the thing will have updated it.
     */
    return thing.modified;
  }

  const timestamp = dayjs(thing.state.timestamp);

  if (!timestamp.isValid()) {
    // For bad data we'll also fall back to the modified date.
    return thing.modified;
  }

  return timestamp.toDate();
};

const liveThingProperties = Object.values(LiveThingProperty);
const timeSeriesProperties = Object.values(TimeseriesType);

export const getMetricCapabilities = (thing: Thing): (LiveThingProperty | TimeseriesType)[] => {
  /*
   * we have this check here so that if capability detection is rolled back we revert
   * to the old behaviour of showing all metrics.
   */
  if (thing.productType.detectedCapabilities === undefined) {
    return [...liveThingProperties, ...timeSeriesProperties];
  }

  /*
   * This function currently relies on the fact that the capabilities coming from
   * the backend are the same strings as used by LiveThingProperty & TimeseriesType
   * if this changes we'll need to do some mapping
   */
  const capabilities = (thing.productType.detectedCapabilities ?? [])
    .filter((capability) => capability.type === "value")
    .filter(
      (capability) =>
        liveThingProperties.includes(capability.name as LiveThingProperty) ||
        timeSeriesProperties.includes(capability.name as TimeseriesType)
    )
    .map((capability) => capability.name as LiveThingProperty | TimeseriesType);

  /* Deal with a few exceptions */
  if (capabilities.includes(TimeseriesType.Pressure)) {
    capabilities.push(LiveThingProperty.WaterPressure);
  }
  if (capabilities.includes(TimeseriesType.Humidity)) {
    capabilities.push(LiveThingProperty.RelativeHumidity);
  }
  if (isTemperatureHumidityThing(thing)) {
    capabilities.push(TimeseriesType.AbsoluteHumidity);
  }

  /* Currently paa monitoring can't be detected so we have to use the product type */
  // TODO: remove this once it's detected
  if (thing.productType.productCode === ProductCode.CaptionDataModbus) {
    capabilities.push(TimeseriesType.PaaConcentration);
  }

  /* Add extra inferred/derived capabilities */
  if (capabilities.includes(TimeseriesType.WaterConsumption)) {
    capabilities.push(TimeseriesType.TotalWaterConsumption);
  }

  if (capabilities.includes(TimeseriesType.PaaConcentration)) {
    capabilities.push(TimeseriesType.PaaConcentrationRollingAverage15Min);
    capabilities.push(TimeseriesType.PaaConcentrationRollingAverage8hour);
  }

  return capabilities;
};

const isTemperatureHumidityThing = (thing: Thing): boolean => {
  return (
    thing.productType.productCode === ProductCode.AqSense ||
    thing.productType.productCode === ProductCode.CubicDetector ||
    thing.productType.productCode === ProductCode.D21TempHum ||
    thing.productType.productCode === ProductName.CubicDetector
  );
};
