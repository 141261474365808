import { ConnectivityMessage, KnownEventType } from "../../models/Timeline";
import { LeakAlarmType, PressureAlarmType, ThingAlarmLike, ThingAlarmType, ThingStatus } from "models/Thing";
import { DashboardPurpose, DashboardTheme } from "constants/dashboardConstants";
import {
  FilterValue,
  GaugeType,
  HealthFilterValue,
  IncidentAllTypes,
  IncidentType,
  LiveThingProperty,
  MapInitialView,
  PropertyMetric,
  SingleValueSourceTypes,
  TimeInterval,
  WidgetType,
} from "constants/widgetConstants";
import { AggregationType, ChartSourceTypes, TimeseriesType } from "models/widgets/ChartWidget";
import { MultipleValueSourceTypes } from "models/widgets/common/value";
import { GeoDataSourceTypes } from "models/widgets/MapWidget";
import { SettingsModalOptions } from "constants/constants";
import {
  MultipleAlarmsValueSourceTypes,
  MultipleStacksValueSourceTypes,
  MultipleThingsValueSourceTypes,
  ThingMetricThreshold,
  ThingMetricThresholdOperator,
} from "models/widgets/TableWidget";
import { NoClassificationReason } from "models/HealthData";
import { ThresholdColor } from "models/widgets/common/common";

const nb = {
  EDIT: "Rediger",
  NEW: "Ny",
  SAVE: "Lagre",
  CREATE: "Opprett",
  NAME: "Navn",
  ADD: "Legg til",
  REMOVE: "Fjern",
  CREATE_COPY: "Lag en kopi",
  MODIFIED: "Endret",
  OWNER: "Eier",
  ADDRESS: "Adresse",
  VIEW: "Vis",
  SETTINGS: "Innstillinger",
  SHARING: "Deling",
  COLOR: "Farge",
  EMPTY: "Tom",
  BACK: "Tilbake",
  SEARCH: "Søk",
  FULLSCREEN: "Fullskjerm",
  RESET: "Tilbakestill",
  RESET_THRESHOLD: "Tilbakestill terskel",

  LOGIN: "Logg inn",
  SEND: "Send",
  EMAIL: "E-post",
  LOGIN_CODE: "Påloggingskode",
  WARNING: "Advarsel",
  NEXT: ">",
  PREVIOUS: "<",

  LOADING: "Laster...",
  MUST_BE_CONFIGURED: (widgetName: string) => `${widgetName} -widgeten må være konfigurert`,

  TIME_INTERVAL: "Tidsintervall",
  THINGS: "Enheter",
  TYPE: "Type",
  ACTIONS: "Handlinger",
  DASHBOARDS: "Instrumentbord",
  THING: "Enhet",
  PRODUCT: "Produkt",
  ERRAND: "Oppdrag",

  MIN: "Minimum",
  MAX: "Maksimum",

  BETWEEN: "Mellom",
  BETWEEN_TO: "til",
  DAYS: "dager",
  N_DAYS: (num: string) => `${num} dager`,

  UNKNOWN_DEVICE: "Ukjent enhet",

  CUSTOMER: "Kunde",
  PROPERTY: "Eiendom",
  STATUS: "Status",

  MORE: "Mer",

  LATITUDE: "Breddegrad",
  LONGITUDE: "Lengdegrad",
  ZOOM: "Zoom",
  PREVIEW: "Forhåndsvisning",

  createDashboard: {
    CREATE_COPY_DASHBOARD_TITLE: (dashboardName: string) => `Opprett en kopi av instrumentbordet "${dashboardName}"`,
  },
  dashboardPurpose: {
    [DashboardPurpose.Generic]: "Generisk instrumentbord",
    [DashboardPurpose.Customer]: "Instrumentbord for en bestemt kunde",
    [DashboardPurpose.Stack]: "Instrumentbord for en bestemt eiendom",
  },

  dashboardShortPurpose: {
    [DashboardPurpose.Generic]: "Generisk",
    [DashboardPurpose.Customer]: "Kunde",
    [DashboardPurpose.Stack]: "Eiendom",
  },

  dashboardSettings: {
    [SettingsModalOptions.General]: "Generelt",
    // [SettingsModalOptions.MetricSystem]: "Metrisk system",
    [SettingsModalOptions.ColorTheme]: "Farger tema",
  },

  themeNames: {
    [DashboardTheme.Default]: "Lys",
    [DashboardTheme.DarkPale]: "Mørk",
    [DashboardTheme.Dark]: "Neon",
    [DashboardTheme.Polygon]: "Polygon",
  },

  protectedHouseholds: {
    TOTAL: "Totalt antall",
    HIGH: "Høy beskyttelse",
    MEDIUM: "Middels beskyttelse",
    LOW: "Lokalisert beskyttelse",
  },

  signedInstallations: {
    CUSTOMER_CREATED_TO_COMPLETE: "hele installasjonen",
    CUSTOMER_CREATED_TO_FIRST_SUGGESTED_INSTALLATION: "til første foreslåtte installasjonstid",
    REVIEWING_IMAGES: "gjennomgår bilder",
    SCHEDULING_INSTALLATION: "planlegger installasjon",
    TOTAL_COMPLETED_INSTALLATIONS: "Totalt antall fullførte installasjoner i tidsperioden",
  },

  timelineEventTypes: {
    [KnownEventType.Alarms]: "Alarmer",
    [KnownEventType.Connectivity]: "Tilkobling",
  },

  // TODO: DRY up the alarm names here and the first set of timelineEventSubTypes
  thingAlarms: {
    [ThingAlarmType.LargeWaterFlowAlarm]: "Stort vannuttak",
    [ThingAlarmType.LargeWaterVolumeAlarm]: "Stort vannvolum",
    [ThingAlarmType.LongWaterFlowAlarm]: "Langvarig vannuttak",
    [ThingAlarmType.LowTempAlarm]: "Lav temperatur",
    [ThingAlarmType.SlowLeakage]: "Sakte lekkasje",
    [ThingAlarmType.WaterDetected]: "Vann oppdaget",
    [ThingAlarmType.PressureAlarm]: "Trykkalarm 1",
    [ThingAlarmLike.PressureAlarm1]: "Trykkalarm 1",
    [ThingAlarmLike.PressureAlarm2]: "Trykkalarm 2",
    [ThingAlarmType.LeakAlarm]: "Lekkasjebalanse",
  },

  alarmTypes: {
    [LeakAlarmType.SmallLeak]: "Liten lekkasje",
    [LeakAlarmType.MediumLeak]: "Middels lekkasje",
    [LeakAlarmType.Burst]: "Brister",
    [PressureAlarmType.PressureAlarm1]: "Trykkalarm 1",
    [PressureAlarmType.PressureAlarm2]: "Trykkalarm 2",
  },

  timelineEventSubTypes: {
    [ThingAlarmType.LargeWaterFlowAlarm]: "Stort vannuttak",
    [ThingAlarmType.LargeWaterVolumeAlarm]: "Stort vannvolum",
    [ThingAlarmType.LongWaterFlowAlarm]: "Langvarig vannuttak",
    [ThingAlarmType.LowTempAlarm]: "Lav temperatur",
    [ThingAlarmType.SlowLeakage]: "Sakte lekkasje",
    [ThingAlarmType.WaterDetected]: "Vann oppdaget",
    [ThingAlarmType.PressureAlarm]: "Trykkalarm",
    [ThingAlarmType.LeakAlarm]: "Lekkasjebalanse",
    [ConnectivityMessage.Offline]: "Frakoblet",
    [ConnectivityMessage.Online]: "Tilkoblet",
    [ConnectivityMessage.Timeout]: "Tidsavbrudd",
  },

  thingProperties: {
    [LiveThingProperty.WaterPressure]: "Vanntrykk",
    [LiveThingProperty.Temperature]: "Temperatur",
    [LiveThingProperty.WifiSignalStrength]: "Wifi signalstyrke",
    [LiveThingProperty.RelativeHumidity]: "Relativ fuktighet",
    [LiveThingProperty.BatteryLevel]: "Batterinivå",
    [LiveThingProperty.Humidity]: "Fuktighet",
    [LiveThingProperty.CaptionDataAbsoluteHumidity]: "Absolutt fuktighet",
    [LiveThingProperty.CaptionDataWME]: "WME",
  } as { [key in LiveThingProperty]: string },

  thingStatus: {
    [ThingStatus.Healthy]: "På nett",
    [ThingStatus.Offline]: "Frakoblet",
    [ThingStatus.HasAlarm]: "Har aktiv alarm",
    [ThingStatus.Preregistered]: "Registrert",
    [ThingStatus.ConfigReceived]: "Konfigurasjon mottatt",
    [ThingStatus.ConfigFailed]: "Konfigurasjonsfeil",
  },

  sourceTypes: {
    "": "Ingenting",
    [SingleValueSourceTypes.Thing]: "Enhet",
    [SingleValueSourceTypes.IncidentStats]: "Hendelsesstatistikk",
    [MultipleStacksValueSourceTypes.StackCreated]: "Prosjektduration",
    [MultipleValueSourceTypes.ProtectedHouseholds]: "Beskyttede husstander",
    [MultipleValueSourceTypes.SignedInstallationStats]: "Installeringer",
    [ChartSourceTypes.ThingEventTime]: "Hendelsestider (enhet)",
    [ChartSourceTypes.ThingsTimeSeries]: "Tidsserie (enhet)",
    [GeoDataSourceTypes.ThingLocations]: "Enhetslokasjoner",
    [GeoDataSourceTypes.CustomerStackLocations]: "Eiendomslokasjoner",
    [MultipleThingsValueSourceTypes.ThingMetricThreshold]: "Verdighet for enheter",
    [MultipleAlarmsValueSourceTypes.Alarms]: "Alarmer",
    undefined: "Ingenting",
  } as Record<string, string>,

  incidentTypes: {
    [IncidentType.Offline]: "Enhet frakoblet",
    [IncidentType.FailedInstallations]: "Mislykkede installeringer",
  } as { [key in IncidentType]: string },

  incidentAllTypes: {
    [IncidentAllTypes.ActiveLoss]: "Aktivt tap",
    [IncidentAllTypes.LargeWaterFlowAlarm]: "Alarm for stort vannvolum",
    [IncidentAllTypes.LargeWaterVolumeAlarm]: "Alarm for stort vannvolum",
    [IncidentAllTypes.LongWaterFlowAlarm]: "Alarm for langvarig vannstrøm",
    [IncidentAllTypes.LeakAlarm]: "Lekkasjebalarm",
    [IncidentAllTypes.Other]: "Annet",
    [IncidentAllTypes.PressureAlarm1]: "Trykkalarm 1",
    [IncidentAllTypes.PressureAlarm2]: "Trykkalarm 2",
    [IncidentAllTypes.Slowleakage]: "Langsom lekkasje",
    [IncidentAllTypes.Waterleakage]: "Vannlekkasje",
    [IncidentAllTypes.Offline]: "Frakoblet",
    [IncidentAllTypes.Wpa2]: "WPA2",
    [IncidentAllTypes.Wifi]: "WiFi",
    [IncidentAllTypes.WaterleakageUnknown]: "Ukjent lekkasje",
    [IncidentAllTypes.PassiveLoss]: "Passivt tap",
    [IncidentAllTypes.SolvedThemselves]: "Løst av seg selv",
    [IncidentAllTypes.WarrentyReplacement]: "Garantibytte",
    [IncidentAllTypes.ReturnSensor]: "Returner sensor",
    [IncidentAllTypes.Test]: "Test",
    [IncidentAllTypes.FalseAlarm]: "Falsk alarm",
    [IncidentAllTypes.Followup]: "Oppfølging",
    [IncidentAllTypes.FailedInstallations]: "Mislykkede installeringer",
    [IncidentAllTypes.LowBattery]: "Advarsel om lavt batteri",
  } as { [key in IncidentAllTypes]: string },

  thingMetricThreshold: {
    [ThingMetricThreshold.Pressure]: "Trykk",
    [ThingMetricThreshold.Temperature]: "Temperatur",
  } as { [key in ThingMetricThreshold]: string },

  thingMetricThresholdUnit: {
    [ThingMetricThreshold.Pressure]: "bar",
    [ThingMetricThreshold.Temperature]: "°C",
  } as {
    [key in ThingMetricThreshold]: string;
  },

  thingMetricThresholdOperator: {
    [ThingMetricThresholdOperator.GreaterThan]: "Større enn",
    [ThingMetricThresholdOperator.GreaterThanEqual]: "Større enn eller lik",
    [ThingMetricThresholdOperator.LessThan]: "Mindre enn",
    [ThingMetricThresholdOperator.LessThanEqual]: "Mindre enn eller lik",
  } as { [key in ThingMetricThresholdOperator]: string },

  tableWidgetColumnTitles: {
    DATA_SOURCE: "Datakilde",
    UPDATED_AT: "Dato",
    STATUS: "Status",
  },

  errors: {
    PROPERTY_NOT_AVAILABLE: (propertyName: string) => `${propertyName} er ikke tilgjengelig for denne enheten`,
    CUSTOMER_DATA_LOAD_ERROR: "Kundedata kunne ikke lastes",
    GENERIC_DATA_LOAD_ERROR: "Data kunne ikke lastes",
    UNEXPECTED_WIDGET_ERROR: "Noe gikk galt ved lasting av denne widgeten",
    NO_DATA_TO_DISPLAY_FOR_PERIOD: (period: string) => `Det er ingen data å vise for de siste ${period} dagene`,
    NO_DATA_TO_DISPLAY: "Ingen data å vise",
    NO_METRICS_FOR_SELECTED_THINGS: "Ingen metrikker tilgjengelig for valgte enheter",
    ERROR_LOADING_DATA: "Feil ved lasting av data",
  },

  thresholds: {
    [ThresholdColor.Green]: "Normal",
    [ThresholdColor.Yellow]: "Advarsel",
    [ThresholdColor.Red]: "Fare",
  } as { [key in ThresholdColor]: string },

  gaugeType: {
    [GaugeType.Classic]: "Klassisk",
    [GaugeType.Solid]: "Solid",
  } as { [key in GaugeType]: string },

  mapInitialView: {
    [MapInitialView.Default]: "Standard",
    [MapInitialView.Custom]: "Tilpasset",
  },

  colors: {
    RED: "Rød",
    YELLOW: "Gul",
  },

  charts: {
    line: "Linje",
    scatter: "Spredning",
  },

  widgets: {
    WEATHER: "Vær",
    GAUGE: "Måler",
    CLOCK: "Klokke",
    TEST: "Testgraf",
    TEXT: "Tekst",
    VALUE: "Verdi",
    STACKED_BAR_CHART: "Stablet søylediagram",
    BAR_CHART: "Søylediagram",
    CUSTOMER: "Kundeoversikt",
    TABLE: "Tabell",
    BOOKMARKS: "Bokmerker",
    OFFLINE: "Frakoblet",
    TIMESERIES_CHART: "Tidsseriegraf",
    GENERIC_CHART: "Graf",
    MAP_WIDGET: "Kart",
    TIME: "Tid",
    PROPERTY_HEALTH: "Eiendomshelse",
    IMAGE: "Bilde",

    settings: {
      DATA_SOURCE_TYPE: "Datatype",
      DATA_SOURCE: "Datakilde",
      DAY_RANGE_FROM: "Fra",
      DAY_RANGE_TO: "Til",
      DAY_RANGE: "Åpent i dager",
      EVENT: "Hendelse",
      INCIDENT_TYPE: "Hendelsestype",
      LOCATION: "Plassering",
      ORGANIZATION_ID: "Organisasjon",
      RANGE: "Område",
      THING_PROPERTY: "Metrikk",
      THINGID: "Enhet",
      THRESHOLDS: "Terskler",
      RANGE_ALARM: "Range High Risk Level",
      RANGE_WARNING: "Range Warning Level",
      RANGE_HEALTHY: "Range Healthy",
      TIME_INTERVAL: "Tidsintervall",
      TIMEZONE: "Tidssone",
      TITLE: "Tittel",
      CHART_TYPE: "Karttype",
      LEFT_AXIS_METRICS: "Venstre akse metrikker",
      RIGHT_AXIS_METRICS: "Høyre akse metrikker",
      DISPLAY_LEFT_AXIS: "Vis venstre akse",
      DISPLAY_RIGHT_AXIS: "Vis høyre akse",
      INCIDENT_DURATION: "Hendelsesperiode",
      ALL_THINGS: "Alle enheter",
      TEMPERATURE_UNIT: "Temperatur enhet",
      TIME_FORMAT: "Tidsformat",
      TWENTY_FOUR_HOUR_CLOCK: "24 Timers Klokke",
      TWELVE_HOUR_CLOCK: "12 Timers Klokke",
      OPERATOR: "Operator",
      VALUE: "Verdi",
      FILTER_BY_PROPERTY: "Filtrer enhet etter egenskap",
      ALL_PROPERTIES: "Alle egenskaper",
      PROPERTY_METRIC: "Metrikk",
      GAUGE_TYPE: "Måler type",
      AGGREGATION_TYPE: "Agregat type",
      INITIAL_MAP_VIEW: "Innledende kartvisning",
      INITIAL_MAP_VIEW_OPTIONS: "Velg det innledende senteret for kartet og det innledende kartzoomnivået",
      IMAGE_URL: "Bilde URL",
    },

    timeseriesTypes: {
      [TimeseriesType.Temperature]: "Temperatur",
      [TimeseriesType.Pressure]: "Trykk",
      [TimeseriesType.WaterConsumption]: "Vannforbruk",
      [TimeseriesType.TotalWaterConsumption]: "Totalt vannforbruk",
      [TimeseriesType.Humidity]: "Luftfuktighet",
      [TimeseriesType.AbsoluteHumidity]: "Absolutt fuktighet",
      [TimeseriesType.PaaConcentrationRollingAverage15Min]: "PAA-konsentrasjon rullende gjennomsnitt (15 min)",
      [TimeseriesType.PaaConcentrationRollingAverage8hour]: "PAA-konsentrasjon rullende gjennomsnitt (8 timer)",
      [TimeseriesType.PaaConcentration]: "PAA-konsentrasjon",
      [TimeseriesType.CaptionDataWME]: "WME",
      [TimeseriesType.CaptionDataAbsoluteHumidity]: "Absolutt luftfuktighet",
      [TimeseriesType.CaptionDataExtHumidity]: "Ekstern luftfuktighet",
      [TimeseriesType.CaptionDataExtTemperature]: "Ekstern temperatur",
    } as { [key in TimeseriesType]: string },

    descriptions: {
      [WidgetType.ChartWidget]: {
        title: "Diagram",
        text: "Viser et diagram med ulike sensorverdier og alarmer",
      },
      [WidgetType.ClockWidget]: {
        title: "Klokke",
        text: "Viser gjeldende tid for en valgt tidssone",
      },
      [WidgetType.CustomerWidget]: {
        title: "Kundeoversikt",
        text: "Viser kontaktinformasjon, enhetsliste og enhetsalarmer for en bestemt kunde",
      },
      [WidgetType.GaugeWidget]: {
        title: "Måler",
        text: "Viser gjeldende status for ulike sensorverdier",
        validationErrorMessage: "Verdien må være større enn forrige verdi",
      },
      [WidgetType.MapWidget]: {
        title: "Kart",
        text: "Viser et kart med ulike sensorverdier og alarmer",
      },
      [WidgetType.StackedBarChartWidget]: {
        title: "Stablet stolpediagram",
        text: "Viser et stolpediagram med verdier stablet oppå hverandre",
      },
      [WidgetType.TextWidget]: {
        title: "Tekstwidget",
        text: "Dynamisk tekstblokk",
      },
      [WidgetType.ValueWidget]: {
        title: "Verdi",
        text: "Viser gjeldende verdi for valgte sensorverdier",
      },
      [WidgetType.WeatherWidget]: {
        title: "Vær",
        text: "Viser gjeldende værforhold for en bestemt geografisk plassering",
      },
      [WidgetType.BarChartWidget]: {
        title: "Stolpediagram",
        text: "Viser et stolpediagram for ulike metrikker.",
      },
      [WidgetType.TableWidget]: {
        title: "Tabell",
        text: "Viser en tabell med ulike sensorverdier og alarmer",
      },
      [WidgetType.BookmarksWidget]: {
        title: "Bokmerker",
        text: "Viser bokmerker for enheter og hendelser",
      },
      [WidgetType.HealthWidget]: {
        title: "Eiendomshelse",
        text: "Viser eiendommens helsekarakteristikker",
      },
      [WidgetType.ImageWidget]: {
        title: "Bilde",
        text: "Viser et bilde fra en URL",
      },
    } as { [key in WidgetType]: { title: string; text: string; validationErrorMessage?: string } },
  },

  propertyHealthWidgetInfo: {
    fuelPoverty:
      "Å overvåke drivstofffattigdom er avgjørende for å løse problemer med rimelighet og komfort. Ved å identifisere energiineffektivitet kan eiendomseiere hjelpe leietakere med å redusere varme- og energikostnadene samtidig som de sikrer et komfortabelt bomiljø.",

    insulation:
      "Isolasjonsmåling er avgjørende for leietakers komfort, energieffektivitet og forebygging av problemer. Utilstrekkelig isolasjon fører til ubehag, høyere energiforbruk og potensiell skade på eiendom. Ved å måle isolasjonsnivåer kan eiere identifisere områder for forbedring, forbedre isolasjonen og skape et komfortabelt, bærekraftig bomiljø.",

    ventilation:
      "Overvåking av ventilasjon i eiendommer er avgjørende for helse, komfort og bærekraft. Å håndtere ventilasjonsproblemer raskt sikrer bedre innendørs luftkvalitet, forhindrer fuktproblemer og forbedrer energieffektiviteten.",

    condensation: `Overvåking og adressering av kondensrisiko er avgjørende for vedlikehold av eiendom. Ved å identifisere og håndtere kondensproblemer raskt, kan eiendomseiere forhindre fuktrelaterte problemer, bevare eiendommens strukturelle integritet og opprettholde et sunt bomiljø.`,
  },

  propertyMetricMould: {
    text: "Overvåking og håndtering av muggrisiko er avgjørende for eiendommens velvære. Ved å identifisere og behandle muggproblemer raskt, kan eiendomseiere forhindre helsefare, opprettholde eiendommens strukturelle integritet og sikre et trygt og sunt bomiljø for leietakere.",
  },

  tableWidget: {
    UNKNOWN_ADDRESS: "Ukjent adresse",
    MORE: "Mer",
    ALARM_TABLE_TITLE: "Siste alarmer",
    STACKS_CREATED_TABLE_TITLE: "Prosjektduration",

    projectDurationTable: {
      tableHeaders: {
        ADDRESS: "Adresse",
        CREATED: "Dager",
      },
    },
  },

  timeIntervals: {
    [TimeInterval.TwentyFourHours]: "24t",
    [TimeInterval.ThreeDays]: "3d",
    [TimeInterval.SevenDays]: "7d",
    [TimeInterval.FourteenDays]: "14d",
    [TimeInterval.ThirtyDays]: "30d",
    [TimeInterval.SixtyDays]: "60d",
    [TimeInterval.NinetyDays]: "90d",
    [TimeInterval.OneHundredEightyDays]: "180d",
    [TimeInterval.ThreeHundredSixtyFiveDays]: "365d",
    custom: "Egendefinert",
  },

  dashboards: {
    ALL_DASHBOARDS: "Alle instrumentbord",
    CREATE_DASHBOARD: "Opprett instrumentbord",
    ADD_WIDGET: "Legg til widget",
    ADD_A_WIDGET: "Legg til en widget",
    ADD_WIDGET_PLACEHOLDER: "--- Velg widget ---",
  },

  [WidgetType.CustomerWidget]: {
    TITLE: "Kontakt",
    NAME: "Navn",
    MOBILE: "Mobil",
    ADDRESS: "Adresse",
  },

  defaultTitle: {
    [WidgetType.WeatherWidget]: "Ny vær-widget",
    [WidgetType.GaugeWidget]: "Ny måler",
    [WidgetType.ClockWidget]: "Ny klokke",
    [WidgetType.TextWidget]: "Ny tekst-widget",
    [WidgetType.ValueWidget]: "Ny verdi-widget",
    [WidgetType.StackedBarChartWidget]: "Ny stabelstolpediagram",
    [WidgetType.CustomerWidget]: "Kontakt", // Dette brukes ikke for øyeblikket
    [WidgetType.MapWidget]: "Ny kart-widget",
    [WidgetType.ChartWidget]: "Ny graf",
    [WidgetType.BarChartWidget]: "Ny stolpediagram",
    [WidgetType.TableWidget]: "Ny tabell",
    [WidgetType.BookmarksWidget]: "Nye bokmerker",
    [WidgetType.HealthWidget]: "Ny eiendomshelse",
    [WidgetType.OfflineWidget]: "Ny frakoblet-widget",
    [WidgetType.ImageWidget]: "Ny bilde-widget",
  } as { [key in WidgetType]: string },

  customerPicker: {
    PLACEHOLDER: "Skriv navn, adresse eller telefonnummer...",
    NO_RESULTS: "Ingen resultater for",
  },

  bookmarks: {
    LAST_ERRAND: "Siste oppdrag",
    NO_ACTIVE_ERRAND: "Ingen aktive oppdrag",
    NO_BOOKMARKS: "Du har ingen bokmerker",
    NOT_FOUND: "Ikke funnet",
  },

  modals: {
    confirmation: {
      YES: "Ja",
      NO: "Nei",
    },
    DELETE_WIDGET_MESSAGE: "Er du sikker på at du vil slette denne widgeten?",
    DELETE_DASHBOARD_MESSAGE: "Er du sikker på at du vil slette dette instrumentbordet?",
  },

  shareSettings: {
    TITLE: "Delingsinnstillinger",
    DESCRIPTION: "Alle kan få tilgang til dette instrumentbordet via lenke",
    COPY_LINK: "Kopier lenke",
    COPIED: "Lenke kopiert",
  },

  propertyFilters: {
    [HealthFilterValue.All || FilterValue.All]: "All",
    [HealthFilterValue.HighRisk]: "High risk",
    [HealthFilterValue.MediumRisk]: "Medium risk",
    [HealthFilterValue.Normal]: "Normal",
    [HealthFilterValue.NoClassification]: "No classification",
    [FilterValue.Alarm]: "Alarm",
    [FilterValue.Warning]: "Warning",
    [FilterValue.Healthy]: "Healthy",
  },

  propertyMetric: {
    [PropertyMetric.FuelPoverty]: "Brenselsskatt (antatt)",
    [PropertyMetric.Insulation]: "Isolasjon",
    [PropertyMetric.Ventilation]: "Ventilasjon",
    [PropertyMetric.InternalMoisture]: "Interne ugunstige fuktighetshendelser",
    [PropertyMetric.Condensation]: "Kondens/Muggfare",
  },

  noClassificationReason: {
    [NoClassificationReason.THRESHOLD]: "Terskel",
    [NoClassificationReason.NOT_ENOUGH_DATA]: "Ikke nok data",
    [NoClassificationReason.MISSING_OUTDOOR_SENSOR]: "Mangler utendørs sensor",
  },

  aggregationType: {
    [AggregationType.Aggregated]: "Aggregert",
    [AggregationType.NonAggregated]: "Ikke-aggregert",
  },
};
export default nb;
