export const isEmpty = (obj: any) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
};

const sortObjectKeys = (key: string, value: any) =>
  value instanceof Object && !(value instanceof Array)
    ? Object.keys(value)
        .sort()
        .reduce((sorted, key) => {
          sorted[key] = value[key];
          return sorted;
        }, {} as Record<string, any>)
    : value;

export class LooseEqualityMap<K, V> {
  private inner = new Map<string, V>();

  constructor(items: [K, V][] = []) {
    items.forEach(([k, v]: any) => this.set(k, v));
  }

  get(key: K): V | undefined {
    return this.inner.get(JSON.stringify(key, sortObjectKeys));
  }

  set(key: K, value: V) {
    return this.inner.set(JSON.stringify(key, sortObjectKeys), value);
  }

  entries() {
    return Array.from(this.inner.entries()).map(([k, v]: any) => [JSON.parse(k), v]);
  }

  values() {
    return Array.from(this.inner.values());
  }

  keys() {
    return Array.from(this.inner.keys());
  }

  toJSON() {
    return Object.fromEntries(this.entries());
  }
}
