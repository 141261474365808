import { useModal } from "@ebay/nice-modal-react";
import styled from "@emotion/styled/macro";
import { Button, ContentContainer, Input, PageContainer } from "components/common";
import DashboardListActionsDropdown from "components/dropdowns/DashboardListActionsDropdown";
import FairtrailLink from "components/FairtrailLink";
import { BuildingIcon, CrownIcon, SearchIcon, UserIcon } from "components/icons";
import CreateDashboardModal from "components/modals/CreateDashboardModal";
import { DashboardPurpose } from "constants/dashboardConstants";
import { FAIRTRAIL_DASHBOARD_ROUTE } from "constants/fairtrailRoutes";
import { DashboardActivity } from "constants/permissionConstants";
import { DASHBOARD_ROUTE } from "constants/routes";
import { observer } from "mobx-react-lite";
import { DashboardMetadata } from "models/Dashboard";
import { useEffect, useMemo, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useDebounce } from "react-use";
import { useStore } from "stores/RootStore";
import { formatDate } from "utils/datetimeUtils";
import { hasActivity } from "utils/permissionUtils";
import language from "../translations/language";

enum SortingOption {
  Name = "name",
  LastModified = "lastModified",
  Owner = "owner",
}

const IndexPage = () => {
  const { dashboardStore } = useStore();
  const [query, setQuery] = useState<string>();
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [sortingOption, setSortingOption] = useState<SortingOption>(SortingOption.LastModified);
  const [sortingOrder, setSortingOrder] = useState<number>(-1);

  useDebounce(() => setDebouncedQuery(query ?? ""), 300, [query]);

  useEffect(() => {
    dashboardStore.loadDashboards(debouncedQuery);
  }, [debouncedQuery, dashboardStore]);

  const createDashboardModal = useModal(CreateDashboardModal);

  const createDashboard = async () => {
    await createDashboardModal.show();
  };

  const changeSortingOption = (option: SortingOption) => {
    if (sortingOption === option) {
      setSortingOrder(sortingOrder * -1);
    } else {
      setSortingOption(option);
      setSortingOrder(1);
    }
  };

  const SortableTd = ({ sortOption, width, title }: { sortOption: SortingOption; width?: string; title: string }) => {
    return (
      <S.ClickableTd style={{ width }} onClick={() => changeSortingOption(sortOption)}>
        <S.TdContainer>
          {title}
          {sortOption === sortingOption && (
            <S.SortingChevronContainer>
              {sortingOrder === 1 ? <FiChevronUp /> : <FiChevronDown />}
            </S.SortingChevronContainer>
          )}
        </S.TdContainer>
      </S.ClickableTd>
    );
  };

  const sortedDashboardsList = useMemo(() => {
    return dashboardStore.dashboards.sort((a, b) => {
      switch (sortingOption) {
        case SortingOption.Name:
          return a.name.localeCompare(b.name) * sortingOrder;
        case SortingOption.LastModified:
          return (a.modified.getTime() - b.modified.getTime()) * sortingOrder;
        case SortingOption.Owner:
          return a.owner.name.localeCompare(b.owner.name) * sortingOrder;
        default:
          return 1;
      }
    });
  }, [dashboardStore.dashboards, sortingOption, sortingOrder]);

  return (
    <PageContainer>
      <ContentContainer>
        <S.Container>
          <S.TopRow>
            <div>
              <S.SearchInputContainer>
                <Input
                  style={{ minWidth: 500 }}
                  placeholder={language.SEARCH}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />

                <Button size="lg">
                  <S.ButtonIconContainer>
                    <SearchIcon />
                  </S.ButtonIconContainer>
                </Button>
              </S.SearchInputContainer>
            </div>
            {hasActivity(DashboardActivity.CreateDashboard) && (
              <Button size="lg" onClick={createDashboard} success>
                {language.dashboards.CREATE_DASHBOARD}
              </Button>
            )}
          </S.TopRow>

          <S.Table style={{ width: "100%" }} cellSpacing="0" cellPadding="0">
            <S.TableHeader>
              <tr>
                <SortableTd sortOption={SortingOption.Name} width="30%" title={language.NAME} />
                <SortableTd sortOption={SortingOption.LastModified} title={language.MODIFIED} />
                <SortableTd sortOption={SortingOption.Owner} title={language.OWNER} />
                <S.RightAlignedTd>{language.ACTIONS}</S.RightAlignedTd>
              </tr>
            </S.TableHeader>
            <tbody>
              {sortedDashboardsList.length === 0 ? (
                <S.TableRow>
                  <S.TableCell colSpan={4} style={{ textAlign: "center" }}>
                    Empty
                  </S.TableCell>
                </S.TableRow>
              ) : (
                sortedDashboardsList.map(({ dashboardId, name, owner, modified, metadata }) => (
                  <S.TableRow key={dashboardId}>
                    <S.TableCell key={dashboardId}>
                      <S.FairtrailLink
                        to={DASHBOARD_ROUTE.replace(":dashboardId", dashboardId)}
                        href={FAIRTRAIL_DASHBOARD_ROUTE.replace(":dashboardId", dashboardId)}
                        title={dashboardMetaOverview(metadata)}
                      >
                        {name}
                      </S.FairtrailLink>
                    </S.TableCell>
                    <S.TableCell>{formatDate(modified)}</S.TableCell>
                    <S.TableCell>{owner.name}</S.TableCell>
                    <S.TableCell>
                      <DashboardListActionsDropdown dashboardId={dashboardId} />
                    </S.TableCell>
                  </S.TableRow>
                ))
              )}
            </tbody>
          </S.Table>
        </S.Container>
      </ContentContainer>
    </PageContainer>
  );
};

export default observer(IndexPage);

const dashboardMetaOverview = (meta: DashboardMetadata) => {
  switch (meta.dashboardPurpose) {
    case DashboardPurpose.Generic:
      return language.dashboardPurpose[meta.dashboardPurpose];
    case DashboardPurpose.Customer:
      return `${language.dashboardPurpose[meta.dashboardPurpose]}: ${meta.customerId}`;
    case DashboardPurpose.Stack:
      return `${language.dashboardPurpose[meta.dashboardPurpose]}: ${meta.stackId}`;
    default:
      return "";
  }
};

// prettier-ignore
const S = {
  Container: styled.div(({ theme }) => `
    ${theme.color.bg.neutral100}
    ${theme.spacing.p(6)}
    border-radius: 5px;
  `),
  
  TopRow: styled.div(({ theme }) => `
    display: flex;
    justify-content: space-between;

    ${theme.spacing.mb(6)}
  `),

  SearchInputContainer: styled.div(({ theme }) => `
    display: flex;

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `),

  Table: styled.table(() => `
    border-collapse: collapse;
  `),

  TableHeader: styled.thead(({ theme }) => `
    td {
      ${theme.spacing.h(10)}
      ${theme.color.bg.neutral700}
      ${theme.color.text.neutral700n}
      ${theme.spacing.px(2)}
    }
  `),

  TableRow: styled.tr(({ theme }) => `
    border-bottom: 1px solid ${theme.color.neutral400};
    ${theme.color.text.primary400}

    &:last-child {
      border: none;
    }
  `),

  TableCell: styled.td(({ theme }) => `
    ${theme.spacing.px(2)}
    ${theme.spacing.h(12)}

    &:last-child {
      ${theme.spacing.pl(0)}
    }
  `),

  IconContainer: styled.div(({ theme }) => `
    flex-shrink: 0;
    ${theme.color.text.primary}
    ${theme.spacing.w(6)}
    ${theme.spacing.h(6)}
  `),

  ButtonIconContainer: styled.div(({ theme }) => `
    display: inline-block;
    ${theme.spacing.w(6)}
    ${theme.spacing.h(6)}
  `),

  FairtrailLink: styled(FairtrailLink)(({ theme }) => `
    display: flex;
    align-items: center;
    text-decoration: none;

    gap: ${theme.spacing(2)};
    min-height: ${theme.spacing(12)};
    ${theme.spacing.px(2)}
    ${theme.spacing.py(2)}
    ${theme.color.text.primary}
    font-weight: bold;

    &:hover {
      ${theme.spacing.pl(3)}
      transition: padding 0.2s ease;
    }
  `),

  RightAlignedTd : styled.td(({ theme }) => `
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `),

  TdContainer : styled.span(({ theme }) => `
    display: flex;
    align-items: center;
  `),

  SortingChevronContainer: styled.div(({ theme }) => `
    display: flex;
    align-items: center;
    ${theme.spacing.px(2)}
    margin-bottom: 1px;
  `),

  ClickableTd: styled.td(({ theme }) => `
    cursor: pointer;
    user-select: none;
  `),

};
