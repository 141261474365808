import { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { observer } from "mobx-react-lite";

import { Button } from "components/common";
import { MapOptions } from "data/widgetData";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { Map, Icon, Point } from "leaflet";
import language from "translations/language";

import { useGeoTaggedDataFromSource } from "datasources/hooks";
import { GeoTaggedDataPoint } from "datasources/geoTaggedData/interfaces";
import { hasData } from "models/Loading";
import { MarkerGreen } from "data/thumbnails";

interface Props {
  onChange: (options: MapOptions) => any;
  value: MapOptions | null;
  dataSource: any;
}

const DEFAULT_MAP_SETTINGS = {
  center: {
    lat: 59.3144,
    lng: 18.0737,
  },
  zoom: 13,
} as const;

const iconOk = new Icon({
  iconUrl: MarkerGreen,
  iconSize: new Point(21, 25),
  iconAnchor: [10.5, 25],
});

const MapOptionsPicker = ({ onChange, dataSource, value }: Props) => {
  const [map, setMap] = useState<Map | null>(null);

  const [latitude, setLatitude] = useState<string>(
    value ? Number(value?.latitude).toFixed(4) : DEFAULT_MAP_SETTINGS.center.lat.toString()
  );
  const [longitude, setLongitude] = useState<string>(
    value ? Number(value?.longitude).toFixed(4) : DEFAULT_MAP_SETTINGS.center.lng.toString()
  );
  const [zoom, setZoom] = useState<number>(value?.zoom ?? DEFAULT_MAP_SETTINGS.zoom);
  const [isReady, setIsReady] = useState(false);

  const geoTaggedData = useGeoTaggedDataFromSource(dataSource);
  const [points, setPoints] = useState<GeoTaggedDataPoint[]>([]);
  const isLoaded = hasData(geoTaggedData);

  const fitBounds = (points: GeoTaggedDataPoint[]) => {
    if (points.length) {
      const bounds: [number, number][] = points.map((p) => [p.lat, p.lng]);
      map?.fitBounds(bounds);
    } else {
      map?.setView(DEFAULT_MAP_SETTINGS.center, DEFAULT_MAP_SETTINGS.zoom);
    }
  };

  useEffect(() => {
    if (isLoaded) {
      setPoints(geoTaggedData.points);
      fitBounds(geoTaggedData.points);
      onViewChange();
    } else {
      setPoints([]);
    }
  }, [
    isLoaded,
    dataSource,
    dataSource.allThings,
    dataSource.thingIds,
    dataSource.customerId,
    dataSource.allProperties,
    dataSource.mapInitialView,
  ]);

  useEffect(() => {
    if (map) {
      if (value) {
        map?.setView({ lat: Number(latitude), lng: Number(longitude) }, zoom);
      } else {
        fitBounds(points);
        onViewChange();
      }
    }
  }, [map]);

  useEffect(() => {
    // this is a hack that map can render well inside modal
    setTimeout(() => {
      setIsReady(true);
    }, 0);
  }, []);

  const onViewChange = () => {
    if (map) {
      const center = map.getCenter();
      const zoom = map.getZoom();

      setLatitude(center.lat.toFixed(4));
      setLongitude(center.lng.toFixed(4));
      setZoom(zoom);

      onChange({ zoom, latitude: center.lat.toString(), longitude: center.lng.toString() });
    }
  };

  const onMove = useCallback(() => {
    onViewChange();
  }, [map]);

  useEffect(() => {
    map?.on("move", onMove);
    return () => {
      map?.off("move", onMove);
    };
  }, [map, onMove]);

  const onResetHandle = useCallback(
    (e: any) => {
      e.preventDefault();

      fitBounds(points);
      onViewChange();
    },
    [map, points]
  );

  return (
    <S.Picker>
      <S.Map>
        {isReady && (
          <MapContainer
            style={{ height: "100%", minHeight: "100%" }}
            center={{ lat: Number(latitude), lng: Number(longitude) }}
            zoom={zoom}
            ref={setMap}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {points.map((p) => (
              <Marker data-id={p.uniqueId} position={[p.lat, p.lng]} key={`point-${p.uniqueId}`} icon={iconOk}></Marker>
            ))}
          </MapContainer>
        )}
      </S.Map>
      <S.Options>
        <S.Option>
          {language.LATITUDE}: {latitude}
        </S.Option>
        <S.Option>
          {language.LONGITUDE}: {longitude}
        </S.Option>
        <S.Option>
          {language.ZOOM}: {zoom}
        </S.Option>
        <Button size="sm" onClick={onResetHandle}>
          {language.RESET}
        </Button>
      </S.Options>
    </S.Picker>
  );
};

export default observer(MapOptionsPicker);

// prettier-ignore
const S = {
  Picker: styled.div`
    display : flex;
    margin-top: 10px;
  `,
  Map: styled.div`
    height: 300px;
    width: 100%;
    flex: 1;
    border-radius: 4px;
    overflow: hidden;
  `,
  Options: styled.div(({ theme }) => `
    width: 200px;
    padding: 0 24px;

    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
  `),
  Option: styled.div(({ theme }) => `
    ${theme.spacing.mb(2)}
  `),
}
