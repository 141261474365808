export enum DashboardActivity {
  CreateDashboard = "st.createDashboard",
  GetDashboards = "st.getDashboards",
  GetDashboard = "st.getDashboard",
  UpdateDashboard = "st.updateDashboard",
  DeleteDashboard = "st.deleteDashboard",
  ShareDashboard = "st.shareDashboard",
  DeleteSharingFromDashboard = "st.deleteSharingFromDashboard",
}

export enum WidgetActivity {
  CreateWidget = "st.createWidget",
  GetWidgets = "st.getWidgets",
  DeleteWidget = "st.deleteWidget",
  UpdateWidget = "st.updateWidget",
}

export enum AccessType {
  Read = "read",
  Write = "write",
  Delete = "delete",
}

export const GLOBAL = "global";
