import styled from "@emotion/styled/macro";

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// prettier-ignore
export const ContentContainer = styled.div(({ theme }) => `
  max-width: 1400px;
  width: 100%;
`);
