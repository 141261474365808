export enum WidgetType {
  TextWidget = "textWidget",
  ClockWidget = "clockWidget",
  GaugeWidget = "gaugeWidget",
  WeatherWidget = "weatherWidget",
  ChartWidget = "chartWidget",
  ValueWidget = "valueWidget",
  StackedBarChartWidget = "stackedBarChartWidget",
  CustomerWidget = "customerWidget",
  MapWidget = "mapWidget",
  BarChartWidget = "barChartWidget",
  TableWidget = "tableWidget",
  BookmarksWidget = "bookmarksWidget",
  HealthWidget = "healthWidget",
  OfflineWidget = "offlineWidget",
  ImageWidget = "imageWidget",
}

export enum TimeInterval {
  TwentyFourHours = "24h",
  ThreeDays = "3d",
  SevenDays = "7d",
  FourteenDays = "14d",
  ThirtyDays = "30d",
  SixtyDays = "60d",
  NinetyDays = "90d",
  OneHundredEightyDays = "180d",
  ThreeHundredSixtyFiveDays = "365d",
}

export enum ChartType {
  Line = "line",
  Scatter = "scatter",
}

export enum TimeRangeType {
  DateRange = "dateRange",
  TimeInterval = "timeInterval",
}

export enum SingleValueSourceTypes {
  Thing = "thing",
  IncidentStats = "incidentStats",
}

export enum GaugeType {
  Classic = "classic",
  Solid = "solid",
}

export enum MapInitialView {
  Default = "default",
  Custom = "custom",
}

export enum MultipleThingValueSourceTypes {
  Thing = "thing",
}

export enum LiveThingProperty {
  Temperature = "temperature",
  WifiSignalStrength = "wifiSignalStrength",
  RelativeHumidity = "relativeHumidity",
  WaterPressure = "waterPressure",
  BatteryLevel = "batteryLevel",
  Humidity = "humidity",
  /**
   * These are prefixed with CaptionData because it doesn't follow camel case convention,
   * so it's not to cause confusion if we ever have this somewhere (where it will most likely be camel cased)
   */
  CaptionDataAbsoluteHumidity = "absolutehumidity",
  CaptionDataWME = "wmevalue",
}

export enum IncidentType {
  Offline = "offline",
  FailedInstallations = "failedInstallations",
  Alarms = "alarms",
}

export enum IncidentAllTypes {
  LargeWaterFlowAlarm = "largeWaterFlowAlarm",
  LargeWaterVolumeAlarm = "largeWaterVolumeAlarm",
  LongWaterFlowAlarm = "longWaterFlowAlarm",
  PressureAlarm1 = "pressureAlarm1",
  PressureAlarm2 = "pressureAlarm2",
  WaterleakageUnknown = "waterleakage_unknown",
  PassiveLoss = "passiveLoss",
  ActiveLoss = "activeLoss",
  Waterleakage = "waterleakage",
  Slowleakage = "slowleakage",
  LeakAlarm = "leakAlarm",
  Offline = "offline",
  Other = "other",
  Wpa2 = "wpa2",
  Wifi = "wifi",
  SolvedThemselves = "solved_themselves",
  WarrentyReplacement = "warrenty_replacement",
  ReturnSensor = "return_sensor",
  Test = "test",
  FalseAlarm = "false_alarm",
  Followup = "followup",
  FailedInstallations = "failed_installations",
  LowBattery = "lowBattery",
}

export const FAKE_WIDGET_ID = "fakeWidgetId";

export enum FilterValue {
  All = "all",
  Alarm = "alarm",
  Warning = "warning",
  Healthy = "healthy",
}

export enum HealthFilterValue {
  All = "all",
  HighRisk = "highRisk",
  MediumRisk = "mediumRisk",
  Normal = "normal",
  NoClassification = "noClassification",
}

export enum PropertyMetric {
  FuelPoverty = "fuelPoverty",
  Insulation = "insulation",
  Ventilation = "ventilation",
  InternalMoisture = "internalMoisture",
  Condensation = "condensation",
}
