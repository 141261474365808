import { useTheme } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { DashboardTheme } from "constants/dashboardConstants";
import ReactSelect, { StylesConfig } from "react-select";
import { deserializeThemeCSS } from "theme/utils";

const PolygonDropdownIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 12L2.80385 6L13.1962 6L8 12Z" fill="currentColor" />
  </svg>
);

type StyleProp = React.CSSProperties | ((...args: any[]) => React.CSSProperties);
type SelectProps = React.ComponentProps<typeof ReactSelect> & {
  size?: "sm" | "md";
  style?: StyleProp;
};

export const Select = ({ size = "md", ...props }: SelectProps) => {
  const theme = useTheme();
  const isSmall = size === "sm";

  const selectStyles: StylesConfig = {
    container: (styles, _state) => ({
      ...styles,
      ...(props.style ?? {}),
      input: {
        boxShadow: "none",
      },
    }),

    control: (styles, state) => ({
      ...styles,
      height: "inherit",
      minHeight: "initial",
      backgroundColor: theme.color.neutral100,
      borderColor: state.isDisabled
        ? theme.color.neutral300
        : state.menuIsOpen
        ? `${theme.color.neutral700} !important`
        : theme.color.neutral700,
      borderRadius: theme.borderRadius.md,
      color: theme.color.text.primary,
      boxShadow: "none",
      ...deserializeThemeCSS(theme.typography.body2),

      ":focus": {
        borderColor: theme.color.neutral700,
      },
      ":hover": {
        borderColor: theme.color.neutral700,
      },
    }),

    menu: (styles, _state) => ({
      ...styles,
      ...deserializeThemeCSS(theme.typography.body2),
      backgroundColor: theme.color.neutral100,
      width: "calc(100% - 2px)",
      marginTop: theme.spacing(1),
      borderRadius: theme.borderRadius.md,
      boxShadow: theme.shadow.dropdown,
    }),

    menuList: (styles, _state) => ({
      ...styles,
      overflowX: "clip",
    }),

    option: (styles, state) => ({
      ...styles,
      margin: "0 1px",
      backgroundColor: state.isFocused || state.isSelected ? theme.color.neutral400 : "transparent",
      color: theme.color.primary,
    }),

    placeholder: (styles, _state) => ({
      ...styles,
      color: "currentcolor",
    }),

    valueContainer: (styles, _state) => ({
      ...styles,
      paddingTop: isSmall ? 1 : 3,
      paddingBottom: isSmall ? 1 : 3,
      paddingLeft: theme.spacing(2),
    }),

    singleValue: (styles, _state) => ({
      ...styles,
      color: "currentcolor",
    }),

    multiValueLabel: (styles, _state) => ({
      ...styles,
      color: "currentcolor",
    }),

    multiValue: (styles, _state) => ({
      ...styles,
      backgroundColor: "transparent",
      borderRadius: theme.borderRadius.md,
      outline: `1px solid ${
        theme.name === DashboardTheme.Dark || theme.name === DashboardTheme.DarkPale
          ? theme.color.primary300
          : theme.color.primary200
      }`,
      padding: 0,
    }),

    indicatorsContainer: (styles, _state) => ({
      ...styles,
      marginTop: "-1px",
      height: "inherit",
    }),

    clearIndicator: (styles, _state) => ({
      ...styles,
      color: theme.color.text.primary,
      alignSelf: "stretch",
      display: "flex",
      alignItems: "center",
      svg: {
        opacity: 0.5,
      },

      ":hover": {
        svg: {
          opacity: 0.7,
        },
      },
    }),
  };

  return (
    <SReactSelect
      theme={theme as any}
      style={props.style}
      styles={selectStyles}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      menuPlacement="auto"
      menuShouldScrollIntoView={false}
      components={{
        DropdownIndicator: () => (
          <SDropdownIndicatorInner>
            <PolygonDropdownIcon />
          </SDropdownIndicatorInner>
        ),
        IndicatorSeparator: () => null,
      }}
      {...props}
    />
  );
};

export default Select;

// prettier-ignore
const SDropdownIndicatorInner = styled.div<{rounded?: boolean}>(({ theme }) => `
  background: transparent;
  ${theme.spacing.pr(3)}
  ${theme.spacing.pl(1)}
  align-self: stretch;
  display: flex;
  align-items: center;
  border-radius: ${`0 ${theme.borderRadius.md} ${theme.borderRadius.md} 0;`};
`);

const SReactSelect = styled(ReactSelect)`
  input {
    color: ${(props) => props.theme.color.primary} !important;
  }
`;
