import { WidgetType } from "constants/widgetConstants";
import { BookmarksWidgetViewModel } from "models/widgets/BookmarksWidget";
import { GeoDataSourceTypes, MapWidgetViewModel } from "models/widgets/MapWidget";
import { OfflineWidgetViewModel } from "models/widgets/OfflineWidget";
import { MultipleAlarmsValueSourceTypes, TableWidgetViewModel } from "models/widgets/TableWidget";
import { WidgetViewModel } from "models/widgets/Widget";
import language from "translations/language";

export const ROW_H_PX = 20;
export const MARGIN_PX = 10;

const BOOKMARKS_H_U = 6.7;

export const HomeDashboardWidgets = (height: number): WidgetViewModel[] => [
  {
    widgetId: "1",
    dashboardId: "7224247e-9b9c-419d-9bb0-48293837d467",
    created: new Date(),
    modified: new Date(),
    type: WidgetType.MapWidget,
    settings: {
      title: language.widgets.MAP_WIDGET,
      position: { x: 0, y: 0 },
      size: {
        width: 7,
        /**
         * bookmarks widget height is fixed at 8 units
         * we calculate the height of the map widget so that widgets fill the entire space and scroll doesn't appear
         * how height works see here https://github.com/react-grid-layout/react-grid-layout#grid-item-heights-and-widths
         * row_h_px * map_h_u + margin * (map_h_u - 1) = window_h - row_h_px * bookmark_h_u - margin * (bookmark_h_u - 1)
         */
        height: Math.min(
          (height - 64 - BOOKMARKS_H_U * ROW_H_PX - MARGIN_PX * (BOOKMARKS_H_U - 1) + MARGIN_PX) /
            (MARGIN_PX + ROW_H_PX),
          24
        ),
      },
      dataSource: {
        sourceType: GeoDataSourceTypes.CustomerStackLocations,
        allProperties: true,
      },
    },
    dimensions: {},
  } as MapWidgetViewModel,
  {
    widgetId: "2",
    dashboardId: "7224247e-9b9c-419d-9bb0-48293837d467",
    created: new Date(),
    modified: new Date(),
    type: WidgetType.TableWidget,
    settings: {
      title: language.tableWidget.ALARM_TABLE_TITLE,
      position: { x: 7, y: 0 },
      size: { width: 5, height: 14.4 },
      dataSource: { sourceType: MultipleAlarmsValueSourceTypes.Alarms },
      color: null,
    },
    dimensions: {},
  } as TableWidgetViewModel,
  {
    widgetId: "3",
    dashboardId: "7224247e-9b9c-419d-9bb0-48293837d467",
    created: new Date(),
    modified: new Date(),
    type: WidgetType.BookmarksWidget,
    settings: {
      title: language.widgets.BOOKMARKS,
      position: {
        x: 0,
        y: Math.min(
          (height - 64 - BOOKMARKS_H_U * ROW_H_PX - MARGIN_PX * (BOOKMARKS_H_U - 1) + MARGIN_PX) /
            (MARGIN_PX + ROW_H_PX),
          24
        ),
      },
      size: { width: 7, height: BOOKMARKS_H_U },
    },
    dimensions: {},
  } as BookmarksWidgetViewModel,
  {
    widgetId: "4",
    dashboardId: "7224247e-9b9c-419d-9bb0-48293837d467",
    created: new Date(),
    modified: new Date(),
    type: WidgetType.OfflineWidget,
    settings: {
      title: language.widgets.OFFLINE,
      position: {
        x: 7,
        y: 14.4,
      },
      size: { width: 5, height: 7.3 },
    },
    dimensions: {},
  } as OfflineWidgetViewModel,
];

export const HomeDashboardWidgetsForTablet = (height: number): WidgetViewModel[] => [
  {
    widgetId: "1",
    dashboardId: "7224247e-9b9c-419d-9bb0-48293837d467",
    created: new Date(),
    modified: new Date(),
    type: WidgetType.MapWidget,
    settings: {
      title: language.widgets.MAP_WIDGET,
      position: { x: 0, y: 0 },
      size: { width: 12, height: 20 },
      dataSource: {
        sourceType: GeoDataSourceTypes.CustomerStackLocations,
        allProperties: true,
      },
    },
    dimensions: {},
  } as MapWidgetViewModel,
  {
    widgetId: "2",
    dashboardId: "7224247e-9b9c-419d-9bb0-48293837d467",
    created: new Date(),
    modified: new Date(),
    type: WidgetType.TableWidget,
    settings: {
      title: language.tableWidget.ALARM_TABLE_TITLE,
      position: { x: 0, y: 20 },
      size: { width: 12, height: 15 },
      dataSource: { sourceType: MultipleAlarmsValueSourceTypes.Alarms },
      color: null,
    },
    dimensions: {},
  } as TableWidgetViewModel,
  {
    widgetId: "3",
    dashboardId: "7224247e-9b9c-419d-9bb0-48293837d467",
    created: new Date(),
    modified: new Date(),
    type: WidgetType.OfflineWidget,
    settings: {
      title: language.widgets.OFFLINE,
      position: { x: 0, y: 35 },
      size: { width: 12, height: 7 },
    },
    dimensions: {},
  } as OfflineWidgetViewModel,
  {
    widgetId: "4",
    dashboardId: "7224247e-9b9c-419d-9bb0-48293837d467",
    created: new Date(),
    modified: new Date(),
    type: WidgetType.BookmarksWidget,
    settings: {
      title: language.widgets.BOOKMARKS,
      position: { x: 0, y: 42 },
      size: { width: 12, height: 7 },
    },
    dimensions: {},
  } as BookmarksWidgetViewModel,
];
