import Dashboard from "components/dashboard/Dashboard";
import DashboardMenu from "components/dashboard/DashboardMenu";
import { useParams } from "react-router-dom";

const DashboardViewPage = () => {
  const { dashboardId } = useParams<{ dashboardId: string }>();

  return (
    <>
      <DashboardMenu dashboardId={dashboardId!} />
      <Dashboard dashboardId={dashboardId!} isEditable={false} />
    </>
  );
};

export default DashboardViewPage;
