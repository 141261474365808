import { Theme, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { FilterValue, HealthFilterValue } from "constants/widgetConstants";
import { useState } from "react";
import { ThemeProps } from "theme/types";
import language from "translations/language";

export type Filter = {
  id: FilterValue | HealthFilterValue;
  count: number;
};

type FiltersProps = {
  filters: Filter[];
  onApplyFilter: (value: any) => void;
  selectedFilter?: FilterValue | HealthFilterValue;
};

const colorForFilter = (theme: Theme, id: FilterValue | HealthFilterValue) => {
  switch (id) {
    case FilterValue.Healthy:
    case HealthFilterValue.Normal:
      return theme.color.success400;
    case FilterValue.Warning:
    case HealthFilterValue.MediumRisk:
      return theme.color.warning400;
    case FilterValue.Alarm:
    case HealthFilterValue.HighRisk:
      return theme.color.error400;
    default:
      return theme.color.primary;
  }
};

const Filters = ({ filters, onApplyFilter, selectedFilter }: FiltersProps) => {
  const theme = useTheme();

  const [currentFilter, setCurrentFilter] = useState(
    selectedFilter ? selectedFilter : (FilterValue.All as FilterValue | HealthFilterValue)
  );

  const applyFilter = (id: FilterValue | HealthFilterValue) => {
    setCurrentFilter(id);
    onApplyFilter(id);
  };

  return (
    <S.List>
      {filters.map(
        (filter) =>
          filter.count > 0 && (
            <S.Item
              key={filter.id}
              color={colorForFilter(theme, filter.id)}
              isActive={currentFilter === filter.id}
              onClick={() => applyFilter(filter.id)}
            >
              {language.propertyFilters[filter.id]} ({filter.count})
            </S.Item>
          )
      )}
    </S.List>
  );
};

// prettier-ignore
const S = {
  List: styled.div(({ theme }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    color: ${theme.color.neutral100};
    cursor: pointer;
  `),

  Item: styled.div(({ theme, color, isActive }: ThemeProps & { color: string, isActive: boolean }) => `
    background-color: ${isActive ? `${color}` : `transparent`};
    border: 1.5px solid ${color};
    color: ${!isActive ? `${color}` : `${theme.color.neutral100}`};
    padding: 5px 15px;
    border-radius: 4px;
    white-space: nowrap;

    &:hover {
      background-color: ${color};
      color: ${theme.color.neutral100};
    }
  `),
};

export default Filters;
