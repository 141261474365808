import type { SVGComponent } from "components/common/types";
import { SUPPORTED_CAPTION_DATA_WIDGETS } from "constants/captionDataConstants";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { CustomerWidgetData } from "data/customerWidget/CustomerWidgetData";
import { MapWidgetData } from "data/mapWidget/MapWidgetData";
import { Dashboard, DashboardMetadata } from "models/Dashboard";
import { EmptyWidget, WidgetSettings, WidgetViewModel } from "models/widgets/Widget";
import { rootStore } from "stores/RootStore";
import { SettingsType } from "../constants/constants";
import { BarChartWidgetData } from "./barChartWidget/BarChartWidgetData";
import { BookmarksWidgetData } from "./bookmarksWidget/bookmarksWidgetData";
import { ChartWidgetData } from "./chartWidget/ChartWidgetData";
import { ClockWidgetData } from "./clockWidget/ClockWidgetData";
import { GaugeWidgetData } from "./gaugeWidget/GaugeWidgetData";
import { StakedBarChartWidgetData } from "./stackedBarChartWidget/StackedBarChartWidgetData";
import { TableWidgetData } from "./tableWidget/TableWidgetData";
import { TextWidgetData } from "./textWidget/TextWidgetData";
import { ValueWidgetData } from "./valueWidget/ValueWidgetData";
import { WeatherWidgetData } from "./weatherWidget/WeatherWidgetData";
import { OfflineWidgetData } from "./offlineWidget/offlineWidgetData";
import { HealthData } from "./healthWidget/HealthData";
import { ImageWidgetData } from "./imageWidget/ImageWidgetData";
import { Threshold, ValueRange } from "models/widgets/common/common";

export type Settings = {
  label?: string;
  hidden?: boolean | ((settings: any) => boolean);
  disabled?: boolean | ((settings: any) => boolean);
};

export type SelectSettings = Settings & {
  type: SettingsType.Select;
  options: { key: any; value: string; color?: string }[];
  default?: string | null | (string | null)[];
  multiple?: boolean;
};

export type CompositeSelectSettings = Settings & {
  type: SettingsType.CompositeSelect;
  keys: string[];
  options: Record<string, { update: Record<string, any>; value: string; color?: string }>;
  default?: string | null;
};

export type HiddenSettings = Omit<Settings, "label"> & {
  type: SettingsType.Hidden;
};

export type TimeIntervalSettings = Settings & {
  type: SettingsType.TimeInterval;
  options: { key: any; value: string }[];
};

export type TextSettings = Settings & {
  type: SettingsType.Text;
  default?: string | null;
};

export type ImageUrlWithPreviewSettings = Settings & {
  type: SettingsType.ImageUrlWithPreview;
  default?: string | null;
};

export type IntegerSettings = Settings & {
  type: SettingsType.Integer;
  default?: number | null;
  min?: number;
  max?: number;
};

type Location = {
  label: string;
  longitude: string;
  latitude: string;
};

export type LocationSettings = Settings & {
  type: SettingsType.Location;
  default?: Location | null;
};

export type OrganizationSettings = Settings & {
  type: SettingsType.Organization;
  multiple?: boolean;
};

export type CustomerSettings = Settings & {
  type: SettingsType.Customer;
};

export type RangeSettings = Settings & {
  type: SettingsType.Range;
  default?: ValueRange | null;
  infoText?: string;
};

export type DayRangeSettings = Settings & {
  type: SettingsType.DayRange;
  default?: { from: number; to: number } | null;
};

export type ThresholdSettings = Settings & {
  type: SettingsType.Thresholds;
  default?: Threshold | null;
};

export type RadioButtonSettings = Settings & {
  type: SettingsType.RadioButton;
  options: { key: string; value: string; image?: JSX.Element }[];
  default: string;
};

export type ThingSettings = Settings & {
  type: SettingsType.Thing;
  multiple?: boolean;
  selectAll?: boolean;
};

export type ThingMetricSettings = Settings & {
  type: SettingsType.ThingMetric;
  multiple?: boolean;
  thingsIdField: string;
  options: "LiveThingProperty" | "TimeseriesType";
};

export type NestedSettings = Settings & {
  type: SettingsType.Nested;
  settings: SettingsDescriptor;
};

export type SwitchSettings = Settings & {
  type: SettingsType.Switch;
  switchField: SelectSettings;
  switchKey: string;
  options: { [key: string]: SettingsDescriptor };
};

export type ToggleSettings = Settings & {
  type: SettingsType.Toggle;
  default?: boolean;
};

export type MapOptions = {
  longitude: string;
  latitude: string;
  zoom: number;
};

export type MapOptionsSettings = Settings & {
  type: SettingsType.MapOptions;
  default?: MapOptions | null;
};

type SingleSetting =
  | IntegerSettings
  | LocationSettings
  | NestedSettings
  | RangeSettings
  | DayRangeSettings
  | OrganizationSettings
  | CustomerSettings
  | SelectSettings
  | CompositeSelectSettings
  | HiddenSettings
  | SwitchSettings
  | ToggleSettings
  | TextSettings
  | ThingSettings
  | ThingMetricSettings
  | ThresholdSettings
  | TimeIntervalSettings
  | RadioButtonSettings
  | MapOptionsSettings
  | ImageUrlWithPreviewSettings;

export type SettingsDescriptor = {
  [key: string]: SingleSetting;
};

type FilePathString = string;

export type WidgetData<TWidgetType extends WidgetType, TSettings extends WidgetSettings> = {
  readonly type: TWidgetType;

  name: string;

  description: {
    title: string;
    text: string;
    thumbnail: FilePathString | SVGComponent;
  };

  dashboardPurposes?: DashboardPurpose[];

  hideTitle?: boolean;

  hideBackground?: boolean;

  readonly settings?: SettingsDescriptor;

  /**
   * Optional function that runs before the update, can be used to transform the data.
   * What is returned from this function is what is sent to the api endpoint as widget settings.
   */
  onSave?: (settings: TSettings) => any;

  component: (props: {
    widget: WidgetViewModel<TWidgetType, TSettings>;
    readonly: boolean;
    dashboardMetadata: DashboardMetadata;
  }) => JSX.Element;
};

export function getWidgetData<T extends WidgetViewModel<any, any>>(
  widget: T | EmptyWidget
): WidgetData<typeof widget.type, typeof widget.settings> | undefined {
  if (!widget) {
    return;
  }

  switch (widget.type) {
    case WidgetType.ClockWidget:
      return ClockWidgetData;
    case WidgetType.CustomerWidget:
      return CustomerWidgetData;
    case WidgetType.TextWidget:
      return TextWidgetData;
    case WidgetType.WeatherWidget:
      return WeatherWidgetData;
    case WidgetType.GaugeWidget:
      return GaugeWidgetData;
    case WidgetType.ChartWidget:
      return ChartWidgetData;
    case WidgetType.ValueWidget:
      return ValueWidgetData;
    case WidgetType.StackedBarChartWidget:
      return StakedBarChartWidgetData;
    case WidgetType.MapWidget:
      return MapWidgetData;
    case WidgetType.BarChartWidget:
      return BarChartWidgetData;
    case WidgetType.TableWidget:
      return TableWidgetData;
    case WidgetType.BookmarksWidget:
      return BookmarksWidgetData;
    case WidgetType.HealthWidget:
      return HealthData;
    case WidgetType.OfflineWidget:
      return OfflineWidgetData;
    case WidgetType.ImageWidget:
      return ImageWidgetData;
    default:
      return;
  }
}

export function getWidgets(dashboard: Dashboard) {
  /* Note: The order of this array dictates the order in the add widget panel */
  const widgetData = [
    ChartWidgetData,
    TableWidgetData,
    ValueWidgetData,
    ImageWidgetData,
    GaugeWidgetData,
    StakedBarChartWidgetData,
    BarChartWidgetData,
    CustomerWidgetData,
    MapWidgetData,
    TextWidgetData,
    WeatherWidgetData,
    ClockWidgetData,
    BookmarksWidgetData,
    HealthData,
  ];

  if (rootStore.configStore.deptId) {
    return widgetData.filter((widgetData) => SUPPORTED_CAPTION_DATA_WIDGETS.includes(widgetData.type));
  }

  /* Filters widgets based on dashboard purpose */
  const dashboardPurpose = dashboard?.metadata?.dashboardPurpose ?? DashboardPurpose.Generic;

  return widgetData.filter((widgetData) => {
    if (widgetData.dashboardPurposes) {
      return widgetData.dashboardPurposes.includes(dashboardPurpose);
    }

    return false;
  });
}

export type WidgetWithColor<T extends WidgetViewModel> = T & {
  settings: {
    color: string | String;
  };
};

export function widgetHasColor<T extends WidgetViewModel>(widget: T | undefined): widget is WidgetWithColor<T> {
  if (!widget) {
    return false;
  }

  if (!("color" in widget.settings)) {
    return false;
  }

  const color = widget.settings.color;

  if (!(typeof color === "string" || color instanceof String)) {
    return false;
  }

  return true;
}

export function getWidgetColor(widget: WidgetViewModel | undefined) {
  if (!widgetHasColor(widget)) {
    return undefined;
  }
  return widget.settings.color.toString();
}
