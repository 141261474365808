import { useRef } from "react";
import styled from "@emotion/styled";
import { IconButton } from "components/common";
import { Dropdown } from "components/common/dropdown";
import { WidgetType } from "constants/widgetConstants";
import { useCategorisedValuesFromDataSource } from "datasources/hooks";
import { categorisedValuesToCSV } from "download/csv/dataSourceToCsv";
import exportAsPng from "download/png/exportAsPng";
import { observer } from "mobx-react";
import { hasData } from "models/Loading";
import { isStackedBarChartWidget } from "models/widgets/StackedBarChartWidget";
import { WidgetViewModel } from "models/widgets/Widget";
import { CSVLink } from "react-csv";
import { FiDownload } from "react-icons/fi";
import { useStore } from "stores/RootStore";

type Props = {
  widget: WidgetViewModel;
};

enum Action {
  Print = "print",
  Png = "png",
  Jpeg = "jpeg",
  Pdf = "pdf",
  Svg = "svg",
  Csv = "csv",
  Xls = "xls",
}

const options = {
  [Action.Print]: { id: Action.Print, name: "Print chart" },
  [Action.Png]: { id: Action.Png, name: "Download PNG image" },
  [Action.Jpeg]: { id: Action.Jpeg, name: "Download JPEG image" },
  [Action.Pdf]: { id: Action.Pdf, name: "Download PDF document" },
  [Action.Svg]: { id: Action.Svg, name: "Download SVG vector image" },
  [Action.Csv]: { id: Action.Csv, name: "Download CSV" },
  [Action.Xls]: { id: Action.Xls, name: "Download XLS" },
};

const getOptionsByGraphType = (type: WidgetType) => {
  switch (type) {
    case WidgetType.ChartWidget:
      return Object.values(options);
    case WidgetType.BarChartWidget:
      return [options.print, options.png, options.jpeg, options.pdf, options.svg];
    case WidgetType.GaugeWidget:
      return Object.values(options);
    case WidgetType.StackedBarChartWidget:
      return [options.png, options.csv];
    case WidgetType.TableWidget:
      return [options.png];
    default:
      return;
  }
};

export const ExportDropdown = ({ widget }: Props) => {
  const { dashboardStore } = useStore();
  const downloadRef = useRef<any>(null);

  const optionOnClick = (actionId: string) => {
    const ref = dashboardStore.getWidgetChartRef(widget.widgetId)?.current;
    // @ts-ignore
    const chart = ref?.chart;

    switch (actionId) {
      case Action.Print:
        return chart?.print();

      case Action.Png:
        // for WidgetType.StackedBarChartWidget, WidgetType.TableWidget
        if (ref instanceof HTMLDivElement) {
          return exportAsPng(ref, widget.settings.title);
        }

        return chart?.exportChart({ type: "image/png" }, {});

      case Action.Jpeg:
        return chart?.exportChart({ type: "image/jpeg" }, {});

      case Action.Pdf:
        return chart?.exportChart({ type: "application/pdf" }, {});

      case Action.Svg:
        return chart?.exportChart({ type: "image/svg+xml" }, {});

      case Action.Csv:
        if (isStackedBarChartWidget(widget)) {
          downloadRef.current.link.click();
          return;
        }

        return chart?.downloadCSV();

      case Action.Xls:
        return chart?.downloadXLS();

      default:
        return;
    }
  };

  const options = getOptionsByGraphType(widget.type);

  return (
    <>
      {options && (
        <S.ExportDropdown>
          <Dropdown options={options} optionOnClick={optionOnClick}>
            <IconButton>
              <FiDownload />
            </IconButton>
          </Dropdown>
        </S.ExportDropdown>
      )}

      {isStackedBarChartWidget(widget) && (
        <CSVLinkForStackedBar
          source={widget.settings.dataSource}
          filename={widget.settings.title}
          downloadRef={downloadRef}
        />
      )}
    </>
  );
};

const CSVLinkForStackedBar = observer(({ source, filename, downloadRef }: any) => {
  const data = useCategorisedValuesFromDataSource(source);
  if (!hasData(data)) return <></>;

  const csv = categorisedValuesToCSV(data).rows;
  return <CSVLink data={csv} filename={filename} ref={downloadRef} />;
});

// prettier-ignore
const S = {
    ExportDropdown: styled.div(`
        display: flex;
        align-items: center;
    `),
};
