import BarChartWidget from "components/widgets/BarChartWidget";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { BarChartWidgetSettings } from "models/widgets/BarChartWidget";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { BarChartThumbSVG } from "../thumbnails";
import { WidgetData } from "../widgetData";
import { barChartWidgetSettings } from "./barChartWidgetSettings";

export const BarChartWidgetData: WidgetData<WidgetType.BarChartWidget, BarChartWidgetSettings & WidgetSettings> = {
  type: WidgetType.BarChartWidget,

  name: language.widgets.BAR_CHART,

  description: {
    ...language.widgets.descriptions[WidgetType.BarChartWidget],
    thumbnail: BarChartThumbSVG,
  },

  dashboardPurposes: [DashboardPurpose.Generic],

  settings: barChartWidgetSettings,

  component: (props) => <BarChartWidget {...props} />,
};
