import { ReactElement, useState } from "react";
import styled from "@emotion/styled/macro";
import { Button } from "./buttons";

type TabTitleProps = {
  isActive: boolean;
  title: string;
  onClick: () => void;
};

type TabProps = {
  title: string;
  children: ReactElement | ReactElement[];
};

type TabsProps = {
  children: ReactElement<TabTitleProps>[];
};

export const Tabs = ({ children }: TabsProps) => {
  const tabs = children.filter((c) => c.props.title);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <>
      <S.TabList>
        {tabs.map((tab, index) => (
          <TabTitle
            isActive={index === activeTabIndex}
            key={tab.props.title}
            title={tab.props.title}
            onClick={() => setActiveTabIndex(index)}
          />
        ))}
      </S.TabList>

      {tabs[activeTabIndex]}
    </>
  );
};

export default Tabs;

const TabTitle = ({ title, isActive, onClick }: TabTitleProps) => (
  <Button
    ghost={!isActive}
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
  >
    {title}
  </Button>
);

export const Tab = ({ children }: TabProps): JSX.Element => <div>{children}</div>;

// prettier-ignore
const S = {
    TabList: styled.div(({theme}) =>`
        display: flex;
        flex-wrap: wrap;
        gap: ${theme.spacing(4)};
    `),
}
