import { Timeline } from "models/Timeline";
import { formatDate } from "utils/datetimeUtils";

export type EventTime = {
  date: Date;
  alarmTime: number; // "12.5" -> 12:30
  eventType: string;
  message: string;
};

export function fromTimeline(events: Timeline[], tz: string = "UTC"): EventTime[] {
  return events.map((event) => {
    const hours = parseInt(formatDate(event.created, "HH", tz));
    const minutes = parseInt(formatDate(event.created, "mm", tz));
    return {
      date: event.created,
      alarmTime: hours + minutes / 60,
      eventType: event.eventType,
      message: event.message,
    };
  });
}
