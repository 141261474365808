import { useState } from "react";
import styled from "@emotion/styled/macro";
import { RadioButtonChecked, RadioButtonUnchecked } from "../icons";

type RadioButtonProps = {
  settingsKey: string;
  options: {
    key: string;
    value: string;
    image?: JSX.Element;
  }[];
  onChange: (key: string, value: string) => void;
  defaultValue: string;
  value: string;
};

export const RadioButton = ({ settingsKey, options, onChange, defaultValue, value }: RadioButtonProps) => {
  const [currentValue, setCurrentValue] = useState<string>(value || defaultValue);

  const onChangeValue = (optionValue: string) => {
    setCurrentValue(optionValue);
    onChange(settingsKey, optionValue);
  };

  return (
    <S.RadioGroup>
      {options.map((option) => (
        <S.RadioWrap key={option.key}>
          <S.RadioInput
            type="radio"
            name={settingsKey}
            value={option.key}
            checked={currentValue === option.key}
            onChange={() => onChangeValue(option.key)}
          />
          <S.RadioLabel>
            {currentValue === option.key ? <S.RadioButtonChecked /> : <S.RadioButtonUnchecked />}
            <S.RadioTitle>{option.value}</S.RadioTitle>
          </S.RadioLabel>
          {option.image && <S.RadioThumbnail>{option.image}</S.RadioThumbnail>}
        </S.RadioWrap>
      ))}
    </S.RadioGroup>
  );
};

// prettier-ignore
const S = {
    RadioGroup: styled.div`
      display: flex;
      gap: 16px;
      cursor: pointer;
    `,

   RadioWrap: styled.label`
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      text-align: center;
      gap: 16px;
    `,

   RadioLabel: styled.div`
      display: flex;
     align-items: center;
     text-align: center;
    `,

    RadioButtonChecked: styled(RadioButtonChecked)`
      color: ${props => props.theme.color.neutral700};
    `,

    RadioButtonUnchecked: styled(RadioButtonUnchecked)`
      color: ${props => props.theme.color.neutral700};
    `,

   RadioInput: styled.input`

   `,

   RadioTitle: styled.span` 
     align-items: center;
     margin-left: 8px;
    `,

   RadioThumbnail: styled.div`
      border-radius: 3px;
      border: 1px solid ${props => props.theme.color.neutral400};
    `,
};
