import { PropsWithChildren } from "react";
import { ClassNames, useTheme } from "@emotion/react";
import ReactModal from "react-modal";
import { useLockBodyScroll } from "react-use";
import { FiX } from "react-icons/fi";
import styled from "@emotion/styled/macro";

type ModalProps = {
  visible: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  afterClose?: () => void;
  styling?: any;
};

export default function Modal({ visible, onCancel, children, styling = "" }: PropsWithChildren<ModalProps>) {
  const { color, spacing } = useTheme();

  useLockBodyScroll(visible);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <ClassNames>
      {({ css }) => (
        <ReactModal
          isOpen={visible}
          appElement={document.getElementById("root") as HTMLElement}
          onRequestClose={handleCancel}
          overlayClassName={{
            base: "modal-overlay-base",
            afterOpen: "modal-overlay-after",
            beforeClose: "modal-overlay-before",
          }}
          className={{
            base: "modal-content-base",
            afterOpen: "modal-content-after",
            beforeClose: "modal-content-before",
          }}
          closeTimeoutMS={500}
          portalClassName={css`
            .modal-overlay-base {
              padding: 1rem;
              ${spacing.p(4)}
              position: fixed;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0);
              opacity: 0;
              transition-property: background-color, opacity;
              transition-duration: 300ms;
              transition-timing-function: ease-in-out;
              outline: 0;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .modal-overlay-after {
              background-color: rgba(0, 0, 0, 0.7);
              opacity: 1;
            }

            .modal-overlay-before {
              background-color: rgba(0, 0, 0, 0);
              opacity: 0;
            }

            .modal-content-base {
              position: relative;
              top: auto;
              left: auto;
              right: auto;
              bottom: auto;
              margin: 0 auto;
              border: 0;
              outline: 0;
              height: 0%;
              width: 0%;
              background-color: transparent;
              transition-property: background-color, width, height;
              transition-duration: 500ms;
              transition-timing-function: ease-in-out;
            }

            .modal-content-after {
              padding: 0;
              min-width: 50vw;
              min-height: 50vh;
              max-height: 80vh;
              height: fit-content;
              grid-template-rows: 10px minmax(50vh, 80vh) 10px;
              ${color.bg.neutral100}
              box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.25), -8px -8px 20px rgba(0, 0, 0, 0.25);
              border-radius: 5px;

              display: flex;
              flex-direction: column;
            }

            .modal-content-before {
              background-color: transparent;
            }

            .modal-header {
              height: 0;
              ${spacing.pt(12)}
              ${spacing.px(6)}
              ${spacing.pb(0)}
            }
            .modal-footer {
              height: 0;
              ${spacing.pt(0)}
              ${spacing.px(6)}
              ${spacing.pb(6)}
            }

            .modal-body {
              ${spacing.px(6)}
              flex: 1;
              overflow-y: auto;
              height: fit-content;
            }
            ${styling}
          `}
        >
          <div className="modal-header">
            <CloseButton onClick={handleCancel} style={{ fontSize: 21 }}>
              <FiX />
            </CloseButton>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer"></div>
        </ReactModal>
      )}
    </ClassNames>
  );
}

// prettier-ignore
const CloseButton = styled.button(({ theme }) => `
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  user-select: none;
  cursor: pointer;

  ${theme.typography.body1};
  ${theme.spacing.h(8)}
  ${theme.spacing.w(8)}

  background-color: transparent;
  ${theme.color.text.primary400}

  border: none;
  box-sizing: content-box;
  ${theme.spacing.p([2, 2, 0, 0])}
  transition: all 0.3s ease;

  &:focus {
    box-shadow: none;
  }

  &:active {
    opacity: 0.5;
  }
`);
