export type MultipleValueDataSource = ProtectedHouseholdsDataSource | SignedInstallationStatsDataSource;

export type ProtectedHouseholdsDataSource = {
  sourceType: MultipleValueSourceTypes.ProtectedHouseholds;
  organizationId: string;
};

export type SignedInstallationStatsDataSource = {
  sourceType: MultipleValueSourceTypes.SignedInstallationStats;
  organizationId: string;
};

export enum MultipleValueSourceTypes {
  ProtectedHouseholds = "protectedHouseholds",
  SignedInstallationStats = "signedInstallationStats",
}
