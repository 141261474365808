import { SettingsType } from "constants/constants";
import { SingleValueSourceTypes } from "constants/widgetConstants";
import { incidentStatsSettings, thingSettings, titleSetting } from "data/commonSettings";
import { SettingsDescriptor } from "data/widgetData";
import language from "translations/language";

const colorPalette = [
  { value: "Default", key: null },
  { value: language.colors.RED, key: "#d98380", color: "#d98380" },
  { value: language.colors.YELLOW, key: "#f0ad77", color: "#f0ad77" },
];

export const valueWidgetSettings: SettingsDescriptor = {
  title: titleSetting,
  dataSource: {
    label: language.widgets.settings.DATA_SOURCE,
    type: SettingsType.Switch,
    switchField: {
      type: SettingsType.Select,
      label: language.widgets.settings.DATA_SOURCE_TYPE,
      default: null,
      multiple: false,
      options: Object.entries(SingleValueSourceTypes).map(([_key, value]) => {
        return { key: value, value: language.sourceTypes[value] };
      }),
    },
    switchKey: "sourceType",
    options: {
      [SingleValueSourceTypes.Thing]: thingSettings,
      [SingleValueSourceTypes.IncidentStats]: incidentStatsSettings,
    },
  },
  color: {
    type: SettingsType.Select,
    label: language.COLOR,
    default: null,
    multiple: false,
    options: colorPalette,
  },
};
