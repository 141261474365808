import { IncidentType } from "constants/widgetConstants";
import { DayRange } from "./widgets/common/date";

export type IncidentId = string;

export enum IncidentStatus {
  Registered = "registered",
  InProgress = "inprogress",
  Contacted = "contacted",
  Done = "done",
}

export type Incident = {
  incidentId: IncidentId;
  url: string;
  sharingUrl: string;
  incidentNumber: string;
  title: string;
  origin: string;
  user: {
    userId: string;
    name: string;
  };
  thing: {
    thingId: string;
    name: string;
  };
  notes: [];
  incidentType: IncidentType;
  estimatedTimeSpent: string | null;
  priority: string;
  status: IncidentStatus;
  startDateTime: number;
  endDateTime: string | null;
  created: string;
  modified: string;
};

export type IncidentStats<T extends IncidentType> = {
  type: T;
  count: number;
  items: Incident[];
  dayRange: DayRange;
};
