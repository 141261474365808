import { IncidentType, LiveThingProperty, WidgetType } from "constants/widgetConstants";
import { Color } from "highcharts";
import { DayRange } from "./common/date";
import { WidgetViewModel } from "./Widget";

export type TableWidgetViewModel = WidgetViewModel<WidgetType.TableWidget, TableWidgetSettings>;

export type TableWidgetSettings = {
  dataSource?:
    | MultipleThingPropertiesDataSource
    | MultipleIncidentStatsDataSource
    | MultipleThingsPropertiesDataSource
    | MultipleAlarmsDataSource
    | MultipleStacksDataSource
    | null;
  color?: Color | null;
};

export type MultipleStacksDataSource = {
  sourceType: MultipleStacksValueSourceTypes.StackCreated;
};

export enum MultipleStacksValueSourceTypes {
  StackCreated = "stackCreated",
}

export type MultipleThingPropertiesDataSource = {
  sourceType: MultiplePropertyValueSourceTypes.Thing;
  thingId: string;
  thingProperty: (LiveThingProperty | string)[];
};

export type MultiplePropertiesDataSource = {
  sourceType: MultiplePropertyValueSourceTypes.Thing;
  thingId: string;
  thingProperty: (LiveThingProperty | string)[];
};

export type MultipleIncidentStatsDataSource = {
  sourceType: MultiplePropertyValueSourceTypes.IncidentStats;
  incidentType: IncidentType[];
  dayRange: DayRange;
};

export type MultipleAlarmsDataSource = {
  sourceType: MultipleAlarmsValueSourceTypes.Alarms;
};

export enum MultipleAlarmsValueSourceTypes {
  Alarms = "alarms",
}

export enum PropertiesValueSourceTypes {
  CreatedDate = "created",
}

export enum MultiplePropertyValueSourceTypes {
  Thing = "thing",
  IncidentStats = "incidentStats",
}

export type MultipleThingsPropertiesDataSource = {
  sourceType: MultipleThingsValueSourceTypes.ThingMetricThreshold;
  metric: ThingMetricThreshold;
  operator: ThingMetricThresholdOperator;
  value: number;
};

export enum MultipleThingsValueSourceTypes {
  ThingMetricThreshold = "thingMetricThreshold",
}

export enum ThingMetricThreshold {
  Pressure = "pressure",
  Temperature = "temperature",
}

export enum ThingMetricThresholdOperator {
  LessThanEqual = "lessThanEqual",
  LessThan = "lessThan",
  GreaterThanEqual = "greaterThanEqual",
  GreaterThan = "greaterThan",
}

export function isTableWidget(widget: WidgetViewModel): widget is TableWidgetViewModel {
  return widget.type === WidgetType.TableWidget;
}
