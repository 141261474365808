import { Theme, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import FairtrailLink from "components/FairtrailLink";
import { FAIRTRAIL_REBORN_STACK_ROUTE, FAIRTRAIL_STACK_ROUTE } from "constants/fairtrailRoutes";
import { GeoStatus, GeoTaggedDataPoint } from "datasources/geoTaggedData/interfaces";
import { useCallback, useEffect, useRef, useState } from "react";
import { ThemeProps } from "theme/types";

type MapPropertiesProps = {
  points: GeoTaggedDataPoint[];
};

const colorForThingStatus = (theme: Theme, status: GeoStatus | undefined) => {
  switch (status) {
    case GeoStatus.Ok:
      return theme.color.bg.success400;
    case GeoStatus.Warning:
      return theme.color.bg.warning400;
    case GeoStatus.Error:
      return theme.color.bg.error400;
    default:
      return theme.color.bg.neutral400;
  }
};

const SIZE = 25;

const MapProperties = ({ points }: MapPropertiesProps) => {
  const theme = useTheme();
  const ref = useRef<HTMLUListElement>(null);
  const [page, setPage] = useState(0);

  const [displayedPoints, setDisplayedPoints] = useState(points.slice(0, SIZE));

  useEffect(() => {
    setDisplayedPoints(points.slice(0, SIZE));
    setPage(0);
  }, [points]);

  const handleScroll = useCallback(() => {
    if (ref.current) {
      const el = ref.current;

      if ((el.scrollHeight - el.clientHeight) * 0.9 < el.scrollTop && points.length > displayedPoints.length) {
        const nextPage = page + 1;
        setPage(nextPage);
        setDisplayedPoints([...displayedPoints, ...points.slice(SIZE * nextPage, SIZE * (nextPage + 1))]);
      }
    }
  }, [points, displayedPoints, page]);

  useEffect(() => {
    const list = ref.current;

    if (list) {
      list.addEventListener("scroll", handleScroll);
      return () => list.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  return (
    <S.List ref={ref}>
      {displayedPoints.map((property, i) => (
        <S.Li key={property.uniqueId}>
          <S.Indicator color={colorForThingStatus(theme, property.status)} />

          {property.stack ? (
            <FairtrailLink
              href={FAIRTRAIL_STACK_ROUTE.replace(":stackId", property.stack!.stackId)}
              rebornHref={FAIRTRAIL_REBORN_STACK_ROUTE.replace(":stackId", property.stack!.stackId)}
            >
              {property.title}
            </FairtrailLink>
          ) : (
            <S.Text>{property.title}</S.Text>
          )}
        </S.Li>
      ))}
    </S.List>
  );
};

export default MapProperties;

// prettier-ignore
const S = {
  List: styled.ul(({ theme }) => `
    margin-top: 25px;

    ${theme.typography.body3};
    font-weight: 700;

    overflow: auto;
    overflow-x: hidden;
  `),
   
  Indicator: styled.span(({ theme, color }: ThemeProps & { color: string }) => `
    display: inline-block;
    ${theme.spacing.h(3)};
    ${theme.spacing.w(3)};
    flex-shrink: 0;
    margin-right: 0.5rem;

    ${color}
    border-radius: 50%;
  `),

  Li: styled.li(({ theme }) => `
    display: flex;
    align-items: center;
    margin-top: 10px;
  `),
  Text: styled.span(({ theme }) => `
  `)
}
