import ValueWidget from "components/widgets/ValueWidget";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { ValueWidgetSettings } from "models/widgets/ValueWidget";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { ValueThumbSVG } from "../thumbnails";
import { WidgetData } from "../widgetData";
import { valueWidgetSettings } from "./valueWidgetSettings";

export const ValueWidgetData: WidgetData<WidgetType.ValueWidget, ValueWidgetSettings & WidgetSettings> = {
  type: WidgetType.ValueWidget,

  name: language.widgets.VALUE,

  description: {
    ...language.widgets.descriptions[WidgetType.ValueWidget],
    thumbnail: ValueThumbSVG,
  },

  dashboardPurposes: [DashboardPurpose.Generic, DashboardPurpose.Customer, DashboardPurpose.Stack],

  settings: valueWidgetSettings,

  component: (props) => <ValueWidget {...props} />,
};
