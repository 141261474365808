import { serializeCSSObj } from "theme/utils";
import type { ColorHelpers, Palette, SetOfColors } from "theme/types";
import { basePalette } from "theme/palettes/base";

export const themeColorHelpers = (palette: Palette = basePalette) => {
  /** 
    Walks the palette and creates a getter for each color
    in the palette. The getter returns the color value or color specific css string.
  */
  const buildColorNamedFields = (fieldValueFn: (color: string) => any) => {
    const returnObj = {} as SetOfColors;
    for (const [key, variants] of Object.entries(palette)) {
      const entries = typeof variants === "object" && variants !== null ? Object.entries(variants) : [];
      for (const [variant, value] of entries) {
        const getterVariant = variant === "default" ? "" : variant;
        const fieldName = key + getterVariant;
        if (typeof value === "string") {
          Object.defineProperty(returnObj, fieldName, { get: () => fieldValueFn(value) });
        }
      }
    }
    return returnObj;
  };

  let module = buildColorNamedFields((color) => color) as ColorHelpers;
  module.text = buildColorNamedFields((color) => serializeCSSObj({ color }));
  module.bg = buildColorNamedFields((color) => serializeCSSObj({ backgroundColor: color }));

  return module;
};
