import { observer } from "mobx-react-lite";
import { AddFullCircleIcon, DangerEllipse, RemoveCirlceIcon, NormalEllipse, WarningEllipse } from "components/icons";
import { IconButton, Input } from "components/common";
import styled from "@emotion/styled";
import { SelectSetting } from "./SelectSetting";
import { ThresholdColor } from "models/widgets/common/common";
import language from "translations/language";

interface Props {
  addThresholdRow: () => any;
  removeThresholdRow: () => any;
  settingsKey: string;
  thresholdColor: ThresholdColor;
  thresholdValue?: number;
  onChange: (threshold: any) => any;
  hideButtons?: boolean;
  label?: string;
  validationError?: boolean;
}

const ThresholdRow = ({
  addThresholdRow,
  removeThresholdRow,
  settingsKey,
  onChange,
  thresholdColor,
  thresholdValue,
  hideButtons = false,
  label,
  validationError,
}: Props) => {
  const options = [
    {
      key: ThresholdColor.Green,
      value: language.thresholds[ThresholdColor.Green],
      icon: <SNormalEllipse />,
    },
    {
      key: ThresholdColor.Yellow,
      value: language.thresholds[ThresholdColor.Yellow],
      icon: <SWaringEllipse />,
    },
    {
      key: ThresholdColor.Red,
      value: language.thresholds[ThresholdColor.Red],
      icon: <SDangerEllipse />,
    },
  ];

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ value: Number.parseFloat(e.target.value) || 0, color: thresholdColor });
  };

  const onTypeChange = (color: ThresholdColor) => {
    onChange({ value: thresholdValue, color });
  };

  return (
    <>
      <label htmlFor={label}>{label}</label>

      <SInput
        type="text"
        data-testid={thresholdColor}
        id={thresholdColor}
        value={thresholdValue}
        onChange={onInputChange}
        validationError={validationError}
      />

      <SSelectSetting
        onChange={(key, value) => onTypeChange(value as ThresholdColor)}
        options={options}
        value={thresholdColor}
        settingsKey={settingsKey}
      />

      {!hideButtons && (
        <>
          <SIconButton
            onClick={(e) => {
              e.bubbles = false;
              e.preventDefault();
              addThresholdRow();
            }}
            disabled={validationError}
          >
            <AddFullCircleIcon />
          </SIconButton>

          <SIconButton
            onClick={(e) => {
              e.bubbles = false;
              e.preventDefault();
              removeThresholdRow();
            }}
          >
            <RemoveCirlceIcon />
          </SIconButton>
        </>
      )}
    </>
  );
};

export default observer(ThresholdRow);

// prettier-ignore
const SInput = styled(Input)`
  width: 139px;
`;

const SIconButton = styled(IconButton)`
  margin-left: 16px;
`;

const SSelectSetting = styled(SelectSetting)`
  width: 139px;
  margin-left: 16px;
`;

const SNormalEllipse = styled(NormalEllipse)`
  margin-right: 10px;
`;

const SWaringEllipse = styled(WarningEllipse)`
  margin-right: 10px;
`;

const SDangerEllipse = styled(DangerEllipse)`
  margin-right: 10px;
`;
