import styled from "@emotion/styled/macro";
import ConfigureMessage from "components/WidgetConfigureMessage";
import WidgetMessage from "components/WidgetMessage";
import { Title } from "components/common/title";
import { MapIcon } from "components/icons";
import { LoadingState } from "components/widgets/loading/LoadingState";
import { EDIT_DASHBOARD_ROUTE } from "constants/routes";
import { GeoTaggedDataPoint } from "datasources/geoTaggedData/interfaces";
import { useGeoTaggedDataFromSource } from "datasources/hooks";
import { observer } from "mobx-react-lite";
import { hasData } from "models/Loading";
import { CustomerStackLocationSource, MapWidgetViewModel } from "models/widgets/MapWidget";
import { LegacyRef, useEffect, useState } from "react";
import { useLocation, useMeasure } from "react-use";
import language from "translations/language";
import MapComponent from "./mapWidget/MapComponent";
import MapFilters from "./mapWidget/MapFilters";
import MapProperties from "./mapWidget/MapProperties";

interface Props {
  widget: MapWidgetViewModel;
  readonly: boolean;
}

const MapWidget = ({ widget, readonly }: Props) => {
  const location = useLocation();

  const geoTaggedData = useGeoTaggedDataFromSource(widget.settings.dataSource);

  const [points, setPoints] = useState<GeoTaggedDataPoint[]>([]);
  const [displayedPoints, setDisplayedPoints] = useState<GeoTaggedDataPoint[]>([]);

  const [ref, { width, height }] = useMeasure();

  const isLoaded = hasData(geoTaggedData);

  const isEditMode = [EDIT_DASHBOARD_ROUTE].some((path) => {
    const regex = new RegExp(`^${path.replace(/:[^/]+/g, "[^/]+")}$`);
    return regex.test(location.pathname || "");
  });

  useEffect(() => {
    if (isLoaded) {
      setPoints(geoTaggedData.points);
      setDisplayedPoints(geoTaggedData.points);
    }
  }, [isLoaded, widget]);

  if (!widget.settings.dataSource) {
    return <ConfigureMessage widgetName={language.widgets.MAP_WIDGET} />;
  }

  if (!hasData(geoTaggedData)) {
    return <LoadingState />;
  }

  if (geoTaggedData.points.length === 0 || points.length === 0) {
    return <WidgetMessage>{language.errors.NO_DATA_TO_DISPLAY}</WidgetMessage>;
  }

  const onApplyFilter = (points: GeoTaggedDataPoint[]) => {
    setPoints(points);
  };

  const onZoom = (pointsIds: string[]) => {
    setDisplayedPoints(points.filter((point) => pointsIds.includes(point.uniqueId)));
  };

  return (
    <S.Container ref={ref as LegacyRef<HTMLDivElement>}>
      <S.Content>
        <S.Header>
          <Title text={widget.settings.title} icon={<MapIcon />} />
          {/* hides filters on the edit mode because it conflicts with drag and drop */}
          {!isEditMode && <MapFilters geoTaggedData={geoTaggedData} onApplyFilter={onApplyFilter} />}
        </S.Header>
        <S.Map>
          <MapComponent
            points={points}
            width={width}
            height={height}
            readonly={readonly}
            onZoom={onZoom}
            initialView={(widget.settings.dataSource as CustomerStackLocationSource).mapInitialView}
            initialViewOptions={(widget.settings.dataSource as CustomerStackLocationSource).mapInitialViewOptions}
          />
        </S.Map>
      </S.Content>
      <S.List>
        <MapProperties points={displayedPoints} />
      </S.List>
    </S.Container>
  );
};

export default observer(MapWidget);

// prettier-ignore
const S = {
  Container: styled.div(({ theme }) => `
    ${theme.spacing.p(6)}
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    gap: 25px;
    font-weight: 700;

    .leaflet-container {
      width: 100%;
      height: 100%;

      border-radius: 4px;
      overflow: hidden;
    }
  `
  ),

  Content: styled.div(({theme}) => `
    flex-basis: 70%;
    max-width: 70%;

    display: flex;
    flex-direction: column;
    gap: 8px;
  `),

  Header: styled.div(({theme}) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    height: 27px;
    padding-right: 25px;

    white-space: nowrap;
    ${theme.color.bg.neutral100}
  `),

  Map: styled.div(({theme}) => `
    flex: 1;

    display: flex;

    border-right: 1px solid #E3E5E6;
    padding-right: 25px;
  `),

  List: styled.div(({theme}) => `
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
  `),
};
