import NiceModal, { antdModal, useModal } from "@ebay/nice-modal-react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "components/common";
import Modal from "components/common/modal";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import language from "translations/language";

interface Props {
  title?: string;
  message?: string;
  onCancel?: () => void;
}

export const ConfirmationModal = NiceModal.create(
  observer(({ title, message, onCancel }: Props) => {
    const modal = useModal();
    const baseModal = antdModal(modal);

    const closeModal = (value: boolean) => {
      modal.resolve(value);
      modal.hide();
      onCancel?.();
    };

    const modalStyling = css`
      .modal-content-after {
        min-width: unset;
        width: auto;
        max-width: 25vw;
        min-height: unset;
      }
    `;

    useEffect(() => {
      const handleEnter = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
          closeModal(true);
        }
      };

      document.addEventListener("keydown", handleEnter);
      return () => document.removeEventListener("keydown", handleEnter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    baseModal.onCancel = () => closeModal(false);

    return (
      <Modal {...baseModal} styling={modalStyling}>
        <S.ModalWrapper>
          {title && <h2>{title}</h2>}
          {message && <p>{message}</p>}
          <S.ModalContentWrapper>
            <Button ghost onClick={() => closeModal(true)}>
              {language.modals.confirmation.YES}
            </Button>
            <Button ghost onClick={() => closeModal(false)}>
              {language.modals.confirmation.NO}
            </Button>
          </S.ModalContentWrapper>
        </S.ModalWrapper>
      </Modal>
    );
  })
);

// prettier-ignore
const S = {
  ModalWrapper: styled.div(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `),

  ModalContentWrapper: styled.div(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.spacing(4)};
    ${theme.spacing.mt(8)}
  `),
}
