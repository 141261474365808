import { Acl } from "./Acl";
import { Owner } from "./Owner";

export type ProductType = {
  productTypeId: string;
  name: ProductName;
  owner: Owner;
  createdBy: string;
  modified: Date;
  created: Date;
  showForCustomer: boolean;
  productCode: string;
  maxPerStack: number;
  productImageUrl: string;
  dashboardUri: string;
  category: string;
  manufacturer: string;
  description: string;
  heartbeat: {
    interval: number;
    timeout: number;
  };
  capabilities: Capability[];
  detectedCapabilities?: Capability[];
  acl: Acl;
  modifiedBy: string;
  offlineJob: any;
  timeSeriesDetails: any;
  timeoutOfflineMessages: number;
};

export type Capability = {
  name: string;
  type: "value" | "alarm" | string; // TODO: find out all values and make this an

  /** Caption data specifics */
  label?: string;
  unit?: string;
};

export enum ThingType {
  TemperatureHumidity = "temperatureHumidity",
  Temperature = "temperature",
  Flowmeter = "flowmeter",
  WaterSwitch = "waterSwitch",
  WaterDetector = "waterDetector",
  MaintenancePlan = "maintenancePlan",
}

export enum ProductName {
  AqSense = "aqsense",
  AqSense1_1 = "aq sense 1.1",
  AqSense1_2 = "aq sense 1.2",
  AqSense1_3 = "aq sense 1.3",
  CubicDetector = "cubicdetector",
  D21TempHum = "d21temphum",
  D21Temp = "d21temp",
  CubicMeter = "cubicmeter",
  AqStop = "aqstop",
  Aq_Stop = "aq stop",
  Vattenfelsbrytare = "vattenfelsbrytare",
  CubicSecure = "cubicsecure",
  Polystop2 = "polystop 2",
  SyrPolystop2 = "syr_polystop2",
  D21WaterDetector = "d21waterdetector",
  MaintenancePlan = "maintenanceplan",
  Underhållsplan = "underhållsplan",
  ExternalCaptionDataDevice = "external:captiondata:device",
}

export enum ProductCode {
  AqSense = "aQSense",
  AqStop = "aQStop",
  CaptionData = "captionData",
  CaptionDataModbus = "CaptionDataModbus",
  CarbonDioxide = "carbonDioxide",
  CubicDetector = "cubicDetector",
  CubicMeter = "cubicMeter",
  CubicSecure = "cubicSecure",
  D21Temp = "d21temp",
  D21TempHum = "d21temphum",
  D21TempHumPro = "d21temphumPro",
  D21WaterDetector = "d21waterdetector",
  MaintenancePlan = "maintenancePlan",
  PolySense2 = "polySense2",
  Smhi = "smhi",
  SyrPolystop2 = "SYR_POLYSTOP2",
}
