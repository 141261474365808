import { Owner } from "./Owner";
import { ProductName, ProductType } from "./ProductType";
import { Stack } from "./Stack";

export function toThing(input: any): Thing {
  Object.keys(input.alarms ?? {}).forEach((key) => {
    const alarm = input.alarms[key];

    if (alarm && alarm.occurred) {
      alarm.occurred = new Date(alarm.occurred);
    }
  });

  return {
    ...input,
    frontendProductId: (input.productType.productCode ?? input.productType.name).toLowerCase() as ProductName,
    modified: new Date(input.modified),
    created: new Date(input.created),
  };
}

export enum ThingAlarmType {
  LargeWaterFlowAlarm = "largeWaterFlowAlarm",
  LongWaterFlowAlarm = "longWaterFlowAlarm",
  LargeWaterVolumeAlarm = "largeWaterVolumeAlarm",
  LowTempAlarm = "lowTempAlarm",
  SlowLeakage = "slowleakage",
  WaterDetected = "waterDetected",
  PressureAlarm = "pressureAlarm",
  LeakAlarm = "leakAlarm",
}

export enum SlowLeakageLevel {
  LowFlat = "low_flat",
  LowRise = "low_rise",
  LowFall = "low_fall",
  HighFlat = "high_flat",
  HighRise = "high_rise",
  HighFall = "high_fall",
  LegacyLowFlat = "The level for potential waterleakage is low and trend is currently flat",
  LegacyLowRise = "The level for potential waterleakage is low and trend is currently rise",
  LegacyLowFall = "The level for potential waterleakage is low and trend is currently fall",
}

type IsoDatetimeString = string;
type MillisecondsSince1970 = number;

type GenericThing = {
  thingId: string;
  url: string;
  name: string;
  owner: Owner;
  connectedThing: {
    thingId: string;
    name: string;
  };
  status: ThingStatus;
  rawStatus: ThingStatus;
  stack: Stack;
  productType: ProductType;
  // frontendProductId allows us to use the type as a discriminated union. The types
  // excluded here have a known shape.
  frontendProductId: Exclude<ProductName, ProductName.CubicSecure | ProductName.D21WaterDetector>;
  serialNumber: string;
  placement: Placement;
  uploads: string[];
  state?: {
    timestamp: MillisecondsSince1970 | IsoDatetimeString;
    waterFlowConfig?: AqStopWaterFlowConfig;
    motor1?: Motor1;
    [key: string]: any;
    bleEnabled?: boolean;
    bleId?: string;
    blePin?: number;
    delayedOffInMinutes?: number;
    delayedOffStarted?: number;
    ssid?: string;
    freeMemory?: number;
    totalMemory?: number;
    loadAverage: {
      oneMin: number;
      fiveMin: number;
      fifteenMin: number;
    };
    uptime: number;
    localIp: string;
    frequency: number;
    softwareVersion: string;
    LTEStatus: string;
    connectionType: string;
    stickInfo: {
      msisdn: string;
      hardwareVersion: string;
      softwareVersion: string;
      signalIcon: string;
      workMode: string;
      roamingStatus: string;
      wanIpAddress: string;
    };
    humidity?: number;
    temperature?: number;
  };
  alarms: {
    [ThingAlarmType.LargeWaterFlowAlarm]?: LargeWaterFlowAlarm;
    [ThingAlarmType.LongWaterFlowAlarm]?: LongWaterFlowAlarm;
    [ThingAlarmType.LargeWaterVolumeAlarm]?: LargeWaterVolumeAlarm;
    [ThingAlarmType.LowTempAlarm]?: LowTempAlarm;
    [ThingAlarmType.SlowLeakage]?: SlowLeakage;
    [ThingAlarmType.WaterDetected]?: WaterDetected;
    [ThingAlarmType.PressureAlarm]?: PressureAlarm;
    [ThingAlarmType.LeakAlarm]?: LeakAlarm;
  };
  surveys: any;
  rulesUrl: string;
  sharingUrl: string;
  modified: Date;
  created: Date;
};

export type Thing = GenericThing | CubicSecureThing | WaterDetectorThing;

export enum Placement {
  Dishwasher = "dishwasher",
  WaterTap = "watertap",
  Sink = "sink",
  Fridge = "fridge",
  Freezer = "freezer",
  WashingMachine = "washingmachine",
  Attic = "attic",
  Basement = "basement",
  Garage = "garage",
  Other = "other",
}

export enum WaterSwitchPlacement {
  Basement = "basement",
  Garage = "garage",
  Other = "other",
}

export type Motor1 = {
  waterOn?: boolean;
  timeSchedulePressureTest: string;
  lastPressureResult?: number;
  temperature?: number;
  waterConsumption?: number;
  pressureTestOn?: boolean;
};

export type CubicSecureThing = Omit<GenericThing, "state" | "frontendProductId"> & {
  frontendProductId: ProductName.CubicSecure;
  state: {
    waterFlowConfig: CubicSecureWaterFlowConfig;
    motor1?: {
      waterOn: boolean;
      temperature: number;
      ambientTemp: number;
      minWaterTemp: number;
      maxWaterTemp: number;
      waterConsumption: number;
      lastPressureResult: number;
      timeSchedulePressureTest: string;
      totalWaterConsumption: number;
      lastTotalWaterConsumption: number;
    };
    timestamp: number;
    wifiSignalStrength: number;
    softwareVersion: string;
    uptime: number;
  };
};

export type WaterDetectorThing = Omit<GenericThing, "state" | "frontendProductId"> & {
  frontendProductId: ProductName.D21WaterDetector;
  state: {
    timestamp: MillisecondsSince1970 | IsoDatetimeString;
    humidity: number;
    temperature: number;
    battery?: number;
    connection: "online" | "offline";
    hardwareVersion: string;
    softwareVersion?: string | number;
    uptime?: number;
    wifiSignalStrength?: number;
    batteryLevel?: number;
    floodSensor?: number;
    macAddress?: string;
    samplingInterval?: number;
  };
};

export type ExternalCaptionDataThing = Omit<GenericThing, "state" | "frontendProductId"> & {
  frontendProductId: ProductName.ExternalCaptionDataDevice;
  state: {
    location: {
      latitude: number;
      longitude: number;
    };
    /* Can have a wide range of state variables */
    [x: string]: any;
  };
};

export enum ThingStatus {
  Preregistered = "preregistered",
  ConfigReceived = "configReceived",
  ConfigFailed = "configFailed",
  HasAlarm = "hasAlarm",
  Offline = "offline",
  Healthy = "healthy",
}

export type AqStopWaterFlowConfig = {
  largeWaterFlowAlarmThreshold: ConfigValues;
  largeWaterVolumeAlarmThreshold: ConfigValues;
  longWaterFlowAlarmThreshold: ConfigValues;
};

export type ConfigValues = {
  ignoreWarning?: boolean;
  ignoreWaterOff: boolean;
  unit?: string; // expected: minutes for long flow, liter for volume flow, liter/s for large flow
  warning?: number;
  waterOff: number;
  duration?: number;
};

export type CubicSecureWaterFlowConfig = {
  largeWaterFlowAlarmThreshold: {
    waterOff: number;
    duration: number;
  };
  longWaterFlowAlarmThreshold: {
    waterOff: number;
    flowRateThreshold: number;
  };
  ignoreWaterOffPressureAlarm: boolean;
};

/**
 * Values used in the same contexts as alarms in the app. Data from the backend will never have these as alarms.
 * e.g. pressureAlarm1 is a type of pressureAlarm and longWaterFlowWarning is when a certain criteria is fulfilled for longWaterFlowAlarm.
 * We can use these along side ThingAlarmType to differentiate between the different alarm/warning states in the app,
 * e.g. whether to show a warning or an alarm icon on a thing in the things list.
 */
export enum ThingAlarmLike {
  /** thing.alarm is ThingAlarmType.LongWaterFlowAlarm but does not exceed the threshold */
  LongWaterFlowWarning = "longWaterFlowWarning",
  /** thing.alarm is ThingAlarmType.LargeWaterVolumeAlarm but does not exceed the threshold */
  LargeWaterVolumeWarning = "largeWaterVolumeWarning",
  /** thing.alarm is ThingAlarmType.PressureAlarm when its alarmType is PressureAlarmType.PressureAlarm1 */
  PressureAlarm1 = "pressureAlarm1",
  /** thing.alarm is ThingAlarmType.PressureAlarm when its alarmType is PressureAlarmType.PressureAlarm2 */
  PressureAlarm2 = "pressureAlarm2",

  Offline = "offline",

  ConfigFailed = "configFailed",
}

export type ThingAlarm = {
  occurred: Date;
  url: string;
  alarmType?: AlarmTypes;
  threshold?: number;
  name?: string;
  action?: AlarmActions.NotifyUser | AlarmActions.WaterOff;
};

export enum AlarmActions {
  NotifyUser = "notifyUser",
  WaterOff = "waterOff",
}

export type LargeWaterFlowAlarm = ThingAlarm & {
  threshold: number;
  action: string;
};

export type LongWaterFlowAlarm = ThingAlarm & {
  threshold: number;
  action: string;
};

export type LargeWaterVolumeAlarm = ThingAlarm & {
  threshold: number;
  action: string;
};

export type LowTempAlarm = ThingAlarm & {
  value: number;
};

export type SlowLeakage = ThingAlarm & {
  trend: string;
  level: string;
};

export type WaterDetected = ThingAlarm & {
  detectedBySensor?: any;
};

export type PressureAlarm = ThingAlarm & {
  values: number[];
  alarmType: PressureAlarmType;
};

export enum PressureAlarmType {
  PressureAlarm1 = "pressureAlarm1",
  PressureAlarm2 = "pressureAlarm2",
}

export type LeakAlarm = ThingAlarm & {
  alarmType: LeakAlarmType;
  name: string;
};

export enum LeakAlarmType {
  SmallLeak = "smallLeak",
  MediumLeak = "mediumLeak",
  Burst = "burst",
}

export type AlarmTypes = PressureAlarmType | LeakAlarmType;
