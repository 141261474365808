import styled from "@emotion/styled/macro";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { TextWidgetViewModel } from "models/widgets/TextWidget";
import { useState } from "react";
import { useStore } from "../../stores/RootStore";

interface Props {
  widget: TextWidgetViewModel;
  readonly: boolean;
}

const TextWidget = ({ widget, readonly }: Props) => {
  const { dashboardStore } = useStore();
  const title = toJS(widget.settings.title);

  const [textValue, setTextValue] = useState(title);
  const [inputValue, setInputValue] = useState(title);

  if (readonly) {
    return (
      <S.H1 height={widget.settings.size?.height!} title={title}>
        {title}
      </S.H1>
    );
  }

  const doUpdate = async (value: string) => {
    const newWidget = toJS(widget);
    newWidget.settings.title = value;
    dashboardStore.updateWidget(widget.dashboardId, widget.widgetId, newWidget);
  };

  const handleKeypress = async (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target as HTMLInputElement;

    if (e.key === "Enter") {
      const text = input.value;
      setTextValue(text);
      await doUpdate(text);
      input.blur();
    } else if (e.key === "Escape") {
      input.blur();
    }
  };

  const handleBlur = () => {
    setInputValue(textValue);
  };

  return (
    <S.Input
      title={title}
      height={widget.settings.size?.height!}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onKeyDown={handleKeypress}
      onBlur={handleBlur}
    />
  );
};

const sharedStyles = (height: number) => `
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-weight: bold;

  font-size: ${2 * (height >= 2 ? 2.25 : height)}rem;
  line-height: ${2 * (height >= 2 ? 2.25 : height)}rem;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding: 10px 40px 10px 10px;
`;

// prettier-ignore
const S = {
  Input: styled.textarea<{height: number}>(({ theme, height }) => `
    ${sharedStyles( height )}

    background: transparent;
    border: none;
    resize: none;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:focus {
      outline: none;
      border-color: ${theme.color.secondary400};
      box-shadow: ${theme.shadow.focus};
    }
  `
  ),

  H1: styled.h1<{ height: number }>(({ height }) => `
    ${sharedStyles(height)}
    margin: 0;
  `
  ),
};

export default observer(TextWidget);
