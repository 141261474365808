import { ChangeEvent, useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { observer } from "mobx-react-lite";
import language from "translations/language";

import { Input } from "components/common";
import { isNumeric } from "utils/numberUtils";
import { ValueRange } from "models/widgets/common/common";

interface Props {
  onChange: (range: ValueRange) => any;
  value: ValueRange | null;
  defaultValue?: ValueRange;
}

const RangePicker = ({ onChange, value, defaultValue }: Props) => {
  const [min, setMin] = useState<string>(value?.min.toString() || "");
  const [max, setMax] = useState<string>(value?.max.toString() || "");

  const changeMin = (e: ChangeEvent<HTMLInputElement>) => {
    const newMin = e.target.value;
    handleRangeUpdate(newMin, max);
    setMin(newMin);
  };
  const changeMax = (e: ChangeEvent<HTMLInputElement>) => {
    const newMax = e.target.value;
    handleRangeUpdate(min, newMax);
    setMax(newMax);
  };

  useEffect(() => {
    if (min === "" && max === "" && defaultValue) {
      setMin(defaultValue.min.toString());
      setMax(defaultValue.max.toString());
      onChange({ min: defaultValue.min, max: defaultValue.max });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleRangeUpdate = (minString: string, maxString: string) => {
    if (isNumeric(minString) && isNumeric(maxString)) {
      const min = parseFloat(minString);
      const max = parseFloat(maxString);
      if (min < max) {
        onChange({ min, max });
      }
    }
  };

  return (
    <>
      <S.RangePickerRow>
        <S.RangePickerRowCell>
          <label htmlFor="min">{language.MIN}</label>
          <Input type="text" data-testid="min" id="min" value={min} onChange={changeMin} />
        </S.RangePickerRowCell>
        <S.RangePickerRowCell>
          <label htmlFor="max">{language.MAX}</label>
          <Input type="text" data-testid="max" id="max" value={max} onChange={changeMax} />
        </S.RangePickerRowCell>
      </S.RangePickerRow>
    </>
  );
};

// prettier-ignore
const S = {
  RangePickerRow: styled.div(({ theme }) => `
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${theme.spacing(8)};

    ${theme.typography.body2}

    label {
      font-weight: bold;
      ${theme.spacing.mr(2)}
    }
  `),
  RangePickerRowCell: styled.div`
    display: flex;
    align-items: center;
  `,
}

export default observer(RangePicker);
