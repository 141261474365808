import { IncidentType, LiveThingProperty, SingleValueSourceTypes } from "../../../constants/widgetConstants";
import { DayRange } from "./date";

export type Color = string;

export type Range<T> = {
  from: T;
  to: T;
};

export type ValueRange = {
  min: number;
  max: number;
};

export enum ThresholdColor {
  Green = "green",
  Yellow = "yellow",
  Red = "red",
}

export type Threshold = {
  color?: ThresholdColor;
  range: ValueRange;
};

export type ThingDataSource = {
  sourceType: SingleValueSourceTypes.Thing;
  thingId: string;
  thingProperty: LiveThingProperty | string;
};

export type IncidentStatsDataSource = {
  sourceType: SingleValueSourceTypes.IncidentStats;
  incidentType: IncidentType;
  dayRange: DayRange;
};

export type SingleValueDataSource = ThingDataSource | IncidentStatsDataSource;

export type DataSource = SingleValueDataSource;
