export function toQueryString(obj: any) {
  const object = Object.assign({}, obj);

  for (const [key, value] of Object.entries(object)) {
    if (value == null) {
      delete object[key];
    } else if (Array.isArray(value)) {
      if (value.length === 0) {
        delete object[key];
      }
      object[key] = value.join(",");
    } else {
      object[key] = value;
    }
  }

  const queryString = new URLSearchParams(object).toString();

  if (queryString.length) {
    return `?${queryString}`;
  } else {
    return "";
  }
}
