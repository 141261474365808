import { MultipleStacksDataSource } from "models/widgets/TableWidget";
import { TableValues, TableValuesDataSource } from "../interfaces";
import { Loadable } from "models/Loading";
import { WidgetViewModel } from "models/widgets/Widget";
import { RootStore, rootStore } from "stores/RootStore";
import { LoadingStatus } from "constants/constants";
import language from "translations/language";
import { daysFromNow } from "utils/datetimeUtils";
import { getWidgetHeight } from "utils/widgetUtils";
import { FAIRTRAIL_REBORN_STACK_ROUTE, FAIRTRAIL_STACK_ROUTE } from "../../../constants/fairtrailRoutes";

const TABLE_STEPS_HEIGHT: { [key: number]: number } = {
  4: 2,
  5: 3,
  6: 5,
  7: 6,
  8: 8,
  9: 9,
  10: 11,
  11: 13,
  12: 14,
  13: 16,
  14: 18,
  15: 19,
  16: 21,
  17: 23,
  18: 25,
  19: 26,
  20: 28,
  21: 29,
  22: 31,
  23: 33,
  24: 35,
  25: 36,
  26: 38,
  27: 40,
  28: 41,
  29: 43,
  30: 45,
  31: 46,
  32: 48,
  33: 50,
  34: 51,
  35: 53,
  36: 55,
};

export class MultipleStacksValueAdapter implements TableValuesDataSource<MultipleStacksDataSource> {
  constructor(private stores: RootStore) {}

  getData(
    config: MultipleStacksDataSource,
    widget?: WidgetViewModel | undefined,
    additionalData?: any
  ): Loadable<TableValues> {
    const useFairtrailRebornLinks = this.stores.configStore.useFairtrailRebornLinks;

    const rows = rootStore.stackStore.getStacksByWidgetId(widget!.widgetId).map((stack) => {
      return {
        loadingStatus: LoadingStatus.Loaded,
        columns: [
          {
            label: stack?.addressLine || stack?.name,
            href: useFairtrailRebornLinks
              ? FAIRTRAIL_REBORN_STACK_ROUTE.replace(":stackId", stack.stackId)
              : FAIRTRAIL_STACK_ROUTE.replace(":stackId", stack.stackId),
          },
          {
            label: daysFromNow(stack?.created),
          },
        ],
      };
    });

    const stacksByWidgetId = rootStore.stackStore.stacksByWidgetId.get(widget?.widgetId || "");

    return {
      loadingStatus: LoadingStatus.Loaded,
      headers: [
        { name: language.tableWidget.projectDurationTable.tableHeaders.ADDRESS, width: "50%" },
        { name: language.tableWidget.projectDurationTable.tableHeaders.CREATED, width: "50%" },
      ],
      rows,
      title: language.tableWidget.STACKS_CREATED_TABLE_TITLE,
      pagination: {
        page: stacksByWidgetId?.page || 0,
        pageSize: stacksByWidgetId?.pageSize || 0,
        totalCount: stacksByWidgetId?.totalCount || 0,
      },
    };
  }

  async loadData(config: MultipleStacksDataSource, widget: WidgetViewModel, additionalData: { page: number }) {
    const widgetHeight = getWidgetHeight(widget);
    const pageSize = TABLE_STEPS_HEIGHT[widgetHeight];
    await rootStore.stackStore.loadStacksForWidget(widget.widgetId, additionalData.page, pageSize, "created");
  }
}
