import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { PopperProps } from "@mui/material/Popper";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { CloseSmallIcon, InfoIcon } from "components/icons";
import { IconButton } from "./buttons";
import { withTransientProps } from "utils/themeUtils";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { createTheme } from "@mui/material/styles";
import { DashboardTheme } from "constants/dashboardConstants";

interface Props {
  infoText?: string | React.ReactNode;
  secondaryInfoText?: string | React.ReactNode;
  verticalDivider?: boolean;
  fontSize?: string;
}

enum PopperPlacement {
  Top = "top",
  Bottom = "bottom",
}

const InfoPopper = ({ infoText, verticalDivider, secondaryInfoText, fontSize }: Props) => {
  const [placement, setPlacement] = useState<PopperPlacement>(PopperPlacement.Top);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const clickAway = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  if (!infoText) {
    return null;
  }

  const popperProps: Partial<PopperProps> = {
    modifiers: [
      {
        name: "onUpdate",
        fn: ({ state }) => setPlacement(state.placement as PopperPlacement),
      },
    ],
  };

  const muiTheme = createTheme({});
  return (
    <ThemeProvider
      theme={{
        ...muiTheme,
        spacing: {
          h: () => "",
          w: () => "",
        },
      }}
    >
      <ClickAwayListener onClickAway={clickAway}>
        <span>
          <STooltip
            verticalDivider={verticalDivider}
            title={
              <SContentWrapper verticalDivider={verticalDivider} fontSize={fontSize}>
                <SCloseIconButton onClick={() => setIsOpen(false)}>
                  <SCloseIcon />
                </SCloseIconButton>

                {!verticalDivider ? (
                  <SInfoText>{infoText}</SInfoText>
                ) : (
                  <SVerticalInfoTextsContainer>
                    <SInfoText>{infoText}</SInfoText>
                    <SVerticalDivider />
                    <SInfoText>{secondaryInfoText}</SInfoText>
                  </SVerticalInfoTextsContainer>
                )}
              </SContentWrapper>
            }
            arrow
            placement={PopperPlacement.Top}
            leaveDelay={Infinity}
            open={isOpen}
            disableFocusListener={true}
            disableHoverListener={true}
            disableTouchListener={true}
            PopperProps={popperProps}
            /** Specific styled component prop because changing the "placement" prop messes with the tooltip component */
            $placement={placement}
          >
            <SIconButton onClick={toggleOpen}>
              <InfoIcon />
            </SIconButton>
          </STooltip>
        </span>
      </ClickAwayListener>
    </ThemeProvider>
  );
};
export default InfoPopper;

const SContentWrapper = styled.div<{ verticalDivider?: boolean; fontSize?: string }>`
  ${({ verticalDivider }) => verticalDivider && "width: 600px;"}
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 16px;
  padding: 18px;
  padding-top: 2px;
  padding-bottom: 32px;
  background-color: ${({ theme }) =>
    theme.name === DashboardTheme.Dark ? theme.color.neutral100 : theme.color.neutral700n};
  box-shadow: 2px 2px 6px 0px
      ${({ theme }) => (theme.name === DashboardTheme.DarkPale ? theme.color.neutral100 : theme.color.neutral400)},
    -2px -2px 6px 0px
      ${({ theme }) => (theme.name === DashboardTheme.DarkPale ? theme.color.neutral100 : theme.color.neutral400)};
  font-size: ${({ fontSize }) => fontSize || "14px"};
`;

const SCloseIcon = styled(CloseSmallIcon)`
  margin-right: -16px;
`;

const SInfoText = styled.div`
  width: 100%;
`;

const SIconButton = styled(IconButton)`
  margin-top: -10px;
  margin-bottom: -12px;
`;

const SCloseIconButton = styled(IconButton)`
  margin-top: 10px;
  margin-right: 10px;
`;

const StyledTooltip = ({
  className,
  $placement,
  ...props
}: {
  className?: string;
  $placement: PopperPlacement;
} & TooltipProps) => <Tooltip {...props} classes={{ tooltip: className }} />;

const STooltip = styled(StyledTooltip, withTransientProps)<{ $placement: PopperPlacement; verticalDivider?: boolean }>`
  padding: 0;
  background-color: ${({ theme }) =>
    theme.name === DashboardTheme.Dark ? theme.color.neutral100 : theme.color.neutral700n};
  color: ${({ theme }) => theme.color.primary};
  right: ${({ verticalDivider }) => verticalDivider && "250px"};

  .MuiTooltip-arrow {
    width: 35px;
    height: 26px;
    background-color: transparent;
    top: ${({ $placement }) => ($placement === PopperPlacement.Top ? "calc(100% - 1px)" : "-16px !important")};
  }

  .MuiTooltip-arrow:before {
    background-color: ${({ theme }) =>
      theme.name === DashboardTheme.Dark ? theme.color.neutral100 : theme.color.neutral700n};
    box-shadow: 0px 0px 5px
      ${({ theme }) => (theme.name === DashboardTheme.DarkPale ? theme.color.neutral100 : theme.color.neutral400)};
    box-shadow: 0px 0px 6px 0px
        ${({ theme }) => (theme.name === DashboardTheme.DarkPale ? theme.color.neutral100 : theme.color.neutral400)},
      0px 0px 3px 0px
        ${({ theme }) => (theme.name === DashboardTheme.DarkPale ? theme.color.neutral100 : theme.color.neutral400)};
  }
`;

const SVerticalDivider = styled.div`
  border-right: 1px solid #cfcfcf;
  margin-left: 20px;
  margin-right: 20px;
`;

const SVerticalInfoTextsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;
