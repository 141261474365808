export enum Environment {
  Production = "production",
  Development = "development",
  Test = "test",
}

export const ENVIRONMENT = process.env.NODE_ENV as Environment;

export enum SettingsType {
  Customer = "customer",
  Integer = "integer",
  Location = "location",
  Nested = "nested",
  Organization = "organization",
  Range = "range",
  DayRange = "dayRange",
  Select = "select",
  CompositeSelect = "compositeSelect",
  Switch = "switch",
  Text = "text",
  Thing = "thing",
  ThingMetric = "thingMetric",
  Thresholds = "thresholds",
  TimeInterval = "timeInterval",
  Toggle = "toggle",
  Hidden = "hidden",
  RadioButton = "radioButton",
  MapOptions = "mapOptions",
  ImageUrlWithPreview = "imageUrlWithPreview",
}

export const TOKEN_QUERY_PARAM = "token";
export const LANGUAGE_QUERY_PARAM = "lang";
export const THEME_QUERY_PARAM = "theme";
export const USE_FAIRTRAIL_LINKS_QUERY_PARAM = "useFairtrailLinks";
export const USE_REBORN_LINKSQUERY_PARAM = "useRebornLinks";
export const CAPTION_DATA_DEPARTMENT_ID_QUERY_PARAM = "deptId";
export const SHOW_ONLY_PROPERTIES = "onlyProperties";

export enum LoadingStatus {
  Loaded = "loaded", // The data has been fetched and everything is fine
  Loading = "loading", // No data is available but a request has been made
  Error = "error", // Data was requested and something went wrong
  Reloading = "reloading", // Data is available, but it may be old - new data is being loaded
}

export enum DataInterval {
  NoInterval = "NoInterval",
  OneHour = "1h",
  TwoHours = "2h",
  SixHours = "6h",
  TwelveHours = "12h",
}

export const DEFAULT_REFRESH_TIMEOUT = 1000 * 60 * 15;

export enum SettingsModalOptions {
  General = "General",
  // MetricSystem = "Metric system",
  ColorTheme = "Color theme",
}

export const ALL_METRICS = "ALL_METRICS";

export const IFRAME_MESSAGE_EVENT_NAME = "message";
