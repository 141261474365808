import { Stack, toStack } from "models/Stack";
import { FairtrailHttpClient } from "../FairtrailHttpClient";

class StacksAPIClient extends FairtrailHttpClient {
  async getStacks() {
    return await this.queryStacks();
  }

  async getStackById(stackId: string) {
    const response = await this.get({
      path: `/stacks/${stackId}`,
      authenticated: true,
    });

    return toStack(response.data);
  }

  async getStacksForUser(userId: string): Promise<Stack[]> {
    return await this.queryStacks(`${userId}`);
  }

  async queryStacks(queryString?: string): Promise<Stack[]> {
    const response = await this.get({
      path: `/stacks`,
      authenticated: true,
      query: {
        size: 1000,
        q: queryString,
      },
    });

    return response.data.map((stack: any) => toStack(stack));
  }

  async queryStacksByIds(stackIds: string): Promise<Stack[]> {
    const response = await this.get({
      path: `/stacks`,
      authenticated: true,
      query: {
        size: 1000,
        stackId: stackIds,
      },
    });

    return response.data.map((stack: any) => toStack(stack));
  }

  async getPaginatedStacks(
    size = 10,
    page = 0,
    sortOrder = "1",
    sortBy = "adressLine",
    stackIds?: string
  ): Promise<{
    stacks: Stack[];
    totalCount: number;
  }> {
    const response = await this.get({
      path: `/stacks`,
      authenticated: true,
      query: {
        size,
        page,
        sortOrder,
        sortBy,
        stackIds,
      },
    });

    return {
      stacks: response.data.map((stack: any) => toStack(stack)),
      totalCount: response.headers["totalcount"],
    };
  }

  async getAllStacks(): Promise<{
    stacks: Stack[];
    totalCount: number;
  }> {
    const response = await this.get({
      path: `/stacks/all`,
      authenticated: true,
      query: {},
    });

    return {
      stacks: response.data.map((stack: any) => toStack(stack)),
      totalCount: response.headers["totalcount"],
    };
  }
}

export default StacksAPIClient;
