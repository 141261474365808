import { CAPTION_DATA_REFRESH_TIMEOUT } from "constants/captionDataConstants";
import { LoadingStatus } from "constants/constants";
import { FAIRTRAIL_REBORN_THING_ROUTE, FAIRTRAIL_THING_ROUTE } from "constants/fairtrailRoutes";
import { LiveThingProperty } from "constants/widgetConstants";
import { getValueForThing } from "datasources/singleValueData/adapters/helpers/thingValues";
import { hasData, Loadable } from "models/Loading";
import { Thing, ThingStatus } from "models/Thing";
import { stateModifiedAt } from "models/ThingDataExtraction";
import { MultipleThingPropertiesDataSource, ThingMetricThresholdOperator } from "models/widgets/TableWidget";
import { RootStore } from "stores/RootStore";
import { isCaptionDataThing } from "../../../utils/captionDataUtils";
import { TableValues, TableValuesDataSource } from "../interfaces";

export class ThingTableAdapter implements TableValuesDataSource<MultipleThingPropertiesDataSource> {
  refreshTimeout: number | undefined;

  constructor(private stores: RootStore) {
    /* If deptId is used, refreshes every CAPTION_DATA_REFRESH_TIMEOUT, otherwise we rely on the incoming websocket messages */
    this.refreshTimeout = this.stores.configStore.deptId ? CAPTION_DATA_REFRESH_TIMEOUT : undefined;
  }

  getData(config: MultipleThingPropertiesDataSource): Loadable<TableValues> {
    const useFairtrailRebornLinks = this.stores.configStore.useFairtrailRebornLinks;
    const thing = this.stores.thingStore.get(config.thingId);

    if (!hasData<Thing>(thing)) {
      return { loadingStatus: LoadingStatus.Loading };
    }

    const values = config.thingProperty.map((property) => {
      const valueForThing = getValueForThing(thing, property as LiveThingProperty);

      const { warningValue, warningOperator } = this.getWarningValue(property as LiveThingProperty);

      return {
        loadingStatus: LoadingStatus.Loaded,
        value: valueForThing.value!,
        label: valueForThing.name!,
        units: valueForThing.units,
        updatedAt: stateModifiedAt(thing) as Date,
        warningValue: isCaptionDataThing(thing) ? 0 : warningValue,
        warningOperator: isCaptionDataThing(thing) ? "" : warningOperator,
        hasAlarm: isCaptionDataThing(thing)
          ? thing.status === ThingStatus.HasAlarm
          : Object.keys(thing.alarms).length > 0,
        title: thing.name,
      };
    });

    return {
      loadingStatus: LoadingStatus.Loaded,
      values,
      url: useFairtrailRebornLinks
        ? FAIRTRAIL_REBORN_THING_ROUTE.replace(":thingId", thing.thingId)
        : FAIRTRAIL_THING_ROUTE.replace(":thingId", thing.thingId),
      hasAlarm: isCaptionDataThing(thing)
        ? thing.status === ThingStatus.HasAlarm
        : Object.keys(thing.alarms).length > 0,
      title: thing.name,
    };
  }

  async loadData(config: MultipleThingPropertiesDataSource) {
    await this.stores.thingStore.load(config.thingId);
  }

  /** Used to determine when to show alarm icon on the table widget */
  getWarningValue(property: LiveThingProperty | string): { warningValue: number; warningOperator: string } {
    switch (property) {
      case LiveThingProperty.WifiSignalStrength:
        return { warningValue: -80, warningOperator: ThingMetricThresholdOperator.LessThanEqual };

      case LiveThingProperty.BatteryLevel:
        return { warningValue: 20, warningOperator: ThingMetricThresholdOperator.LessThanEqual };

      case LiveThingProperty.Temperature:
        return { warningValue: 90, warningOperator: ThingMetricThresholdOperator.GreaterThanEqual };

      case LiveThingProperty.RelativeHumidity:
        return { warningValue: 90, warningOperator: ThingMetricThresholdOperator.GreaterThanEqual };

      case LiveThingProperty.WaterPressure:
        return { warningValue: 0, warningOperator: ThingMetricThresholdOperator.LessThanEqual };
    }

    // TODO: This return is to handle caption data sensors, we don't necessarily know what a good or a bad value is for their sensors atm.
    // And currently Threshold and Incident stats are disabled for caption data sensors.
    return { warningValue: 1111111111111, warningOperator: "" };
  }
}
