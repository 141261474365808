import { ChartDataSource, ChartSourceTypes, ChartWidgetSettings } from "models/widgets/ChartWidget";
import { WidgetSettings } from "models/widgets/Widget";
import { isEmpty } from "utils/common";

export function chartWidgetOnSave(settings: ChartWidgetSettings & WidgetSettings) {
  const updatedSettings = { ...settings };

  /* If no metrics are selected for an axis we set it to null to represent no axis */
  if (
    isEmpty(updatedSettings.leftAxisMetrics) ||
    (updatedSettings?.leftAxisMetrics as ChartDataSource)?.sourceType === ("" as ChartSourceTypes)
  ) {
    updatedSettings.leftAxisMetrics = null;
  }

  if (
    isEmpty(updatedSettings.rightAxisMetrics) ||
    (updatedSettings?.rightAxisMetrics as ChartDataSource)?.sourceType === ("" as ChartSourceTypes)
  ) {
    updatedSettings.rightAxisMetrics = null;
  }

  return updatedSettings;
}
