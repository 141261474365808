import { SettingsType } from "constants/constants";
import { GaugeType, SingleValueSourceTypes } from "constants/widgetConstants";
import { incidentStatsSettings, thingSettings, titleSetting } from "data/commonSettings";
import { SettingsDescriptor } from "data/widgetData";
import language from "translations/language";
import { GaugeThumbSVG, GaugeThumbClassicSVG } from "../thumbnails";

const GaugeTypeImage: Record<GaugeType, JSX.Element> = {
  [GaugeType.Classic]: <GaugeThumbClassicSVG />,
  [GaugeType.Solid]: <GaugeThumbSVG />,
};

export const gaugeWidgetSettings: SettingsDescriptor = {
  type: {
    type: SettingsType.RadioButton,
    default: GaugeType.Classic,
    options: Object.entries(GaugeType).map(([_key, value]) => {
      return {
        key: value,
        value: language.gaugeType[value],
        image: GaugeTypeImage[value],
      };
    }),
  },
  title: titleSetting,

  dataSource: {
    label: language.widgets.settings.DATA_SOURCE,
    type: SettingsType.Switch,
    switchField: {
      type: SettingsType.Select,
      label: language.widgets.settings.DATA_SOURCE_TYPE,
      default: null,
      multiple: false,
      options: Object.entries(SingleValueSourceTypes).map(([_key, value]) => {
        return { key: value, value: language.sourceTypes[value] };
      }),
    },
    switchKey: "sourceType",
    options: {
      [SingleValueSourceTypes.Thing]: thingSettings,
      [SingleValueSourceTypes.IncidentStats]: incidentStatsSettings,
    },
  },

  thresholds: {
    type: SettingsType.Thresholds,
    label: language.widgets.settings.THRESHOLDS,
  },
};
