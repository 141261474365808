import { Stack } from "models/Stack";
import StacksAPIClient from "../services/api/StacksAPIClient";
import { SimpleStore } from "stores/SimpleStore";
import { Loadable, hasData } from "models/Loading";
import { LoadingStatus } from "constants/constants";
import { makeObservable, observable, runInAction } from "mobx";
import { rootStore } from "./RootStore";
import { Thing } from "models/Thing";

type StackId = string;

export class StackStore extends SimpleStore<StackId, Stack, "stackId"> {
  private client = new StacksAPIClient();

  private allStacksIsLoading = false;
  private allStacksIsLoaded = false;
  allStacksRegistry: Map<string, Loadable<Stack, "stackId", string>> = new Map();

  stacksByWidgetId = new Map<
    string,
    {
      stackIds: string[];
      totalCount: number;
      pageSize: number;
      page: number;
    }
  >();

  constructor() {
    super();
    makeObservable(this, {
      allStacksRegistry: observable,
      stacksByWidgetId: observable,
    });
  }

  protected async fetchFromClientForKey(stackId: StackId): Promise<Stack> {
    return await this.client.getStackById(stackId);
  }

  protected getRegistryIndex(stackId: StackId): string {
    return stackId;
  }

  readonly keyFieldName = "stackId" as const;

  async loadAllForUser(userId?: string): Promise<StackId[]> {
    userId = userId ?? rootStore.authStore.authUser!.userId;
    const stacks = await this.client.getStacksForUser(userId);

    runInAction(() => {
      stacks.forEach((stack) => {
        this.registry.set(stack.stackId, { loadingStatus: LoadingStatus.Loaded, ...stack });
      });
    });

    return stacks.map((stack) => stack.stackId);
  }

  public async loadForQuery(query: string): Promise<StackId[]> {
    const stacks = await this.client.queryStacks(query);
    stacks.forEach((stack) => this.updateEntry(stack.stackId, stack));
    return stacks.map((stack) => stack.stackId);
  }

  get loadedStacks(): Stack[] {
    return Array.from(this.registry.values()).filter(hasData);
  }

  async loadStacks({
    page = 0,
    size = 1000,
    sortBy = "name",
    sortOrder = "1",
    stackIds,
  }: {
    page?: number;
    size?: number;
    sortBy?: string;
    sortOrder?: string;
    stackIds?: string;
  }) {
    const { stacks, totalCount } = await this.client.getPaginatedStacks(size, page, sortOrder, sortBy, stackIds);

    runInAction(() => {
      stacks.forEach((stack) => {
        this.registry.set(stack.stackId, { loadingStatus: LoadingStatus.Loaded, ...stack });
        stack.things.forEach((thing: Thing) => rootStore.thingStore.addThing(thing));
      });
    });

    return { stacks, totalCount };
  }

  get allStacks(): Stack[] {
    return Array.from(this.allStacksRegistry.values()).filter(hasData);
  }

  async loadAllStacks() {
    if (this.allStacksIsLoaded || this.allStacksIsLoading) {
      return;
    }

    this.allStacksIsLoading = true;

    const { stacks, totalCount } = await this.client.getAllStacks();

    runInAction(() => {
      stacks.forEach((stack) => {
        this.allStacksRegistry.set(stack.stackId, { loadingStatus: LoadingStatus.Loaded, ...stack });
      });
    });

    this.allStacksIsLoaded = true;

    return { stacks, totalCount };
  }

  getStacksByWidgetId(widgetId: string): Stack[] {
    const stacksByWidgetId = this.stacksByWidgetId.get(widgetId);

    if (stacksByWidgetId) {
      return stacksByWidgetId.stackIds.map((stackId) => this.registry.get(stackId)).filter(hasData);
    } else {
      return [];
    }
  }

  async loadStacksForWidget(
    widgetId: string,
    page = 0,
    size = 10,
    sortBy = "name",
    sortOrder?: "1" | "-1",
    stackIds?: string
  ) {
    const { stacks, totalCount } = await this.loadStacks({ page, size, sortBy, sortOrder, stackIds });
    runInAction(() =>
      this.stacksByWidgetId.set(widgetId, {
        stackIds: stacks.map((stack) => stack.stackId),
        totalCount,
        pageSize: size,
        page,
      })
    );
  }
}
