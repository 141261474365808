import { FilterValue } from "constants/widgetConstants";
import { GeoTaggedData } from "datasources/geoTaggedData/interfaces";
import { Thing, ThingStatus } from "models/Thing";
import Filters from "../common/Filters";

type MapFiltersProps = {
  geoTaggedData: GeoTaggedData;
  onApplyFilter: (value: any) => void;
};

const getThingStatusByFilter = (id: FilterValue) => {
  switch (id) {
    case FilterValue.Healthy:
      return ThingStatus.Healthy;
    case FilterValue.Warning:
      return ThingStatus.Offline;
    case FilterValue.Alarm:
      return ThingStatus.HasAlarm;
    default:
      return;
  }
};

const getCountByFilter = (id: FilterValue, things: Thing[]) => {
  const status = getThingStatusByFilter(id);

  if (status) {
    return things.filter((thing) => thing.status === status).length;
  }

  return things.length;
};

const MapFilters = ({ geoTaggedData, onApplyFilter }: MapFiltersProps) => {
  const applyFilter = (id: FilterValue) => {
    const status = getThingStatusByFilter(id);
    if (status) {
      const filteredPoints = geoTaggedData.points.filter(
        (point) => (point.stack && point.stack.things.findIndex((t) => t.status === status) > -1) || false
      );
      onApplyFilter(filteredPoints);
    } else {
      onApplyFilter(geoTaggedData.points);
    }
  };

  const things = geoTaggedData.points.reduce((acc: Thing[], point) => {
    if (point.stack && point.stack.things) {
      acc = [...acc, ...point.stack.things];
    }
    return acc;
  }, []);

  const filters = Object.values(FilterValue).map((id) => ({
    id,
    count: getCountByFilter(id, things),
  }));

  return <Filters filters={filters} onApplyFilter={applyFilter} />;
};

export default MapFilters;
