import NiceModal, { antdModal, useModal } from "@ebay/nice-modal-react";
import styled from "@emotion/styled";
import { Button } from "components/common";
import { Checkbox } from "components/common/checkbox";
import * as Form from "components/common/form";
import Modal from "components/common/modal";
import { Tabs, Tab } from "components/common/tabs";
import {
  DarkThemePreview,
  LightThemePreview,
  LinkIcon,
  NeonThemePreview,
  PolygonThemePreview,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "components/icons";
import { DashboardTheme } from "constants/dashboardConstants";
import { DASHBOARD_VIEW_ROUTE } from "constants/routes";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore, rootStore } from "stores/RootStore";
import language from "translations/language";
import { getParamRoute } from "utils/routeUtils";

type Props = {
  dashboardId: string;
};

export const DashboardSettingsModal = NiceModal.create(
  observer(({ dashboardId }: Props) => {
    const { dashboardStore, themeStore } = useStore();

    const dashboard = dashboardStore.getDashboardById(dashboardId);

    const modal = useModal();

    const [currentTheme, setCurrentTheme] = useState<DashboardTheme>(dashboard?.settings.theme!);
    const [isPublic, setIsPublic] = useState<boolean>(dashboard?.settings.isPublic!);

    useEffect(() => {
      if (modal.visible && dashboard) {
        themeStore.setThemeFromDashboard(dashboard);
      }

      return () => themeStore.resetTheme();
    }, [modal.visible, dashboard, themeStore]);

    if (!dashboard) {
      modal.reject("Can't find the dashboard with id " + dashboardId);
      return null;
    }

    const saveSettings = async (e: any) => {
      e.preventDefault();

      try {
        const res = await dashboardStore.updateDashboard(dashboardId, dashboard.name, {
          theme: currentTheme,
          isPublic,
        });

        modal.resolve(res);
        modal.hide();
      } catch (err) {
        console.error("Error updating dashboard settings: ", err);
        modal.reject(err);
      } finally {
        themeStore.resetTheme();
      }
    };

    const baseModal = antdModal(modal);

    baseModal.onCancel = () => {
      themeStore.resetTheme();
      modal.hide();
    };

    return (
      <Modal {...baseModal}>
        <Tabs>
          {rootStore.configStore.deptId ? (
            <Tab title={language.dashboardSettings.General}>
              <S.ShareSettingsContainer>
                <S.ShareSettingsTitle>{language.shareSettings.TITLE}</S.ShareSettingsTitle>
                <Checkbox
                  id="isPublic"
                  label={language.shareSettings.DESCRIPTION}
                  defaultValue={isPublic}
                  onChange={setIsPublic}
                />
              </S.ShareSettingsContainer>
              {isPublic === true ? <Copy dashboardId={dashboardId} /> : <></>}
            </Tab>
          ) : (
            <></>
          )}

          {!rootStore.configStore.deptId ? (
            <Tab title={language.dashboardSettings["Color theme"]}>
              <ChangeTheme currentTheme={currentTheme} selectedTheme={setCurrentTheme} />
            </Tab>
          ) : (
            <></>
          )}
        </Tabs>
        <Form.Form onSubmit={saveSettings}>
          <S.ButtonContainer>
            <Button>{language.SAVE}</Button>
          </S.ButtonContainer>
        </Form.Form>
      </Modal>
    );
  })
);

export default DashboardSettingsModal;

const Copy = ({ dashboardId }: { dashboardId: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (e: any) => {
    e.preventDefault();
    const { deptId } = rootStore.configStore;
    navigator.clipboard.writeText(
      [
        window.location.origin,
        getParamRoute(DASHBOARD_VIEW_ROUTE, { dashboardId }),
        deptId ? `?deptId=${deptId}` : "",
      ].join("")
    );
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <S.Copy>
      <S.CopyLink onClick={(e) => copyToClipboard(e)}>
        <LinkIcon />
        <S.CopyLinkText>{language.shareSettings.COPY_LINK}</S.CopyLinkText>
      </S.CopyLink>
      {isCopied && <div>✔ {language.shareSettings.COPIED}</div>}
    </S.Copy>
  );
};

const ChangeTheme = observer(
  ({ selectedTheme }: { currentTheme: DashboardTheme; selectedTheme: (theme: DashboardTheme) => unknown }) => {
    const { themeStore } = useStore();

    const selectTheme = (theme: DashboardTheme) => {
      themeStore.theme = theme;
    };

    return (
      <S.SettingsContainer>
        {Object.values(DashboardTheme).map((theme: DashboardTheme) => (
          <S.ThemePreviewContainer key={theme}>
            <div
              onClick={(e: any) => {
                e.preventDefault();
                selectedTheme(theme);
                selectTheme(theme);
              }}
            >
              <RadioButton toggled={themeStore.theme === theme} themeName={language.themeNames[theme]} />

              <ThemeThumbnail theme={theme} />
            </div>
          </S.ThemePreviewContainer>
        ))}
      </S.SettingsContainer>
    );
  }
);
interface RadioButtonProps {
  toggled: boolean;
  themeName: string;
}

const RadioButton = observer(({ toggled, themeName }: RadioButtonProps) => {
  return toggled ? (
    <S.ThemeButtonRow>
      <RadioButtonChecked /> {themeName}
    </S.ThemeButtonRow>
  ) : (
    <S.ThemeButtonRow>
      <RadioButtonUnchecked /> {themeName}
    </S.ThemeButtonRow>
  );
});

const ThemeThumbnail = ({ theme }: { theme: DashboardTheme }) => {
  switch (theme) {
    case DashboardTheme.Default:
      return <LightThemePreview />;

    case DashboardTheme.DarkPale:
      return <DarkThemePreview />;

    case DashboardTheme.Dark:
      return <NeonThemePreview />;

    case DashboardTheme.Polygon:
      return <PolygonThemePreview />;

    default:
      return <LightThemePreview />;
  }
};

// prettier-ignore
const S = {
  SettingsContainer: styled.div(({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(4)};
  `
  ),
  IconContainer: styled.div(({ theme }) => `
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: center;
  `
  ),
  ButtonContainer: styled.div(({ theme }) => `
    display: flex;
    justify-content: flex-end;
    ${theme.spacing.mt(8)}
  `
  ),
  ThemePreviewContainer: styled.div(({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(4)};
    margin-top: ${theme.spacing(6)};
  `
  ),
  ThemeButtonRow: styled.div(({ theme }) => `
    display: flex;
    gap: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(4)};
    align-items: center;
  `
  ),
  ShareSettingsContainer: styled.div(({theme}) => `
    margin-top: ${theme.spacing(6)};
  `),
  ShareSettingsTitle: styled.h3(({ theme }) => `
    ${theme.spacing.my(3)}
  `),
  Copy: styled.div(({theme})=> `
    margin-top: ${theme.spacing(3)};
    display: flex;
    align-items: center;
  `),
  CopyLink: styled.a(({theme}) => `
    display: flex;
    color: ${theme.color.secondary};
    fill: ${theme.color.secondary};
    align-items: center;
    cursor: pointer;
  `),
  CopyLinkText: styled.div(({theme}) => `
    margin: 0 8px;
  `)
};
