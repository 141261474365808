import axios, { AxiosResponse, Method } from "axios";
import { toQueryString } from "../utils/queryUtils";

type RequestOptions = {
  method: Method;
  path: string;
  body?: any;
  query?: Record<string, string | string[] | number | undefined | boolean>;
  headers?: Record<string, string>;
  authenticated?: boolean;
};

export class HttpClient {
  protected axiosInstance: ReturnType<typeof axios.create>;

  constructor(protected baseURL: string, protected timeout: number = 3000) {
    this.axiosInstance = axios.create?.({
      baseURL: this.baseURL,
      timeout: this.timeout,
    });
  }

  setTimeout(timeout: number) {
    this.axiosInstance.defaults.timeout = timeout;
  }

  protected async post({
    path,
    body,
    headers,
    authenticated,
  }: Pick<RequestOptions, "path" | "body" | "headers" | "authenticated">) {
    return await this.doRequest({ method: "POST", path, body, headers, authenticated });
  }

  protected async get({
    path,
    query,
    headers,
    authenticated,
  }: Pick<RequestOptions, "path" | "query" | "headers" | "authenticated">) {
    return await this.doRequest({ method: "GET", path, query, headers, authenticated });
  }

  protected async put({
    path,
    body,
    headers,
    authenticated,
  }: Pick<RequestOptions, "path" | "body" | "headers" | "authenticated">) {
    return await this.doRequest({ method: "PUT", path, body, headers, authenticated });
  }

  protected async patch({
    path,
    body,
    headers,
    authenticated,
  }: Pick<RequestOptions, "path" | "body" | "headers" | "authenticated">) {
    return await this.doRequest({ method: "PATCH", path, body, headers, authenticated });
  }

  protected async delete({ path, headers, authenticated }: Pick<RequestOptions, "path" | "headers" | "authenticated">) {
    return await this.doRequest({ method: "DELETE", path, headers, authenticated });
  }

  protected async doRequest({
    method,
    path,
    body,
    query,
    headers = {},
    authenticated,
  }: RequestOptions): Promise<AxiosResponse<any, any>> {
    const queryString = query ? toQueryString(query) : "";

    try {
      return await this.axiosInstance.request({
        method,
        url: path + queryString,
        data: body,
        headers: {
          ...this.getDefaultHeaders(),
          ...(authenticated ? this.getAuthHeaders() : {}),
          ...headers,
        },
      });
    } catch (err: any) {
      let error = err;

      if ("toJSON" in err) {
        error = err.toJSON();
      }

      throw error;
    }
  }

  protected getDefaultHeaders() {
    return {};
  }

  protected getAuthHeaders(): Record<string, string> {
    return {};
  }
}
