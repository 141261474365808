import { useTheme } from "@emotion/react";
import styled from "@emotion/styled/macro";
import ConfigureMessage from "components/WidgetConfigureMessage";
import { ErrorState } from "components/widgets/loading/ErrorState";
import { LoadingState } from "components/widgets/loading/LoadingState";
import { LoadingStatus } from "constants/constants";
import { FontSize, Spacing } from "constants/themeConstants";
import { useCategorisedValuesFromDataSource } from "datasources/hooks";
import { observer } from "mobx-react-lite";
import { StackedBarChartWidgetViewModel } from "models/widgets/StackedBarChartWidget";
import { useEffect, useRef } from "react";
import { Bar, BarChart, LabelList, ResponsiveContainer } from "recharts";
import { useStore } from "stores/RootStore";
import language from "translations/language";

interface Props {
  widget: StackedBarChartWidgetViewModel;
}

const MINIMIMUM_CHART_VALUE = 1;

const StackedBarChartWidget = ({ widget }: Props) => {
  const theme = useTheme();
  const data = useCategorisedValuesFromDataSource(widget.settings.dataSource);
  const { dashboardStore } = useStore();
  const exportRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    dashboardStore.setWidgetChartRef(widget.widgetId, exportRef);
  });

  if (!widget.settings.dataSource) return <ConfigureMessage widgetName={language.widgets.STACKED_BAR_CHART} />;

  if (data.loadingStatus === LoadingStatus.Loading) {
    return <LoadingState />;
  }

  if (data.loadingStatus === LoadingStatus.Error) {
    return <ErrorState />;
  }

  // We set a minimum display value for each bar, so that the bars are visible even if the values are very small.
  // currently the minimum display value is 20% of the total value or 1 whichever is greater.
  const sumTotal = Object.values(data.categories).reduce((acc, category) => acc + category.value, 0);
  const minimumDisplayValue = Math.max(Math.round(sumTotal * 0.2), MINIMIMUM_CHART_VALUE);

  const chartData = {
    ...Object.fromEntries(
      Object.entries(data.categories).map(([key, categoryData]) => [
        key,
        { value: categoryData.value, display: Math.max(categoryData.value, minimumDisplayValue) },
      ])
    ),
    total: { value: data.total?.value, display: data.total?.value },
  };

  const barColors = [
    theme.color.chartlow,
    theme.color.chartmedium,
    theme.color.charthigh,
    theme.color.chart100,
    theme.color.chart200,
    theme.color.chart300,
    theme.color.chart400,
    theme.color.chart500,
  ];

  return (
    <S.Container ref={exportRef}>
      <S.BarContainer currentThemeName={theme.name ?? ""}>
        <BarChart
          key={`barchart-${Math.random()}`}
          data={[chartData]}
          barCategoryGap="0%"
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          {Object.entries(data.categories).map(([key, categoryData], index) => (
            <Bar
              stackId="bar"
              key={`${key}.display`}
              dataKey={`${key}.display`}
              fill={barColors[index % barColors.length]}
              isAnimationActive={false}
            >
              <LabelList dataKey={`${key}.value`} position="center" fontSize={FontSize.Large} />
            </Bar>
          ))}
        </BarChart>
      </S.BarContainer>

      <S.LabelsContainer>
        <BarChart
          key={`barchartlabels-${Math.random()}`}
          data={[chartData]}
          barCategoryGap="0%"
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          {Object.entries(data.categories).map(([key, categoryData]) => (
            <Bar stackId="bar" dataKey={`${key}.display`} fill="transparent" key={key} isAnimationActive={false}>
              <LabelList
                dataKey={`${key}.value`}
                fontSize={FontSize.Large}
                content={renderCustomizedLabel(categoryData.label)}
              />
            </Bar>
          ))}
        </BarChart>
      </S.LabelsContainer>
      {data.total && (
        <S.TotalContainer>
          <S.TotalWrapper>
            <S.TotalText>{data.total.value}</S.TotalText>
            <S.LabelText>{data.total.label}</S.LabelText>
          </S.TotalWrapper>
        </S.TotalContainer>
      )}
    </S.Container>
  );
};
export default observer(StackedBarChartWidget);

// prettier-ignore
const S = {
  Container: styled.div(({ theme }) =>`
    display: flex;
    width: 100%;
    padding: 0 32px 16px 32px;
    align-items: flex-start;
    height: calc(100% - 64px); // 64px = Header height + bottom widget padding

    ${theme.color.bg.neutral100}
  `),
  // prettier-ignore
  BarContainer: styled(ResponsiveContainer)<{ currentThemeName: string }>(({ theme, currentThemeName } ) => `
    max-width: 74px;

    text {
      fill: ${currentThemeName.toLowerCase().includes("dark") ? theme.color.neutral : theme.color.primary};
    }
  `),
  // prettier-ignore
  LabelsContainer: styled(ResponsiveContainer)(({ theme }) => `
    margin-left: 24px;

    svg {
      overflow: visible;
    }

    text {
      fill: ${theme.color.primary};
    }
  `),
  TotalContainer: styled.div`
    margin-left: auto;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
  `,
  // prettier-ignore
  TotalWrapper: styled.div(({ theme }) => `
    padding-left: ${Spacing.s4};
    padding-top: ${Spacing.s4};
    ${theme.color.bg.neutral100}
    
    z-index: 1;
    flex-direction: column-reverse;
    display: flex;
  `
  ),
  TotalText: styled.p`
    margin: 0;
    text-align: right;
    font-weight: bold;
    font-size: ${FontSize.XXXL};
    line-height: ${FontSize.XXXL};
  `,
  LabelText: styled.p`
    margin: 0;
    text-align: right;
    font-weight: normal;
    font-size: ${FontSize.Medium};
    line-height: ${FontSize.Large};
    margin-bottom: ${Spacing.s1};
  `,
};

const renderCustomizedLabel = (text: string) => (props: any) => {
  const { x, y, height } = props;

  return (
    <g>
      <text x={x} y={y + height / 2} textAnchor="start" dominantBaseline="middle">
        {text}
      </text>
    </g>
  );
};
