import styled from "@emotion/styled/macro";
import { Input } from "components/common";
import { runInAction } from "mobx";
import { useLocalObservable } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Threshold, ThresholdColor } from "models/widgets/common/common";
import language from "translations/language";
import ThresholdRow from "./ThresholdRow";
import { useState } from "react";
import { WidgetType } from "constants/widgetConstants";
import { isNumeric } from "utils/numberUtils";

interface Props {
  onChange: (threshold: Threshold[]) => any;
  threshold: Threshold[] | null;
}

enum ThresholdOperator {
  LessThan = "<",
  LessThanOrEqual = "<=", // Currently the only one supported
  GreaterThan = ">",
  GreaterThanOrEqual = ">=",
}

type ThresholdValue = {
  min: string;
  value?: string;
  color?: ThresholdColor;
};

const ThresholdsPicker = ({ onChange, threshold }: Props) => {
  const [error, setError] = useState<{ hasError: boolean; index: number }>({ hasError: false, index: -1 });

  const thresholds: Threshold[] = useLocalObservable(
    () =>
      threshold ?? [
        {
          color: ThresholdColor.Green,
          range: {
            min: 0,
            max: 0,
          },
        },
        {
          color: ThresholdColor.Yellow,
          range: {
            min: 0,
            max: 0,
          },
        },
        {
          color: ThresholdColor.Red,
          range: {
            min: 0,
            max: 0,
          },
        },
      ]
  );

  const handleThresholdsUpdate = (index: number, thresholdValue: ThresholdValue) => {
    runInAction(() => {
      if (isNumeric(thresholdValue.min)) {
        thresholds[index].range.min = Number.parseFloat(thresholdValue.min);
      } else {
        thresholds[index].color = thresholdValue.color ?? ThresholdColor.Green;
        if (isNumeric(thresholdValue?.value!)) {
          thresholds[index].range.max = Number.parseFloat(thresholdValue?.value!);
        }

        if (index < thresholds.length - 1) {
          thresholds[index + 1].range.min = thresholds[index].range.max;
        }
      }
    });

    for (let i = 1; i < thresholds.length; i++) {
      if (thresholds[i].range.max <= thresholds[i - 1].range.max) {
        setError({ hasError: true, index: i });
        return;
      }
    }
    setError({ hasError: false, index: -1 });

    performChange();
  };

  const handleAddRow = (index: number) => {
    runInAction(() =>
      thresholds.splice(index + 1, 0, {
        color: ThresholdColor.Green,
        range: {
          min: thresholds[index].range.max,
          max: thresholds[index].range.max,
        },
      })
    );

    performChange();
  };

  const handleRemoveRow = (index: number) => {
    if (thresholds.length === 2) return;

    runInAction(() => thresholds.splice(index, 1));
    performChange();
  };

  const performChange = () => {
    onChange(thresholds);
  };

  return (
    <>
      <SThresholdPickerRow>
        <SThresholdPickerRowCell>
          <label htmlFor="min">{language.MIN}</label>
          <SInput
            type="text"
            data-testid="min"
            id="min"
            value={thresholds[0]?.range.min}
            onChange={(e) => handleThresholdsUpdate(0, { min: e.target.value })}
          />
        </SThresholdPickerRowCell>
      </SThresholdPickerRow>

      {thresholds.map((threshold, index) => (
        <SThresholdPickerRow key={index}>
          <SThresholdPickerRowCell>
            <ThresholdRow
              addThresholdRow={() => handleAddRow(index)}
              removeThresholdRow={() => handleRemoveRow(index)}
              settingsKey={ThresholdOperator.LessThanOrEqual}
              thresholdColor={threshold.color || ThresholdColor.Green}
              thresholdValue={threshold.range.max}
              onChange={(value) => {
                handleThresholdsUpdate(index, value);
              }}
              hideButtons={index === thresholds.length - 1}
              label={thresholds.length - 1 === index ? language.MAX : ThresholdOperator.LessThanOrEqual}
              validationError={error.hasError && error.index === index}
            />
          </SThresholdPickerRowCell>

          {error.hasError && error.index === index && (
            <SThresholdPickerRow validationError={error.hasError}>
              {language.widgets.descriptions[WidgetType.GaugeWidget].validationErrorMessage}
            </SThresholdPickerRow>
          )}
        </SThresholdPickerRow>
      ))}
    </>
  );
};
export default observer(ThresholdsPicker);

// prettier-ignore
const SThresholdPickerRow = styled.div<{validationError?: boolean}> `
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spacing(4)};
  margin-bottom: ${({theme, validationError}) => validationError ?theme.spacing(0) : theme.spacing(4)};
  margin-top: ${({theme, validationError}) => validationError ?theme.spacing(0) : theme.spacing(4)};
  margin-left: ${({ validationError }) => validationError && "90px"};
  color: ${({theme, validationError}) => validationError && theme.color.error};

  ${({theme}) => theme.typography.body2}

  label {
    font-weight: bold;
    ${({theme}) => theme.spacing.mr(2)}
    width: 80px;
  }
`;

const SThresholdPickerRowCell = styled.div`
  display: flex;
  align-items: center;
`;

const SInput = styled(Input)`
  width: 139px;
`;
