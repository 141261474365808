import { observer } from "mobx-react-lite";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  DASHBOARDS_ROUTE,
  DASHBOARD_ROUTE,
  EDIT_DASHBOARD_ROUTE,
  EDIT_WIDGET_ROUTE,
  LOGIN_ROUTE,
  DASHBOARD_VIEW_ROUTE,
  GRAPH_ROUTE,
  HOME_PAGE_DASHBOARD_ROUTE,
} from "./constants/routes";
import NiceModal from "@ebay/nice-modal-react";
import DashboardPage from "./pages/DashboardPage";
import EditDashboardPage from "./pages/EditDashboardPage";
import EditWidgetPage from "./pages/EditWidgetPage";
import IndexPage from "./pages/IndexPage";
import LoginPage from "./pages/LoginPage";
import DashboardViewPage from "./pages/DashboardViewPage";
import { useStore } from "./stores/RootStore";
import GraphPage from "pages/GraphPage";
import HomePageDashboardPage from "pages/HomePageDashboardPage";

const AppRoutes = () => {
  const { authStore } = useStore();

  const AuthenticatedRoute = (Element: () => JSX.Element) =>
    !authStore.hasToken ? <Navigate to={LOGIN_ROUTE} /> : <Element />;

  return (
    <BrowserRouter>
      <NiceModal.Provider>
        <Routes>
          <Route path={DASHBOARDS_ROUTE} element={AuthenticatedRoute(IndexPage)} />
          <Route path={DASHBOARD_ROUTE} element={AuthenticatedRoute(DashboardPage)} />
          <Route path={EDIT_DASHBOARD_ROUTE} element={AuthenticatedRoute(EditDashboardPage)} />
          <Route path={EDIT_WIDGET_ROUTE} element={AuthenticatedRoute(EditWidgetPage)} />
          <Route path={DASHBOARD_VIEW_ROUTE} element={<DashboardViewPage />} />
          <Route path={GRAPH_ROUTE} element={<GraphPage />} />
          <Route
            path={LOGIN_ROUTE}
            element={<>{authStore.hasToken ? <Navigate to={DASHBOARDS_ROUTE} /> : <LoginPage />}</>}
          />
          <Route path="*" element={<Navigate to={DASHBOARDS_ROUTE} />} />
          <Route path={HOME_PAGE_DASHBOARD_ROUTE} element={AuthenticatedRoute(HomePageDashboardPage)} />
        </Routes>
      </NiceModal.Provider>
    </BrowserRouter>
  );
};

export default observer(AppRoutes);
