import { LoadingStatus } from "constants/constants";
import { hasData, Loadable } from "models/Loading";
import { ProtectedHouseholdsDataSource } from "models/widgets/common/value";
import { RootStore } from "stores/RootStore";
import language from "translations/language";
import { CategorisedDataSource, CategorisedValues } from "../interfaces";

export class ProtectedHouseholdsCategoryDataAdapter implements CategorisedDataSource<ProtectedHouseholdsDataSource> {
  constructor(private stores: RootStore) {}

  getData(config: ProtectedHouseholdsDataSource): Loadable<CategorisedValues> {
    const household = this.stores.protectedHouseholdsStore.get(config.organizationId);

    if (!hasData(household)) {
      return household;
    }
    return {
      loadingStatus: LoadingStatus.Loaded,
      categories: {
        low: { value: household.low, label: language.protectedHouseholds.LOW },
        medium: { value: household.medium, label: language.protectedHouseholds.MEDIUM },
        high: { value: household.high, label: language.protectedHouseholds.HIGH },
      },
      total: { value: household.total, label: language.protectedHouseholds.TOTAL },
    };
  }

  async loadData(config: ProtectedHouseholdsDataSource) {
    await this.stores.protectedHouseholdsStore.load(config.organizationId);
  }
}
