import styled from "@emotion/styled/macro";
import { Button, Input, Toggle } from "components/common";
import * as Form from "components/common/form";
import CompositeSelect from "components/edit/CompositeSelect";
import { CustomerPicker } from "components/edit/CustomerPicker";
import DateRangeIntervalPicker from "components/edit/DateRangeIntervalPicker";
import LocationPicker, { Location } from "components/edit/LocationPicker";
import RangePicker from "components/edit/RangePicker";
import { SelectSetting } from "components/edit/SelectSetting";
import ThingMetricPicker from "components/edit/ThingMetricPicker";
import ThingPicker from "components/edit/ThingPicker";
import ThresholdPicker from "components/edit/ThresholdPicker";
import { SettingsType } from "constants/constants";
import { DashboardPurpose } from "constants/dashboardConstants";
import { MapOptions, SettingsDescriptor } from "data/widgetData";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { DashboardMetadata } from "models/Dashboard";
import { Thing } from "models/Thing";
import { DateRangeOrInterval } from "models/widgets/ChartWidget";
import { isNumeric } from "utils/numberUtils";
import DayRangePicker, { DayRange } from "./DayRangePicker";
import OrganizationPicker from "./OrganizationPicker";
import { useState } from "react";
import { RadioButton } from "../common/radioButton";
import MapOptionsPicker from "./MapOptionsPicker";
import { Threshold, ValueRange } from "models/widgets/common/common";
import language from "translations/language";
import {
  HEALTH_WIDGET_HIGH_RISK_DEFAULT_MAX,
  HEALTH_WIDGET_HIGH_RISK_DEFAULT_MIN,
  HEALTH_WIDGET_MEDIUM_RISK_DEFAULT_MAX,
  HEALTH_WIDGET_MEDIUM_RISK_DEFAULT_MIN,
  HEALTH_WIDGET_NORMAL_DEFAULT_MAX,
  HEALTH_WIDGET_NORMAL_DEFAULT_MIN,
} from "data/healthWidget/healthSettings";

type WidgetSettingsProps = {
  settingsDescriptor: SettingsDescriptor;
  state: Record<string, any>;
  /** Full state of the widget settings, only passed on to sub components */
  fullState?: Record<string, any>;
  dashboardMeta: DashboardMetadata;
};

const thingFilterForDashboard = (dashboardMeta: DashboardMetadata) => {
  if (dashboardMeta.dashboardPurpose === DashboardPurpose.Customer) {
    return (thing: Thing) => thing.owner.userId === dashboardMeta.customerId;
  }

  if (dashboardMeta.dashboardPurpose === DashboardPurpose.Stack) {
    return (thing: Thing) => thing.stack.stackId === dashboardMeta.stackId;
  }

  return undefined;
};

const SettingsComponents = ({
  settingsDescriptor,
  state: externalState,
  fullState,
  dashboardMeta,
}: WidgetSettingsProps) => {
  const [state, setState] = useState(externalState);

  const setValue = (key: string, value: any) => {
    runInAction(() => (externalState[key] = value));

    setState({ ...externalState });
  };
  return (
    <>
      {Object.entries(settingsDescriptor).map(([key, setting]) => {
        if (setting.type === SettingsType.Hidden) {
          /* Hidden settings are not rendered and just for other areas of the system to know a setting exists */
          return null;
        }

        const hidden = typeof setting.hidden === "function" ? setting.hidden(fullState ?? state) : setting.hidden;

        /* hidden is optional */
        if (hidden === true) {
          return null;
        }

        const title = <S.Label>{setting.label}</S.Label>;

        let settingsComponent = null;
        let noTitle = false;

        const disabled =
          typeof setting.disabled === "function" ? setting.disabled(fullState ?? state) : setting.disabled;

        switch (setting.type) {
          case SettingsType.Select:
            settingsComponent = (
              <SelectSetting
                settingsKey={key}
                options={setting.options}
                value={state[key]}
                defaultValue={setting.default!}
                onChange={setValue}
                multiple={setting.multiple}
              />
            );
            break;

          case SettingsType.CompositeSelect:
            settingsComponent = (
              <CompositeSelect
                options={setting.options}
                values={Object.fromEntries(setting.keys.map((key) => [key, state[key]]))}
                onChange={setValue}
              />
            );
            break;

          case SettingsType.Text:
            settingsComponent = (
              <Input defaultValue={state[key] || ""} onChange={(e) => setValue(key, e.target.value)} />
            );
            break;

          case SettingsType.ImageUrlWithPreview:
            settingsComponent = (
              <Input defaultValue={state[key] || ""} onChange={(e) => setValue(key, e.target.value)} imagePreview />
            );
            break;

          case SettingsType.Toggle:
            settingsComponent = <Toggle value={state[key]} onChange={(e) => setValue(key, !!e.target.checked)} />;
            break;

          case SettingsType.Integer:
            settingsComponent = (
              <Input
                type="number"
                step="1"
                min={setting.min}
                max={setting.max}
                defaultValue={state[key]}
                onChange={(e) => isNumeric(e.target.value) && setValue(key, parseInt(e.target.value))}
              />
            );
            break;

          case SettingsType.Location:
            settingsComponent = (
              <LocationPicker value={state[key]} onChange={(location: Location) => setValue(key, location)} />
            );
            break;

          case SettingsType.Range:
            settingsComponent = (
              <RangePicker
                value={state[key]}
                defaultValue={setting.default!}
                onChange={(range: ValueRange) => setValue(key, range)}
              />
            );
            break;

          case SettingsType.DayRange:
            settingsComponent = (
              <DayRangePicker
                value={state[key]}
                onChange={(range: DayRange) => setValue(key, { from: range.from, to: range.to })}
              />
            );
            break;

          case SettingsType.TimeInterval:
            settingsComponent = (
              <DateRangeIntervalPicker
                options={setting.options}
                value={state[key]}
                onChange={(dateRangeIntervalValue: DateRangeOrInterval) => setValue(key, dateRangeIntervalValue)}
              />
            );
            break;

          case SettingsType.Thresholds:
            settingsComponent = (
              <ThresholdPicker
                threshold={state[key]}
                onChange={(thresholds: Threshold[]) => setValue(key, thresholds)}
              />
            );
            break;

          case SettingsType.Thing:
            const thingFilter = thingFilterForDashboard(dashboardMeta);
            noTitle = true;

            settingsComponent = (
              <ThingPicker
                settingsKey={key}
                value={state[key]}
                onChange={setValue}
                multiple={setting.multiple}
                thingFilter={thingFilter}
                disabled={disabled}
              />
            );
            break;

          case SettingsType.Nested:
            if (state[key] == null) {
              runInAction(() => (state[key] = {}));
            }
            settingsComponent = (
              <>
                <SettingsComponents
                  settingsDescriptor={setting.settings}
                  state={state[key]}
                  fullState={fullState ?? state}
                  dashboardMeta={dashboardMeta}
                />
                <Button
                  onClick={() => {
                    setValue(key, {
                      highRisk: {
                        min: HEALTH_WIDGET_HIGH_RISK_DEFAULT_MIN,
                        max: HEALTH_WIDGET_HIGH_RISK_DEFAULT_MAX,
                      },
                      mediumRisk: {
                        min: HEALTH_WIDGET_MEDIUM_RISK_DEFAULT_MIN,
                        max: HEALTH_WIDGET_MEDIUM_RISK_DEFAULT_MAX,
                      },
                      normal: {
                        min: HEALTH_WIDGET_NORMAL_DEFAULT_MIN,
                        max: HEALTH_WIDGET_NORMAL_DEFAULT_MAX,
                      },
                    });
                  }}
                  rounded
                  ghost
                >
                  {language.RESET_THRESHOLD}
                </Button>
              </>
            );
            break;

          case SettingsType.Switch:
            if (state[key] == null) {
              runInAction(() => (state[key] = {}));
            }
            const selectedValue = state[key][setting.switchKey];
            const allowedKeys = [setting.switchKey, ...Object.keys(setting.options[selectedValue] ?? {})];

            runInAction(() => {
              // Only data configurable for this branch of the switch should be in the state
              for (const innerKey of Object.keys(state[key])) {
                if (!allowedKeys.includes(innerKey)) {
                  delete state[key][innerKey];
                }
              }
            });

            const nestedSettings = selectedValue ? (
              <SettingsComponents
                settingsDescriptor={setting.options[selectedValue]}
                state={state[key]}
                fullState={fullState ?? state}
                dashboardMeta={dashboardMeta}
              />
            ) : null;

            const innerSetValue = (innerKey: string, value: any) =>
              runInAction(() => {
                if (!externalState[key]) {
                  externalState[key] = {};
                }

                externalState[key][innerKey] = value;

                setState({ ...externalState });
              });

            settingsComponent = (
              <>
                <SelectSetting
                  settingsKey={setting.switchKey}
                  options={setting.switchField.options.filter((option) =>
                    hasVisibleSettings(setting.options[option.key])
                  )}
                  value={selectedValue}
                  defaultValue={setting.switchField.default!}
                  onChange={innerSetValue}
                  multiple={false}
                />
                {nestedSettings}
              </>
            );
            break;

          case SettingsType.Organization:
            settingsComponent = <OrganizationPicker settingsKey={key} value={state[key]} onChange={setValue} />;
            break;

          case SettingsType.Customer:
            settingsComponent = (
              <CustomerPicker
                initialCustomerId={state[key] ?? undefined}
                onCustomerChange={(customer) => {
                  setValue(key, customer?.userId);
                }}
              />
            );
            break;

          case SettingsType.RadioButton:
            settingsComponent = (
              <RadioButton
                value={state[key]}
                settingsKey={key}
                options={setting.options}
                defaultValue={setting.default}
                onChange={setValue}
              />
            );
            break;

          case SettingsType.ThingMetric:
            settingsComponent = (
              <ThingMetricPicker
                settingsKey={key}
                options={setting.options}
                defaultValue={state[key]}
                onChange={setValue}
                multiple={setting.multiple}
                getSelectedThingIds={() =>
                  Array.isArray(state[setting.thingsIdField])
                    ? state[setting.thingsIdField]
                    : [state[setting.thingsIdField]]
                }
              />
            );
            break;

          case SettingsType.MapOptions:
            settingsComponent = (
              <MapOptionsPicker
                value={state[key]}
                onChange={(options: MapOptions) => setValue(key, options)}
                dataSource={externalState}
              />
            );
        }

        return (
          <Form.Row key={key}>
            {!noTitle && title}
            {settingsComponent}
          </Form.Row>
        );
      })}
    </>
  );
};

export default observer(SettingsComponents);

function hasVisibleSettings(settings: SettingsDescriptor) {
  return !Object.values(settings ?? {}).every((setting) => {
    const hidden = typeof setting.hidden === "function" ? setting.hidden(settings) : setting.hidden;

    return hidden === true;
  });
}

// prettier-ignore
const S = {
	ButtonContainer: styled.div(({ theme }) => `
	  display: flex;
	  justify-content: flex-end;
	  ${theme.spacing.mt(4)}
	`),

  Label: styled.label(({ theme }) => `
    display: block;
    
    ${theme.typography.body2}
    font-weight: bold;
    ${theme.color.primary300}
  `),
  };
