import { useState } from "react";
import styled from "@emotion/styled/macro";

type Option = { id: string; name: string; icon?: JSX.Element };

type DropdownProps = {
  children?: JSX.Element | JSX.Element[] | string;
  options: Option[];
  optionOnClick: (id: string) => void;
  width?: string;
};

export const Dropdown = ({ children, options, optionOnClick, width }: DropdownProps) => {
  const [opened, setOpened] = useState(false);

  return (
    <S.Dropdown onMouseLeave={() => setOpened(false)}>
      <S.Icon onClick={() => setOpened(!opened)}>{children}</S.Icon>

      {opened && (
        <S.Menu width={width}>
          {options.map((option) => (
            <S.MenuItem
              key={option.id}
              onClick={() => {
                optionOnClick(option.id);
                setOpened(false);
              }}
            >
              <S.MenuItemIcon>{option.icon}</S.MenuItemIcon>
              {option.name}
            </S.MenuItem>
          ))}
        </S.Menu>
      )}
    </S.Dropdown>
  );
};

// prettier-ignore
const S = {
  Dropdown: styled.div(`
        position: relative;
  `),

  Icon: styled.div(({ theme }) => `
      cursor: pointer;
  `),

  Menu: styled.div<{width: string | undefined}>( ({ theme, width }) => `
    position: absolute;
    top: 16px;
    right: 0;
    background-color: ${theme.color.neutral100};
    border: 1px solid;
    border-color: ${theme.color.neutral400};
    border-radius: ${theme.borderRadius.md};
    color: ${theme.color.text.primary};
    ${theme.typography.body3};
    width: ${width ? width : "200px"};
    padding: 8px 0;
    z-index: 2;
    cursor: pointer;
    box-shadow: ${theme.shadow.dropdown};
  `),

  MenuItem: styled.div(({ theme }) => `
    display: flex;
    padding: 4px 12px;
    align-items: center;
  
    &:hover {
        background-color: ${theme.color.neutral700};
        color:  ${theme.color.neutral100};
    },
  `),

	MenuItemIcon: styled.span(({ theme }) => `
		margin-right: 5px;
	`)
};
