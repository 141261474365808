import { SettingsType } from "constants/constants";
import { SettingsDescriptor } from "data/widgetData";
import language from "translations/language";

export const imageWidgetSettings: SettingsDescriptor = {
  url: {
    type: SettingsType.ImageUrlWithPreview,
    label: language.widgets.settings.IMAGE_URL,
  },
};
