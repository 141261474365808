import { CAPTION_DATA_REFRESH_TIMEOUT } from "constants/captionDataConstants";
import { LoadingStatus } from "constants/constants";
import { LiveThingProperty } from "constants/widgetConstants";
import { getValueForThing } from "datasources/singleValueData/adapters/helpers/thingValues";
import { SingleValue, SingleValueDataSource } from "datasources/singleValueData/interfaces";
import { hasData, Loadable } from "models/Loading";
import { Thing } from "models/Thing";
import { stateModifiedAt } from "models/ThingDataExtraction";
import { ThingDataSource } from "models/widgets/common/common";
import { RootStore } from "stores/RootStore";

export class ThingAdapterSingleValueAdapter implements SingleValueDataSource<ThingDataSource> {
  refreshTimeout: number | undefined;

  constructor(private stores: RootStore) {
    /* If deptId is used, refreshes every CAPTION_DATA_REFRESH_TIMEOUT, otherwise we rely on the incoming websocket messages */
    this.refreshTimeout = this.stores.configStore.deptId ? CAPTION_DATA_REFRESH_TIMEOUT : undefined;
  }

  getData(config: ThingDataSource): Loadable<SingleValue> {
    const thing = this.stores.thingStore.get(config.thingId);

    if (!hasData<Thing>(thing)) {
      return thing;
    }

    const value = getValueForThing(thing, config.thingProperty as LiveThingProperty);

    if (!value?.hasValue) {
      return {
        loadingStatus: LoadingStatus.Error,
        error: value?.error,
      };
    }

    return {
      loadingStatus: LoadingStatus.Loaded,
      value: value.value,
      label: value.name,
      units: value.units,
      updatedAt: stateModifiedAt(thing),
    };
  }

  async loadData(config: ThingDataSource) {
    await this.stores.thingStore.load(config.thingId);
  }
}
