import { ConnectivityMessage, KnownEventType } from "../../models/Timeline";
import { LeakAlarmType, PressureAlarmType, ThingAlarmLike, ThingAlarmType, ThingStatus } from "models/Thing";
import { DashboardPurpose, DashboardTheme } from "constants/dashboardConstants";
import {
  FilterValue,
  GaugeType,
  HealthFilterValue,
  IncidentAllTypes,
  IncidentType,
  LiveThingProperty,
  MapInitialView,
  PropertyMetric,
  SingleValueSourceTypes,
  TimeInterval,
  WidgetType,
} from "constants/widgetConstants";
import { AggregationType, ChartSourceTypes, TimeseriesType } from "models/widgets/ChartWidget";
import { MultipleValueSourceTypes } from "models/widgets/common/value";
import { GeoDataSourceTypes } from "models/widgets/MapWidget";
import { SettingsModalOptions } from "constants/constants";
import {
  MultipleAlarmsValueSourceTypes,
  MultipleStacksValueSourceTypes,
  MultipleThingsValueSourceTypes,
  ThingMetricThreshold,
  ThingMetricThresholdOperator,
} from "models/widgets/TableWidget";
import { NoClassificationReason } from "models/HealthData";
import { ThresholdColor } from "models/widgets/common/common";

const fi = {
  EDIT: "Muokkaa",
  NEW: "Uusi",
  SAVE: "Tallenna",
  CREATE: "Luo",
  NAME: "Nimi",
  ADD: "Lisää",
  REMOVE: "Poista",
  CREATE_COPY: "Luo kopio",
  MODIFIED: "Muokattu",
  OWNER: "Omistaja",
  ADDRESS: "Osoite",
  VIEW: "Näytä",
  SETTINGS: "Asetukset",
  SHARING: "Jakaminen",
  COLOR: "Väri",
  EMPTY: "Tyhjä",
  BACK: "Takaisin",
  SEARCH: "Haku",
  FULLSCREEN: "Koko näyttö",
  RESET: "Palauta",
  RESET_THRESHOLD: "Palauta kynnys",

  LOGIN: "Kirjaudu",
  SEND: "Lähetä",
  EMAIL: "Sähköposti",
  LOGIN_CODE: "Kirjautumiskoodi",
  WARNING: "Varoitus",
  NEXT: ">",
  PREVIOUS: "<",

  LOADING: "Ladataan...",
  MUST_BE_CONFIGURED: (widgetName: string) => `${widgetName} -widgetin on oltava määritetty`,

  TIME_INTERVAL: "Aikaväli",
  THINGS: "Laitteet",
  TYPE: "Tyyppi",
  ACTIONS: "Toiminnot",
  DASHBOARDS: "Kojelaudat",
  THING: "Laite",
  PRODUCT: "Tuote",
  ERRAND: "Tehtävä",

  MIN: "Vähimmäisarvo",
  MAX: "Enimmäisarvo",

  BETWEEN: "Väliltä",
  BETWEEN_TO: "kunnes",
  DAYS: "päivää",
  N_DAYS: (num: string) => `${num} päivää`,

  UNKNOWN_DEVICE: "Tuntematon laite",

  CUSTOMER: "Asiakas",
  PROPERTY: "Kiinteistö",
  STATUS: "Tila",

  MORE: "Lisää",

  LATITUDE: "Leveysaste",
  LONGITUDE: "Pituusaste",
  ZOOM: "Zoomaus",
  PREVIEW: "Esikatselu",

  createDashboard: {
    CREATE_COPY_DASHBOARD_TITLE: (dashboardName: string) => `Luo kopio kojelaudasta "${dashboardName}"`,
  },

  dashboardPurpose: {
    [DashboardPurpose.Generic]: "Yleinen kojelauta",
    [DashboardPurpose.Customer]: "Asiakkaan kojelauta",
    [DashboardPurpose.Stack]: "Kiinteistön kojelauta",
  },

  dashboardShortPurpose: {
    [DashboardPurpose.Generic]: "Yleinen",
    [DashboardPurpose.Customer]: "Asiakas",
    [DashboardPurpose.Stack]: "Kiinteistö",
  },

  dashboardSettings: {
    [SettingsModalOptions.General]: "Yleiset",
    // [SettingsModalOptions.MetricSystem]: "Metrijärjestelmä",
    [SettingsModalOptions.ColorTheme]: "Koko näytön väriteema",
  },

  themeNames: {
    [DashboardTheme.Default]: "Vaalea",
    [DashboardTheme.DarkPale]: "Tumma",
    [DashboardTheme.Dark]: "Neon",
    [DashboardTheme.Polygon]: "Monikulmio",
  },

  protectedHouseholds: {
    TOTAL: "Yhteensä",
    HIGH: "Korkea suojaus",
    MEDIUM: "Keskitaso suojausta",
    LOW: "Paikallinen suojaus",
  },

  signedInstallations: {
    CUSTOMER_CREATED_TO_COMPLETE: "koko asennus",
    CUSTOMER_CREATED_TO_FIRST_SUGGESTED_INSTALLATION: "ensimmäisestä ehdotetusta asennusajasta",
    REVIEWING_IMAGES: "kuvien tarkistus",
    SCHEDULING_INSTALLATION: "asennuksen aikataulutus",
    TOTAL_COMPLETED_INSTALLATIONS: "Valmiiksi saadut asennukset yhteensä aikavälin aikana",
  },

  timelineEventTypes: {
    [KnownEventType.Alarms]: "Hälytykset",
    [KnownEventType.Connectivity]: "Yhteys",
  },

  // TODO: DRY up the alarm names here and the first set of timelineEventSubTypes
  thingAlarms: {
    [ThingAlarmType.LargeWaterFlowAlarm]: "Suuri vesivirta",
    [ThingAlarmType.LargeWaterVolumeAlarm]: "Suuri vesimäärä",
    [ThingAlarmType.LongWaterFlowAlarm]: "Pitkä vesivirta",
    [ThingAlarmType.LowTempAlarm]: "Matala lämpötila",
    [ThingAlarmType.SlowLeakage]: "Hitaasti vuotava",
    [ThingAlarmType.WaterDetected]: "Vedentunnistus",
    [ThingAlarmType.PressureAlarm]: "painehälytin",
    [ThingAlarmType.LeakAlarm]: "Vuotovahinko hälytys",
  },

  alarmTypes: {
    [LeakAlarmType.SmallLeak]: "Pieni vuoto",
    [LeakAlarmType.MediumLeak]: "Keskikokoinen vuoto",
    [LeakAlarmType.Burst]: "Räjähdys",
    [PressureAlarmType.PressureAlarm1]: "Paine hälytys 1",
    [PressureAlarmType.PressureAlarm2]: "Paine hälytys 2",
  },

  timelineEventSubTypes: {
    [ThingAlarmType.LargeWaterFlowAlarm]: "Suuri vesivirta",
    [ThingAlarmType.LargeWaterVolumeAlarm]: "Suuri vesimäärä",
    [ThingAlarmType.LongWaterFlowAlarm]: "Pitkä vesivirta",
    [ThingAlarmType.LowTempAlarm]: "Matala lämpötila",
    [ThingAlarmType.PressureAlarm]: "Paine hälytys 1",
    [ThingAlarmLike.PressureAlarm1]: "Paine hälytys 1",
    [ThingAlarmLike.PressureAlarm2]: "Paine hälytys 2",
    [ThingAlarmType.SlowLeakage]: "Hitaasti vuotava",
    [ThingAlarmType.WaterDetected]: "Vedentunnistus",
    [ThingAlarmType.LeakAlarm]: "Vuotovahinko hälytys",
    [ConnectivityMessage.Offline]: "Offline",
    [ConnectivityMessage.Online]: "Online",
    [ConnectivityMessage.Timeout]: "Aikakatkaisu",
  },

  thingProperties: {
    [LiveThingProperty.WaterPressure]: "Vedenpaine",
    [LiveThingProperty.Temperature]: "Lämpötila",
    [LiveThingProperty.WifiSignalStrength]: "WiFi-signaalin voimakkuus",
    [LiveThingProperty.RelativeHumidity]: "Suhteellinen kosteus",
    [LiveThingProperty.BatteryLevel]: "Akun taso",
    [LiveThingProperty.Humidity]: "Kosteus",
    [LiveThingProperty.CaptionDataAbsoluteHumidity]: "Absoluuttinen kosteus",
    [LiveThingProperty.CaptionDataWME]: "WME",
  } as { [key in LiveThingProperty]: string },

  thingStatus: {
    [ThingStatus.Healthy]: "Verkossa",
    [ThingStatus.Offline]: "Offline",
    [ThingStatus.HasAlarm]: "Aktiivinen hälytys",
    [ThingStatus.Preregistered]: "Rekisteröity",
    [ThingStatus.ConfigReceived]: "Konfiguraatio vastaanotettu",
    [ThingStatus.ConfigFailed]: "Konfiguraatio epäonnistui",
  },

  sourceTypes: {
    "": "Ei mitään",
    [SingleValueSourceTypes.Thing]: "Laite",
    [SingleValueSourceTypes.IncidentStats]: "Tapahtumien tilasto",
    [MultipleStacksValueSourceTypes.StackCreated]: "Projektin kesto",
    [MultipleValueSourceTypes.ProtectedHouseholds]: "Suojatut kotitaloudet",
    [MultipleValueSourceTypes.SignedInstallationStats]: "Asennukset",
    [ChartSourceTypes.ThingEventTime]: "Tapahtuma-ajat (laite)",
    [ChartSourceTypes.ThingsTimeSeries]: "Aikasarjat (laite)",
    [GeoDataSourceTypes.ThingLocations]: "Laitepaikat",
    [GeoDataSourceTypes.CustomerStackLocations]: "Kiinteistöpaikat",
    [MultipleThingsValueSourceTypes.ThingMetricThreshold]: "Arvokynnys laitteille",
    [MultipleAlarmsValueSourceTypes.Alarms]: "Hälytykset",
    undefined: "Ei mitään",
  } as Record<string, string>,

  incidentTypes: {
    [IncidentType.Offline]: "Laite offline",
    [IncidentType.FailedInstallations]: "Epäonnistuneet asennukset",
  } as { [key in IncidentType]: string },

  incidentAllTypes: {
    [IncidentAllTypes.ActiveLoss]: "Aktiivinen vika",
    [IncidentAllTypes.LargeWaterFlowAlarm]: "Suuri vesivirta hälytys",
    [IncidentAllTypes.LargeWaterVolumeAlarm]: "Suuri vesimäärä hälytys",
    [IncidentAllTypes.LongWaterFlowAlarm]: "Pitkä vesivirta hälytys",
    [IncidentAllTypes.LeakAlarm]: "Vuotovahinko hälytys",
    [IncidentAllTypes.Other]: "Muu",
    [IncidentAllTypes.PressureAlarm1]: "Paine hälytys 1",
    [IncidentAllTypes.PressureAlarm2]: "Paine hälytys 2",
    [IncidentAllTypes.Slowleakage]: "Hitaasti vuotava vika",
    [IncidentAllTypes.Waterleakage]: "Veden vuotovahinko",
    [IncidentAllTypes.Offline]: "Offline",
    [IncidentAllTypes.Wpa2]: "WPA2",
    [IncidentAllTypes.Wifi]: "WiFi",
    [IncidentAllTypes.WaterleakageUnknown]: "Tuntematon vuotovahinko",
    [IncidentAllTypes.PassiveLoss]: "Passiivinen vika",
    [IncidentAllTypes.SolvedThemselves]: "Ratkaisevat itse",
    [IncidentAllTypes.WarrentyReplacement]: "Takuuvaihto",
    [IncidentAllTypes.ReturnSensor]: "Palauta anturi",
    [IncidentAllTypes.Test]: "Testi",
    [IncidentAllTypes.FalseAlarm]: "Virheellinen hälytys",
    [IncidentAllTypes.Followup]: "Seuranta",
    [IncidentAllTypes.FailedInstallations]: "Epäonnistuneet asennukset",
    [IncidentAllTypes.LowBattery]: "Alhainen akun varaus",
  } as { [key in IncidentAllTypes]: string },

  thingMetricThreshold: {
    [ThingMetricThreshold.Pressure]: "Paine",
    [ThingMetricThreshold.Temperature]: "Lämpötila",
  } as { [key in ThingMetricThreshold]: string },

  thingMetricThresholdUnit: {
    [ThingMetricThreshold.Pressure]: "baari",
    [ThingMetricThreshold.Temperature]: "°C",
  } as { [key in ThingMetricThreshold]: string },

  thingMetricThresholdOperator: {
    [ThingMetricThresholdOperator.GreaterThan]: "Suurempi kuin",
    [ThingMetricThresholdOperator.GreaterThanEqual]: "Suurempi tai yhtä suuri kuin",
    [ThingMetricThresholdOperator.LessThan]: "Pienempi kuin",
    [ThingMetricThresholdOperator.LessThanEqual]: "Pienempi tai yhtä suuri kuin",
  } as { [key in ThingMetricThresholdOperator]: string },

  tableWidgetColumnTitles: {
    DATA_SOURCE: "Tietolähde",
    UPDATED_AT: "Päiväys",
    STATUS: "Tila",
  },

  errors: {
    PROPERTY_NOT_AVAILABLE: (propertyName: string) => `${propertyName} ei ole saatavilla tälle laitteelle`,
    CUSTOMER_DATA_LOAD_ERROR: "Asiakastietoja ei voitu ladata",
    GENERIC_DATA_LOAD_ERROR: "Tietoja ei voitu ladata",
    UNEXPECTED_WIDGET_ERROR: "Jotain meni pieleen tämän pienoisohjelman latauksessa",
    NO_DATA_TO_DISPLAY_FOR_PERIOD: (period: string) => `Ei ole näytettävää dataa viimeisen ${period} päivän ajalta`,
    NO_DATA_TO_DISPLAY: "Ei näytettävää dataa",
    NO_METRICS_FOR_SELECTED_THINGS: "Valituille laitteille ei ole saatavilla mittareita",
    ERROR_LOADING_DATA: "Virhe ladattaessa dataa",
  },

  thresholds: {
    [ThresholdColor.Green]: "Normaali",
    [ThresholdColor.Yellow]: "Varoitus",
    [ThresholdColor.Red]: "Vaara",
  } as { [key in ThresholdColor]: string },

  gaugeType: {
    [GaugeType.Classic]: "Klassinen",
    [GaugeType.Solid]: "Kiinteä",
  } as { [key in GaugeType]: string },

  mapInitialView: {
    [MapInitialView.Default]: "Oletusarvo",
    [MapInitialView.Custom]: "Mukautettu",
  },

  colors: {
    RED: "Punainen",
    YELLOW: "Keltainen",
  },

  charts: {
    line: "Viiva",
    scatter: "Hajonta",
  },

  widgets: {
    WEATHER: "Sää",
    GAUGE: "Mittari",
    CLOCK: "Kello",
    TEST: "Testikaavio",
    TEXT: "Teksti",
    VALUE: "Arvo",
    STACKED_BAR_CHART: "Pinottu pylväskaavio",
    BAR_CHART: "Pylväskaavio",
    CUSTOMER: "Asiakasnäkymä",
    TABLE: "Taulukko",
    BOOKMARKS: "Kirjanmerkit",
    OFFLINE: "Offline",
    TIMESERIES_CHART: "Aikasarjakaavio",
    GENERIC_CHART: "Kaavio",
    MAP_WIDGET: "Kartta",
    TIME: "Aika",
    PROPERTY_HEALTH: "Kiinteistön kunto",
    IMAGE: "Kuva",

    settings: {
      DATA_SOURCE_TYPE: "Tyyppi",
      DATA_SOURCE: "Tietolähde",
      DAY_RANGE_FROM: "Alkaen",
      DAY_RANGE_TO: "Päättyen",
      DAY_RANGE: "Aukiolo päivät",
      EVENT: "Tapahtuma",
      INCIDENT_TYPE: "Tapahtuman tyyppi",
      LOCATION: "Sijainti",
      ORGANIZATION_ID: "Organisaatio",
      RANGE: "Kattavuus",
      THING_PROPERTY: "Mittari",
      THINGID: "Laite",
      THRESHOLDS: "Kynnykset",
      RANGE_ALARM: "Range High Risk Level",
      RANGE_WARNING: "Range Warning Level",
      RANGE_HEALTHY: "Range Healthy",
      TIME_INTERVAL: "Aikaväli",
      TIMEZONE: "Aikavyöhyke",
      TITLE: "Otsikko",
      CHART_TYPE: "Kaaviotyyppi",
      LEFT_AXIS_METRICS: "Vasemman akselin mittarit",
      RIGHT_AXIS_METRICS: "Oikean akselin mittarit",
      DISPLAY_LEFT_AXIS: "Näytä vasen akseli",
      DISPLAY_RIGHT_AXIS: "Näytä oikea akseli",
      INCIDENT_DURATION: "Tapahtuman kesto",
      ALL_THINGS: "Kaikki laitteet",
      TEMPERATURE_UNIT: "Lämpötilayksikkö",
      TIME_FORMAT: "Ajan muoto",
      TWENTY_FOUR_HOUR_CLOCK: "24 tunnin kello",
      TWELVE_HOUR_CLOCK: "12 tunnin kello",
      OPERATOR: "Operaattori",
      VALUE: "Arvo",
      FILTER_BY_PROPERTY: "Suodata laite ominaisuuden mukaan",
      ALL_PROPERTIES: "Kaikki ominaisuudet",
      PROPERTY_METRIC: "Mittari",
      GAUGE_TYPE: "Mittarityyppi",
      AGGREGATION_TYPE: "Koontityyppi",
      INITIAL_MAP_VIEW: "Alustava karttanäkymä",
      INITIAL_MAP_VIEW_OPTIONS: "Valitse kartan alustava keskusta ja alustava kartan zoomaustaso",
      IMAGE_URL: "Kuvan URL",
    },

    timeseriesTypes: {
      [TimeseriesType.Temperature]: "Lämpötila",
      [TimeseriesType.Pressure]: "Paine",
      [TimeseriesType.WaterConsumption]: "Vedenkulutus",
      [TimeseriesType.TotalWaterConsumption]: "Kokonaisvesikulutus",
      [TimeseriesType.Humidity]: "Ilmankosteus",
      [TimeseriesType.AbsoluteHumidity]: "Absoluuttinen kosteus",
      [TimeseriesType.PaaConcentrationRollingAverage15Min]: "PAA-konsentraatio liukuva keskiarvo (15 min)",
      [TimeseriesType.PaaConcentrationRollingAverage8hour]: "PAA-konsentraatio liukuva keskiarvo (8 tuntia)",
      [TimeseriesType.PaaConcentration]: "PAA-konsentraatio",
      [TimeseriesType.CaptionDataWME]: "WME",
      [TimeseriesType.CaptionDataAbsoluteHumidity]: "Absoluuttinen kosteus",
      [TimeseriesType.CaptionDataExtHumidity]: "Ulkoilman kosteus",
      [TimeseriesType.CaptionDataExtTemperature]: "Ulkoilman lämpötila",
    } as { [key in TimeseriesType]: string },

    descriptions: {
      [WidgetType.ChartWidget]: {
        title: "Kaavio",
        text: "Näyttää kaavion eri antureiden mittauksilla ja hälytyksillä",
      },
      [WidgetType.ClockWidget]: {
        title: "Kello",
        text: "Näyttää nykyisen ajan valitulle aikavyöhykkeelle",
      },
      [WidgetType.CustomerWidget]: {
        title: "Asiakasnäkymä",
        text: "Näyttää yhteystiedot, laitelistauksen ja laitteen hälytykset tietylle asiakkaalle",
      },
      [WidgetType.GaugeWidget]: {
        title: "Mittari",
        text: "Näyttää eri anturien mittaukset nykytilan",
        validationErrorMessage: "Arvon on oltava suurempi kuin edellinen arvo",
      },
      [WidgetType.MapWidget]: {
        title: "Kartta",
        text: "Näyttää kartan eri antureiden mittauksilla ja hälytyksillä",
      },
      [WidgetType.StackedBarChartWidget]: {
        title: "Pinottu pylväskaavio",
        text: "Näyttää pylväskaavion, jossa arvot on pinottu päällekkäin",
      },
      [WidgetType.TextWidget]: {
        title: "Tekstipienoisohjelma",
        text: "Dynaaminen tekstikenttä",
      },
      [WidgetType.ValueWidget]: {
        title: "Arvo",
        text: "Näyttää valitun anturin mittauksen nykyarvon",
      },
      [WidgetType.WeatherWidget]: {
        title: "Sää",
        text: "Näyttää nykyisen sään tiettynä maantieteellisenä sijaintina",
      },
      [WidgetType.BarChartWidget]: {
        title: "Pylväskaavio",
        text: "Näyttää pylväskaavion eri mittareista",
      },
      [WidgetType.TableWidget]: {
        title: "Taulukko",
        text: "Näyttää taulukon eri antureiden mittauksilla ja hälytyksillä",
      },
      [WidgetType.BookmarksWidget]: {
        title: "Kirjanmerkit",
        text: "Näyttää kirjanmerkkejä laitteisiin ja tapahtumiin",
      },
      [WidgetType.HealthWidget]: {
        title: "Kiinteistön kunto",
        text: "Näyttää kiinteistön kuntoon liittyviä ominaisuuksia",
      },
      [WidgetType.ImageWidget]: {
        title: "Kuva",
        text: "Näyttää kuvan",
      },
    } as { [key in WidgetType]: { title: string; text: string; validationErrorMessage?: string } },
  },

  propertyHealthWidgetInfo: {
    fuelPoverty:
      "Polttoaineköyhyyden seuranta on välttämätöntä kohtuuhintaisuuteen ja mukavuuteen liittyvien huolenaiheiden ratkaisemiseksi. Tunnistamalla energiatehokkuudet kiinteistönomistajat voivat auttaa vuokralaisia ​alentamaan lämmitys- ja energiakustannuksia ja varmistamaan samalla viihtyisän asuinympäristön.",

    insulation:
      "Eristysmittaus on elintärkeää vuokralaisen mukavuuden, energiatehokkuuden ja ongelmien ehkäisemisen kannalta. Riittämätön eristys aiheuttaa epämukavuutta, korkeampaa energiankulutusta ja mahdollisia omaisuusvahinkoja. Eristystasoja mittaamalla omistajat voivat tunnistaa kehittämiskohteita, parantaa eristystä ja luoda mukavan, kestävän elinympäristön.",

    ventilation:
      "Kiinteistöjen ilmanvaihdon valvonta on elintärkeää terveyden, mukavuuden ja kestävyyden kannalta. Ilmanvaihtoongelmien nopea ratkaiseminen varmistaa paremman sisäilman laadun, ehkäisee kosteusongelmia ja parantaa energiatehokkuutta.",

    condensation: `Kondensaatioriskien seuranta ja hallinta on ratkaisevan tärkeää kiinteistön ylläpidon kannalta. Tunnistamalla ja hallitsemalla kondenssivesiongelmat ripeästi kiinteistönomistajat voivat ehkäistä kosteusongelmia, säilyttää kiinteistön rakenteellisen eheyden ja ylläpitää terveellistä elinympäristöä.`,
  },

  propertyMetricMould: {
    text: `Homeriskien seuranta ja hallinta on kriittistä kiinteistön hyvinvoinnille. Tunnistamalla homeongelmat ja puuttumalla niihin ripeästi kiinteistönomistajat voivat ehkäistä terveyshaittoja, ylläpitää kiinteistön rakenteellista eheyttä ja varmistaa vuokralaisille turvallisen ja terveellisen elinympäristön.`,
  },

  tableWidget: {
    UNKNOWN_ADDRESS: "Tuntematon osoite",
    MORE: "Lisää",
    ALARM_TABLE_TITLE: "Viimeisimmät hälytykset",
    STACKS_CREATED_TABLE_TITLE: "Projektin kesto",

    projectDurationTable: {
      tableHeaders: {
        ADDRESS: "Osoite",
        CREATED: "Päivät",
      },
    },
  },

  timeIntervals: {
    [TimeInterval.TwentyFourHours]: "24 tuntia",
    [TimeInterval.ThreeDays]: "3 päivää",
    [TimeInterval.SevenDays]: "7 päivää",
    [TimeInterval.FourteenDays]: "14 päivää",
    [TimeInterval.ThirtyDays]: "30 päivää",
    [TimeInterval.SixtyDays]: "60 päivää",
    [TimeInterval.NinetyDays]: "90 päivää",
    [TimeInterval.OneHundredEightyDays]: "180 päivää",
    [TimeInterval.ThreeHundredSixtyFiveDays]: "365 päivää",
    custom: "Mukautettu",
  },

  dashboards: {
    ALL_DASHBOARDS: "Kaikki koontinäytöt",
    CREATE_DASHBOARD: "Luo koontinäyttö",
    ADD_WIDGET: "Lisää pienoisohjelma",
    ADD_A_WIDGET: "Lisää pienoisohjelma",
    ADD_WIDGET_PLACEHOLDER: "--- Valitse pienoisohjelma ---",
  },

  [WidgetType.CustomerWidget]: {
    TITLE: "Yhteystiedot",
    NAME: "Nimi",
    MOBILE: "Puhelin",
    ADDRESS: "Osoite",
  },

  defaultTitle: {
    [WidgetType.WeatherWidget]: "Uusi sääennuste-widget",
    [WidgetType.GaugeWidget]: "Uusi mittari",
    [WidgetType.ClockWidget]: "Uusi kello",
    [WidgetType.TextWidget]: "Uusi teksti-widget",
    [WidgetType.ValueWidget]: "Uusi arvo-widget",
    [WidgetType.StackedBarChartWidget]: "Uusi pinottu pylväskaavio",
    [WidgetType.CustomerWidget]: "Yhteystiedot", // tätä ei käytetä tällä hetkellä
    [WidgetType.MapWidget]: "Uusi kartta-widget",
    [WidgetType.ChartWidget]: "Uusi kaavio",
    [WidgetType.BarChartWidget]: "Uusi pylväskaavio",
    [WidgetType.TableWidget]: "Uusi taulukko",
    [WidgetType.BookmarksWidget]: "Uusi kirjanmerkit",
    [WidgetType.HealthWidget]: "Uusi kiinteistön kunto",
    [WidgetType.OfflineWidget]: "Uusi offline-widget",
    [WidgetType.ImageWidget]: "Uusi kuva-widget",
  } as { [key in WidgetType]: string },

  customerPicker: {
    PLACEHOLDER: "Kirjoita nimi, osoite tai puhelinnumero...",
    NO_RESULTS: "Ei tuloksia haulle",
  },

  bookmarks: {
    LAST_ERRAND: "Viimeisin asioiminen",
    NO_ACTIVE_ERRAND: "Ei aktiivisia asioita",
    NO_BOOKMARKS: "Sinulla ei ole kirjanmerkkejä",
    NOT_FOUND: "Ei löytynyt",
  },

  modals: {
    confirmation: {
      YES: "Kyllä",
      NO: "Ei",
    },
    DELETE_WIDGET_MESSAGE: "Haluatko varmasti poistaa tämän pienoisohjelman?",
    DELETE_DASHBOARD_MESSAGE: "Haluatko varmasti poistaa tämän koontinäytön?",
  },

  shareSettings: {
    TITLE: "Jaa asetukset",
    DESCRIPTION: "Kaikki voivat käyttää tätä koontinäyttöä linkin avulla",
    COPY_LINK: "Kopioi linkki",
    COPIED: "linkki kopioitu",
  },

  propertyFilters: {
    [HealthFilterValue.All || FilterValue.All]: "All",
    [HealthFilterValue.HighRisk]: "High risk",
    [HealthFilterValue.MediumRisk]: "Medium risk",
    [HealthFilterValue.Normal]: "Normal",
    [HealthFilterValue.NoClassification]: "No classification",
    [FilterValue.Alarm]: "Alarm",
    [FilterValue.Warning]: "Warning",
    [FilterValue.Healthy]: "Healthy",
  },

  propertyMetric: {
    [PropertyMetric.FuelPoverty]: "Polttoaineköyhyys (oletettu)",
    [PropertyMetric.Insulation]: "Eristys",
    [PropertyMetric.Ventilation]: "Ilmanvaihto",
    [PropertyMetric.InternalMoisture]: "Sisäiset haitalliset kosteusolosuhteet",
    [PropertyMetric.Condensation]: "Kondensaatio/Hometta",
  },

  noClassificationReason: {
    [NoClassificationReason.THRESHOLD]: "Kynnysarvo",
    [NoClassificationReason.NOT_ENOUGH_DATA]: "Ei tarpeeksi tietoa",
    [NoClassificationReason.MISSING_OUTDOOR_SENSOR]: "Puuttuva ulkolämpötila-anturi",
  },

  aggregationType: {
    [AggregationType.Aggregated]: "Koottu",
    [AggregationType.NonAggregated]: "Ei koottu",
  },
};
export default fi;
