import styled from "@emotion/styled/macro";
import { VisualisationIframeEventType, VisualisationIframeNavigateToEvent } from "constants/iframeConstants";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useStore } from "stores/RootStore";

interface Props {
  rebornHref?: string;
  href?: string;
  to?: string;
  title?: string;
  children: React.ReactNode;
  /** Should be type Interpolation<Theme>, but Interpolation is not importable*/
  css?: any;
}

/**
 * Depending on the configStore.useFairtrailLinks value, this component will either render as a href
 *, a react-router-dom Link or just the children.
 * - If configStore.useFairtrailLinks is true, the href prop is used.
 * - If configStore.useFairtrailLinks is false, the to prop is used if set.
 * - If configStore.useFairtrailLinks is false and the to prop is not set, the children are rendered. E.g. if a text should link to fairtrail, but only to fairtrail.
 */
const FairtrailLink = ({ href, rebornHref, to, children, title, ...props }: Props) => {
  const { configStore } = useStore();

  const navigateThroughIframeCommand = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    /*
     * Because the navigation in reborn doesn't just pick up any old url changes we need to send a command to the parent of the iframe
     * Otherwise it would refresh the page completely, so this makes for a smoother experience
     */
    const command: VisualisationIframeNavigateToEvent = {
      action: VisualisationIframeEventType.NavigateTo,
      payload: { url: rebornHref! },
    };

    window.parent.postMessage(command, "*");
  };

  if (configStore.useFairtrailLinks) {
    if (configStore.useFairtrailRebornLinks) {
      if (rebornHref) {
        return (
          <StyledAnchor href={rebornHref} title={title} {...props} onClick={navigateThroughIframeCommand}>
            {children}
          </StyledAnchor>
        );
      }
    } else {
      if (href) {
        return (
          <StyledAnchor href={href} title={title} {...props}>
            {children}
          </StyledAnchor>
        );
      }
    }
  }

  if (to) {
    return (
      <Link to={to} title={title} {...props}>
        {children}
      </Link>
    );
  } else {
    return <>{children}</>;
  }
};

export default observer(FairtrailLink);

const StyledAnchor = styled.a(
  ({ theme }) => `
    ${theme.color.text.secondary};
    box-shadow: none;
`
);
