import { makeAutoObservable, runInAction } from "mobx";
import { FairtrailConnectionStatus } from "../constants/webSocketConstants";

/** Store for the current status of the websocket connection(s) */
class WebSocketStore {
  webSocketStatus: FairtrailConnectionStatus = FairtrailConnectionStatus.NotConnectedToFairtrail;

  webSocketError: any | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  webSocketOnConnect() {
    runInAction(() => {
      this.webSocketError = undefined;
      this.webSocketStatus = FairtrailConnectionStatus.ConnectedToFairtrail;
    });
  }

  webSocketOnError(error: any) {
    runInAction(() => (this.webSocketError = error));
  }

  webSocketOnDisconnect() {
    console.log("DISCONNECTED from websocket");
    runInAction(() => (this.webSocketStatus = FairtrailConnectionStatus.NotConnectedToFairtrail));
  }
}

export default WebSocketStore;
