import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FontSize, FontSizeMultiplier } from "../../../constants/themeConstants";
import { fallbackResponsiveUnit, responsiveUnit } from "../../../utils/styleUtils";
import { GaugeWidgetProps } from "./ClassicGauge";
import { GaugeWidgetOptions } from "./GaugeWidget";

export const SolidGaugeWidget = ({ chartComponentRef, widget, data, yAxisConfig }: GaugeWidgetProps) => {
  const solidGaugeOptions: GaugeWidgetOptions = {
    title: null,
    chart: {
      type: "solidgauge",
      animation: { duration: 0 },
    },
    // @ts-ignore
    yAxis: {
      min: yAxisConfig.threshold.range.min ?? 0,
      max: yAxisConfig.threshold.range.max ?? 100,
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      labels: { y: 16 },
    },
    pane: {
      center: ["50%", "85%"],
      size: "150%",
      startAngle: -90,
      endAngle: 90,
      background: [
        {
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc",
        },
      ],
    },
    series: [
      {
        type: "solidgauge",
        data: [0],
      },
    ],
    credits: { enabled: false },
    responsive: {
      rules: [
        {
          condition: { maxWidth: 400 },
          chartOptions: {
            pane: {
              center: ["50%", "75%"],
              size: "100%",
            },
          },
        },
      ],
    },
    tooltip: {
      enabled: false,
    },
  };

  const chartOptions = {
    ...solidGaugeOptions,
    series: [
      {
        type: "solidgauge",
        name: data.label,
        data: [data.value],
        tooltip: { valueSuffix: data.units != null ? ` ${data.units}` : "" },
        dataLabels: {
          format: `{y} ${data.units ?? ""}`,
          backgroundColor: "none",
          borderWidth: 0,
          borderColor: "none",
          fontSize: `${fallbackResponsiveUnit(FontSize.Small)}`,
          y: 20,
          style: {
            fontSize: responsiveUnit(FontSize.Small, FontSizeMultiplier.Large, FontSize.Large),
            fontWeight: "900",
            textOutline: "none",
          },
        },
      },
    ],
    yAxis: {
      ...solidGaugeOptions.yAxis,
      ...yAxisConfig,
    },
    exporting: {
      enabled: false,
      title: widget.settings.title,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      ref={chartComponentRef}
      containerProps={{ style: { height: "100%" } }}
    />
  );
};
