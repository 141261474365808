import { getValueFromKey, pxOrRaw } from "theme/utils";

import type { TypographyConfig, TypographyKey, TypographySpecs } from "theme/types";

const getTypographyStyles = (variant: TypographyKey = "default", typography: TypographyConfig) => ({
  ...typography.default,
  ...(variant !== "default" && getValueFromKey<TypographySpecs>(variant, typography)),
});

export const typography = (variant: TypographyKey, typography: TypographyConfig) => {
  const items = { ...getTypographyStyles(variant, typography) };

  Object.entries(items).forEach(([key, value]) => {
    if (key === "fontSize" || key === "lineHeight") {
      items[key] = pxOrRaw(value as string | number);
    }
  });

  return items;
};
