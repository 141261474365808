export enum WebSocketEvent {
  Connect = "connect",
  Disconnect = "disconnect",
  Error = "error",
  Update = "update",
}

export enum FairtrailConnectionStatus {
  ConnectedToFairtrail = "connectedToFairtrail",
  NotConnectedToFairtrail = "notConnectedToFairtrail",
}
