export enum Theme {
  Default = "DEFAULT",
}

export enum Margin {
  Small = "8px",
  Medium = "16px",
  Large = "24px",
  ExtraLarge = "32px",
  ExtraExtraLarge = "64px",
}

export enum Padding {
  Small = "8px",
  Medium = "16px",
  Large = "24px",
  ExtraLarge = "32px",
  ExtraExtraLarge = "64px",
}

export enum FontSize {
  ExtremelySmall = "7px",
  Smallest = "8px",
  ExtraExtraSmall = "10px",
  ExtraSmall = "12px",
  Small = "14px",
  Base = "16px",
  Medium = "18px",
  Large = "24px",
  ExtraLarge = "32px",
  ExtraExtraLarge = "46px",
  XXXL = "64px",
}

export enum Spacing {
  s1 = "4px",
  s2 = "8px",
  s3 = "12px",
  s4 = "16px",
  s5 = "20px",
  s6 = "24px",
  s7 = "28px",
  s8 = "32px",
}

export enum FontFamily {
  Sans = "Lato, Arial, Helvetica, sans-serif",
}

export enum FontSizeMultiplier {
  ExtremelySmall = 0.3,
  Small = 0.5,
  Large = 0.7,
}
