import WebSocketClient from "socket.io-client";
import { FAIRTRAIL_WEB_SOCKET_HOST } from "../config/config";
import { WebSocketEvent } from "../constants/webSocketConstants";
import { rootStore } from "../stores/RootStore";

/**
 * Note: we have to use deps "socket.io-client": "^2.3.1" & "@types/socket.io-client": "^1.4.34"
 * because the server is socket.io v2 and newer clients only work with v3
 */

let sockets: SocketIOClient.Socket[] = [];

function connect(namespace?: string): Promise<SocketIOClient.Socket> {
  const { authStore, webSocketStore } = rootStore;
  const token = authStore.token;

  const options = {
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: Infinity,
    secure: true,
    transports: ["websocket"],
    query: { token },
  };

  const client = WebSocketClient.connect(`${FAIRTRAIL_WEB_SOCKET_HOST}${namespace}`, options);

  return new Promise((resolve) => {
    client.on(WebSocketEvent.Connect, () => {
      console.log("connected to", namespace);

      sockets.push(client);

      resolve(client);

      webSocketStore?.webSocketOnConnect();
    });
  });
}

export function disconnectFromWebSocket() {
  sockets.forEach((socket) => socket?.close());
  sockets = [];
}

export async function initializeWebSocketsChannels() {
  try {
    const { thingStore, timelineStore } = rootStore;

    const nsThings = "things";
    const nsTimelines = "timelines";

    /** Things */
    const socketThings = await connect(nsThings);

    handleUpdates(socketThings, thingStore?.thingUpdated.bind(thingStore));

    /** nsTimelines */
    const socketTimelines = await connect(nsTimelines);

    handleUpdates(socketTimelines, timelineStore?.timelineUpdated.bind(timelineStore));
  } catch (err) {
    console.log(err);
  }
}

function handleUpdates(
  socket: SocketIOClient.Socket,
  actionUpdate: Function,
  /**
   * Optional if we need to do something special with these
   *  defaults to websocket store actions
   **/
  actionDisconnect?: Function,
  actionError?: Function,
  actionConnect?: Function
) {
  const { webSocketStore } = rootStore;

  const webSocketOnError = webSocketStore?.webSocketOnError.bind(webSocketStore);
  const webSocketOnConnect = webSocketStore?.webSocketOnConnect.bind(webSocketStore);
  const webSocketOnDisconnect = webSocketStore?.webSocketOnDisconnect.bind(webSocketStore);

  actionDisconnect = actionDisconnect || webSocketOnDisconnect;
  actionError = actionError || webSocketOnError;
  actionConnect = webSocketOnConnect || webSocketOnConnect;

  socket.on(WebSocketEvent.Connect, () => actionConnect!());

  socket.on(WebSocketEvent.Disconnect, (disconnect: any) => actionDisconnect!(disconnect));

  socket.on(WebSocketEvent.Error, (error: any) => actionError!(error));

  socket.on(WebSocketEvent.Update, (update: any) => actionUpdate(update));
}
