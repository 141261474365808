import { useEffect, useMemo } from "react";
import { useStore } from "stores/RootStore";
import { User } from "models/User";
import { Stack } from "models/Stack";

export const useThingsForUser = ({ userId }: Pick<User, "userId">) => {
  const { thingStore } = useStore();

  useEffect(() => {
    thingStore.loadAllThingsForUser(userId);
  }, [thingStore, userId]);

  return useMemo(() => {
    return thingStore.loadedThings.filter((t) => t.owner.userId === userId);
  }, [thingStore.loadedThings, userId]);
};

export const useThingsForStack = ({ stackId, owner }: Pick<Stack, "stackId" | "owner">) => {
  const { thingStore } = useStore();

  useEffect(() => {
    // We load by user because it doesn't seem to be possible to load by stack id at the moment
    thingStore.loadAllThingsForUser(owner.userId);
  }, [thingStore, stackId, owner.userId]);

  return useMemo(() => {
    return thingStore.loadedThings.filter((t) => t.stack.stackId === stackId);
  }, [thingStore.loadedThings, stackId]);
};
