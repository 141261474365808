import { LoadingStatus } from "constants/constants";
import { FAIRTRAIL_USER_ROUTE } from "constants/fairtrailRoutes";
import {
  GeoStatus,
  GeoTaggedData,
  GeoTaggedDataPoint,
  GeoTaggedDataSource,
} from "datasources/geoTaggedData/interfaces";
import { Loadable } from "models/Loading";
import { addressForStack, Stack } from "models/Stack";
import { Thing, ThingStatus } from "models/Thing";
import { CustomerStackLocationSource } from "models/widgets/MapWidget";
import { RootStore } from "stores/RootStore";
import { default as language, default as Language } from "translations/language";

export class CustomerStackLocationAdapter implements GeoTaggedDataSource<CustomerStackLocationSource> {
  private getDataCache = new Map<string, Loadable<GeoTaggedData>>();
  constructor(private stores: RootStore) {}

  getData(config: CustomerStackLocationSource): Loadable<GeoTaggedData> {
    if (this.getDataCache.has(JSON.stringify(config))) {
      return this.getDataCache.get(JSON.stringify(config))!;
    }

    const stacks = config.allProperties
      ? this.stores.stackStore.allStacks
      : this.stores.stackStore.loadedStacks.filter((s) => s.owner.userId === config.customerId);

    const loadable: Loadable<GeoTaggedData> = {
      loadingStatus: !!stacks.filter(hasLocation).length ? LoadingStatus.Loaded : LoadingStatus.Loading,
      points: stacks.filter(hasLocation).map((stack) => this.toDataPoint(stack, config)),
    };

    if (loadable.loadingStatus !== LoadingStatus.Loading) {
      this.getDataCache.set(JSON.stringify(config), loadable);
    }

    return loadable;
  }

  private toDataPoint(stack: StackWithLocation, config: CustomerStackLocationSource): GeoTaggedDataPoint {
    const stackThings = config.allProperties
      ? stack.things
      : this.stores.thingStore.loadedThings.filter((t) => t.stack.stackId === stack.stackId);

    return {
      lat: stack.latitude,
      lng: stack.longitude,
      title: stack.name,
      uniqueId: stack.stackId,
      stack,
      metaData: {
        owner: {
          title: Language.OWNER,
          value: stack.owner.name,
          href: FAIRTRAIL_USER_ROUTE.replace(":userId", stack.owner.userId),
        },
        address: { title: language.ADDRESS, value: addressForStack(stack) },
      },
      status: this.getStatusForStack(stackThings),
      things: stackThings,
    };
  }

  private getStatusForStack(things: Thing[]): GeoStatus {
    if (things.some((t) => t.status === ThingStatus.HasAlarm)) {
      return GeoStatus.Error;
    } else if (things.some((t) => t.status === ThingStatus.Offline || t.status === ThingStatus.ConfigFailed)) {
      return GeoStatus.Warning;
    }

    return GeoStatus.Ok;
  }

  async loadData(config: CustomerStackLocationSource) {
    if (config.allProperties) {
      await this.stores.stackStore.loadAllStacks();
    } else {
      await Promise.all([
        this.stores.thingStore.loadAllThingsForUser(config.customerId),
        this.stores.stackStore.loadAllForUser(config.customerId),
      ]);
    }
  }
}

type StackWithLocation = Stack & { latitude: number; longitude: number };

function hasLocation(stack: Stack): stack is StackWithLocation {
  return (
    stack.latitude !== null && stack.latitude !== undefined && stack.longitude !== null && stack.longitude !== undefined
  );
}
