import { useState } from "react";
import styled from "@emotion/styled/macro";

type CheckboxProps = {
  id: string;
  defaultValue: boolean;
  label: string;
  onChange: (value: boolean) => void;
};

export const Checkbox = ({ id, defaultValue = false, label, onChange }: CheckboxProps) => {
  const [value, setValue] = useState<boolean>(defaultValue);

  const onChangeValue = (e: { target: { checked: any } }) => {
    const newValue = !!e.target.checked;
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <S.Label htmlFor={id}>
      {label}
      <S.Input id={id} type="checkbox" checked={value} onChange={(e) => onChangeValue(e)} />
      <S.Checkbox />
    </S.Label>
  );
};

// prettier-ignore
const S = {
   Label: styled.label(() =>`
    position: relative;
    display: inline-block;
    cursor: pointer;
    line-height: 24px;
    margin-left: 32px;
  `),

  Input: styled.input(({theme}) => `
    height: 0;
    width: 0;
    opacity: 0;
    z-index: -1;

    &:checked + div:after {
      display: block;
      top: 4px;
      left: 8px;
      width: 30%;
      height: 60%;
      border: solid ${theme.color.primary400};
      border-width: 0 0.2em 0.2em 0;
      transform: rotate(45deg);
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }
  `),

  Checkbox: styled.div(({theme}) => `
    width: 24px;
    height: 24px;
    background: ${theme.color.neutral100};
    position: absolute;
    top: 0;
    left: -32px;
    border: 2px solid ${theme.color.neutral400};
    border-radius: ${theme.borderRadius.md};

    &:after {
      content: ' ';
      position: absolute;
    }
  `),
};
