import { SettingsType } from "constants/constants";
import { MapInitialView } from "constants/widgetConstants";
import { titleSetting } from "data/commonSettings";
import { SettingsDescriptor } from "data/widgetData";
import {
  CustomerStackLocationSource,
  GeoDataSourceTypes,
  MapWidgetSettings,
  ThingLocationsSource,
} from "models/widgets/MapWidget";
import { rootStore } from "stores/RootStore";
import language from "translations/language";

export const mapWidgetSettings: SettingsDescriptor = {
  title: titleSetting,
  dataSource: {
    label: language.widgets.settings.DATA_SOURCE,
    type: SettingsType.Switch,
    switchField: {
      type: SettingsType.Select,
      label: language.widgets.settings.DATA_SOURCE_TYPE,
      default: GeoDataSourceTypes.CustomerStackLocations,
      multiple: false,
      options: Object.entries(GeoDataSourceTypes).map(([_key, value]) => {
        return { key: value, value: language.sourceTypes[value] };
      }),
    },
    switchKey: "sourceType",
    options: {
      [GeoDataSourceTypes.CustomerStackLocations]: {
        allProperties: {
          type: SettingsType.Toggle,
          label: language.widgets.settings.ALL_PROPERTIES,
          default: false,
        },
        customerId: {
          type: SettingsType.Customer,
          label: language.CUSTOMER,
          default: null,
          hidden: (settings: MapWidgetSettings) =>
            !!(settings.dataSource as CustomerStackLocationSource)?.allProperties,
        },
        mapInitialView: {
          type: SettingsType.RadioButton,
          label: language.widgets.settings.INITIAL_MAP_VIEW,
          default: MapInitialView.Default,
          options: Object.values(MapInitialView).map((v) => ({
            key: v,
            value: language.mapInitialView[v],
          })),
        },
        mapInitialViewOptions: {
          type: SettingsType.MapOptions,
          label: language.widgets.settings.INITIAL_MAP_VIEW_OPTIONS,
          default: null,
          hidden: (settings: MapWidgetSettings) =>
            (settings.dataSource as CustomerStackLocationSource)?.mapInitialView !== MapInitialView.Custom,
        },
      },
      [GeoDataSourceTypes.ThingLocations]: {
        allThings: {
          type: SettingsType.Toggle,
          label: language.widgets.settings.ALL_THINGS,
          default: false,
          hidden: () => !rootStore.configStore.deptId,
        },
        thingIds: {
          type: SettingsType.Thing,
          label: language.widgets.settings.THINGID,
          multiple: true,
          hidden: (settings: MapWidgetSettings) =>
            !rootStore.configStore.deptId || !!(settings.dataSource as ThingLocationsSource)?.allThings,
        },
        mapInitialView: {
          type: SettingsType.RadioButton,
          label: language.widgets.settings.INITIAL_MAP_VIEW,
          default: MapInitialView.Default,
          options: Object.values(MapInitialView).map((v) => ({
            key: v,
            value: language.mapInitialView[v],
          })),
          hidden: () => !rootStore.configStore.deptId,
        },
        mapInitialViewOptions: {
          type: SettingsType.MapOptions,
          label: language.widgets.settings.INITIAL_MAP_VIEW_OPTIONS,
          default: null,
          hidden: (settings: MapWidgetSettings) =>
            !rootStore.configStore.deptId && settings.dataSource?.mapInitialView !== MapInitialView.Custom,
        },
      },
    },
  },
};
