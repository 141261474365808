import { THEME_QUERY_PARAM } from "constants/constants";
import { DashboardTheme } from "constants/dashboardConstants";
import { makeAutoObservable, runInAction } from "mobx";
import { Dashboard } from "models/Dashboard";
import { themes } from "theme/config";
import { rootStore } from "./RootStore";

export class ThemeStore {
  private _theme: DashboardTheme = this.getInitialTheme();

  constructor() {
    makeAutoObservable(this);
  }

  get theme() {
    return this._theme;
  }

  get themeObject() {
    const currentTheme = themes[this._theme as DashboardTheme] ?? themes.default;

    // Enrich the emotion theme with the theme name string
    return { theme: { ...currentTheme, name: this._theme } };
  }

  set theme(theme: DashboardTheme) {
    this._theme = theme;
  }

  resetTheme() {
    runInAction(() => (this._theme = this.getInitialTheme(false)));
  }

  private getInitialTheme(initialPageLoad = true) {
    const themeFromQuery = initialPageLoad
      ? new URLSearchParams(window.location.search).get(THEME_QUERY_PARAM)
      : rootStore.configStore.theme;

    if (themeFromQuery === "" || themeFromQuery === null) {
      return DashboardTheme.Default;
    } else {
      return themeFromQuery as DashboardTheme;
    }
  }

  setThemeFromDashboard(dashboard: Dashboard) {
    runInAction(() => (this.theme = dashboard.settings.theme));
  }
}
