import { Owner } from "./Owner";
import { Thing } from "./Thing";

export type Stack = {
  stackId: string;
  addressLine: string;
  apartmentNumber: string | null;
  city: string;
  connectedWeatherStation: {
    name: string;
    thingId: string;
  };
  country: string;
  created: Date;
  householdAddition: string[];
  householdNum: number;
  latitude: number | null;
  longitude: number | null;
  modified: Date;
  name: string;
  owner: Owner;
  propertyType: string;
  riscProfile: {};
  season: {
    active: string;
    autumn: string;
  };
  type: string;
  url: string;
  zip: string;
  things: Thing[];
};

export function toStack(input: any): Stack {
  return {
    ...input,
    modified: new Date(input.modified),
    created: new Date(input.created),
  };
}

export function addressForStack(stack: Stack) {
  // TODO: proper international address formatting
  return stack.addressLine ? `${stack.addressLine}, ${stack.city}, ${stack.zip}` : stack.name;
}
