import { TimeInterval, TimeRangeType, WidgetType } from "constants/widgetConstants";
import { DateRange } from "./common/date";
import { WidgetViewModel } from "./Widget";

export type ChartWidgetViewModel = WidgetViewModel<WidgetType.ChartWidget, ChartWidgetSettings>;

export type ChartWidgetSettings = {
  leftAxisMetrics: ChartDataSource | ChartDataSource[] | null;
  rightAxisMetrics: ChartDataSource | ChartDataSource[] | null;
  timeInterval: DateRangeOrInterval;
};

export type ChartDataSource = ThingEventTimeDataSource | ThingsTimeSeriesDataSource;

export enum ChartSourceTypes {
  ThingEventTime = "thingEventTime",
  ThingsTimeSeries = "thingsTimeSeries",
}

export enum AggregationType {
  Aggregated = "aggregated",
  NonAggregated = "nonAggregated",
}

export type ThingEventTimeDataSource = {
  sourceType: ChartSourceTypes.ThingEventTime;
  thingId: string;
  eventType: string;
  eventSubType: string;
};

export type ThingsTimeSeriesDataSource = {
  sourceType: ChartSourceTypes.ThingsTimeSeries;
  thingIds: string[];
  metrics: (TimeseriesType | string)[];
  aggregationType: AggregationType;
};

export enum TimeseriesType {
  Temperature = "temperature",
  Pressure = "pressure",
  WaterConsumption = "waterConsumption",
  TotalWaterConsumption = "totalWaterConsumption",
  Humidity = "humidity",
  AbsoluteHumidity = "mcGm3",
  PaaConcentrationRollingAverage15Min = "paaConcentrationRollingAverage_15Min",
  PaaConcentrationRollingAverage8hour = "paaConcentrationRollingAverage_8Hour",
  PaaConcentration = "paaConcentration",
  CaptionDataAbsoluteHumidity = "absolutehumidity",
  CaptionDataWME = "wmevalue",
  CaptionDataExtHumidity = "exthumidityJ10",
  CaptionDataExtTemperature = "exttemperatureJ10",
}

export type DateRangeOrInterval = {
  type: TimeRangeType;
  interval: TimeInterval | null;
  dateRange: DateRange;
};

export function isChartWidget(widget: WidgetViewModel): widget is ChartWidgetViewModel {
  return widget.type === WidgetType.ChartWidget;
}
