import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { ImageWidgetSVG } from "../thumbnails";
import { WidgetData } from "../widgetData";
import { imageWidgetSettings } from "./imageWidgetSettings";
import ImageWidget from "components/widgets/ImageWidget";
import { ImageWidgetSettings } from "models/widgets/ImageWidget";

export const ImageWidgetData: WidgetData<WidgetType.ImageWidget, ImageWidgetSettings & WidgetSettings> = {
  type: WidgetType.ImageWidget,

  name: language.widgets.IMAGE,

  hideTitle: true,

  hideBackground: true,

  description: {
    ...language.widgets.descriptions[WidgetType.ImageWidget],
    thumbnail: ImageWidgetSVG,
  },

  dashboardPurposes: [DashboardPurpose.Generic, DashboardPurpose.Customer, DashboardPurpose.Stack],

  settings: imageWidgetSettings,

  component: (props) => <ImageWidget {...props} />,
};
