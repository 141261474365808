import styled from "@emotion/styled/macro";
import { observer } from "mobx-react-lite";
import { formatDate } from "utils/datetimeUtils";

interface LastUpdatedProps {
  datetime: Date;
  timeZone?: string;
}

const LastUpdated = ({ datetime, timeZone }: LastUpdatedProps) => {
  return <Text>{formatDate(datetime, "YYYY-MM-DD HH:mm", timeZone)}</Text>;
};

export default observer(LastUpdated);

// prettier-ignore
const Text = styled.p(({ theme }) => `
  margin: 0;
  text-align: center;
  ${theme.typography.body4};
`);
