import { useEffect, useMemo, useRef } from "react";
import { useWindowSize } from "react-use";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled/macro";
import Highcharts, { SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsExportData from "highcharts/modules/export-data";
import language from "translations/language";
import ConfigureMessage from "../WidgetConfigureMessage";
import { floatToTimeString, fromUnixTime, toUnixTime } from "utils/datetimeUtils";
import { LoadingState } from "components/widgets/loading/LoadingState";
import { ErrorState } from "components/widgets/loading/ErrorState";
import { useTimeChartValuesFromManyDataSources } from "datasources/hooks";
import { TimeChartValues } from "datasources/timeChartValuesData/interfaces";
import { allArrayItemsHaveData, getErrorsFromArray, hasData, Loadable } from "models/Loading";
import WidgetMessage from "components/WidgetMessage";
import { ChartWidgetViewModel } from "models/widgets/ChartWidget";
import { useStore } from "stores/RootStore";

highchartsExportData(Highcharts);

(function (H) {
  H.wrap(H.Chart.prototype, "getDataRows", function (proceed, multiLevelHeaders) {
    // @ts-ignore
    var rows = proceed.call(this, multiLevelHeaders);

    rows = rows.map((row: any) => {
      if (row.x) {
        row[0] = Highcharts.dateFormat("%Y-%m-%d %H:%M", row.x * 1000);
        if (row[1]) {
          row[1] = row.name;
        }
        if (row[2]) {
          row[2] = row.name;
        }
      }
      return row;
    });

    return rows;
  });
})(Highcharts);

type ChartOptions = Omit<Highcharts.Options, "title"> &
  Partial<{
    title: Highcharts.TitleOptions | undefined | null;
    tooltip: { crosshairs?: boolean };
    styledMode: boolean;
  }>;

const color = (color: any) =>
  typeof color === "string" ? `<b class="highcharts-tooltip-bullet" style="background: ${color};">w</b>` : "";

const defaultChartOptions: ChartOptions = {
  styledMode: true,
  title: { text: "" },

  xAxis: {
    categories: [],
    type: "datetime",
    labels: {
      align: "left",
      reserveSpace: true,
    },
    title: { text: language.widgets.TIME },
  },

  yAxis: {},

  tooltip: {
    useHTML: true,
    // TODO: We probably want to standardize the tooltip formats
    borderColor: "transparent",
    formatter: function () {
      const heading = "<b>" + (this.x ? fromUnixTime(this.x, "YYYY-MM-DD - HH:mm") : "") + "</b>";

      if (this.points) {
        return (this.points ?? []).reduce(function (tooltip, point) {
          return tooltip + pointTooltip(point.point, point.series);
        }, heading);
      }

      return heading + pointTooltip(this.point, this.series);
    },
    shared: true,
    crosshairs: true,
  },

  series: [],

  credits: { enabled: false },

  plotOptions: {
    series: {
      animation: false,
    },
  },
};

const timeOfDayAxisProperties = {
  min: 0,
  max: 24,
  tickInterval: 2,
  labels: {
    formatter(): string {
      // @ts-ignore - typescript can't really known what "this" is. sorry
      return this.value + ":00";
    },
  },
};

enum YAxis {
  Left = 0,
  Right = 1,
}

// Requiring points to have both x and y explicitly means we can combine time series
// that occur on two different dates as highcharts deals with merging the two sets of x axis
// values for us
type FullySpecifiedPointData = {
  y: number;
  x: number;
  name: string;
};

type SeriesOptions = SeriesOptionsType & { data: FullySpecifiedPointData[] };

interface Props {
  widget: ChartWidgetViewModel;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  loadingStateComponent?: JSX.Element;
}

const ChartWidget = ({ widget, containerProps, loadingStateComponent }: Props) => {
  const { dashboardStore } = useStore();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const windowSize = useWindowSize();

  const leftAxisMetrics = axisArray(widget.settings.leftAxisMetrics);
  const rightAxisMetrics = axisArray(widget.settings.rightAxisMetrics);
  const timeInterval = widget.settings.timeInterval;

  const title = widget.settings.title;

  const leftChartData = useTimeChartValuesFromManyDataSources(
    leftAxisMetrics.map((metric) => ({
      ...metric,
      dateRangeOrInterval: timeInterval,
    }))
  );

  const rightChartData = useTimeChartValuesFromManyDataSources(
    rightAxisMetrics.map((metric) => ({
      ...metric,
      dateRangeOrInterval: timeInterval,
    }))
  );

  const dataSeries = useMemo(() => {
    return [
      ...leftChartData.flatMap((data) => buildSeries(data, YAxis.Left)),
      ...rightChartData.flatMap((data) => buildSeries(data, YAxis.Right)),
    ];
  }, [leftChartData, rightChartData]);

  const chartOptions: ChartOptions = useMemo(
    () => ({
      ...defaultChartOptions,
      series: dataSeries,
      xAxis: {
        ...defaultChartOptions.xAxis,
        type: "datetime",
        title: {
          text: "",
        },
        showLastLabel: false,
        labels: {
          align: "left",
          step: 0,
          // Rotation is a hack to display the labels some interval.
          // More is rotation is more often the labels are displayed.
          rotation: 8,
          formatter: function () {
            return fromUnixTime(this.value, "YYYY-MM-DD") ?? "";
          },
        },
      },
      yAxis: [
        {
          title: {
            text: "",
          },
          visible: leftChartData.filter(hasData).flatMap((d) => d.series).length > 0,
          showEmpty: true,
          // If any of the metrics are time of day then the entire axis is time of day
          ...(hasTimeAxis(leftChartData) ? timeOfDayAxisProperties : {}),
        },
        {
          title: {
            text: "",
          },
          opposite: true,
          showEmpty: true,
          visible: rightChartData.filter(hasData).flatMap((d) => d.series).length > 0,
          gridLineWidth: 0,
          // If any of the metrics are time of day then the entire axis is time of day
          ...(hasTimeAxis(rightChartData) ? timeOfDayAxisProperties : {}),
        },
      ],
      exporting: {
        enabled: false,
        filename: title,
      },
    }),
    [dataSeries, leftChartData, rightChartData, title]
  );

  useEffect(() => chartComponentRef.current?.chart?.reflow(), [windowSize]);

  useEffect(() => {
    if (chartComponentRef !== null) {
      dashboardStore.setWidgetChartRef(widget.widgetId, chartComponentRef);
    }
  });

  if (!widget.settings.leftAxisMetrics && !widget.settings.rightAxisMetrics) {
    return <ConfigureMessage widgetName={language.widgets.GENERIC_CHART} />;
  }

  const allChartData = [...rightChartData, ...leftChartData];

  const loadingErrors = getErrorsFromArray(allChartData);
  if (loadingErrors.length > 0) {
    console.error("Error loading data for chart widget", loadingErrors);
    return <ErrorState />;
  }

  if (!allArrayItemsHaveData(allChartData)) {
    return loadingStateComponent ?? <LoadingState />;
  }

  const dataPointsCount = dataSeries.map((series) => series.data.length).reduce((a, b) => a + b, 0);

  if (dataPointsCount === 0) {
    return (
      <WidgetMessage>
        {widget.settings.timeInterval.interval
          ? language.errors.NO_DATA_TO_DISPLAY_FOR_PERIOD(widget.settings.timeInterval.interval)
          : language.errors.NO_DATA_TO_DISPLAY}
      </WidgetMessage>
    );
  }

  return (
    <S.Container>
      <HighchartsReact
        ref={chartComponentRef}
        containerProps={{ style: { height: "100%", width: "100%" }, ...containerProps }}
        highcharts={Highcharts}
        options={chartOptions}
      />
    </S.Container>
  );
};

export default observer(ChartWidget);

const S = {
  /** styled components syntax highlighting doesn't work if there is a new row between the arrow function and the return string */
  // prettier-ignore
  Container: styled.div(({ theme }) => `
    ${theme.spacing.p([0, 6, 8, 4])}
    margin-top: -${theme.spacing(4)};
    display: flex;
    height: 90%;
    width: 100%;
    justify-content: center;
    align-items: center;

    ${theme.typography.body3}
  `),
};

function buildSeries(data: Loadable<TimeChartValues>, axis: YAxis): SeriesOptions[] {
  if (!hasData(data)) {
    return [];
  }

  if (!data) {
    return [];
  }

  return data.series.map((series) => {
    if (!series) {
      return {
        name: "",
        yAxis: axis,
        data: [],
        type: "line",
      };
    }

    return {
      name: series.label,
      yAxis: axis,
      data: series.values
        .filter(([date, value]) => value !== null)
        .map(([date, value]) => ({
          x: toUnixTime(date),
          y: value ?? 0,
          name: series.valueType === "timeOfDay" ? floatToTimeString(value ?? 0) : (value ?? 0).toFixed(3),
        })),
      type: series.plotType === "discrete" ? "scatter" : "line",
    };
  });
}

function axisArray<T>(setting: T | T[] | null): T[] {
  if (!setting) {
    return [];
  }
  return Array.isArray(setting) ? setting : [setting];
}

function hasTimeAxis(chartData: Loadable<TimeChartValues>[]) {
  return chartData
    .filter(hasData)
    .flatMap((d) => d.series)
    .map((d) => d.valueType)
    .includes("timeOfDay");
}

function pointTooltip(point: Highcharts.Point, series: Highcharts.Series): string {
  return `<br/>${color(series.color)} ${series.name}: <b>${point.name}</b>`;
}
