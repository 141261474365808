import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export const useIsVisible = () => {
  const location = useLocation();
  const isVisible = useCallback(
    (visibleOn: string[]) => {
      return visibleOn.some((path) => {
        const regex = new RegExp(`^${path.replace(/:[^/]+/g, "[^/]+")}$`);
        return regex.test(location.pathname);
      });
    },
    [location.pathname]
  );

  return isVisible;
};
