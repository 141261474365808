import { ChangeEvent, useState } from "react";
import styled from "@emotion/styled/macro";
import { observer } from "mobx-react-lite";

import { Input } from "components/common";
import { isNumeric } from "utils/numberUtils";
import language from "translations/language";

export interface Location {
  label: string;
  latitude: string;
  longitude: string;
}

interface Props {
  onChange: (location: Location) => any;
  value: Location | null;
}

const LocationPicker = ({ onChange, value }: Props) => {
  const [latitude, setLatitude] = useState<string>(value?.latitude || "");
  const [longitude, setLongitude] = useState<string>(value?.longitude || "");

  const changeLatitude = (e: ChangeEvent<HTMLInputElement>) => {
    const newLatitude = e.target.value;
    handleLocationUpdate(newLatitude, longitude);
    setLatitude(newLatitude);
  };
  const changeLongitude = (e: ChangeEvent<HTMLInputElement>) => {
    let newLongitude = e.target.value;
    handleLocationUpdate(latitude, newLongitude);
    setLongitude(newLongitude);
  };

  const handleLocationUpdate = (newLatitude: string, newLongitude: string) => {
    // TODO: Better validation that this is a valid lat & long
    if (isNumeric(newLatitude) && isNumeric(newLongitude)) {
      onChange({ label: "", latitude: newLatitude, longitude: newLongitude });
    }
    // TODO: Set an error state if the lat & long are invalid
  };

  return (
    <S.LocationPickerRow>
      <S.LocationPickerRowCell>
        <label htmlFor="latitude"> {language.LATITUDE}</label>
        <Input type="text" data-testid="latitude" id="latitude" value={latitude} onChange={changeLatitude} />
      </S.LocationPickerRowCell>
      <S.LocationPickerRowCell>
        <label htmlFor="longitude">{language.LONGITUDE}</label>
        <Input type="text" data-testid="longitude" id="longitude" value={longitude} onChange={changeLongitude} />
      </S.LocationPickerRowCell>
    </S.LocationPickerRow>
  );
};

export default observer(LocationPicker);

// prettier-ignore
const S = {
  LocationPickerRow: styled.div(({ theme }) => `
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${theme.spacing(8)};

    ${theme.typography.body2}

    label {
      font-weight: bold;
      ${theme.spacing.mr(2)}
    }
  `),
  LocationPickerRowCell: styled.div`
    display: flex;
    align-items: center;
  `,
}
