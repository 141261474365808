import { observer } from "mobx-react-lite";
import styled from "@emotion/styled/macro";
import { useEffect, useState } from "react";
import { FAIRTRAIL_INCIDENTS_ROUTE, FAIRTRAIL_REBORN_INCIDENTS_OFFLINE_ROUTE } from "constants/fairtrailRoutes";
import { DashboardMetadata } from "models/Dashboard";
import { OfflineIcon } from "components/icons";
import language from "translations/language";
import { LoadingState } from "./loading/LoadingState";
import { Title } from "components/common/title";
import { MoreLink } from "components/common/moreLink";
import { OfflineWidgetViewModel } from "models/widgets/OfflineWidget";
import IncidentsAPIClient from "services/api/IncidentsAPIClient";

interface Props {
  widget: OfflineWidgetViewModel;
  dashboardMetadata: DashboardMetadata;
}

const incidentsAPIClient = new IncidentsAPIClient();

const OfflineWidget = ({ widget }: Props) => {
  const [days2, setDays2] = useState(-1);
  const [days7, setDays7] = useState(-1);
  const [days14, setDays14] = useState(-1);
  const [days30, setDays30] = useState(-1);

  useEffect(() => {
    const fetchData = async () => {
      setDays2(await incidentsAPIClient.getIncidentsOfflineCount({ gte: 2, lt: 0 }));
      setDays7(await incidentsAPIClient.getIncidentsOfflineCount({ gte: 7, lt: 2 }));
      setDays14(await incidentsAPIClient.getIncidentsOfflineCount({ gte: 14, lt: 7 }));
      setDays30(await incidentsAPIClient.getIncidentsOfflineCount({ gte: 30, lt: 14 }));
    };

    fetchData();
  }, []);

  if ([days2, days7, days14, days30].includes(-1)) {
    return <LoadingState />;
  }

  return (
    <S.Container>
      <Title text={widget.settings.title} icon={<OfflineIcon />} />
      <S.List>
        <S.Item>
          <S.Title>{`< 2 ${language.DAYS}`}</S.Title>
          <S.Value>{days2}</S.Value>
        </S.Item>

        <S.Item>
          <S.Title>3-7 {language.DAYS}</S.Title>
          <S.Value>{days7}</S.Value>
        </S.Item>

        <S.Item>
          <S.Title>8-14 {language.DAYS}</S.Title>
          <S.Value>{days14}</S.Value>
        </S.Item>

        <S.Item>
          <S.Title>30 {language.DAYS}</S.Title>
          <S.Value>{days30}</S.Value>
        </S.Item>
      </S.List>
      <MoreLink href={FAIRTRAIL_INCIDENTS_ROUTE} hrefNew={FAIRTRAIL_REBORN_INCIDENTS_OFFLINE_ROUTE} />
    </S.Container>
  );
};

export default observer(OfflineWidget);

//prettier-ignore
export const S = {
    Container: styled.div(({ theme }) => `
      position: absolute;
      top: 0;
      left: 0;
      ${theme.spacing.p(6)}
      height: calc(var(--widget-height) * 1px);
      width: calc(var(--widget-width) * 1px);

      ${theme.color.text.neutral700}
    `),
  
    List: styled.div`
      display: flex;
      justify-content: space-between;

      margin: 18px 0 8px 0;
    `,

    Item: styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
    `,

    Title: styled.div(({ theme }) => `
      color: ${theme.color.primary400} ;
      font-size: 16px;
      font-weight: 700;
    `),

    Value: styled.div(({ theme }) => `
      color: ${theme.color.primary400} ;
      font-size: 55px;

      margin-top: 8px;
    `),
  
  };
