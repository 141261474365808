import styled from "@emotion/styled";
import FairtrailLink from "components/FairtrailLink";
import { MoreIcon } from "components/icons";
import language from "translations/language";

type MoreLinkProps = {
  href: string;
  hrefNew: string;
};

export const MoreLink = ({ href, hrefNew }: MoreLinkProps) => {
  return (
    <S.MoreLink>
      <FairtrailLink href={href} rebornHref={hrefNew}>
        {language.MORE}
      </FairtrailLink>
      <MoreIcon />
    </S.MoreLink>
  );
};

// prettier-ignore
const S = {
    MoreLink: styled.div(({theme}) => `
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        
        font-size: 14px;
        ${theme.color.text.secondary}
        
        padding-top: 8px;
        border-top: 1px solid #E3E5E6;
    `)
};
