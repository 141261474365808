import { css, Global, useTheme } from "@emotion/react";
import { normalize, reset } from "styles/resets";
import { typography } from "styles/typography";
import { reactGrid } from "styles/react-grid";

export default function GlobalStyles() {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        @layer normalize, new-css-reset, app;

        @layer normalize {
          ${normalize}
        }

        @layer new-css-reset {
          ${reset}
        }

        @layer app {
          ${typography(theme)}
          ${reactGrid}
        }
      `}
    />
  );
}
