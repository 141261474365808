import BookmarksWidget from "components/widgets/BookmarksWidget";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { BookmarksSVG } from "../thumbnails";
import { WidgetData } from "../widgetData";

export const BookmarksWidgetData: WidgetData<WidgetType.BookmarksWidget, WidgetSettings> = {
  type: WidgetType.BookmarksWidget,

  name: language.widgets.BOOKMARKS,

  description: {
    ...language.widgets.descriptions[WidgetType.BookmarksWidget],
    thumbnail: BookmarksSVG,
  },

  dashboardPurposes: [DashboardPurpose.Generic, DashboardPurpose.Customer, DashboardPurpose.Stack],

  hideTitle: true,

  component: (props) => <BookmarksWidget {...props} />,
};
