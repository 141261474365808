import { WidgetType } from "constants/widgetConstants";

export type EmptyWidget = Omit<WidgetViewModel, "widgetId" | "created" | "modified" | "dimensions"> & {
  widgetId: null;
};

export function toWidget(json: any): WidgetViewModel {
  return {
    ...json,
    created: new Date(json.created),
    modified: new Date(json.modified),
  };
}

export type WidgetViewModel<WType extends WidgetType = WidgetType, WSettings extends Record<string, any> = {}> = {
  widgetId: string;
  dashboardId: string;
  type: WType;
  settings: WSettings & WidgetSettings;
  created: Date;
  modified: Date;
  dimensions: {} & WidgetDimensions;
};

export type WidgetSettings = {
  title: string;
  position: {
    x: number;
    y: number;
  };
  size?: {
    width: number;
    height: number;
  };
};

export type WidgetDimensions = {
  /**
   * Width in grid units.
   */
  width: number;

  /**
   * Height in grid units.
   */
  height: number;

  /**
   * Minimum width in grid units.
   */
  minWidth?: number | undefined;

  /**
   * Maximum width in grid units.
   */
  maxWidth?: number | undefined;

  /**
   * Minimum height in grid units.
   */
  minHeight?: number | undefined;

  /**
   * Maximum height in grid units.
   */
  maxHeight?: number | undefined;
};
