import styled from "@emotion/styled/macro";
import ChartWidget from "components/widgets/ChartWidget";
import { ALL_METRICS, IFRAME_MESSAGE_EVENT_NAME } from "constants/constants";
import { VisualisationIframeAction, VisualisationIframeCommand } from "constants/iframeConstants";
import { TimeInterval, TimeRangeType, WidgetType } from "constants/widgetConstants";
import { chartWidgetAlarmTypes } from "data/chartWidget/chartWidgetSettings";
import { ThingAlarmType } from "models/Thing";
import { KnownEventType } from "models/Timeline";
import { AggregationType, ChartSourceTypes, ChartWidgetViewModel } from "models/widgets/ChartWidget";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "stores/RootStore";

const GraphPage = () => {
  const { configStore } = useStore();
  const { thingId } = useParams<{ thingId: string }>();
  const [metrics, setMetrics] = useState<string[]>([ALL_METRICS]);
  const [graphInterval, setGraphInterval] = useState<TimeInterval>(TimeInterval.SevenDays);
  const [sourceType, setSourceType] = useState<ChartSourceTypes>(ChartSourceTypes.ThingsTimeSeries);
  const [eventType, setEventType] = useState<string>("");
  const [eventSubType, setEventSubType] = useState<string>("");
  const [aggregationType, setAggregationType] = useState<AggregationType>(AggregationType.Aggregated);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const graphIntervalParam = searchParams.get("interval");
    const metricsParam = searchParams.get("metrics");
    const sourceTypeParam = searchParams.get("sourceType");
    const eventTypeParam = searchParams.get("eventType");
    const aggregationTypeParam = searchParams.get("aggregationType");
    const onlyProperties = searchParams.get("onlyProperties");

    if (aggregationTypeParam) {
      setAggregationType(aggregationTypeParam as AggregationType);
    }

    if (graphIntervalParam) {
      setGraphInterval(graphIntervalParam as TimeInterval);
    }

    if (metricsParam) {
      const metrics = decodeURIComponent(metricsParam).split(",");
      setMetrics(metrics);
    }

    if (sourceTypeParam === ChartSourceTypes.ThingEventTime) {
      setSourceType(ChartSourceTypes.ThingEventTime);
    }

    if (eventTypeParam) {
      if ((Object.values(chartWidgetAlarmTypes) as string[]).includes(eventTypeParam)) {
        setEventType(KnownEventType.Alarms);
        setEventSubType(eventTypeParam);
      } else {
        setEventType(KnownEventType.Connectivity);
        setEventSubType(`${KnownEventType.Connectivity}_${eventTypeParam}`);
      }
    }

    const handleMessage = (event: MessageEvent<VisualisationIframeCommand>) => {
      // TODO: It is recommended to have some security, e.g. check origin. Not sure how sensitive these commands are considered though 🤔

      if (!event.data.action) {
        return;
      }

      switch (event.data.action) {
        case VisualisationIframeAction.SetGraphInterval: {
          const { interval } = event.data.payload;
          setGraphInterval(interval?.length ? interval : TimeInterval.SevenDays);
          break;
        }

        case VisualisationIframeAction.SetMetrics: {
          setMetrics(event.data.payload.metrics);
          break;
        }

        case VisualisationIframeAction.SetAggregationType: {
          setAggregationType(event.data.payload.aggregationType);
          break;
        }
      }
    };

    window.addEventListener(IFRAME_MESSAGE_EVENT_NAME, handleMessage, false);

    return () => window.removeEventListener(IFRAME_MESSAGE_EVENT_NAME, handleMessage);
  }, []);

  const widget: ChartWidgetViewModel = useMemo(
    () =>
      ({
        widgetId: "",
        dashboardId: "",
        created: new Date(),
        modified: new Date(),
        dimensions: { height: 1, width: 1 },
        type: WidgetType.ChartWidget,
        settings: {
          position: { x: 0, y: 0 },
          timeInterval: {
            type: TimeRangeType.TimeInterval,
            interval: graphInterval,
          },
          title: "",
          size: { height: 1, width: 1 },
          leftAxisMetrics:
            sourceType === ChartSourceTypes.ThingsTimeSeries
              ? {
                  thingIds: [thingId],
                  metrics: [...metrics],
                  sourceType,
                  aggregationType,
                }
              : {
                  thingId: thingId,
                  sourceType,
                  eventType,
                  eventSubType,
                },
        },
      } as ChartWidgetViewModel),
    [thingId, graphInterval, metrics, sourceType, eventType, eventSubType, aggregationType]
  );

  return (
    <SChartContainer>
      <ChartWidget widget={widget} containerProps={CONTAINER_PROPS} loadingStateComponent={<SLoading />} />
    </SChartContainer>
  );
};

export default GraphPage;

const CONTAINER_PROPS = {
  style: {
    height: "100%",
    width: "100%",
  },
};

const SChartContainer = styled.div`
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 20px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.theme.color.bg.neutral100}
`;

const SLoading = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.theme.color.bg.neutral100}
`;
