import ChartWidget from "components/widgets/ChartWidget";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { ChartWidgetSettings } from "models/widgets/ChartWidget";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { ChartThumbSVG } from "../thumbnails";
import { WidgetData } from "../widgetData";
import { chartWidgetOnSave } from "./chartWidgetOnSave";
import { chartWidgetSettings } from "./chartWidgetSettings";

export const ChartWidgetData: WidgetData<WidgetType.ChartWidget, ChartWidgetSettings & WidgetSettings> = {
  type: WidgetType.ChartWidget,

  name: language.widgets.GENERIC_CHART,

  description: {
    ...language.widgets.descriptions[WidgetType.ChartWidget],
    thumbnail: ChartThumbSVG,
  },

  dashboardPurposes: [DashboardPurpose.Generic, DashboardPurpose.Customer, DashboardPurpose.Stack],

  settings: chartWidgetSettings,

  onSave: chartWidgetOnSave,

  component: (props) => <ChartWidget {...props} />,
};
