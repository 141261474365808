import NiceModal, { antdModal, useModal } from "@ebay/nice-modal-react";
import styled from "@emotion/styled/macro";
import { Button, Input } from "components/common";
import * as Form from "components/common/form";
import Modal from "components/common/modal";
import { CustomerPicker, StackPicker } from "components/edit/CustomerPicker";
import { BuildingIcon, CrownIcon, UserIcon } from "components/icons";
import { DashboardPurpose, DashboardTheme } from "constants/dashboardConstants";
import { EDIT_DASHBOARD_ROUTE } from "constants/routes";
import { WidgetType } from "constants/widgetConstants";
import { observer } from "mobx-react-lite";
import { CustomerDashboardMetadata, DashboardMetadata, StackDashboardMetadata } from "models/Dashboard";
import { Stack } from "models/Stack";
import { User } from "models/User";
import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardStore } from "stores/DashboardStore";
import { useStore } from "stores/RootStore";
import language from "../../translations/language";

interface Props {
  cloneDashboardId?: string;
}

const CreateDashboardModal = NiceModal.create(({ cloneDashboardId }: Props) => {
  const modal = useModal();
  const { dashboardStore } = useStore();
  const navigate = useNavigate();

  const [genericBoardName, setGenericBoardName] = useState("");
  const [dashboardPurpose, setDashboardPurpose] = useState<DashboardPurpose>(DashboardPurpose.Generic);
  const [customer, setCustomer] = useState<User | null>(null);
  const [stack, setStack] = useState<Stack | null>(null);
  const [theme, setTheme] = useState(DashboardTheme.Default);

  const dashboardToClone = dashboardStore.getDashboardById(cloneDashboardId!);

  useEffect(() => {
    if (cloneDashboardId && dashboardToClone) {
      setDashboardPurpose(dashboardToClone.metadata.dashboardPurpose);
      setTheme(dashboardToClone.settings?.theme ?? DashboardTheme.Default);
    }

    setGenericBoardName("");
  }, [cloneDashboardId, dashboardToClone]);

  const createDashboard: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    let dashboardName = genericBoardName;
    const dashboardMetadata = { dashboardPurpose } as DashboardMetadata;
    const dashboardSettings = { theme, isPublic: false };

    switch (dashboardPurpose) {
      case DashboardPurpose.Customer:
        if (!customer) {
          console.error("Customer must be selected");
          return;
        }

        dashboardName = `${customer.name}`;
        (dashboardMetadata as CustomerDashboardMetadata).customerId = customer.userId;

        break;
      case DashboardPurpose.Stack:
        if (!stack || !customer) {
          console.error("Stack & Customer must be selected");
          return;
        }

        dashboardName = `${customer.name} - ${stack.name}`;
        (dashboardMetadata as StackDashboardMetadata).stackId = stack.stackId;

        break;
      default:
    }

    const newDashboardId = await dashboardStore.createDashboard(
      dashboardName,
      dashboardMetadata,
      dashboardSettings,
      cloneDashboardId
    );

    if (!cloneDashboardId) {
      switch (dashboardPurpose) {
        case DashboardPurpose.Customer:
        case DashboardPurpose.Stack:
          await addCustomerWidget(dashboardStore, newDashboardId);
          break;
        default:
      }
    }

    modal.resolve();
    modal.hide();

    if (newDashboardId) {
      navigate(EDIT_DASHBOARD_ROUTE.replace(":dashboardId", newDashboardId));
    }

    return false;
  };

  const baseModal = antdModal(modal);

  baseModal.onCancel = () => {
    modal.resolve();
    modal.hide();
  };

  return (
    <Modal {...baseModal}>
      {cloneDashboardId && <h3>{language.createDashboard.CREATE_COPY_DASHBOARD_TITLE(dashboardToClone?.name!)}</h3>}
      <Form.Form>
        <Form.Row>
          <DashboardTypePicker currentPurpose={dashboardPurpose} onSelect={setDashboardPurpose} />
        </Form.Row>
        {dashboardPurpose === DashboardPurpose.Generic && (
          <Form.Row>
            <Input
              placeholder={language.NAME}
              onChange={(e) => setGenericBoardName(e.target.value)}
              style={{ width: "400px" }}
              value={genericBoardName}
            />
          </Form.Row>
        )}

        {dashboardPurpose === DashboardPurpose.Customer && (
          <Form.Row>
            <CustomerPicker onCustomerChange={setCustomer} />
          </Form.Row>
        )}

        {dashboardPurpose === DashboardPurpose.Stack && (
          <Form.Row>
            <StackPicker onStackChange={setStack} onCustomerChange={setCustomer} />
          </Form.Row>
        )}

        <Form.Row>
          <Button onClick={createDashboard}>{cloneDashboardId ? language.CREATE_COPY : language.CREATE}</Button>
        </Form.Row>
      </Form.Form>
    </Modal>
  );
});

const DashboardTypePicker = ({
  onSelect,
  currentPurpose,
}: {
  currentPurpose: DashboardPurpose;
  onSelect: (purpose: DashboardPurpose) => unknown;
}) => {
  return (
    <S.PickerContainer>
      {Object.values(DashboardPurpose).map((purpose: DashboardPurpose) => (
        <Button
          key={purpose}
          ghost={currentPurpose !== purpose}
          onClick={(e) => {
            e.preventDefault();
            onSelect(purpose);
          }}
        >
          {language.dashboardShortPurpose[purpose]}
        </Button>
      ))}
    </S.PickerContainer>
  );
};

const addCustomerWidget = async (dashboardStore: DashboardStore, newDashboardId: string) => {
  await dashboardStore.createWidget(newDashboardId, {
    type: WidgetType.CustomerWidget,
    settings: { title: "Customer", position: { x: 0, y: 0 } },
  });
};

export default observer(CreateDashboardModal);

// prettier-ignore
const S = {
  PickerContainer: styled.div(({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(4)};
  `),

  IconContainer: styled.div( ({ theme }) => `
    display: inline-block;
    ${theme.spacing.w(6)}
    ${theme.spacing.h(6)}
    ${theme.spacing.mr(2)}
  `),
};
