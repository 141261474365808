import styled from "@emotion/styled/macro";
import { Button, ContentContainer, PageContainer } from "components/common";
import * as Form from "components/common/form";
import SettingsComponents from "components/edit/SettingsComponents";
import { EDIT_DASHBOARD_ROUTE } from "constants/routes";
import { getWidgetData } from "data/widgetData";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite";
import { hasData } from "models/Loading";
import { WidgetSettings } from "models/widgets/Widget";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStoreItem } from "stores/hooks";
import { useStore } from "stores/RootStore";
import language from "translations/language";
import { getParamRoute } from "utils/routeUtils";

const BackIcon = () => (
  <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.02667 0.880812C5.95927 0.845753 5.88359 0.829712 5.80776 0.83441C5.73193 0.839107 5.65881 0.864367 5.59625 0.907478L0.179584 4.65748C0.124235 4.69582 0.0790032 4.74701 0.0477628 4.80666C0.0165223 4.86631 0.00020398 4.93264 0.000203974 4.99998C0.000203968 5.06731 0.0165223 5.13364 0.0477627 5.19329C0.0790032 5.25294 0.124235 5.30413 0.179584 5.34248L5.59625 9.09248C5.65876 9.13573 5.73191 9.16106 5.80779 9.16572C5.88366 9.17038 5.95936 9.1542 6.0267 9.11892C6.09403 9.08364 6.15043 9.03062 6.18979 8.96559C6.22915 8.90055 6.24997 8.82599 6.25 8.74998L6.25 1.24998C6.25001 1.17392 6.22921 1.09931 6.18984 1.03424C6.15047 0.96916 6.09404 0.916102 6.02667 0.880812Z"
      fill="currentColor"
    />
  </svg>
);

// TODO: remove the page entirely once the modal widget editing works properly
const EditWidgetPage = () => {
  const { widgetId, dashboardId } = useParams<{ widgetId: string; dashboardId: string }>();
  const { dashboardStore } = useStore();
  const navigate = useNavigate();
  const dashboard = useStoreItem(dashboardStore, dashboardId!);

  useEffect(() => {
    dashboardStore.loadWidgetById(dashboardId!, widgetId!);
  }, [widgetId, dashboardId, dashboardStore]);

  const widget = dashboardStore.getWidgetById(widgetId!);
  const settingsState = useLocalObservable<any>(() => ({ ...(widget?.settings ?? {}) }));

  useEffect(() => {
    if (widget) {
      runInAction(() => {
        Object.keys(widget.settings).forEach(
          (key) => (settingsState[key] = widget.settings[key as keyof WidgetSettings])
        );
      });
    }
  }, [settingsState, widget]);

  const widgetData = getWidgetData(widget!);

  if (!widget || !widgetData?.settings || !hasData(dashboard)) {
    // TODO: Consider showing loading indicator or error message?
    return <></>;
  }

  const updateWidget = async (e: any) => {
    e.preventDefault();

    let updatedSettings = {
      ...widget.settings,
      ...settingsState,
    };

    if (widgetData.onSave) {
      updatedSettings = widgetData.onSave(updatedSettings);
    }

    await dashboardStore.updateWidget(widget.dashboardId, widgetId!, {
      ...widget,
      settings: updatedSettings,
    });

    navigate(EDIT_DASHBOARD_ROUTE.replace(":dashboardId", dashboardId!));
  };

  return (
    <S.EditWidgetWrapper>
      <PageContainer>
        <ContentContainer>
          <S.Row>
            <Button type="text" link to={getParamRoute(EDIT_DASHBOARD_ROUTE, { dashboardId: dashboardId! })}>
              <S.BackIconContainer>
                <BackIcon />
              </S.BackIconContainer>
              {language.BACK}
            </Button>
          </S.Row>
          <S.Title>
            {language.EDIT} {widgetData.name.toLowerCase()}
          </S.Title>
          <Form.Form onSubmit={updateWidget}>
            {widgetData.settings ? (
              <SettingsComponents
                settingsDescriptor={widgetData.settings}
                state={settingsState}
                dashboardMeta={dashboard.metadata}
              />
            ) : (
              <></>
            )}

            <S.ButtonContainer>
              <Button>{language.SAVE}</Button>
            </S.ButtonContainer>
          </Form.Form>
        </ContentContainer>
      </PageContainer>
    </S.EditWidgetWrapper>
  );
};

export default observer(EditWidgetPage);

//prettier-ignore
const S = {
  Row: styled.div(({ theme }) => `
    {theme.spacing.h(10)}
    margin-left: -${theme.spacing(3)};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  `),
  ButtonContainer: styled.div(({ theme }) => `
    display: flex;
    justify-content: flex-end;
    ${theme.spacing.mt(8)}
  `),
  BackIconContainer: styled.div(({ theme }) => `
    ${theme.spacing.mr(2)};
  `),
  //prettier-ignore
  Title: styled.h3(({ theme }) => `
    ${theme.spacing.mb(10)}
  `),
  EditWidgetWrapper: styled.h3(({ theme }) => `
    ${theme.spacing.p(3)}
    padding-top: 0;
  `),
};
