import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { SelectSetting } from "./SelectSetting";

/*
 * This is the shape used to represent the options that update multiple fields
 * the first part is the value as far as the select is concerned and the second part is the fields and
 * values to set in the state.
 * For example:
 * {
 *    "online_event": {update: {"eventType": "connectivity", "eventSubType": "online"}, value: "Online"},
 * }
 */
type MappedUpdates = Record<string, { update: Record<string, any>; value: string; color?: string }>;

interface Props {
  options: MappedUpdates;
  values: Record<string, any>;
  onChange: (key: string, value: any) => void;
}
const CompositeSelect = ({ values, options, onChange }: Props) => {
  const onChangeMapper = useCallback(
    (_: unknown, value: any) => {
      const update = options[value].update;
      for (const key in update) {
        onChange(key, update[key]);
      }
    },
    [onChange, options]
  );

  const optionsForSelect = Object.entries(options).map(([key, config]) => ({
    key,
    value: config.value,
    color: config.color,
  }));

  const value = Object.entries(options).find(([_, config]) => match(config.update, values))?.[0] ?? null;

  return (
    <SelectSetting
      onChange={onChangeMapper}
      settingsKey={""}
      value={value}
      multiple={false}
      options={optionsForSelect}
    />
  );
};

function match(a: Record<string, any>, b: Record<string, any>) {
  for (const key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

export default observer(CompositeSelect);
