import { UnitsType } from "models/widgets/WeatherWidget";
import OpenWeatherAPIClient from "../../../services/api/OpenWeatherAPIClient";

type Percentage = number;
type Celsius = number;
type Meters = number;
type MetersPerSecond = number;
type Degrees = number;
type HectoPascal = number; // hPa - this is the same as 1mb
type MillisecondTimestamp = number;

// These "types" of weather originally came from https://openweathermap.org/api/one-call-3
// but they are pretty generic so should always work for our purposes.
type WeatherType =
  | "Clear"
  | "Clouds"
  | "Rain"
  | "Snow"
  | "Thunderstorm"
  | "Drizzle"
  | "Mist"
  | "Smoke"
  | "Haze"
  | "Dust"
  | "Fog"
  | "Sand"
  | "Ash"
  | "Squall"
  | "Tornado";

export type WeatherReport = {
  timezone: {
    name: string;
    offset: number; // in hours
  };
  current: {
    temperature: Celsius;
    temperatureUnit: UnitsType;
    temperatureFeelsLike: Celsius;
    forecastLowest: Celsius;
    forecastHighest: Celsius;
    sunset: MillisecondTimestamp;
    sunrise: MillisecondTimestamp;
    pressure: HectoPascal;
    humidity: Percentage;
    dewPoint: Celsius;
    uvIndex: number;
    cloudiness: Percentage;
    visibility: Meters;
    windSpeed: MetersPerSecond;
    windDirection: Degrees;
    overview: {
      icon: string;
      main: WeatherType;
      description: string;
    };
  };
};

export const ExampleWeatherReport: WeatherReport = {
  timezone: {
    name: "Asia/Bangkok",
    offset: 0,
  },
  current: {
    temperature: 1,
    temperatureUnit: UnitsType.Metric,
    temperatureFeelsLike: 1,
    forecastLowest: 1,
    forecastHighest: 10,
    sunset: 1669879021000,
    sunrise: 1669903004000,
    pressure: 1035,
    humidity: 97,
    dewPoint: 273.84,
    uvIndex: 0,
    cloudiness: 75,
    visibility: 7000,
    windSpeed: 0,
    windDirection: 0,
    overview: {
      icon: "04d",
      main: "Clouds",
      description: "broken clouds",
    },
  },
};

export type WeatherSource = (lat: string, long: string, unit: UnitsType) => Promise<WeatherReport>;

export const configureOpenWeatherMapSource = (client: OpenWeatherAPIClient) => {
  const source: WeatherSource = async (lat, lon, unit) => {
    const weatherData = await client.getWeatherByLatAndLong(lat, lon, unit || UnitsType.Metric);
    return {
      timezone: {
        name: weatherData.timezone,
        offset: weatherData.timezone_offset / 3600,
      },
      current: {
        temperature: Math.round(weatherData.current.temp),
        temperatureUnit: unit,
        temperatureFeelsLike: weatherData.current.feels_like,
        forecastLowest: Math.round(Math.min(...weatherData.daily.map((d: any) => d?.temp?.min))),
        forecastHighest: Math.round(Math.max(...weatherData.daily.map((d: any) => d?.temp?.max))),
        sunrise: weatherData.current.sunrise * 1000,
        sunset: weatherData.current.sunset * 1000,
        pressure: weatherData.current.pressure,
        humidity: weatherData.current.humidity,
        dewPoint: weatherData.current.dew_point,
        uvIndex: weatherData.current.uvi,
        cloudiness: weatherData.current.clouds,
        visibility: weatherData.current.visibility,
        windSpeed: weatherData.current.wind_speed,
        windDirection: weatherData.current.wind_deg,
        overview: {
          main: weatherData.current.weather[0].main,
          icon: weatherData.current.weather[0].icon,
          description: weatherData.current.weather[0].description,
        },
      },
    };
  };
  return source;
};
