import styled from "@emotion/styled/macro";
import { WidgetFrameProvider } from "components/widgets/WidgetFrame";
import { ErrorState } from "components/widgets/loading/ErrorState";
import { observer } from "mobx-react-lite";
import { WidgetViewModel } from "models/widgets/Widget";
import React, { LegacyRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useMeasure } from "react-use";
import language from "translations/language";

interface Props {
  children: any;
  widget: WidgetViewModel;
  hideable?: boolean;
}

const HomePageWidgetFrame = ({ children, widget, hideable }: Props) => {
  const [ref, { width, height }] = useMeasure();
  const Container = hideable ? S.HiddenContainer : hideable ? S.TextContainer : S.Container;

  return (
    <WidgetFrameProvider
      options={{
        color: "#fff",
        setColor: () => {},
      }}
    >
      <Container className="widget" width={width} height={height} ref={ref as LegacyRef<HTMLDivElement>}>
        <ErrorBoundary FallbackComponent={() => <ErrorState>{language.errors.UNEXPECTED_WIDGET_ERROR}</ErrorState>}>
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, { widget });
          })}
        </ErrorBoundary>
      </Container>
    </WidgetFrameProvider>
  );
};

type DimensionProps = {
  width: number;
  height: number;
};

// prettier-ignore
const S = {
  Container: styled.div<DimensionProps>(({ theme, width, height, color }) =>`
    --widget-width: ${width};
    --widget-height: ${height};
    ${color ? "color: " + color + ";": ""}
    ${theme.color.bg.neutral100}
    border-radius: 5px;
    height: 100%;
    box-shadow: -2px -2px 5px rgba(67, 82, 90, 0.1), 2px 2px 5px rgba(67, 82, 90, 0.1);
    ${color ? "border: " + color + " 4px solid;": ""}
    overflow: hidden;
    container-type: size;
  `),

  TextContainer: styled.div<{ height: number, width: number }>(({ theme, width, height }) => `
    --widget-width: ${width};
    --widget-height: ${height};
    border-radius: 5px;
    width: inherit;
    height: inherit;
    box-shadow: ${theme.shadow.sm};
    ${theme.color.bg.neutral100}
    overflow-y: hidden;
    overflow-x: hidden;
    transition: all 0.15s ease-in-out;
  `),

  HiddenContainer: styled.div`
    background-color: transparent;
    overflow: hidden;
    width: inherit;
    height: inherit;
  `,

};

export default observer(HomePageWidgetFrame);
