import { SettingsType } from "constants/constants";
import { SettingsDescriptor } from "data/widgetData";
import language from "translations/language";

export const customerWidgetSettings: SettingsDescriptor = {
  customerId: {
    type: SettingsType.Customer,
    label: language.CUSTOMER,
  },
};
