import ClockWidget from "components/widgets/ClockWidget";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { ClockWidgetSettings } from "models/widgets/ClockWidget";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { ClockThumbSVG } from "../thumbnails";
import { WidgetData } from "../widgetData";
import { clockWidgetSettings } from "./clockWidgetSettings";

export const ClockWidgetData: WidgetData<WidgetType.ClockWidget, ClockWidgetSettings & WidgetSettings> = {
  type: WidgetType.ClockWidget,

  name: language.widgets.CLOCK,

  description: {
    ...language.widgets.descriptions[WidgetType.ClockWidget],
    thumbnail: ClockThumbSVG,
  },

  dashboardPurposes: [DashboardPurpose.Generic, DashboardPurpose.Customer, DashboardPurpose.Stack],

  hideTitle: true,

  settings: clockWidgetSettings,

  component: (props) => <ClockWidget {...props} />,
};
