import { FairtrailHttpClient } from "../FairtrailHttpClient";
import { HealthData } from "models/HealthData";

export class HealthWidgetAPIClient extends FairtrailHttpClient {
  async getData(widgetId: string): Promise<HealthData> {
    const response = await this.get({
      path: `/visualisations/health-widget/${widgetId}`,
      authenticated: true,
    });

    return response.data;
  }
}
