import styled from "@emotion/styled/macro";
import { Button, Select } from "components/common";
import { WidgetType } from "constants/widgetConstants";
import { getWidgets } from "data/widgetData";
import { observer } from "mobx-react-lite";
import { Dashboard } from "models/Dashboard";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "stores/RootStore";

const AddDevWidget = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { dashboardStore } = useStore();

  const { dashboardId } = useParams<{ dashboardId: string }>();
  const widgetData = getWidgets(undefined as unknown as Dashboard);

  const openCreateWidgetModal = async (newValue: any) => {
    const widget = dashboardStore.buildEmptyWidget(newValue.value as WidgetType, "dev widget", dashboardId!);
    dashboardStore.createWidget(dashboardId!, widget, true);
  };

  if (process.env.NODE_ENV !== "development") {
    return null;
  }

  return (
    <S.Container>
      <Button onClick={() => setIsVisible(!isVisible)}>Dev tools</Button>
      {isVisible && (
        <S.ToolsContainer>
          <label htmlFor="create-widget">Create dev-widget</label>
          <Select
            id={"create-widget"}
            options={widgetData.map((w) => ({ value: w.type, label: w.name }))}
            onChange={openCreateWidgetModal}
          />
        </S.ToolsContainer>
      )}
    </S.Container>
  );
};

export default observer(AddDevWidget);

// prettier-ignore
const S = {
  Container: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 300px;
    height: auto;
  `,

  ToolsContainer: styled.div(({theme})=>`
    z-index: 100000;
    border-radius: 5px;
    ${theme.color.bg.neutral100};
    box-shadow: ${theme.shadow.sm};
    display: flex;
    flex-direction: column;
    ${theme.spacing.px(4)}
    ${theme.spacing.py(4)}

    & > * {
      margin-top: 5px;
    }
  `)
};
