import { IncidentType } from "constants/widgetConstants";
import { SettingsType } from "../constants/constants";
import language from "../translations/language";
import { SettingsDescriptor, TextSettings } from "./widgetData";

export const titleSetting: TextSettings = {
  label: language.widgets.settings.TITLE,
  type: SettingsType.Text,
};

export const thingSettings: SettingsDescriptor = {
  thingId: {
    type: SettingsType.Thing,
    label: language.widgets.settings.THINGID,
    multiple: false,
  },
  thingProperty: {
    type: SettingsType.ThingMetric,
    label: language.widgets.settings.THING_PROPERTY,
    options: "LiveThingProperty",
    multiple: false,
    thingsIdField: "thingId",
  },
};

export const incidentStatsSettings: SettingsDescriptor = {
  incidentType: {
    type: SettingsType.Select,
    label: language.widgets.settings.INCIDENT_TYPE,
    default: null,
    multiple: false,
    options: Object.entries(IncidentType).map(([_key, value]) => {
      return { key: value, value: language.incidentTypes[value] };
    }),
  },
  dayRange: {
    type: SettingsType.DayRange,
    label: language.widgets.settings.INCIDENT_DURATION,
  },
};

export const organizationSettings: SettingsDescriptor = {
  organizationId: {
    type: SettingsType.Organization,
    label: language.widgets.settings.ORGANIZATION_ID,
  },
};

export const customerStackLocationsSettings: SettingsDescriptor = {
  customerId: {
    type: SettingsType.Customer,
    label: language.CUSTOMER,
  },
};
