import React from "react";
import styled from "@emotion/styled/macro";

type ToggleProps = {
  value: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const Toggle = ({ value, onChange }: ToggleProps) => {
  return (
    <Label>
      <input type="checkbox" checked={value} onChange={onChange} />
      <Slider />
    </Label>
  );
};

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;

  /* round */
  border-radius: 32px;

  &:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;

    /* round */
    border-radius: 50%;
  }
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 64px;
  height: 32px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + ${Slider} {
    background-color: #2196f3;
  }

  input:focus + ${Slider} {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + ${Slider}:before {
    transform: translateX(32px);
  }
`;
