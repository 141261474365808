import { ConnectivityMessage, KnownEventType } from "models/Timeline";
import { LeakAlarmType, PressureAlarmType, ThingAlarmLike, ThingAlarmType, ThingStatus } from "models/Thing";
import { SettingsModalOptions } from "constants/constants";
import { DashboardPurpose, DashboardTheme } from "constants/dashboardConstants";
import {
  FilterValue,
  GaugeType,
  HealthFilterValue,
  IncidentAllTypes,
  IncidentType,
  LiveThingProperty,
  MapInitialView,
  PropertyMetric,
  SingleValueSourceTypes,
  TimeInterval,
  WidgetType,
} from "constants/widgetConstants";
import { AggregationType, ChartSourceTypes, TimeseriesType } from "models/widgets/ChartWidget";
import { MultipleValueSourceTypes } from "models/widgets/common/value";
import { GeoDataSourceTypes } from "models/widgets/MapWidget";
import {
  MultipleAlarmsValueSourceTypes,
  MultipleStacksValueSourceTypes,
  MultipleThingsValueSourceTypes,
  ThingMetricThreshold,
  ThingMetricThresholdOperator,
} from "models/widgets/TableWidget";
import { NoClassificationReason } from "models/HealthData";
import { ThresholdColor } from "models/widgets/common/common";

const de = {
  EDIT: "Bearbeiten",
  NEW: "Neu",
  SAVE: "Speichern",
  CREATE: "Neu",
  NAME: "Name",
  ADD: "Hinzufügen",
  REMOVE: "Löschen",
  CREATE_COPY: "Kopie erstellen",
  MODIFIED: "Zuletzt bearbeitet",
  OWNER: "Besitzer",
  ADDRESS: "Addresse",
  VIEW: "Präsentation",
  SETTINGS: "Einstellungen",
  SHARING: "Teilen",
  COLOR: "Farbe",
  EMPTY: "Leer",
  BACK: "Zurück",
  SEARCH: "Suchen",
  FULLSCREEN: "Vollbild",
  RESET: "Zurücksetzen",
  RESET_THRESHOLD: "Schwellenwert zurücksetzen",

  LOGIN: "Einloggen",
  SEND: "Senden",
  EMAIL: "E-Mail",
  LOGIN_CODE: "Kod zum Einloggen",
  WARNING: "Warnung",
  NEXT: ">",
  PREVIOUS: "<",

  LOADING: "Lädt...",
  MUST_BE_CONFIGURED: (widgetName: string) => `${widgetName} widget muss erst noch konfiguriert werden`,

  TIME_INTERVAL: "Zeitinterval",
  THINGS: "Geräte",
  TYPE: "Typ",
  ACTIONS: "Aktionen",
  DASHBOARDS: "Dashboards",
  THING: "Ding",
  PRODUCT: "Produkt",
  ERRAND: "Bestellung",

  MIN: "Minimum",
  MAX: "Maximum",
  UNKNOWN_DEVICE: "Unbekanntes Gerät",

  BETWEEN: "Zwischen",
  BETWEEN_TO: "bis",
  DAYS: "Tage",
  N_DAYS: (num: string) => `${num} Tage`,

  CUSTOMER: "Kunde",
  PROPERTY: "Eigentum",
  STATUS: "Status",

  MORE: "Mehr",

  LATITUDE: "Breitengrad",
  LONGITUDE: "Längengrad",
  ZOOM: "Zoom",
  PREVIEW: "Vorschau",

  createDashboard: {
    CREATE_COPY_DASHBOARD_TITLE: (dashboardName: string) => `Erstelle eine Kopie des Dashboards "${dashboardName}"`,
  },

  dashboardPurpose: {
    [DashboardPurpose.Generic]: "Allgemeines Dashboard",
    [DashboardPurpose.Customer]: "Dashboard für einen bestimmten Kunden",
    [DashboardPurpose.Stack]: "Dashboard für eine bestimmte Immobilie",
  },

  dashboardShortPurpose: {
    [DashboardPurpose.Generic]: "Allgemein",
    [DashboardPurpose.Customer]: "Kunde",
    [DashboardPurpose.Stack]: "Immobilie",
  },

  dashboardSettings: {
    [SettingsModalOptions.General]: "General",
    // [SettingsModalOptions.MetricSystem]: "Metrisches System",
    [SettingsModalOptions.ColorTheme]: "Vollbild-Farbthema",
  },

  themeNames: {
    [DashboardTheme.Default]: "Licht",
    [DashboardTheme.DarkPale]: "Dunkel",
    [DashboardTheme.Dark]: "Neon",
    [DashboardTheme.Polygon]: "Polygon",
  },

  protectedHouseholds: {
    TOTAL: "Insgesamt",
    HIGH: "Hohes Schutzniveau",
    MEDIUM: "Mittleres Schutzniveau",
    LOW: "Lokales Schutzniveau",
  },

  signedInstallations: {
    CUSTOMER_CREATED_TO_COMPLETE: "Die gesamte Installation",
    CUSTOMER_CREATED_TO_FIRST_SUGGESTED_INSTALLATION: "Bis zum ersten Terminvorschlag",
    REVIEWING_IMAGES: "Bilder überprüfen",
    SCHEDULING_INSTALLATION: "Installationstermin vereinbaren",
    TOTAL_COMPLETED_INSTALLATIONS: "Anzahl abgeschlossener Installationen",
  },

  timelineEventTypes: {
    [KnownEventType.Alarms]: "Alarme",
    [KnownEventType.Connectivity]: "Konnektivität",
  },

  // TODO: DRY up the alarm names here and the first set of timelineEventSubTypes
  thingAlarms: {
    [ThingAlarmType.LargeWaterFlowAlarm]: "Großer Wasserfluss",
    [ThingAlarmType.LargeWaterVolumeAlarm]: "Große Wassermenge",
    [ThingAlarmType.LongWaterFlowAlarm]: "langer Wasserflussalarm",
    [ThingAlarmType.LowTempAlarm]: "Niedrige Temperatur",
    [ThingAlarmType.SlowLeakage]: "Langsammes Wasserleck",
    [ThingAlarmType.WaterDetected]: "Wasser entdeckt",
    [ThingAlarmType.PressureAlarm]: "DruckAlarm",
    [ThingAlarmType.LeakAlarm]: "Leckalarm",
  },

  alarmTypes: {
    [LeakAlarmType.SmallLeak]: "Kleines Wasserleck",
    [LeakAlarmType.MediumLeak]: "Mittleres Wasserleck",
    [LeakAlarmType.Burst]: "Großes Wasserleck",
    [PressureAlarmType.PressureAlarm1]: "DruckAlarm1",
    [PressureAlarmType.PressureAlarm2]: "DruckAlarm2",
  },

  timelineEventSubTypes: {
    [ThingAlarmType.LargeWaterFlowAlarm]: "Großer Wasserfluss",
    [ThingAlarmType.LargeWaterVolumeAlarm]: "Große Wassermenge",
    [ThingAlarmType.LongWaterFlowAlarm]: "Langer Wassfluss",
    [ThingAlarmType.LowTempAlarm]: "Niedrige Temperatur",
    [ThingAlarmType.SlowLeakage]: "Langsammes Wasserleck",
    [ThingAlarmType.WaterDetected]: "Wasser entdeckt",
    [ThingAlarmType.PressureAlarm]: "DruckAlarm 1",
    [ThingAlarmLike.PressureAlarm1]: "DruckAlarm 1",
    [ThingAlarmLike.PressureAlarm2]: "DruckAlarm 2",
    [ThingAlarmType.LeakAlarm]: "Leckalarm",
    [ConnectivityMessage.Offline]: "Offline",
    [ConnectivityMessage.Online]: "Online",
    [ConnectivityMessage.Timeout]: "Timeout",
  },

  thingProperties: {
    [LiveThingProperty.WaterPressure]: "Wasserdruck",
    [LiveThingProperty.Temperature]: "Temperatur",
    [LiveThingProperty.WifiSignalStrength]: "Wifi Signalstärke",
    [LiveThingProperty.RelativeHumidity]: "Relative Luftfeuchtigkeit",
    [LiveThingProperty.BatteryLevel]: "Batteriestärke",
    [LiveThingProperty.Humidity]: "Luftfeuchtigkeit",
    [LiveThingProperty.CaptionDataAbsoluteHumidity]: "Absolute Luftfeuchtigkeit",
    [LiveThingProperty.CaptionDataWME]: "WME",
  } as { [key in LiveThingProperty]: string },

  thingStatus: {
    [ThingStatus.Healthy]: "Online",
    [ThingStatus.Offline]: "Offline",
    [ThingStatus.HasAlarm]: "Zeigt Alarm an",
    [ThingStatus.Preregistered]: "Installation ausstehend",
    [ThingStatus.ConfigReceived]: "Installation gestartet",
    [ThingStatus.ConfigFailed]: "Installation fehlgeschlagen",
  },

  sourceTypes: {
    "": "Nichts",
    [SingleValueSourceTypes.Thing]: "Gerät",
    [SingleValueSourceTypes.IncidentStats]: "Statistik über Vorfälle",
    [MultipleStacksValueSourceTypes.StackCreated]: "Projektlänge",
    [MultipleValueSourceTypes.ProtectedHouseholds]: "Geschützte Immobilien",
    [MultipleValueSourceTypes.SignedInstallationStats]: "Installationen",
    [ChartSourceTypes.ThingEventTime]: "Event times (thing)",
    [ChartSourceTypes.ThingsTimeSeries]: "Time series (thing)",
    [GeoDataSourceTypes.CustomerStackLocations]: "Platz der Immobilien",
    [MultipleThingsValueSourceTypes.ThingMetricThreshold]: "Wertschwelle für Dinge",
    [GeoDataSourceTypes.ThingLocations]: "Platz der Geräte",
    [MultipleAlarmsValueSourceTypes.Alarms]: "Alarme",
  } as Record<string, string>,

  incidentTypes: {
    [IncidentType.Offline]: "Gerät Offline",
    [IncidentType.FailedInstallations]: "Fehlgeschlagene Installationen",
  } as { [key in IncidentType]: string },

  incidentAllTypes: {
    [IncidentAllTypes.ActiveLoss]: "Active loss",
    [IncidentAllTypes.LargeWaterFlowAlarm]: "Großer Wasserflussalarm",
    [IncidentAllTypes.LargeWaterVolumeAlarm]: "Großes Wasservolumenalarm",
    [IncidentAllTypes.LongWaterFlowAlarm]: "Langer Wasserflussalarm",
    [IncidentAllTypes.LeakAlarm]: "Leckalarm",
    [IncidentAllTypes.Other]: "Anderes",
    [IncidentAllTypes.PressureAlarm1]: "Druckalarm 1",
    [IncidentAllTypes.PressureAlarm2]: "Druckalarm 2",
    [IncidentAllTypes.Slowleakage]: "Langsammes Leck",
    [IncidentAllTypes.Waterleakage]: "Wasserleck",
    [IncidentAllTypes.Offline]: "Offline",
    [IncidentAllTypes.Wpa2]: "Wpa2",
    [IncidentAllTypes.Wifi]: "Wifi",
    [IncidentAllTypes.WaterleakageUnknown]: "Wasser leck unbekannt",
    [IncidentAllTypes.PassiveLoss]: "Passive loss",
    [IncidentAllTypes.SolvedThemselves]: "Selbst gelöst",
    [IncidentAllTypes.WarrentyReplacement]: "Garantieersatz",
    [IncidentAllTypes.ReturnSensor]: "Sensor zurück",
    [IncidentAllTypes.Test]: "Test",
    [IncidentAllTypes.FalseAlarm]: "Falscher Alarm",
    [IncidentAllTypes.Followup]: "Nachverfolgung",
    [IncidentAllTypes.FailedInstallations]: "Fehlgeschlagene Installationen",
    [IncidentAllTypes.LowBattery]: "Schwache Batterywarnung",
  } as { [key in IncidentAllTypes]: string },

  thingMetricThreshold: {
    [ThingMetricThreshold.Pressure]: "Druck",
    [ThingMetricThreshold.Temperature]: "Temperatur",
  } as { [key in ThingMetricThreshold]: string },

  thingMetricThresholdUnit: {
    [ThingMetricThreshold.Pressure]: "bar",
    [ThingMetricThreshold.Temperature]: "°C",
  } as { [key in ThingMetricThreshold]: string },

  thingMetricThresholdOperator: {
    [ThingMetricThresholdOperator.GreaterThan]: "Größer als",
    [ThingMetricThresholdOperator.GreaterThanEqual]: "Größer als oder gleich",
    [ThingMetricThresholdOperator.LessThan]: "Kleiner als",
    [ThingMetricThresholdOperator.LessThanEqual]: "Kleiner als oder gleich",
  } as { [key in ThingMetricThresholdOperator]: string },

  tableWidgetColumnTitles: {
    DATA_SOURCE: "Datenquelle",
    UPDATED_AT: "Datum",
    STATUS: "Status",
  },

  errors: {
    PROPERTY_NOT_AVAILABLE: (propertyName: string) => `${propertyName} ist für diese Gerät nicht verfügbar.`,
    CUSTOMER_DATA_LOAD_ERROR: "Die Kundendatan konnten nicht geladen werden",
    GENERIC_DATA_LOAD_ERROR: "Die Daten konnten nicht geladen werden",
    UNEXPECTED_WIDGET_ERROR: "Ein unerwarteter Fehler ist aufgetreten",
    NO_DATA_TO_DISPLAY_FOR_PERIOD: (period: string) =>
      `Für die letzten ${period} Tage sind keine Daten zum Anzeigen vorhanden`,
    NO_DATA_TO_DISPLAY: "Keine Daten zum Anzeigen",
    NO_METRICS_FOR_SELECTED_THINGS: "Keine Metriken für die ausgewählten Geräte vorhanden",
    ERROR_LOADING_DATA: "Fehler beim Laden der Daten",
  },

  thresholds: {
    [ThresholdColor.Green]: "Normal",
    [ThresholdColor.Yellow]: "Warnung",
    [ThresholdColor.Red]: "Gefahr",
  } as { [key in ThresholdColor]: string },

  gaugeType: {
    [GaugeType.Classic]: "Klassisch",
    [GaugeType.Solid]: "Solide",
  } as { [key in GaugeType]: string },

  mapInitialView: {
    [MapInitialView.Default]: "Standard",
    [MapInitialView.Custom]: "Brauch",
  },

  colors: {
    RED: "Rot",
    YELLOW: "Gelb",
  },

  charts: {
    line: "Liniengrafik",
    scatter: "Streugrafik",
  },

  widgets: {
    WEATHER: "Wetter",
    GAUGE: "Messgerätediagramm", //???
    CLOCK: "Uhr",
    TEST: "Testgrafik",
    TEXT: "Text",
    VALUE: "Zahlenwert",
    STACKED_BAR_CHART: "Balkendiagramm",
    BAR_CHART: "gestapeltes Balkendiagramm",
    CUSTOMER: "Kundenübersicht",
    TABLE: "Tabelle",
    BOOKMARKS: "Lesezeichen",
    OFFLINE: "Offline",
    TIMESERIES_CHART: "Timeseries-Diagramm",
    GENERIC_CHART: "Diagramm",
    MAP_WIDGET: "Karte",
    TIME: "Zeit",
    PROPERTY_HEALTH: "Immobilienzustand",
    IMAGE: "Bild",

    settings: {
      DATA_SOURCE_TYPE: "Typ der Datenquelle",
      DATA_SOURCE: "Datenquelle",
      DAY_RANGE_FROM: "von",
      DAY_RANGE_TO: "bis",
      DAY_RANGE: "Zeitraum",
      EVENT: "Ereignis",
      INCIDENT_TYPE: "Vorfallstyp",
      LOCATION: "Ort",
      ORGANIZATION_ID: "Organisation",
      RANGE: "Zeitraum",
      THING_PROPERTY: "Gerätefähigkeit",
      THINGID: "Geräte-ID",
      THRESHOLDS: "Schwellenwerte",
      RANGE_ALARM: "Hohes Risiko",
      RANGE_WARNING: "Niedriges Risiko",
      RANGE_HEALTHY: "Kein Risiko",
      TIME_INTERVAL: "Zeitintervall",
      TIMEZONE: "Zeitzone",
      TITLE: "Überschrift",
      CHART_TYPE: "Diagrammtyp",
      LEFT_AXIS_METRICS: "Linker Achsenwert",
      RIGHT_AXIS_METRICS: "Rechter Achsenwert",
      DISPLAY_LEFT_AXIS: "Linken Achsenwert anzeigen",
      DISPLAY_RIGHT_AXIS: "Rechten Achsenwert anzeigen",
      INCIDENT_DURATION: "Vorfallsdauer",
      ALL_THINGS: "Alle Geräte",
      TEMPERATURE_UNIT: "Temperatureinheit",
      TIME_FORMAT: "Zeitformat",
      TWENTY_FOUR_HOUR_CLOCK: "24-Stunden-Uhr",
      TWELVE_HOUR_CLOCK: "12-Stunden-Uhr",
      OPERATOR: "Operator",
      VALUE: "Wert",
      FILTER_BY_PROPERTY: "Filtern Sie nach Immobilien",
      ALL_PROPERTIES: "Alle Immobilien",
      PROPERTY_METRIC: "Metriken",
      GAUGE_TYPE: "Typ",
      AGGREGATION_TYPE: "Aggregation Typ",
      INITIAL_MAP_VIEW: "Erste Kartenansicht",
      INITIAL_MAP_VIEW_OPTIONS: "Zeigen Sie auf die anfängliche Kartenmitte und die anfängliche Kartenzoomstufe",
      IMAGE_URL: "Bild URL",
    },

    timeseriesTypes: {
      [TimeseriesType.Temperature]: "Temperatur",
      [TimeseriesType.Pressure]: "Druck",
      [TimeseriesType.WaterConsumption]: "Wasserverbrauch",
      [TimeseriesType.TotalWaterConsumption]: "Gesamtwasserverbrauch",
      [TimeseriesType.Humidity]: "Feuchtigkeit",
      [TimeseriesType.AbsoluteHumidity]: "Absolute Feuchtigkeit",
      [TimeseriesType.PaaConcentrationRollingAverage15Min]:
        "PAA Konzentration (Peressigsäure), Durschnitt der letzten 15 min",
      [TimeseriesType.PaaConcentrationRollingAverage8hour]:
        "PAA Konzentration (Peressigsäure), Durschnitt der letzten 8 Stunden",
      [TimeseriesType.PaaConcentration]: "PAA Konzentration (Peressigsäure)",
      [TimeseriesType.CaptionDataWME]: "WME",
      [TimeseriesType.CaptionDataAbsoluteHumidity]: "Absolute Feuchtigkeit",
      [TimeseriesType.CaptionDataExtHumidity]: "Ext. Feuchtigkeit",
      [TimeseriesType.CaptionDataExtTemperature]: "Ext. Temperatur",
    } as { [key in TimeseriesType]: string },

    descriptions: {
      [WidgetType.ChartWidget]: {
        title: "Diagramm",
        text: "Zeigt ein Diagramm mit verschiedenen Sensormetriken und Alarmen an",
      },
      [WidgetType.ClockWidget]: {
        title: "Uhr",
        text: "Zeigt die aktuelle Uhrzeit für eine ausgewählte Zeitzone an",
      },
      [WidgetType.CustomerWidget]: {
        title: "Kundenübersicht",
        text: "Zeigt Kontaktdaten, Geräteliste und Gerätealarme für einen bestimmten Kunden an",
      },
      [WidgetType.GaugeWidget]: {
        title: "Messgerätediagramm",
        text: "Zeigt den aktuellen Status der verschiedenen Sensormetriken an",
        validationErrorMessage: "Der Wert muss größer als der vorherige Wert sein",
      },
      [WidgetType.MapWidget]: {
        title: "Karte",
        text: "Zeigt eine Karte mit verschiedenen Sensormetriken und Alarmen an",
      },
      [WidgetType.StackedBarChartWidget]: {
        title: "Balkendiagramm",
        text: "Zeigt ein Balkendiagramm mit verschiedenen Sensormetriken und Alarmen an",
      },
      [WidgetType.TextWidget]: {
        title: "Text-Widget",
        text: "Dynamischer Textblock",
      },
      [WidgetType.ValueWidget]: {
        title: "Zahlenwert",
        text: "Zeigt den aktuellen Wert einer bestimmten Sensormetrik an",
      },
      [WidgetType.WeatherWidget]: {
        title: "Wetter",
        text: "Zeigt das aktuelle Wetter für einen bestimmten geografischen Ort an",
      },
      [WidgetType.BarChartWidget]: {
        title: "Gestapeltes Balkendiagramm",
        text: "Zeigt ein gestapeltes Balkendiagramm mit verschiedenen Sensormetriken und Alarmen an",
      },
      [WidgetType.TableWidget]: {
        title: "Tabelle",
        text: "Zeigt eine Tabelle mit verschiedenen Sensormetriken und Alarmen an",
      },
      [WidgetType.BookmarksWidget]: {
        title: "Lesezeichen",
        text: "Zeigt Lesezeichen von Dingen und Vorfällen an",
      },
      [WidgetType.HealthWidget]: {
        title: "Eigentumsgesundheit",
        text: "Zeigt die Gesundheitsmerkmale der Immobilie an",
      },
      [WidgetType.ImageWidget]: {
        title: "Bild",
        text: "Zeigt ein Bild anhand einer URL an",
      },
    } as { [key in WidgetType]: { title: string; text: string; validationErrorMessage?: string } },
  },

  propertyHealthWidgetInfo: {
    fuelPoverty:
      "Die Überwachung der Energiearmut ist für die Bewältigung von Erschwinglichkeits- und Komfortproblemen von entscheidender Bedeutung. Durch die Identifizierung von Energieineffizienzen können Immobilieneigentümer ihren Mietern dabei helfen, die Heiz- und Energiekosten zu senken und gleichzeitig für ein komfortables Wohnumfeld zu sorgen.",

    insulation:
      "Die Messung der Isolierung ist für den Komfort der Mieter, die Energieeffizienz und die Vermeidung von Problemen von entscheidender Bedeutung. Eine unzureichende Isolierung führt zu Unbehagen, höherem Energieverbrauch und möglichen Sachschäden. Durch die Messung des Isolationsgrads können Eigentümer Verbesserungsmöglichkeiten identifizieren, die Isolierung verbessern und ein komfortables, nachhaltiges Wohnumfeld schaffen.",

    ventilation:
      "Die Überwachung der Belüftung in Immobilien ist für Gesundheit, Komfort und Nachhaltigkeit von entscheidender Bedeutung. Die rechtzeitige Behebung von Lüftungsproblemen sorgt für eine bessere Raumluftqualität, beugt Feuchtigkeitsproblemen vor und verbessert die Energieeffizienz.",

    condensation: `Die Überwachung und Bekämpfung von Kondensationsrisiken ist für die Instandhaltung von Immobilien von entscheidender Bedeutung.“ Durch die rechtzeitige Erkennung und Bewältigung von Kondensationsproblemen können Immobilieneigentümer feuchtigkeitsbedingte Probleme verhindern, die strukturelle Integrität der Immobilie bewahren und ein gesundes Wohnumfeld aufrechterhalten.`,
  },

  propertyMetricMould: {
    text: `Die Überwachung und Bewältigung von Schimmelrisiken ist für das Wohlbefinden von Immobilien von entscheidender Bedeutung. Durch die rechtzeitige Erkennung und Behebung von Schimmelproblemen können Immobilieneigentümer Gesundheitsrisiken vorbeugen, die strukturelle Integrität der Immobilie aufrechterhalten und den Mietern ein sicheres und gesundes Wohnumfeld bieten.`,
  },

  tableWidget: {
    UNKNOWN_ADDRESS: "Unbekannte Adresse",
    MORE: "Mehr",
    ALARM_TABLE_TITLE: "Letzter Alarm",
    STACKS_CREATED_TABLE_TITLE: "Projektlänge",

    projectDurationTable: {
      tableHeaders: {
        ADDRESS: "Adresse",
        CREATED: "Tage",
      },
    },
  },

  timeIntervals: {
    [TimeInterval.TwentyFourHours]: "24 Stunden",
    [TimeInterval.ThreeDays]: "3 Tage",
    [TimeInterval.SevenDays]: "7 Tage",
    [TimeInterval.FourteenDays]: "14 Tage",
    [TimeInterval.ThirtyDays]: "30 Tage",
    [TimeInterval.SixtyDays]: "60 Tage",
    [TimeInterval.NinetyDays]: "90 Tage",
    [TimeInterval.OneHundredEightyDays]: "180 Tage",
    [TimeInterval.ThreeHundredSixtyFiveDays]: "365 Tage",
    custom: "Custom",
  },

  dashboards: {
    ALL_DASHBOARDS: "Alle Dashboards",
    CREATE_DASHBOARD: "Neues Dashboard",
    ADD_WIDGET: "Neues Widget",
    ADD_A_WIDGET: "Widget hinzufügen",
    ADD_WIDGET_PLACEHOLDER: "--- Widget wählen ---",
  },

  [WidgetType.CustomerWidget]: {
    TITLE: "Kontakt",
    NAME: "Name",
    MOBILE: "Telefon",
    ADDRESS: "Adresse",
  },

  defaultTitle: {
    [WidgetType.WeatherWidget]: "Wetter",
    [WidgetType.GaugeWidget]: "Neues Messgerätediagramm",
    [WidgetType.ClockWidget]: "Neue Uhr",
    [WidgetType.TextWidget]: "Neuer Text",
    [WidgetType.ValueWidget]: "Neuer Zahlenwert",
    [WidgetType.StackedBarChartWidget]: "Neues gestapeltes Balkendiagramm",
    [WidgetType.CustomerWidget]: "Neuer Kontakt",
    [WidgetType.MapWidget]: "Neue Karte",
    [WidgetType.ChartWidget]: "Neues Diagramm",
    [WidgetType.BarChartWidget]: "Neues Balkendiagramm",
    [WidgetType.TableWidget]: "Neue Tabelle",
    [WidgetType.BookmarksWidget]: "Neue Lesezeichen",
    [WidgetType.HealthWidget]: "Neuer Eigenschaftszustand",
    [WidgetType.OfflineWidget]: "Neue Offlineanzeige",
    [WidgetType.ImageWidget]: "Neues Bild",
  },

  customerPicker: {
    PLACEHOLDER: "Name, Adresse oder Telefonnummer eingeben...",
    NO_RESULTS: "Keine Ergebnisse für",
  },

  bookmarks: {
    LAST_ERRAND: "Letzter Auftrag",
    NO_ACTIVE_ERRAND: "Keine aktiven Besorgungen",
    NO_BOOKMARKS: "Sie haben keine Lesezeichen",
    NOT_FOUND: "Nicht gefunden oder wurde gelöscht",
  },

  modals: {
    confirmation: {
      YES: "Ja",
      NO: "Nein",
    },
    DELETE_WIDGET_MESSAGE: "Sind Sie sicher, dass Sie dieses Widget löschen möchten?",
    DELETE_DASHBOARD_MESSAGE: "Sind Sie sicher, dass Sie dieses Dashboard löschen möchten?",
  },

  shareSettings: {
    TITLE: "Einstellungen teilen",
    DESCRIPTION: "Jeder kann auf dieses Dashboard per Link zugreifen",
    COPY_LINK: "Link kopieren",
    COPIED: "verknüpfung kopiert",
  },

  propertyFilters: {
    [HealthFilterValue.All || FilterValue.All]: "All",
    [HealthFilterValue.HighRisk]: "High risk",
    [HealthFilterValue.MediumRisk]: "Medium risk",
    [HealthFilterValue.Normal]: "Normal",
    [HealthFilterValue.NoClassification]: "No classification",
    [FilterValue.Alarm]: "Alarm",
    [FilterValue.Warning]: "Warning",
    [FilterValue.Healthy]: "Healthy",
  },

  propertyMetric: {
    [PropertyMetric.FuelPoverty]: "Kraftstoffarmut (vermutlich)",
    [PropertyMetric.Insulation]: "Isolierung",
    [PropertyMetric.Ventilation]: "Belüftung",
    [PropertyMetric.InternalMoisture]: "Interne unerwünschte Feuchtigkeitsereignisse",
    [PropertyMetric.Condensation]: "Kondensation/Schimmelrisiko",
  },

  noClassificationReason: {
    [NoClassificationReason.THRESHOLD]: "Schwellenwerte stimmen nicht",
    [NoClassificationReason.NOT_ENOUGH_DATA]: "Nicht genug Daten",
    [NoClassificationReason.MISSING_OUTDOOR_SENSOR]: "Fehlender Außensensor",
  },

  aggregationType: {
    [AggregationType.Aggregated]: "Aggregiert",
    [AggregationType.NonAggregated]: "Nicht aggregiert",
  },
};
export default de;
