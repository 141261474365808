import { LoadingStatus } from "constants/constants";
import { FAKE_WIDGET_ID, WidgetType } from "constants/widgetConstants";
import HighchartsReact from "highcharts-react-official";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { Dashboard, DashboardMetadata, DashboardSettings } from "models/Dashboard";
import { Loadable } from "models/Loading";
import { EmptyWidget, WidgetViewModel } from "models/widgets/Widget";
import { RefObject } from "react";
import { SingleItemSource } from "stores/interfaces";
import { VisualisationAPIClient } from "../services/api/VisualisationsAPIClient";

export class DashboardStore implements SingleItemSource<string, Dashboard, "dashboardId"> {
  private visualisationAPIClient = new VisualisationAPIClient();

  private dashboardRegistry = new Map<string, Dashboard>();
  private widgetRegistry = new Map<string, WidgetViewModel>();
  private chartRegistry = new Map<string, RefObject<HighchartsReact.RefObject> | RefObject<HTMLDivElement>>();

  constructor() {
    makeAutoObservable(this);
  }

  async createDashboard(
    name: string,
    metadata: DashboardMetadata,
    settings?: DashboardSettings,
    cloneDashboardId?: string
  ): Promise<string> {
    const createdDashboard = await this.visualisationAPIClient.createDashboard(
      name,
      metadata,
      settings,
      cloneDashboardId
    );

    runInAction(() => this.dashboardRegistry.set(createdDashboard.dashboardId, createdDashboard));

    return createdDashboard.dashboardId;
  }

  async loadDashboards(q: string = "") {
    const dashboards = await this.visualisationAPIClient.getDashboards(undefined, undefined, undefined, undefined, q);

    runInAction(() => {
      this.dashboardRegistry.clear();
      dashboards.forEach((dashboard) => this.dashboardRegistry.set(dashboard.dashboardId, dashboard));
    });
  }

  async loadDashboardById(dashboardId: string) {
    const existingDashboard = this.dashboardRegistry.get(dashboardId);

    if (existingDashboard) {
      return;
    }

    const dashboard = await this.visualisationAPIClient.getDashboard(dashboardId);

    runInAction(() => this.dashboardRegistry.set(dashboard.dashboardId, dashboard));
  }

  async deleteDashboard(dashboardId: string) {
    await this.visualisationAPIClient.deleteDashboard(dashboardId);

    runInAction(() => this.dashboardRegistry.delete(dashboardId));
  }

  async updateDashboard(dashboardId: string, name: string, settings?: DashboardSettings) {
    const dashboard = await this.visualisationAPIClient.updateDashboard(dashboardId, name, settings);

    runInAction(() => this.dashboardRegistry.set(dashboard.dashboardId, dashboard));
  }

  async createWidget(dashboardId: string, widget: Pick<WidgetViewModel, "type" | "settings">, fakeWidget?: boolean) {
    if (fakeWidget) {
      const createdWidget = {
        ...widget,
        widgetId: FAKE_WIDGET_ID + this.widgetRegistry.size,
        dimensions: {
          width: 3,
          height: 3,
          maxHeight: 30,
          maxWidth: 30,
          minHeight: 1,
          minWidth: 1,
        },
      } as WidgetViewModel;

      runInAction(() => this.widgetRegistry.set(createdWidget.widgetId, createdWidget));
    } else {
      // TODO: Create temporary local widget first and then update it with the server response, to make ui snappier
      const createdWidget = await this.visualisationAPIClient.createWidget(dashboardId, widget);

      runInAction(() => this.widgetRegistry.set(createdWidget.widgetId, createdWidget));
    }
  }

  async loadWidgetsByDashboardId(dashboardId: string) {
    runInAction(() => this.widgetRegistry.clear());

    const widgets = await this.visualisationAPIClient.getWidgetsByDashboardId(dashboardId);

    runInAction(() => widgets.map((widget) => this.widgetRegistry.set(widget.widgetId, widget)));
  }

  async loadWidgetById(dashboardId: string, widgetId: string) {
    const widget = await this.visualisationAPIClient.getWidgetById(dashboardId, widgetId);

    runInAction(() => this.widgetRegistry.set(widget.widgetId, widget));
  }

  async updateWidget(
    dashboardId: string,
    widgetId: string,
    widget: Omit<WidgetViewModel, "created" | "modified" | "dimensions">
  ) {
    if (widgetId.startsWith(FAKE_WIDGET_ID)) {
      runInAction(() => this.widgetRegistry.set(widgetId, widget as WidgetViewModel));
      return;
    }

    const updateData = { settings: { ...widget.settings } };
    const updatedWidget = await this.visualisationAPIClient.updateWidget(dashboardId, widgetId, updateData);

    runInAction(() => this.widgetRegistry.set(updatedWidget.widgetId, updatedWidget));
  }

  async updateWidgetLayouts(dashboardId: string, widgets: WidgetViewModel[]) {
    const updatedWidgets = await this.visualisationAPIClient.batchUpdateWidgetLayouts(
      dashboardId,
      widgets
        .filter((widget) => !widget.widgetId.startsWith(FAKE_WIDGET_ID))
        .map(({ widgetId, settings: { position, size } }) => ({
          widgetId,
          position,
          size,
        }))
    );

    runInAction(() => updatedWidgets.map((widget) => this.widgetRegistry.set(widget.widgetId, widget)));

    /* Updates dev widgets right away */
    runInAction(() =>
      widgets
        .filter((widget) => widget.widgetId.startsWith(FAKE_WIDGET_ID))
        .map((widget) => this.widgetRegistry.set(widget.widgetId, widget))
    );
  }

  async deleteWidget(dashboardId: string, widgetId: string) {
    if (widgetId.startsWith(FAKE_WIDGET_ID)) {
      runInAction(() => this.widgetRegistry.delete(widgetId));
      return;
    }

    await this.visualisationAPIClient.deleteWidget(dashboardId, widgetId);

    runInAction(() => this.widgetRegistry.delete(widgetId));
  }

  get dashboards() {
    return Array.from(this.dashboardRegistry.values()).sort((a, b) => (a.modified > b.modified ? -1 : 1));
  }

  get widgets() {
    return Array.from(this.widgetRegistry.values());
  }

  getDashboardById(dashboardId: string) {
    return this.dashboardRegistry.get(dashboardId);
  }

  /** Quick access to the current dashboard if in viewing/editing view, otherwise first dashboard in the list */
  get currentDashboard() {
    return this.dashboards[0];
  }

  getWidgetsByDashboardId(dashboardId: string): WidgetViewModel[] {
    return Array.from(this.widgetRegistry.values()).filter((widget) => widget.dashboardId === dashboardId);
  }

  getWidgetById(widgetId: string) {
    return this.widgetRegistry.get(widgetId);
  }

  setWidgetChartRef(widgetId: string, chartRef: RefObject<HighchartsReact.RefObject> | RefObject<HTMLDivElement>) {
    runInAction(() => this.chartRegistry.set(widgetId, chartRef));
  }

  getWidgetChartRef(widgetId: string) {
    return this.chartRegistry.get(widgetId);
  }

  buildEmptyWidget(widgetType: WidgetType, title: string, dashboardId: string) {
    const emptyWidget: EmptyWidget = {
      widgetId: null,
      type: widgetType,
      dashboardId,
      settings: {
        title: title,
        position: { x: 0, y: 0 },
      },
    };

    return observable(emptyWidget);
  }

  addWidget(widget: Partial<WidgetViewModel | "widgetId">) {
    const widgetId = this.widgetRegistry.size.toString();

    const newWidget: WidgetViewModel = {
      ...(widget as any),
      widgetId,
    };

    runInAction(() => this.widgetRegistry.set(newWidget.widgetId, newWidget));
    return newWidget;
  }

  keyFieldName: "dashboardId" = "dashboardId";

  get(dashboardId: string): Loadable<Dashboard, "dashboardId", string> {
    // TODO: Consider implementing the loading status properly in the entire store
    // so that we can catch errors
    const board = this.getDashboardById(dashboardId);
    if (board) {
      return { ...board, loadingStatus: LoadingStatus.Loaded };
    }
    return { loadingStatus: LoadingStatus.Loading, dashboardId };
  }

  async load(dashboardId: string) {
    return await this.loadDashboardById(dashboardId);
  }
}
