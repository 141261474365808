import React from "react";
import { ReactComponent as SunIcon } from "./icons/01_sun.svg";
import { ReactComponent as SunCloudIcon } from "./icons/02_sun_cloud.svg";
import { ReactComponent as CloudIcon } from "./icons/03_cloud.svg";
import { ReactComponent as CloudLineIcon } from "./icons/04_cloud_line.svg";
import { ReactComponent as CloudRainIcon } from "./icons/09_cloud_rain.svg";
import { ReactComponent as CloudLightningIcon } from "./icons/11_cloud_lightning.svg";
import { ReactComponent as CloudSnowIcon } from "./icons/13_cloud_snow.svg";
import { ReactComponent as MistIcon } from "./icons/50_mist.svg";

// const WeatherIcons: { [K: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
const WeatherIcons = {
  // Weather API returns icon codes like "01d" or "01n"
  //   "01d": "Clear sky during the day",
  //   "01n": "Clear sky at night",
  //   "02d": "Few clouds during the day",
  //   "02n": "Few clouds at night",
  //   "03d": "Scattered clouds during the day",
  //   "03n": "Scattered clouds at night",
  //   "04d": "Broken clouds during the day",
  //   "04n": "Broken clouds at night",
  //   "09d": "Shower rain during the day",
  //   "09n": "Shower rain at night",
  //   "10d": "Rain during the day",
  //   "10n": "Rain at night",
  //   "11d": "Thunderstorm during the day",
  //   "11n": "Thunderstorm at night",
  //   "13d": "Snow during the day",
  //   "13n": "Snow at night",
  //   "50d": "Mist during the day",
  //   "50n": "Mist at night",
  //
  // Which can be used as img src like this:
  // img src={`http://openweathermap.org/img/wn/${code}@2x.png`} />
  //
  // But here is a custom list of icons
  "01d": SunIcon,
  "01n": SunIcon,
  "02d": SunCloudIcon,
  "02n": SunCloudIcon,
  "03d": CloudIcon,
  "03n": CloudIcon,
  "04d": CloudLineIcon,
  "04n": CloudLineIcon,
  "09d": CloudRainIcon,
  "09n": CloudRainIcon,
  "10d": CloudRainIcon,
  "10n": CloudRainIcon,
  "11d": CloudLightningIcon,
  "11n": CloudLightningIcon,
  "13d": CloudSnowIcon,
  "13n": CloudSnowIcon,
  "50d": MistIcon,
  "50n": MistIcon,
};

type Code = keyof typeof WeatherIcons;

const WeatherIcon = ({ code }: { code: string }) => {
  if (!Object.keys(WeatherIcons).includes(code)) {
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img src={`https://openweathermap.org/img/wn/${code}@2x.png`} alt={`${code} icon`} />;
  } else {
    return React.createElement(WeatherIcons[code as Code]);
  }
};

export default WeatherIcon;
