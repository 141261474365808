import { WidgetType } from "constants/widgetConstants";
import { MultipleValueDataSource } from "./common/value";
import { WidgetViewModel } from "./Widget";

export type StackedBarChartWidgetViewModel = WidgetViewModel<
  WidgetType.StackedBarChartWidget,
  StackedBarChartWidgetSettings
>;

export type StackedBarChartWidgetSettings = {
  dataSource?: MultipleValueDataSource | null;
};

export function isStackedBarChartWidget(widget: WidgetViewModel): widget is StackedBarChartWidgetViewModel {
  return widget.type === WidgetType.StackedBarChartWidget;
}
