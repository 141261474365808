import { WidgetType } from "constants/widgetConstants";
import { WidgetViewModel } from "./Widget";

export type WeatherWidgetViewModel = WidgetViewModel<WidgetType.WeatherWidget, WeatherWidgetSettings>;

export type WeatherWidgetSettings = {
  location: {
    label?: string;
    longitude: string;
    latitude: string;
  } | null;
  unit: UnitsType;
};

export enum UnitsType {
  Metric = "metric",
  Imperial = "imperial",
}

export function isWeatherWidget(widget: WidgetViewModel): widget is WeatherWidgetViewModel {
  return widget.type === WidgetType.WeatherWidget;
}
