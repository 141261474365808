import { MetaData } from "components/widgets/mapWidget/MetaData";
import { GeoStatus, GeoTaggedDataPoint } from "datasources/geoTaggedData/interfaces";
import L, { Map, Icon, Point } from "leaflet";
import "leaflet/dist/leaflet.css";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useWindowSize } from "react-use";
import { StackThingList } from "../common/ThingList";
import { MarkerOrange, MarkerGreen, MarkerRed } from "data/thumbnails/index";
import debounce from "lodash.debounce";
import { MapInitialView } from "constants/widgetConstants";
import { MapOptions } from "data/widgetData";

const DEFAULT_MAP_SETTINGS = {
  center: {
    lat: 59.3144,
    lng: 18.0737,
  },
  zoom: 13,
} as const;

type MapContentsProps = {
  points: GeoTaggedDataPoint[];
  width: number;
  height: number;
  readonly: boolean;
  onZoom: (points: string[]) => void;
  initialView: MapInitialView;
  initialViewOptions: MapOptions;
};

const iconWarning = new Icon({
  iconUrl: MarkerOrange,
  iconSize: new Point(21, 25),
  iconAnchor: [10.5, 25],
});

const iconError = new Icon({
  iconUrl: MarkerRed,
  iconSize: new Point(21, 25),
  iconAnchor: [10.5, 25],
});

const iconOk = new Icon({
  iconUrl: MarkerGreen,
  iconSize: new Point(21, 25),
  iconAnchor: [10.5, 25],
});

const getMarkers = (clusters: any) => {
  const markers: string[] = [];

  clusters.map((cluster: any) => {
    if (cluster._childClusters?.length) {
      const clusterMarkers = getMarkers(cluster._childClusters);

      markers.push(...clusterMarkers);
    }
    if (cluster._markers?.length) {
      markers.push(...cluster._markers.map((marker: any) => marker.options["data-id"]));
    } else {
      markers.push(cluster.options["data-id"]);
    }
  });

  return markers;
};

const MapContents = observer(
  ({ points, width, height, readonly, onZoom, initialView, initialViewOptions }: MapContentsProps) => {
    const map = useMap();

    useMapBounds(map, points, [{ width, height }], readonly, initialView, initialViewOptions);

    const onZoomOrMoveEnd = () => {
      const clusters: any[] = [];
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker && map.getBounds().contains(layer.getLatLng())) {
          clusters.push(layer);
        }
      });

      onZoom(getMarkers(clusters));
    };

    const debouncedZoomOrMoveEnd = useCallback(debounce(onZoomOrMoveEnd, 400), [points]);

    map.on("zoomend", debouncedZoomOrMoveEnd);
    map.on("moveend", debouncedZoomOrMoveEnd);

    const colorForStatus = (status?: GeoStatus) => {
      switch (status) {
        case GeoStatus.Error:
          return iconError;
        case GeoStatus.Warning:
          return iconWarning;
        case GeoStatus.Ok:
          return iconOk;
        default:
          return iconOk;
      }
    };

    return (
      <MarkerClusterGroup chunkedLoading disableClusteringAtZoom={12} spiderfyOnMaxZoom={false}>
        {points.map((p) => (
          <Marker
            data-id={p.uniqueId}
            icon={colorForStatus(p.status)}
            position={[p.lat, p.lng]}
            key={`point-${p.uniqueId}-${p.status}`}
          >
            <Popup maxHeight={300}>
              <MetaData point={p} />
              {p.stack && <StackThingList stack={p.stack}></StackThingList>}
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    );
  }
);

const useMapBounds = (
  map: Map | null,
  points: { lat: number; lng: number }[],
  deps: any[] = [],
  readonly: boolean,
  initialView: MapInitialView,
  initialViewOptions: MapOptions
) => {
  const bounds: [number, number][] = points.map((p) => [p.lat, p.lng]);
  const boundsSerialized = JSON.stringify(bounds);
  const depsSerialized = JSON.stringify(deps);
  const windowSize = useWindowSize();

  useEffect(
    () => {
      if (!map) {
        return;
      }

      if (initialView === MapInitialView.Custom) {
        map.invalidateSize();
        map.setView(
          { lat: Number(initialViewOptions.latitude), lng: Number(initialViewOptions.longitude) },
          initialViewOptions.zoom
        );
      } else {
        map.invalidateSize();
        map.fitBounds(bounds);

        if (points.length === 1) {
          map.setZoom(DEFAULT_MAP_SETTINGS.zoom);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map, windowSize, boundsSerialized, depsSerialized, initialView, initialViewOptions]
  );
};

const withLeafletContainer = (Component: any) => (props: MapContentsProps) =>
  (
    <MapContainer center={DEFAULT_MAP_SETTINGS.center} zoom={DEFAULT_MAP_SETTINGS.zoom}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Component {...props} />
    </MapContainer>
  );

export default withLeafletContainer(MapContents);
