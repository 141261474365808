export type BookmarkId = string;

export enum BookmarkType {
  Thing = "thing",
  Incident = "incident",
  Customer = "customer",
  Property = "property",
}

export type Bookmark = {
  bookmarkId: BookmarkId;
  entityType: BookmarkType;
  entityId: string;
  userId: string;
};
