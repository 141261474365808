import { ThingValue } from "datasources/singleValueData/adapters/helpers/thingValues";
import { hasData, Loadable } from "models/Loading";
import { Capability, ProductName } from "models/ProductType";
import { Thing } from "models/Thing";
import objectPath from "object-path";
import { intersection, unique } from "./arrayUtils";
import { LooseEqualityMap } from "./common";
import { htmlDecodeString } from "./stringUtils";

export function isCaptionDataThing(thing: Thing) {
  return thing.frontendProductId === ProductName.ExternalCaptionDataDevice;
}

export function areCaptionDataThings(things: Thing[]) {
  /* If one thing is a caption data thing, every thing will be a caption data thing */
  return things.some(isCaptionDataThing);
}

export function getCaptionDataMetricOptions(
  things: LooseEqualityMap<string, Loadable<Thing, "thingId", string>>,
  multiple?: boolean
) {
  const thingsWithValues = things.values().filter(hasData<Thing>);
  const capabilitiesForThings = thingsWithValues.map((thing) => thing.productType.detectedCapabilities!);

  if (capabilitiesForThings.length === 0) {
    return [];
  }

  let capabilities: Capability[];

  if (multiple) {
    /* For many metrics we let them be selected if any thing supports them */
    capabilities = capabilitiesForThings.reduce(unique);
  } else {
    /* If we're only picking one metric it should be supported by all the things */
    capabilities = capabilitiesForThings.reduce(intersection);
  }

  capabilities = capabilities.reduce((acc, capability) => {
    if (
      !acc.some(
        (c) =>
          c.name === capability.name &&
          c.type === capability.type &&
          c.label === capability.label &&
          c.unit === capability.unit
      )
    ) {
      acc.push(capability);
    }
    return acc;
  }, [] as Capability[]);

  return capabilities.map((capability) => ({
    key: capability.name,
    value: htmlDecodeString(capability.label ?? capability.name),
  }));
}

export function captionDataThingValue(thing: Thing, property: string): ThingValue {
  const capability = findMatchingCapability(thing, property);

  return {
    hasValue: true,
    name: htmlDecodeString(capability?.label! || capability?.name!),
    value: objectPath.get(thing, ["state", capability?.name || ""]),
    units: htmlDecodeString(capability?.unit!),
  };
}

function findMatchingCapability(thing: Thing, property: string) {
  return thing.productType.detectedCapabilities?.find((capability) => capability.name === property);
}
