import { ProtectedHouseholdsAPIClient } from "../services/api/ProtectedHouseholdsAPIClient";
import { SimpleStore } from "./SimpleStore";
import { ProtectedHouseholds } from "../models/ProtectedHouseholds";

type OrganisationId = string;

export class ProtectedHouseholdsStore extends SimpleStore<OrganisationId, ProtectedHouseholds, "organisationId"> {
  private client = new ProtectedHouseholdsAPIClient();

  protected async fetchFromClientForKey(organisationId: OrganisationId) {
    return (await this.client.getData(organisationId)) as ProtectedHouseholds;
  }

  protected getRegistryIndex(organisationId: OrganisationId) {
    return organisationId;
  }

  public readonly keyFieldName = "organisationId" as const;
}
