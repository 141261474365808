import TextWidget from "components/widgets/TextWidget";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { TextThumbSVG } from "../thumbnails";
import { WidgetData } from "../widgetData";

export const TextWidgetData: WidgetData<WidgetType.TextWidget, WidgetSettings> = {
  type: WidgetType.TextWidget,

  name: language.widgets.TEXT,

  hideBackground: true,

  description: {
    ...language.widgets.descriptions[WidgetType.TextWidget],
    thumbnail: TextThumbSVG,
  },

  dashboardPurposes: [DashboardPurpose.Generic, DashboardPurpose.Customer, DashboardPurpose.Stack],

  hideTitle: true,

  component: (props) => <TextWidget {...props} />,
};
