import styled from "@emotion/styled/macro";
import { LoadingState } from "components/widgets/loading/LoadingState";
import { FontSize, FontSizeMultiplier } from "constants/themeConstants";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { UnitsType, WeatherWidgetViewModel } from "models/widgets/WeatherWidget";
import { useEffect, useState } from "react";
import OpenWeatherAPIClient from "services/api/OpenWeatherAPIClient";
import language from "translations/language";
import { fallbackResponsiveUnit, responsiveUnit } from "utils/styleUtils";
import ConfigureMessage from "../WidgetConfigureMessage";
import WidgetMessage from "../WidgetMessage";
import WeatherIcon from "./weatherwidget/WeatherIcons";
import { configureOpenWeatherMapSource, WeatherReport, WeatherSource } from "./weatherwidget/WeatherSource";
import { HumidityIcon, TemperatureIcon } from "components/icons";

enum state {
  NOT_CONFIGURED = "NOT_CONFIGURED",
  LOADING = "LOADING",
  LOADED = "LOADED",
  FAILED = "FAILED",
}

type LoadedWeatherData = {
  state: state.LOADED;
  report: WeatherReport;
};

type LoadingWeatherNotConfigured = {
  state: state.NOT_CONFIGURED;
};

type LoadingWeatherData = {
  state: state.LOADING;
};

type FailedToLoadWeatherData = {
  state: state.FAILED;
  error: string;
};

type WeatherData = LoadingWeatherNotConfigured | LoadingWeatherData | LoadedWeatherData | FailedToLoadWeatherData;

interface Props {
  widget: WeatherWidgetViewModel;
  weatherSource?: WeatherSource;
}

const defaultWeatherSource: WeatherSource = configureOpenWeatherMapSource(new OpenWeatherAPIClient());

const WeatherWidget = ({ widget, weatherSource }: Props) => {
  const [weather, setWeather] = useState<WeatherData>({ state: state.NOT_CONFIGURED });

  const { location, unit } = widget.settings;

  useEffect(() => {
    const fetchWeather = async () => {
      if (!location) {
        return;
      }
      try {
        setWeather({ state: state.LOADING });
        const report = await (weatherSource || defaultWeatherSource)(location.latitude, location.longitude, unit);
        setWeather({ state: state.LOADED, report });
      } catch (error: any) {
        setWeather({ state: state.FAILED, error: error instanceof Error ? error.message : "unknown error" });
      }
    };
    fetchWeather().then();
    // Reload the weather every 10 minutes
    const interval = setInterval(fetchWeather, 1000 * 60 * 10);
    return () => clearInterval(interval);
  }, [location, unit, weatherSource]);

  if (weather.state === state.NOT_CONFIGURED) {
    return <ConfigureMessage widgetName={language.widgets.WEATHER} data-testid="weather-requires-config" />;
  }

  if (weather.state === state.LOADING) {
    return <LoadingState />;
  }

  if (weather.state === state.FAILED) {
    return <WidgetMessage data-testid="weather-loading-error">Ooops. Something went wrong.</WidgetMessage>;
  }

  const widgetTitle = toJS(widget.settings.title);
  const cityName = widgetTitle?.trim().length > 0 ? widgetTitle : weather.report.timezone.name.split("/")[1];

  const currentWeather = weather.report.current;

  return (
    <S.WeatherContainer>
      <S.WeatherTitle>
        <S.City title={cityName}>{cityName}</S.City>
      </S.WeatherTitle>

      <S.WeatherOverview>
        <S.Description data-testid="display-weather-type">{currentWeather.overview.main}</S.Description>
        <S.IconContainer>
          <WeatherIcon code={currentWeather.overview.icon} />
        </S.IconContainer>
      </S.WeatherOverview>

      <S.WeatherData>
        <S.WeatherDataMulti>
          <S.IconContainer>
            <TemperatureIcon />
          </S.IconContainer>
          <S.WeatherValue>
            <S.WeatherValueTop data-testid="display-temp">
              {currentWeather.temperature}&deg;
              {currentWeather.temperatureUnit === UnitsType.Imperial ? "F" : "C"}
            </S.WeatherValueTop>
          </S.WeatherValue>
        </S.WeatherDataMulti>
        <S.WeatherValueBottom>
          H: {currentWeather.forecastHighest}&deg;&nbsp;L: {currentWeather.forecastLowest}&deg;
        </S.WeatherValueBottom>

        <S.WeatherDataSingle>
          <S.IconContainer>
            <HumidityIcon />
          </S.IconContainer>
          <S.WeatherValueSingle data-testid="display-humidity">{currentWeather.humidity}&#x25;</S.WeatherValueSingle>
        </S.WeatherDataSingle>
      </S.WeatherData>
    </S.WeatherContainer>
  );
};

export default observer(WeatherWidget);

/**********
 Styles
 **********/

const S = {
  // prettier-ignore
  WeatherContainer: styled.div(({theme}) => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc( min(var(--widget-width), var(--widget-height)) * 0.7 * 1px );
    height: calc( min(var(--widget-width), var(--widget-height)) * 0.7 * 1px );
    aspect-ratio: 1/1;

    ${theme.typography.body1}
    
    container-type: size;
  `),
  WeatherTitle: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
  `,
  WeatherOverview: styled.div`
    display: flex;
    align-items: center;
  `,

  WeatherDataMulti: styled.div`
    display: flex;
  `,
  WeatherValue: styled.div`
    display: flex;
    margin-left: ${fallbackResponsiveUnit(FontSize.ExtraExtraSmall)};
    @supports (container-type: size) {
      margin-left: ${responsiveUnit(FontSize.ExtraExtraSmall, FontSizeMultiplier.Small)};
    }
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  `,
  WeatherData: styled.div`
    display: flex;
    align-items: inherit;
    flex-direction: column;
    text-align: center;
    margin-top: ${responsiveUnit(FontSize.ExtraExtraSmall, FontSizeMultiplier.Small)};
  `,

  WeatherDataSingle: styled.div`
    display: flex;
    margin-top: ${responsiveUnit(FontSize.ExtraExtraSmall, FontSizeMultiplier.Small)};
  `,
  WeatherValueSingle: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-left: ${fallbackResponsiveUnit(FontSize.ExtraExtraSmall)};
    font-size: ${fallbackResponsiveUnit(FontSize.Large)};

    @supports (container-type: size) {
      margin-left: ${responsiveUnit(FontSize.ExtraExtraSmall, FontSizeMultiplier.Small)};
      font-size: ${responsiveUnit(FontSize.Large, FontSizeMultiplier.Large)};
    }
  `,
  // prettier-ignore
  City: styled.div`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    
      font-size: ${fallbackResponsiveUnit(FontSize.Medium)};
      line-height: 1.33333;

      @supports (container-type: size) {
        font-size: ${responsiveUnit(FontSize.Medium, FontSizeMultiplier.Large)};
      }
    `,
  // prettier-ignore
  Description: styled.div`
    margin-right: ${fallbackResponsiveUnit(FontSize.Medium)};
    font-size: ${fallbackResponsiveUnit(FontSize.Small)};
    @supports (container-type: size) {
      margin-right: ${fallbackResponsiveUnit(FontSize.Medium)};
      font-size: ${responsiveUnit(FontSize.Small, FontSizeMultiplier.Large)};
    }
  `,
  // prettier-ignore
  WeatherValueTop: styled.div`
    font-weight: bold;
    font-size: ${fallbackResponsiveUnit(FontSize.Large)};
    
    @supports (container-type: size) {
      font-size: ${responsiveUnit(FontSize.Large, FontSizeMultiplier.Large)};
    }
  `,
  // prettier-ignore
  WeatherValueBottom: styled.div`
    font-weight: 400;
    font-size: ${fallbackResponsiveUnit(FontSize.ExtraExtraSmall)};
    margin-top: -10px;
    margin-right: ${fallbackResponsiveUnit(FontSize.ExtraExtraLarge)};
    @supports (container-type: size) {
      font-size: ${responsiveUnit(FontSize.ExtraExtraSmall, FontSizeMultiplier.Small, FontSize.Smallest)};
      margin-right: ${responsiveUnit(FontSize.ExtraExtraLarge, FontSizeMultiplier.Small)};
    }
  `,
  // prettier-ignore
  IconContainer: styled.div`
      display: inherit;
      width: ${fallbackResponsiveUnit(FontSize.ExtraLarge)};
      height: ${fallbackResponsiveUnit(FontSize.ExtraLarge)};
      
      @supports (container-type: size) {
        width: ${responsiveUnit(FontSize.ExtraLarge, FontSizeMultiplier.Large)};
        height: ${responsiveUnit(FontSize.ExtraLarge, FontSizeMultiplier.Large)};
      }
    `,
};
