export enum DashboardPurpose {
  Generic = "generic",
  Customer = "customer",
  Stack = "stack",
}

export enum DashboardTheme {
  Default = "default",
  Dark = "dark",
  DarkPale = "darkPale",
  Polygon = "polygon",
}
