import NiceModal, { antdModal, useModal } from "@ebay/nice-modal-react";
import { Button } from "components/common";
import * as Form from "components/common/form";
import Modal from "components/common/modal";
import SettingsComponents from "components/edit/SettingsComponents";
import { DashboardPurpose } from "constants/dashboardConstants";
import { getWidgetData } from "data/widgetData";
import { observer } from "mobx-react";
import type { EmptyWidget } from "models/widgets/Widget";
import { useStore } from "stores/RootStore";
import language from "translations/language";

type Props = {
  widget: EmptyWidget;
  onCancel?: () => void;
};

export const CreateWidgetModal = NiceModal.create(({ widget, onCancel }: Props) => {
  const { dashboardStore } = useStore();
  const modal = useModal();

  if (widget.dashboardId === undefined || widget.settings === undefined) {
    modal.reject("Can't find the widget");
    return null;
  }

  const widgetData = getWidgetData(widget);
  const settingsDescriptor = (widgetData ?? {}).settings;

  /* Current widget settings form state */
  const widgetSettings = (widget ?? {}).settings;

  const createWidget = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      let updatedSettings = { ...widgetSettings };

      if (widgetData?.onSave) {
        updatedSettings = widgetData.onSave(updatedSettings);
      }

      await dashboardStore.createWidget(widget.dashboardId!, {
        type: widget.type!,
        settings: { ...updatedSettings },
      });

      modal.resolve();
      modal.hide();

      /* open widgets sidebar again */
      onCancel?.();
      modal.reject();
    } catch (err) {
      console.error("Error creating empty widget: ", err);
    }
  };

  if (!settingsDescriptor) {
    return <></>;
  }

  return (
    /* antdModal helpers is the easiest way to standardize the modal props */
    <Modal
      {...antdModal(modal)}
      onCancel={() => {
        antdModal(modal).onCancel();
        // open widgets sidebar again
        onCancel?.();
        modal.reject();
      }}
    >
      <Form.Form onSubmit={createWidget}>
        <SettingsComponents
          settingsDescriptor={settingsDescriptor}
          state={widgetSettings!}
          dashboardMeta={dashboardStore.currentDashboard.metadata ?? { dashboardPurpose: DashboardPurpose.Generic }}
        />
        <Form.SubmitRow>
          <Button size="lg">{language.SAVE}</Button>
        </Form.SubmitRow>
      </Form.Form>
    </Modal>
  );
});

export default observer(CreateWidgetModal);
