import styled from "@emotion/styled/macro";
import ReactGridLayout, { Layout as ReactGridLayoutType } from "react-grid-layout";

const GridLayoutComponent = styled(ReactGridLayout)`
  transition: none !important;
  .react-grid-item {
    transition: none !important;
  }
`;

export default GridLayoutComponent;

export type Layout = ReactGridLayoutType;
