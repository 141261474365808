import { LoadingStatus } from "../constants/constants";

// The loadable type described below will always have these fields:
// {
//   loadingStatus: LoadingStatus;
//   [TKeyFieldName]: TKey
// }
// So for example Loadable<SomeThing, "someThingId", string> could look like:
// {
//   loadingStatus: "loading";
//   someThingId: "a string";
// }
export type LoadingEntity<TKeyFieldName extends string, TKey> = { [P in TKeyFieldName]: TKey } & {
  loadingStatus: LoadingStatus.Loading;
};

export type FailedToLoadEntity<TKeyFieldName extends string, TKey> = { [P in TKeyFieldName]: TKey } & {
  loadingStatus: LoadingStatus.Error;
  error: string;
};

export type LoadedEntity<T, TKeyFieldName extends string = never, TKey = never> = T & { [P in TKeyFieldName]: TKey } & {
  loadingStatus: LoadingStatus.Loaded;
};

export type ReloadingEntity<T, TKeyFieldName extends string = never, TKey = never> = T & {
  [P in TKeyFieldName]: TKey;
} & {
  loadingStatus: LoadingStatus.Reloading;
};

export type Loadable<T = {}, TIdField extends string = never, TKey = never> =
  | LoadingEntity<TIdField, TKey>
  | FailedToLoadEntity<TIdField, TKey>
  | LoadedEntity<T, TIdField, TKey>
  | ReloadingEntity<T, TIdField, TKey>;

export function hasData<T, TIdField extends string = never, TKey = never>(
  entity: Loadable<T, TIdField, TKey> | undefined | null
): entity is LoadedEntity<T, TIdField, TKey> | ReloadingEntity<T, TIdField, TKey> {
  return (
    entity != null &&
    (entity.loadingStatus === LoadingStatus.Loaded || entity.loadingStatus === LoadingStatus.Reloading)
  );
}

export function getError(entity: Loadable | undefined | null): string | undefined {
  if (!entity || entity.loadingStatus !== LoadingStatus.Error) {
    return undefined;
  }
  return entity.error;
}

export function allMapItemsHaveData<T, TIdField extends string = never, TKey = never>(
  entities: Record<any, Loadable<T, TIdField, TKey> | undefined | null>
): entities is Record<any, LoadedEntity<T, TIdField, TKey> | ReloadingEntity<T, TIdField, TKey>> {
  return entities != null && Object.values(entities).every(hasData);
}

export function getErrorsFromMap(entities: Record<any, Loadable | undefined | null>): string[] {
  return Object.values(entities)
    .map(getError)
    .filter((e) => e != null) as string[];
}

export function allArrayItemsHaveData<T, TIdField extends string = never, TKey = never>(
  dataSources: Loadable<T, TIdField, TKey>[]
): dataSources is (LoadedEntity<T, TIdField, TKey> | ReloadingEntity<T, TIdField, TKey>)[] {
  return !dataSources.map(hasData).includes(false);
}

export function getErrorsFromArray(dataSources: Loadable[]): string[] {
  return dataSources.map(getError).filter((e) => e !== undefined) as string[];
}
