import styled from "@emotion/styled/macro";
import FairtrailLink from "components/FairtrailLink";
import Pagination from "components/Pagination";
import ConfigureMessage from "components/WidgetConfigureMessage";
import WidgetMessage from "components/WidgetMessage";
import { LoadingStatus } from "constants/constants";
import { useMultipleValueFromDataSource } from "datasources/hooks";
import { TableValues, TableHeader, TableRow } from "datasources/tableData/interfaces";
import { observer } from "mobx-react-lite";
import { hasData, Loadable } from "models/Loading";
import {
  MultipleAlarmsValueSourceTypes,
  MultipleStacksValueSourceTypes,
  TableWidgetViewModel,
  ThingMetricThresholdOperator,
} from "models/widgets/TableWidget";
import { useEffect, useRef, useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { useStore } from "stores/RootStore";
import language from "translations/language";
import { ErrorState } from "./loading/ErrorState";
import { LoadingState } from "./loading/LoadingState";
import { useWidgetFrame } from "./WidgetFrame";
import { Title } from "components/common/title";
import { MoreLink } from "components/common/moreLink";
import { formatDate } from "utils/datetimeUtils";

const ALARM_COLOR = "#d00202";

interface Props {
  widget: TableWidgetViewModel;
  readonly: boolean;
}

const TableWidget = ({ widget, readonly }: Props) => {
  const { dashboardStore, thingStore } = useStore();
  const [page, setPage] = useState(0);
  const widgetFrame = useWidgetFrame();

  const data = useMultipleValueFromDataSource(widget.settings.dataSource, widget, { page }) as Loadable<TableValues>;
  const thingsByWidgetId = thingStore.thingsByWidgetId.get(widget.widgetId)!;
  const exportRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dashboardStore.setWidgetChartRef(widget.widgetId, exportRef);
  });

  useEffect(() => {
    if (!readonly) {
      setPage(0);
    }
  }, [widget, readonly]);

  if (!widget.settings.dataSource) {
    return <ConfigureMessage widgetName={language.widgets.TABLE} />;
  }

  if (data.loadingStatus === LoadingStatus.Loading) {
    return <LoadingState />;
  }

  if (data.loadingStatus === LoadingStatus.Error) {
    return <ErrorState />;
  }

  if (!hasData(data)) {
    return null;
  }

  if (data.hasAlarm) {
    widgetFrame.setColor(ALARM_COLOR);
  } else {
    widgetFrame.setColor(undefined);
  }

  if (data?.values?.every((item) => item.value === undefined)) {
    return <WidgetMessage>{language.errors.NO_DATA_TO_DISPLAY}</WidgetMessage>;
  }

  return (
    <>
      <STableContainer ref={exportRef}>
        {widget.settings.dataSource.sourceType === MultipleStacksValueSourceTypes.StackCreated ||
        widget.settings.dataSource.sourceType === MultipleAlarmsValueSourceTypes.Alarms ? (
          <>
            <Table
              title={widget.settings.title}
              tableUrl={data.url!}
              rows={data.rows || []}
              headers={data.headers!}
              icon={data.icon}
              showMoreLink={data.showMoreLink}
            />
            {data.pagination && (
              <Pagination
                currentPage={data.pagination?.page || 0}
                pageSize={data.pagination?.pageSize || 10}
                totalCount={data.pagination?.totalCount || 0}
                onChange={(newPage) => setPage(newPage)}
              />
            )}
          </>
        ) : (
          <>
            <DeprecatedTable
              tableTitle={widget.settings.title}
              url={data.url!}
              data={data}
              columns={[
                { name: language.tableWidgetColumnTitles.DATA_SOURCE, width: "40%" },
                { name: language.tableWidgetColumnTitles.UPDATED_AT, width: "40%" },
                { name: language.tableWidgetColumnTitles.STATUS, width: "20%" },
              ]}
            />
            <Pagination
              currentPage={thingsByWidgetId?.page}
              pageSize={thingsByWidgetId?.pageSize}
              totalCount={thingsByWidgetId?.totalCount}
              onChange={(newPage) => setPage(newPage)}
            />
          </>
        )}
      </STableContainer>
    </>
  );
};

export default observer(TableWidget);

interface DeprecatedTableProps {
  data: TableValues;
  columns: {
    name: string;
    width?: string;
  }[];
  tableTitle: string;
  url: string;
}

interface TableProps {
  title: string;
  headers: TableHeader[];
  rows: TableRow[];
  tableUrl?: string;
  hasAlarm?: boolean;
  icon?: JSX.Element;
  showMoreLink?: string;
}

const Table = ({ title, icon, headers, rows, tableUrl, hasAlarm = false, showMoreLink }: TableProps) => {
  return (
    <STableWrapper>
      <STableHeaderContainer>
        <FairtrailLink href={tableUrl} rebornHref={tableUrl}>
          <Title text={title} icon={icon} />
        </FairtrailLink>
        {hasAlarm && <SAlertIcon color={ALARM_COLOR} size={30} />}
      </STableHeaderContainer>

      <STableBody>
        <STable>
          <tbody>
            {headers && (
              <tr>
                {headers.map((column, i) => (
                  <STDColumnName key={i} width={column.width}>
                    {column.name}
                  </STDColumnName>
                ))}
              </tr>
            )}

            {rows.map((row, i) => {
              return (
                <tr key={`row_${i}`}>
                  {row.columns.map((col, j) => (
                    <STd title={col.label} key={`${col.title}_${i}_${j}`}>
                      <FairtrailLink href={col.href} rebornHref={col.href}>
                        {col.label}
                      </FairtrailLink>
                    </STd>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </STable>
      </STableBody>

      {showMoreLink && rows.length > 0 && <MoreLink href={showMoreLink} hrefNew={showMoreLink} />}
    </STableWrapper>
  );
};

const DeprecatedTable = ({ data, columns, tableTitle, url }: DeprecatedTableProps) => {
  return (
    <STableWrapper>
      <STableHeaderContainer>
        <FairtrailLink href={url} rebornHref={url}>
          <SH1>{tableTitle}</SH1>
        </FairtrailLink>
        {data.hasAlarm && <SAlertIcon color={ALARM_COLOR} size={30} />}
      </STableHeaderContainer>

      <STable>
        <tbody>
          <tr>
            {columns.map((column, i) => (
              <STDColumnName key={i} width={column.width}>
                {column.name}
              </STDColumnName>
            ))}
          </tr>

          {data.values?.map((dataValue, i) => {
            const formattedDate = formatDate(dataValue.updatedAt);
            const warning = !!hasWarning(Number(dataValue.value), dataValue.warningValue!, dataValue.warningOperator!);

            return (
              <tr key={dataValue.label + i}>
                <STd title={dataValue.label}>
                  <FairtrailLink href={dataValue.url} rebornHref={dataValue.url}>
                    {dataValue.label}
                  </FairtrailLink>
                </STd>

                <STd title={formattedDate}>{formattedDate}</STd>

                <STDValue title={`${dataValue.value}${dataValue.units ? ` ${dataValue.units}` : ""}`}>
                  <div>{warning && <SAlertIcon />}</div>

                  <SValueContainer hasWarning={warning}>
                    {typeof dataValue.value === "number" ? Number(dataValue.value).toFixed(2) : dataValue.value}
                    {dataValue.units}
                  </SValueContainer>
                </STDValue>
              </tr>
            );
          })}
        </tbody>
      </STable>
    </STableWrapper>
  );
};

const hasWarning = (value: number, warningValue: number, warningOperator: string) => {
  switch (warningOperator) {
    case ThingMetricThresholdOperator.GreaterThan:
      return value > warningValue;

    case ThingMetricThresholdOperator.GreaterThanEqual:
      return value >= warningValue;

    case ThingMetricThresholdOperator.LessThan:
      return value < warningValue;

    case ThingMetricThresholdOperator.LessThanEqual:
      return value <= warningValue;
  }
};

// prettier-ignore
const STableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 24px 28px;
  position: absolute;
  top: 0;
  color: ${props=>props.theme.color.primary} !important;
`;

// prettier-ignore
const STableHeaderContainer = styled.div(({ theme }) => `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
);

// prettier-ignore
const STableBody = styled.div`
  flex: 1;
`

// prettier-ignore
const STable = styled.table(({ theme }) => `
  width: 100%;
  margin: ${theme.spacing(3)} 0;
  table-layout: fixed;

  font-size: 12px;
  font-weight: 400;
`);

const STd = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px 1px;

  a {
    color: ${({ theme }) => theme.color.secondary400};
    font-weight: bold;
  }
`;

const STDValue = styled(STd)`
  padding: 5px;
  display: flex;
`;
const STDColumnName = styled.td<{ width: string | undefined }>`
  padding-top: 10px;
  padding-bottom: 10px;
  color: #b4b4b4;
  overflow-x: hidden;
  text-overflow: ellipsis;
  ${({ width }) => width && `width: ${width};`}
`;

const SAlertIcon = styled(FiAlertTriangle)`
  color: #d9534f;
  margin-right: 10px;
`;

const SH1 = styled.h1`
  /* font-size: 20px;
  font-weight: 700;
  line-height: 24px; */

  width: 100%;
  padding: 14px 13px;
  color: ${(props) => props.theme.color.text};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  border-radius: 4px;

  // waiting for a new font to use bold
  // font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

const SValueContainer = styled.div<{ hasWarning: boolean }>`
  ${({ hasWarning }) => hasWarning && `color: #d9534f;`}
  ${({ hasWarning }) => hasWarning && `background-color: rgba(240, 117, 109, 0.2);`}
  border-radius: 5px;
`;

const STableWrapper = styled.div`
  height: auto;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
