import { observer } from "mobx-react-lite";
import { Thing } from "models/Thing";
import { useEffect, useMemo, useState } from "react";
import { areCaptionDataThings } from "utils/captionDataUtils";
import { useStore } from "../../stores/RootStore";
import { SelectSetting } from "./SelectSetting";
import styled from "@emotion/styled/macro";
import { CustomerPicker } from "./CustomerPicker";
import { User } from "models/User";
import { Owner } from "models/Owner";
import language from "translations/language";

interface Props {
  value: string | string[] | null;
  settingsKey: string;
  onChange: (key: string, value: any) => void;
  multiple?: boolean;
  thingFilter?: (thing: Thing) => boolean;
  disabled?: boolean;
}

const ThingPicker = ({ value, onChange, settingsKey, multiple, thingFilter, disabled = false }: Props) => {
  const { thingStore, configStore } = useStore();
  const [isCaptionData, setIsCaptionData] = useState<boolean>(false);
  const [customer, setCustomer] = useState<User | Owner>();

  const filter = useMemo(() => {
    /** Makes sure selected thing doesn't just vanishes if you select a property */
    const thingIsSelected = (thing: Thing) => {
      if (multiple) {
        return (value as string[])?.includes(thing.thingId);
      } else {
        return thing.thingId === value;
      }
    };

    if (thingFilter) {
      return (thing: Thing) => thingIsSelected(thing) || thingFilter(thing);
    } else if (customer) {
      return (thing: Thing) => thingIsSelected(thing) || thing?.owner?.userId === customer?.userId;
    } else {
      return () => true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thingFilter, customer]) as (thing: Thing) => boolean;

  const getSelectOptions = () => {
    return thingStore.loadedThings
      .filter(filter)
      .map((thing) => ({ key: thing.thingId as string, value: thing.name }))
      .sort((a, b) => a.value.localeCompare(b.value));
  };

  const [options, setOptions] = useState<{ key: string; value: string }[]>(getSelectOptions());

  useEffect(() => {
    // TODO: We should implement a real search later on!
    thingStore.loadAll().then(() => {
      setOptions(getSelectOptions());

      if (areCaptionDataThings(thingStore.loadedThings)) {
        setIsCaptionData(true);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const onChangeValue = async (key: string, value: string | string[]) => {
    onChange(key, value);

    /* We need to get the thing by id to get /inputs (detectedCapabilities) for caption data once selected */
    if (isCaptionData && value) {
      if (multiple) {
        await thingStore.loadMany(value as string[]);
      } else {
        await thingStore.load(value as string);
      }
    }
  };

  const onFilter = (user: User | null) => {
    setCustomer(user ?? undefined);
    setTimeout(() => setOptions(getSelectOptions()), 100);
  };

  if (!!configStore.deptId) {
    return (
      <>
        <SLabel>{language.widgets.settings.THINGID}</SLabel>
        <SelectSetting
          onChange={onChangeValue}
          settingsKey={settingsKey}
          value={value}
          multiple={multiple}
          options={options}
          disabled={disabled}
        />
      </>
    );
  }

  return (
    <>
      <SFormContainer>
        <SLabel>{language.widgets.settings.FILTER_BY_PROPERTY}</SLabel>
        <SLabel>{language.widgets.settings.THINGID}</SLabel>
      </SFormContainer>

      <SFormContainer>
        <div>
          <CustomerPicker onCustomerChange={onFilter} initialCustomerId={customer?.userId} />
        </div>

        <div>
          <SelectSetting
            onChange={onChangeValue}
            settingsKey={settingsKey}
            value={value}
            multiple={multiple}
            options={options}
            disabled={disabled}
          />
        </div>
      </SFormContainer>
    </>
  );
};

export default observer(ThingPicker);

const SFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${(props) => props.theme.spacing(2)};
  flex-wrap: wrap;

  > div,
  > label {
    flex: 2;
    max-width: 50%;
  }

  > div:first-of-type,
  > label:first-of-type {
    flex: 1;
  }
`;

// prettier-ignore
const SLabel = styled.label(({ theme }) => `
  display: block;
  ${theme.typography.body2}
  font-weight: bold;
  ${theme.color.primary300}
`);
