import { useModal } from "@ebay/nice-modal-react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import InfoPopper from "components/common/InfoPopper";
import HealthDetailsModal from "components/modals/HealthDetailsModal";
import { EDIT_DASHBOARD_ROUTE } from "constants/routes";
import { FontSize } from "constants/themeConstants";
import { HealthFilterValue, PropertyMetric } from "constants/widgetConstants";
import { useIsVisible } from "hooks/useIsVisible";
import { observer } from "mobx-react-lite";
import { HealthData } from "models/HealthData";
import { HealthWidgetViewModel } from "models/widgets/HealthWidget";
import { useEffect, useState } from "react";
import { useStore } from "stores/RootStore";
import { ThemeProps } from "theme/types";
import language from "translations/language";

interface Props {
  widget: HealthWidgetViewModel;
}

const HealthWidget = ({ widget }: Props) => {
  const { healthDataStore } = useStore();
  const isVisible = useIsVisible();

  const [normal, setNormal] = useState(0);
  const [mediumRisk, setMediumRisk] = useState(0);
  const [highRisk, setHighRisk] = useState(0);
  const [all, setAll] = useState(0);
  const [noClassification, setNoClassification] = useState(0);
  const [data, setData] = useState<HealthData | null>(null);
  const theme = useTheme();

  const detailsModal = useModal(HealthDetailsModal);

  const loadData = async () => {
    await healthDataStore.load(widget.widgetId);
    const healthData = (await healthDataStore.get(widget.widgetId)) as HealthData;

    setNormal(healthData?.normal?.length || 0);
    setMediumRisk(healthData?.mediumRisk?.length || 0);
    setHighRisk(healthData?.highRisk?.length || 0);
    setNoClassification(healthData?.noClassification?.length || 0);
    setAll(
      healthData?.normal?.length +
        healthData?.mediumRisk?.length +
        healthData?.highRisk?.length +
        healthData?.noClassification?.length
    );
    setData(healthData);
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadData();
    };

    fetchData();
  }, [widget.settings]);

  const onSelectedFilter = (filter: HealthFilterValue) => {
    if (data !== null) {
      detailsModal.show({
        type: widget.settings.type,
        data: data,
        widgetId: widget.widgetId,
        selectedFilter: filter,
      });
    }
  };

  const handleInfoText = (widget: HealthWidgetViewModel) => {
    switch (widget.settings.type) {
      case PropertyMetric.Condensation:
        return language.propertyHealthWidgetInfo.condensation;

      case PropertyMetric.FuelPoverty:
        return language.propertyHealthWidgetInfo.fuelPoverty;

      case PropertyMetric.Insulation:
        return language.propertyHealthWidgetInfo.insulation;

      case PropertyMetric.Ventilation:
        return language.propertyHealthWidgetInfo.ventilation;

      default:
        return "";
    }
  };

  const title = widget.settings.title;

  const normalPercentage = all !== 0 ? (normal / all) * 100 : 0;
  const mediumRiskPercentage = all !== 0 ? (mediumRisk / all) * 100 : 0;
  const alarmRiskPercentage = all !== 0 ? (highRisk / all) * 100 : 0;
  const noClassificationPercentage = all !== 0 ? (noClassification / all) * 100 : 0;

  return (
    <SContainer>
      <SWrapper>
        <STitle>{title ? title : language.propertyMetric[widget.settings.type]}</STitle>

        {!isVisible([EDIT_DASHBOARD_ROUTE]) && (
          <SIconContainer>
            <InfoPopper
              fontSize={FontSize.ExtraSmall}
              infoText={handleInfoText(widget)}
              verticalDivider={widget.settings.type === PropertyMetric.Condensation}
              secondaryInfoText={language.propertyMetricMould.text}
            />
          </SIconContainer>
        )}
      </SWrapper>

      <SChart>
        <SNormal percentage={normalPercentage} onClick={() => onSelectedFilter(HealthFilterValue.Normal)} />

        <SWarning percentage={mediumRiskPercentage} onClick={() => onSelectedFilter(HealthFilterValue.MediumRisk)} />

        <SAlarm percentage={alarmRiskPercentage} onClick={() => onSelectedFilter(HealthFilterValue.HighRisk)} />

        <SNoClassification
          percentage={noClassificationPercentage}
          onClick={() => onSelectedFilter(HealthFilterValue.NoClassification)}
        />
      </SChart>

      <SValueContainer>
        {normal > 0 && (
          <SValue percentage={normalPercentage} color={theme.color.success} width={widget.settings.size?.width!}>
            {normal}
          </SValue>
        )}

        {mediumRisk > 0 && (
          <SValue percentage={mediumRiskPercentage} color={theme.color.warning} width={widget.settings.size?.width!}>
            {mediumRisk}
          </SValue>
        )}

        {highRisk > 0 && (
          <SValue percentage={alarmRiskPercentage} color={theme.color.error} width={widget.settings.size?.width!}>
            {highRisk}
          </SValue>
        )}

        {noClassification > 0 && (
          <SValue
            percentage={noClassificationPercentage}
            color={theme.color.neutral400}
            width={widget.settings.size?.width!}
          >
            {noClassification}
          </SValue>
        )}
      </SValueContainer>
    </SContainer>
  );
};

export default observer(HealthWidget);

// prettier-ignore
const SContainer = styled.div(({ theme }) => `
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  ${theme.spacing.p(6)}
`);

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// prettier-ignore
const SIconContainer = styled.div(({ theme }) => `
${theme.spacing.p(6)}
`);

// prettier-ignore
const STitle = styled.div(({ theme }) => `
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  ${theme.color.bg.neutral100}
  margin: 22px 0;
  text-align: center;
`);

const SChart = styled.div`
  height: 46px;
  width: 100%;
  display: flex;
`;

// prettier-ignore
const SNormal = styled.div(({ theme, percentage }: ThemeProps & { percentage: number }) => `
  background-color: ${theme.color.success};
  flex-basis: ${percentage}%;
  &:hover {
      cursor: pointer;
  }
`);

// prettier-ignore
const SWarning = styled.div(({ theme, percentage }: ThemeProps & { percentage: number }) => `
  background-color: ${theme.color.warning};
  flex-basis: ${percentage}%;
  &:hover {
    cursor: pointer;
  }
`);

// prettier-ignore
const SAlarm = styled.div(({ theme, percentage }: ThemeProps & { percentage: number }) => `
  background-color: ${theme.color.error};
  flex-basis: ${percentage}%;
  &:hover {
    cursor: pointer;
  }
`);

// prettier-ignore
const SNoClassification = styled.div(({ theme, percentage }: ThemeProps & { percentage: number }) =>`
  background-color: ${theme.color.neutral400};
  flex-basis: ${percentage}%;
  &:hover {
    cursor: pointer;
  }
`);

const SValueContainer = styled.div`
  text-align: center;
  display: flex;
`;

// prettier-ignore
const SValue = styled.div(({ color, percentage, width }: { color: string; percentage: number, width: number }) => `
  flex-basis: ${percentage}%;
  color: ${color};
  font-style: normal;
  font-weight: 450;
  font-size: ${width >= 5 ? "35px" : "20px"};
  line-height: 56px;
  margin-top: 7px;
`
);
