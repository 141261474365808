/** Added all fields I could find but some needs verification that they are set correctly */
export type User = {
  userId: string;
  name: string;
  status: string;
  primaryServiceProvider: string;
  termsAgreedDate: string;
  cellPhones: string[];
  emails: string[];
  language: string;
  devices: {
    deviceId: string;
    name: string;
    os: string;
    url: string;
  }[];
  latitude: number;
  longitude: number;
  receiveNotifications: object;
  toogleFeatures: {
    replaceProductImage: boolean;
    shareToFriends: boolean;
    shareToOrganisations: boolean;
    addStack: boolean;
    showSurveys: boolean;
  };
  organizationId: string;
  externalID: string;
  subscription: number;
  subscriptionDate: Date;
  subscriptionExpr: Date;
  reportsSent: [];
  sentReports: [];
  notificationFlags: object;
  url: string;
  rightsUrl: string;
  modified: Date;
  created: Date;
};

export function toUser(input: any): User {
  return {
    ...input,
    modified: new Date(input.modified),
    created: new Date(input.created),
    subscriptionDate: new Date(input.subscriptionDate),
    subscriptionExpr: new Date(input.subscriptionExpr),
  };
}
