import { ConnectivityMessage, KnownEventType } from "../../models/Timeline";
import { LeakAlarmType, PressureAlarmType, ThingAlarmLike, ThingAlarmType, ThingStatus } from "models/Thing";
import { DashboardPurpose, DashboardTheme } from "constants/dashboardConstants";
import {
  FilterValue,
  GaugeType,
  HealthFilterValue,
  IncidentAllTypes,
  IncidentType,
  LiveThingProperty,
  MapInitialView,
  PropertyMetric,
  SingleValueSourceTypes,
  TimeInterval,
  WidgetType,
} from "constants/widgetConstants";
import { AggregationType, ChartSourceTypes, TimeseriesType } from "models/widgets/ChartWidget";
import { MultipleValueSourceTypes } from "models/widgets/common/value";
import { GeoDataSourceTypes } from "models/widgets/MapWidget";
import { SettingsModalOptions } from "constants/constants";
import {
  MultipleAlarmsValueSourceTypes,
  MultipleStacksValueSourceTypes,
  MultipleThingsValueSourceTypes,
  ThingMetricThreshold,
  ThingMetricThresholdOperator,
} from "models/widgets/TableWidget";
import { NoClassificationReason } from "models/HealthData";
import { ThresholdColor } from "models/widgets/common/common";

const en = {
  EDIT: "Edit",
  NEW: "New",
  SAVE: "Save",
  CREATE: "Create",
  NAME: "Name",
  ADD: "Add",
  REMOVE: "Remove",
  CREATE_COPY: "Create a copy",
  MODIFIED: "Modified",
  OWNER: "Owner",
  ADDRESS: "Address",
  VIEW: "View",
  SETTINGS: "Settings",
  SHARING: "Sharing",
  COLOR: "Color",
  EMPTY: "Empty",
  BACK: "Back",
  SEARCH: "Search",
  FULLSCREEN: "Fullscreen",
  RESET: "Reset",
  RESET_THRESHOLD: "Reset threshold",

  LOGIN: "Login",
  SEND: "Send",
  EMAIL: "Email",
  LOGIN_CODE: "Login code",
  WARNING: "Warning",
  NEXT: ">",
  PREVIOUS: "<",

  LOADING: "Loading...",
  MUST_BE_CONFIGURED: (widgetName: string) => `${widgetName} widget must be configured`,

  TIME_INTERVAL: "Time interval",
  THINGS: "Devices",
  TYPE: "Type",
  ACTIONS: "Actions",
  DASHBOARDS: "Dashboards",
  THING: "Device",
  PRODUCT: "Product",
  ERRAND: "Errand",

  MIN: "Minimum",
  MAX: "Maximum",

  BETWEEN: "Between",
  BETWEEN_TO: "to",
  DAYS: "days",
  N_DAYS: (num: string) => `${num} days`,

  UNKNOWN_DEVICE: "Unknown device",

  CUSTOMER: "Customer",
  PROPERTY: "Property",
  STATUS: "Status",

  MORE: "More",

  LATITUDE: "Latitude",
  LONGITUDE: "Longitude",
  ZOOM: "Zoom",
  PREVIEW: "Preview",

  createDashboard: {
    CREATE_COPY_DASHBOARD_TITLE: (dashboardName: string) => `Create a copy of dashboard "${dashboardName}"`,
  },

  dashboardPurpose: {
    [DashboardPurpose.Generic]: "Generic Dashboard",
    [DashboardPurpose.Customer]: "Dashboard for a specific customer",
    [DashboardPurpose.Stack]: "Dashboard for a specific property",
  },

  dashboardShortPurpose: {
    [DashboardPurpose.Generic]: "Generic",
    [DashboardPurpose.Customer]: "Customer",
    [DashboardPurpose.Stack]: "Property",
  },

  dashboardSettings: {
    [SettingsModalOptions.General]: "General",
    // [SettingsModalOptions.MetricSystem]: "Metric system",
    [SettingsModalOptions.ColorTheme]: "Fullscreen color theme",
  },

  themeNames: {
    [DashboardTheme.Default]: "Light",
    [DashboardTheme.DarkPale]: "Dark",
    [DashboardTheme.Dark]: "Neon",
    [DashboardTheme.Polygon]: "Polygon",
  },

  protectedHouseholds: {
    TOTAL: "Total number",
    HIGH: "High protection",
    MEDIUM: "Medium protection",
    LOW: "Localized protection",
  },

  signedInstallations: {
    CUSTOMER_CREATED_TO_COMPLETE: "entire installation",
    CUSTOMER_CREATED_TO_FIRST_SUGGESTED_INSTALLATION: "until suggested installation time",
    REVIEWING_IMAGES: "reviewing images",
    SCHEDULING_INSTALLATION: "scheduling installation",
    TOTAL_COMPLETED_INSTALLATIONS: "Total amount of completed installations during time period",
  },

  timelineEventTypes: {
    [KnownEventType.Alarms]: "Alarms",
    [KnownEventType.Connectivity]: "Connectivity",
  },

  // TODO: DRY up the alarm names here and the first set of timelineEventSubTypes
  thingAlarms: {
    [ThingAlarmType.LargeWaterFlowAlarm]: "Large water flow",
    [ThingAlarmType.LargeWaterVolumeAlarm]: "Large water volume",
    [ThingAlarmType.LongWaterFlowAlarm]: "Long water flow",
    [ThingAlarmType.LowTempAlarm]: "Low temperature",
    [ThingAlarmType.SlowLeakage]: "Slow leakage",
    [ThingAlarmType.WaterDetected]: "Water detected",
    [ThingAlarmType.PressureAlarm]: "Pressure alarm",
    [ThingAlarmType.LeakAlarm]: "Leak alarm",
  },

  alarmTypes: {
    [LeakAlarmType.SmallLeak]: "Small leak",
    [LeakAlarmType.MediumLeak]: "Medium leak",
    [LeakAlarmType.Burst]: "Burst",
    [PressureAlarmType.PressureAlarm1]: "PressureAlarm1",
    [PressureAlarmType.PressureAlarm2]: "PressureAlarm2",
  },

  timelineEventSubTypes: {
    [ThingAlarmType.LargeWaterFlowAlarm]: "Large water flow",
    [ThingAlarmType.LargeWaterVolumeAlarm]: "Large water volume",
    [ThingAlarmType.LongWaterFlowAlarm]: "Long water flow",
    [ThingAlarmType.LowTempAlarm]: "Low temperature",
    [ThingAlarmType.SlowLeakage]: "Slow leakage",
    [ThingAlarmType.WaterDetected]: "Water detected",
    [ThingAlarmType.LeakAlarm]: "Leak alarm",
    [ConnectivityMessage.Offline]: "Offline",
    [ConnectivityMessage.Online]: "Online",
    [ConnectivityMessage.Timeout]: "Timeout",
    [ThingAlarmType.PressureAlarm]: "Pressure alarm 1",
    [ThingAlarmLike.PressureAlarm1]: "Pressure alarm 1",
    [ThingAlarmLike.PressureAlarm2]: "Pressure alarm 2",
  },

  thingProperties: {
    [LiveThingProperty.WaterPressure]: "Water pressure",
    [LiveThingProperty.Temperature]: "Temperature",
    [LiveThingProperty.WifiSignalStrength]: "Wifi signal strength",
    [LiveThingProperty.RelativeHumidity]: "Relative humidity",
    [LiveThingProperty.BatteryLevel]: "Battery level",
    [LiveThingProperty.Humidity]: "Humidity",
    [LiveThingProperty.CaptionDataAbsoluteHumidity]: "Absolute humidity",
    [LiveThingProperty.CaptionDataWME]: "WME",
  } as { [key in LiveThingProperty]: string },

  thingStatus: {
    [ThingStatus.Healthy]: "Online",
    [ThingStatus.Offline]: "Offline",
    [ThingStatus.HasAlarm]: "Has active alarm",
    [ThingStatus.Preregistered]: "Registered",
    [ThingStatus.ConfigReceived]: "Configuration received",
    [ThingStatus.ConfigFailed]: "Configuration failed",
  },

  sourceTypes: {
    "": "Nothing",
    [SingleValueSourceTypes.Thing]: "Thing",
    [SingleValueSourceTypes.IncidentStats]: "Incident Statistics",
    [MultipleStacksValueSourceTypes.StackCreated]: "Project duration",
    [MultipleValueSourceTypes.ProtectedHouseholds]: "Protected households",
    [MultipleValueSourceTypes.SignedInstallationStats]: "Installations",
    [ChartSourceTypes.ThingEventTime]: "Event times (thing)",
    [ChartSourceTypes.ThingsTimeSeries]: "Time series (thing)",
    [GeoDataSourceTypes.ThingLocations]: "Thing locations",
    [GeoDataSourceTypes.CustomerStackLocations]: "Property locations",
    [MultipleThingsValueSourceTypes.ThingMetricThreshold]: "Value threshold for things",
    [MultipleAlarmsValueSourceTypes.Alarms]: "Alarms",
    undefined: "Nothing",
  } as Record<string, string>,

  incidentTypes: {
    [IncidentType.Offline]: "Device Offline",
    [IncidentType.FailedInstallations]: "Failed Installations",
  } as { [key in IncidentType]: string },

  incidentAllTypes: {
    [IncidentAllTypes.ActiveLoss]: "Active loss",
    [IncidentAllTypes.LargeWaterFlowAlarm]: "Large water flow alarm",
    [IncidentAllTypes.LargeWaterVolumeAlarm]: "Large water volume alarm",
    [IncidentAllTypes.LongWaterFlowAlarm]: "Long water flow alarm",
    [IncidentAllTypes.LeakAlarm]: "Leak alarm",
    [IncidentAllTypes.Other]: "Other",
    [IncidentAllTypes.PressureAlarm1]: "Pressure alarm 1",
    [IncidentAllTypes.PressureAlarm2]: "Pressure alarm 2",
    [IncidentAllTypes.Slowleakage]: "Slow leakage",
    [IncidentAllTypes.Waterleakage]: "Water leakage",
    [IncidentAllTypes.Offline]: "Offline",
    [IncidentAllTypes.Wpa2]: "Wpa2",
    [IncidentAllTypes.Wifi]: "Wifi",
    [IncidentAllTypes.WaterleakageUnknown]: "Leak unknown",
    [IncidentAllTypes.PassiveLoss]: "Passive loss",
    [IncidentAllTypes.SolvedThemselves]: "Solved themselves",
    [IncidentAllTypes.WarrentyReplacement]: "Warrenty replacement",
    [IncidentAllTypes.ReturnSensor]: "Return sensor",
    [IncidentAllTypes.Test]: "Test",
    [IncidentAllTypes.FalseAlarm]: "False alarm",
    [IncidentAllTypes.Followup]: "Follow up",
    [IncidentAllTypes.FailedInstallations]: "Failed installations",
    [IncidentAllTypes.LowBattery]: "Low battery warning",
  } as { [key in IncidentAllTypes]: string },

  thingMetricThreshold: {
    [ThingMetricThreshold.Pressure]: "Pressure",
    [ThingMetricThreshold.Temperature]: "Temperature",
  } as { [key in ThingMetricThreshold]: string },

  thingMetricThresholdUnit: {
    [ThingMetricThreshold.Pressure]: "bar",
    [ThingMetricThreshold.Temperature]: "°C",
  } as { [key in ThingMetricThreshold]: string },

  thingMetricThresholdOperator: {
    [ThingMetricThresholdOperator.GreaterThan]: "Greater than",
    [ThingMetricThresholdOperator.GreaterThanEqual]: "Greater than or equal to",
    [ThingMetricThresholdOperator.LessThan]: "Less Than",
    [ThingMetricThresholdOperator.LessThanEqual]: "Less than or equal to",
  } as { [key in ThingMetricThresholdOperator]: string },

  tableWidgetColumnTitles: {
    DATA_SOURCE: "Data Source",
    UPDATED_AT: "Date",
    STATUS: "Status",
  },

  errors: {
    PROPERTY_NOT_AVAILABLE: (propertyName: string) => `${propertyName} is not available for this thing`,
    CUSTOMER_DATA_LOAD_ERROR: "Customer data could not be loaded",
    GENERIC_DATA_LOAD_ERROR: "Data could not be loaded",
    UNEXPECTED_WIDGET_ERROR: "Something went wrong loading this widget",
    NO_DATA_TO_DISPLAY_FOR_PERIOD: (period: string) => `There is no data to display for the last ${period} days`,
    NO_DATA_TO_DISPLAY: "No data to display",
    NO_METRICS_FOR_SELECTED_THINGS: "No metrics available for selected things",
    ERROR_LOADING_DATA: "There was a problem loading data for this widget",
  },

  thresholds: {
    [ThresholdColor.Green]: "Normal",
    [ThresholdColor.Yellow]: "Warning",
    [ThresholdColor.Red]: "Danger",
  } as { [key in ThresholdColor]: string },

  gaugeType: {
    [GaugeType.Classic]: "Classic",
    [GaugeType.Solid]: "Solid",
  } as { [key in GaugeType]: string },

  mapInitialView: {
    [MapInitialView.Default]: "Default",
    [MapInitialView.Custom]: "Custom",
  },

  colors: {
    RED: "Red",
    YELLOW: "Yellow",
  },

  charts: {
    line: "Line",
    scatter: "Scatter",
  },

  widgets: {
    WEATHER: "Weather",
    GAUGE: "Gauge",
    CLOCK: "Clock",
    TEST: "Test graph",
    TEXT: "Text",
    VALUE: "Value",
    STACKED_BAR_CHART: "Stacked bar chart",
    BAR_CHART: "Bar chart",
    CUSTOMER: "Customer Overview",
    TABLE: "Table",
    BOOKMARKS: "Bookmarks",
    OFFLINE: "Offline",
    TIMESERIES_CHART: "Timeseries chart",
    GENERIC_CHART: "Chart",
    MAP_WIDGET: "Map",
    TIME: "Time",
    PROPERTY_HEALTH: "Property health",
    IMAGE: "Image",

    settings: {
      DATA_SOURCE_TYPE: "Type",
      DATA_SOURCE: "Data source",
      DAY_RANGE_FROM: "From",
      DAY_RANGE_TO: "To",
      DAY_RANGE: "Open for days",
      EVENT: "Event",
      INCIDENT_TYPE: "Incident type",
      LOCATION: "Location",
      ORGANIZATION_ID: "Organization",
      RANGE: "Range",
      THING_PROPERTY: "Metric",
      THINGID: "Thing",
      THRESHOLDS: "Thresholds",
      RANGE_ALARM: "Range High Risk Level",
      RANGE_WARNING: "Range Warning Level",
      RANGE_HEALTHY: "Range Healthy",
      TIME_INTERVAL: "Time interval",
      TIMEZONE: "Timezone",
      TITLE: "Title",
      CHART_TYPE: "Chart type",
      LEFT_AXIS_METRICS: "Left axis metrics",
      RIGHT_AXIS_METRICS: "Right axis metrics",
      DISPLAY_LEFT_AXIS: "Display left axis",
      DISPLAY_RIGHT_AXIS: "Display right axis",
      INCIDENT_DURATION: "Incident period",
      ALL_THINGS: "All things",
      TEMPERATURE_UNIT: "Temperature unit",
      TIME_FORMAT: "Time format",
      TWENTY_FOUR_HOUR_CLOCK: "24 Hour Clock",
      TWELVE_HOUR_CLOCK: "12 Hour Clock",
      OPERATOR: "Operator",
      VALUE: "Value",
      FILTER_BY_PROPERTY: "Filter thing by property",
      ALL_PROPERTIES: "All properties",
      PROPERTY_METRIC: "Metric",
      GAUGE_TYPE: "Gauge type",
      AGGREGATION_TYPE: "Aggregation type",
      INITIAL_MAP_VIEW: "Initial map view",
      INITIAL_MAP_VIEW_OPTIONS: "Choose the initial center of the map and the initial map zoom level",
      IMAGE_URL: "Image URL",
    },

    timeseriesTypes: {
      [TimeseriesType.Temperature]: "Temperature",
      [TimeseriesType.Pressure]: "Pressure",
      [TimeseriesType.WaterConsumption]: "Water consumption",
      [TimeseriesType.TotalWaterConsumption]: "Total water consumption",
      [TimeseriesType.Humidity]: "Humidity",
      [TimeseriesType.AbsoluteHumidity]: "Absolute humidity",
      [TimeseriesType.PaaConcentrationRollingAverage15Min]: "PAA concentration rolling average (15 min)",
      [TimeseriesType.PaaConcentrationRollingAverage8hour]: "PAA concentration rolling average (8 hours)",
      [TimeseriesType.PaaConcentration]: "PAA concentration",
      [TimeseriesType.CaptionDataWME]: "WME",
      [TimeseriesType.CaptionDataAbsoluteHumidity]: "Absolute humidity",
      [TimeseriesType.CaptionDataExtHumidity]: "Ext. humidity",
      [TimeseriesType.CaptionDataExtTemperature]: "Ext. temperature",
    } as { [key in TimeseriesType]: string },

    descriptions: {
      [WidgetType.ChartWidget]: {
        title: "Chart",
        text: "Displays a chart with different sensors metrics and alarms",
      },
      [WidgetType.ClockWidget]: {
        title: "Clock",
        text: "Displays the current time for a selected timezone",
      },
      [WidgetType.CustomerWidget]: {
        title: "Customer Overview",
        text: "Displays contact info, device list, and device alarms for a specific customer",
      },
      [WidgetType.GaugeWidget]: {
        title: "Gauge",
        text: "Displays the current status of the different sensor metrics",
        validationErrorMessage: "Value must be greater than previous value",
      },
      [WidgetType.MapWidget]: {
        title: "Map",
        text: "Displays a map with different sensors metrics and alarms",
      },
      [WidgetType.StackedBarChartWidget]: {
        title: "Stacked Bar Chart",
        text: "Displays a bar chart with values stacked on top of each other",
      },
      [WidgetType.TextWidget]: {
        title: "Text widget",
        text: "Dynamic text block",
      },
      [WidgetType.ValueWidget]: {
        title: "Value",
        text: "Displays the current value of a chosen sensor's metrics",
      },
      [WidgetType.WeatherWidget]: {
        title: "Weather",
        text: "Displays the current weather for a specific geographical location",
      },
      [WidgetType.BarChartWidget]: {
        title: "Bar Chart",
        text: "Displays a bar chart for different metrics.",
      },
      [WidgetType.TableWidget]: {
        title: "Table",
        text: "Displays a table with different sensors metrics and alarms",
      },
      [WidgetType.BookmarksWidget]: {
        title: "Bookmarks",
        text: "Displays bookmarks of things and incidents",
      },
      [WidgetType.HealthWidget]: {
        title: "Property health",
        text: "Displays property's health characteristics",
      },
      [WidgetType.ImageWidget]: {
        title: "Image",
        text: "Displays an image from a web url",
      },
    } as { [key in WidgetType]: { title: string; text: string; validationErrorMessage?: string } },
  },

  propertyHealthWidgetInfo: {
    fuelPoverty:
      "Monitoring fuel poverty is essential for addressing affordability and comfort concerns. By identifying energy inefficiencies, property owners can help tenants reduce heating and energy costs while ensuring a comfortable living environment.",

    insulation:
      "Insulation measurement is vital for tenant comfort, energy efficiency, and preventing issues. Inadequate insulation leads to discomfort, higher energy consumption, and potential property damage. By measuring insulation levels, owners can identify areas for improvement, enhance insulation, and create a comfortable, sustainable living environment.",

    ventilation:
      "Monitoring ventilation in properties is vital for health, comfort, and sustainability. Addressing ventilation issues promptly ensures better indoor air quality, prevents moisture problems, and improves energy efficiency.",

    condensation: `Monitoring and addressing condensation risks is crucial for property maintenance. By identifying and managing condensation issues promptly, property owners can prevent moisture-related problems, preserve the property's structural integrity, and maintain a healthy living environment.
      `,
  },

  propertyMetricMould: {
    text: `Monitoring and managing mould risks is critical for property well-being. By identifying and addressing mould issues promptly, property owners can prevent health hazards, maintain the property's structural integrity, and ensure a safe and healthy living environment for tenants.
    `,
  },

  tableWidget: {
    UNKNOWN_ADDRESS: "Unknown address",
    MORE: "More",
    ALARM_TABLE_TITLE: "Latest alarms",
    STACKS_CREATED_TABLE_TITLE: "Project duration",

    projectDurationTable: {
      tableHeaders: {
        ADDRESS: "Address",
        CREATED: "Days",
      },
    },
  },

  timeIntervals: {
    [TimeInterval.TwentyFourHours]: "24h",
    [TimeInterval.ThreeDays]: "3d",
    [TimeInterval.SevenDays]: "7d",
    [TimeInterval.FourteenDays]: "14d",
    [TimeInterval.ThirtyDays]: "30d",
    [TimeInterval.SixtyDays]: "60d",
    [TimeInterval.NinetyDays]: "90d",
    [TimeInterval.OneHundredEightyDays]: "180d",
    [TimeInterval.ThreeHundredSixtyFiveDays]: "365d",
    custom: "Custom",
  },

  dashboards: {
    ALL_DASHBOARDS: "All dashboards",
    CREATE_DASHBOARD: "Create dashboard",
    ADD_WIDGET: "Add widget",
    ADD_A_WIDGET: "Add a widget",
    ADD_WIDGET_PLACEHOLDER: "--- Select widget ---",
  },

  [WidgetType.CustomerWidget]: {
    TITLE: "Contact",
    NAME: "Name",
    MOBILE: "Mobile",
    ADDRESS: "Address",
  },

  defaultTitle: {
    [WidgetType.WeatherWidget]: "New weather widget",
    [WidgetType.GaugeWidget]: "New gauge",
    [WidgetType.ClockWidget]: "New clock",
    [WidgetType.TextWidget]: "New text widget",
    [WidgetType.ValueWidget]: "New value widget",
    [WidgetType.StackedBarChartWidget]: "New stacked bar chart",
    [WidgetType.CustomerWidget]: "Contact", // this is not used atm
    [WidgetType.MapWidget]: "New map",
    [WidgetType.ChartWidget]: "New chart",
    [WidgetType.BarChartWidget]: "New bar chart",
    [WidgetType.TableWidget]: "New table",
    [WidgetType.BookmarksWidget]: "New bookmarks",
    [WidgetType.HealthWidget]: "New property health",
    [WidgetType.OfflineWidget]: "New offline widget",
    [WidgetType.ImageWidget]: "New image widget",
  } as { [key in WidgetType]: string },

  customerPicker: {
    PLACEHOLDER: "Type name, address, or phone number...",
    NO_RESULTS: "No results for",
  },

  bookmarks: {
    LAST_ERRAND: "Last errand",
    NO_ACTIVE_ERRAND: "No active errands",
    NO_BOOKMARKS: "You don't have any bookmarks",
    NOT_FOUND: "Not found",
  },

  modals: {
    confirmation: {
      YES: "Yes",
      NO: "No",
    },
    DELETE_WIDGET_MESSAGE: "Are you sure you want to delete this widget?",
    DELETE_DASHBOARD_MESSAGE: "Are you sure you want to delete this dashboard?",
  },

  shareSettings: {
    TITLE: "Share settings",
    DESCRIPTION: "Everyone can access this dashboard by link",
    COPY_LINK: "Copy link",
    COPIED: "link copied",
  },

  propertyFilters: {
    [HealthFilterValue.All || FilterValue.All]: "All",
    [HealthFilterValue.HighRisk]: "High risk",
    [HealthFilterValue.MediumRisk]: "Medium risk",
    [HealthFilterValue.Normal]: "Normal",
    [HealthFilterValue.NoClassification]: "No classification",
    [FilterValue.Alarm]: "Alarm",
    [FilterValue.Warning]: "Warning",
    [FilterValue.Healthy]: "Healthy",
  },

  propertyMetric: {
    [PropertyMetric.FuelPoverty]: "Fuel poverty (presumed)",
    [PropertyMetric.Insulation]: "Insulation",
    [PropertyMetric.Ventilation]: "Ventilation",
    [PropertyMetric.InternalMoisture]: "Internal adverse moisture events",
    [PropertyMetric.Condensation]: "Condensation/Mould risk",
  },

  noClassificationReason: {
    [NoClassificationReason.THRESHOLD]: "Thresholds incorrect",
    [NoClassificationReason.NOT_ENOUGH_DATA]: "Not enough data",
    [NoClassificationReason.MISSING_OUTDOOR_SENSOR]: "Missing outdoor sensor",
  },

  aggregationType: {
    [AggregationType.Aggregated]: "Aggregated",
    [AggregationType.NonAggregated]: "Non-aggregated",
  },
};
export default en;
