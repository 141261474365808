import { observer } from "mobx-react-lite";
import { useThingsForStack, useThingsForUser } from "stores/hooks/things";
import { Stack } from "models/Stack";
import { Thing, ThingStatus } from "models/Thing";
import { Theme, useTheme } from "@emotion/react";
import { messageForThingStatus } from "components/tooltips/things";
import FairtrailLink from "components/FairtrailLink";
import { FAIRTRAIL_REBORN_THING_ROUTE, FAIRTRAIL_THING_ROUTE } from "constants/fairtrailRoutes";
import { User } from "models/User";
import styled from "@emotion/styled/macro";
import { ThemeProps } from "theme/types";
import language from "translations/language";

//prettier-ignore
export const S = {
  Heading: styled.h3(({ theme }) => `
    ${theme.spacing.my(3)}
  `),

  Things: styled.div(({ theme }) => `
    height: 100%;
    overflow-y: auto;

    ul {
      list-style: none;
      padding-left: 0;
      margin-top: 0;
      margin-left: 0
    }

    li {
      ${theme.typography.body2}
      font-weight: bold;
      ${theme.color.text.secondary}
      ${theme.spacing.mb(1)}

      display: flex;
      justify-content: start;
      align-items: center;
    }
  `),

  Indicator: styled.span(({ theme, color }: ThemeProps & { color: string }) => `
    display: inline-block;
    ${theme.spacing.h(3)};
    ${theme.spacing.w(3)};
    flex-shrink: 0;
    margin-right: 0.5rem;

    ${color}
    border-radius: 50%;
  `),

  Li: styled.li(({ theme }) => `
    a {
      ${theme.color.text.secondary}
    }
  `),
};

export const StackThingList = observer(({ stack }: { stack: Stack }) => {
  const stackThings = useThingsForStack(stack);

  return (
    <>
      <ThingsCount things={stackThings} />
      <S.Things>
        <ul>
          {stackThings.map((thing) => (
            <ThingRow thing={thing} key={thing.thingId} />
          ))}
        </ul>
      </S.Things>
    </>
  );
});

export const UserThingList = observer(({ user }: { user: User }) => {
  const userThings = useThingsForUser(user);

  return (
    <>
      <ThingsCount things={userThings} />
      <S.Things>
        <ul>
          {userThings.map((thing) => (
            <ThingRow thing={thing} key={thing.thingId} />
          ))}
        </ul>
      </S.Things>
    </>
  );
});

export const ThingRow = ({ thing }: { thing: Thing }) => {
  const theme = useTheme();
  const statusTooltip = messageForThingStatus(thing);

  return (
    <S.Li key={thing.thingId} title={statusTooltip}>
      <S.Indicator color={colorForThingStatus(theme, thing)} title={statusTooltip} />
      <FairtrailLink
        href={FAIRTRAIL_THING_ROUTE.replace(":thingId", thing.thingId)}
        rebornHref={FAIRTRAIL_REBORN_THING_ROUTE.replace(":thingId", thing.thingId)}
      >
        {thing.name}
      </FairtrailLink>
    </S.Li>
  );
};

export const colorForThingStatus = (theme: Theme, { status }: Pick<Thing, "status">) => {
  switch (status) {
    case ThingStatus.Healthy:
      return theme.color.bg.success400;
    case ThingStatus.Offline:
      return theme.color.bg.warning400;
    case ThingStatus.HasAlarm:
      return theme.color.bg.error400;
    default:
      return theme.color.bg.neutral400;
  }
};

const ThingsCount = observer(({ things }: { things: Thing[] }) => {
  return (
    <S.Heading>
      {language.THINGS} ({things.length})
    </S.Heading>
  );
});
