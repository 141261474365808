import { observer } from "mobx-react-lite";
import styled from "@emotion/styled/macro";
import Highcharts from "highcharts";

import { ResponsiveContainer } from "recharts";
import { FontSize, Spacing } from "constants/themeConstants";
import ConfigureMessage from "components/WidgetConfigureMessage";
import { LoadingStatus } from "constants/constants";
import { LoadingState } from "components/widgets/loading/LoadingState";
import { useCategorisedValuesFromDataSource } from "datasources/hooks";
import { ErrorState } from "components/widgets/loading/ErrorState";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef } from "react";
import { useStore } from "stores/RootStore";
import { BarChartWidgetViewModel } from "models/widgets/BarChartWidget";

interface Props {
  widget: BarChartWidgetViewModel;
}

const BarChartWidget = ({ widget }: Props) => {
  const { dashboardStore } = useStore();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const data = useCategorisedValuesFromDataSource(widget.settings.dataSource);

  useEffect(() => {
    if (chartComponentRef !== null) {
      dashboardStore.setWidgetChartRef(widget.widgetId, chartComponentRef);
    }
  });

  if (!widget.settings.dataSource) return <ConfigureMessage widgetName={"language.widgets.BAR_CHART"} />;

  if (data.loadingStatus === LoadingStatus.Loading) {
    return <LoadingState />;
  }

  if (data.loadingStatus === LoadingStatus.Error) {
    return <ErrorState />;
  }

  const chartOptions: unknown = {
    chart: {
      type: "bar",
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: Object.values(data.categories).map((category) => category.label),
      title: {
        text: null,
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        overflow: "justify",
        format: `{value} ${data.units ?? ""}`,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          format: `{y} ${data.units ?? ""}`,
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        data: Object.values(data.categories).map((category) => category.value),
      },
    ],
    credits: { enabled: false },
    exporting: {
      enabled: false,
      filename: widget.settings.title,
    },
  };

  return (
    <S.Container>
      <HighchartsReact
        ref={chartComponentRef}
        containerProps={{ style: { height: "90%", width: "100%" } }}
        highcharts={Highcharts}
        options={chartOptions}
      />
    </S.Container>
  );
};
export default observer(BarChartWidget);

const S = {
  Container: styled.div`
    display: flex;
    width: 100%;
    padding: 0 32px 16px 32px;
    align-items: flex-start;
    height: calc(100% - 64px); // 64px = Header height + bottom widget padding
  `,
  // prettier-ignore
  BarContainer: styled(ResponsiveContainer)<{ currentThemeName: string }>(({ theme, currentThemeName } ) => `
    max-width: 74px;

    text {
      fill: ${currentThemeName.toLowerCase().includes("dark") ? theme.color.neutral : theme.color.primary};
    }
  `),
  // prettier-ignore
  LabelsContainer: styled(ResponsiveContainer)(({ theme }) => `
    margin-left: 24px;

    svg {
      overflow: visible;
    }

    text {
      fill: ${theme.color.primary};
    }
  `),
  TotalContainer: styled.div`
    margin-left: auto;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
  `,
  // prettier-ignore
  TotalWrapper: styled.div(({ theme }) => `
    padding-left: ${Spacing.s4};
    padding-top: ${Spacing.s4};
    ${theme.color.bg.neutral100}
    
    z-index: 1;
    flex-direction: column-reverse;
    display: flex;
  `
  ),
  TotalText: styled.p`
    margin: 0;
    text-align: right;
    font-weight: bold;
    font-size: ${FontSize.XXXL};
    line-height: ${FontSize.XXXL};
  `,
  LabelText: styled.p`
    margin: 0;
    text-align: right;
    font-weight: normal;
    font-size: ${FontSize.Medium};
    line-height: ${FontSize.Large};
    margin-bottom: ${Spacing.s1};
  `,
};
