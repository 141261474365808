import { FC, PropsWithChildren } from "react";
import styled from "@emotion/styled/macro";
import { FiAlertTriangle } from "react-icons/fi";

interface Props {
  noIcon?: boolean;
}

/** Just a simple component to display messages the same way accross widgets  */
const WidgetMessage: FC<PropsWithChildren<Props>> = ({ children, noIcon, ...props }) => (
  <S.MessageContainer>
    <S.Message>
      {!noIcon && (
        <S.MessageIconWrapper>
          <FiAlertTriangle />
        </S.MessageIconWrapper>
      )}
      <S.MessageText {...props}>{children}</S.MessageText>
    </S.Message>
  </S.MessageContainer>
);

export default WidgetMessage;

const S = {
  Container: styled.div`
    display: flex;
    height: 80%;
    width: 100%;
    justify-content: center;
    align-items: center;
  `,
  // prettier-ignore
  MessageContainer: styled.div(({ theme }) => `
    ${theme.typography.body1}
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    ${theme.color.text.primary}

    * {
      color: inherit;
    }
  `),
  Message: styled.div`
    display: flex;
    align-items: center;
  `,
  // prettier-ignore
  MessageIconWrapper: styled.div(({ theme }) => `
    margin-right: 0.5em;
    flex-shrink: 0;
    font-size: 1.1em;
    display: flex;
    align-items: center;
  `),
  // prettier-ignore
  MessageText: styled.span(({ theme }) => `
    ${theme.typography.body1}
    text-align: left;
  `),
};
