import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { FontSize, FontSizeMultiplier } from "../../constants/themeConstants";
import styled from "@emotion/styled/macro";
import { responsiveUnit, fallbackResponsiveUnit } from "../../utils/styleUtils";
import { ClockWidgetViewModel } from "models/widgets/ClockWidget";
import { UnitsType } from "models/widgets/ClockWidget";

interface Props {
  widget: ClockWidgetViewModel;
}

const ClockWidget = ({ widget }: Props) => {
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  const getFormattedTime = () => {
    let date = dayjs(value);

    if (widget.settings.timezone) {
      date = date.tz(widget.settings.timezone);
    }

    if (widget.settings.timeFormat === UnitsType.Twelve) {
      return date.format("h:mm A");
    } else {
      return date.format("HH:mm");
    }
  };

  const getFormattedDate = () => {
    if (widget.settings.timezone) {
      return dayjs(value)
        .tz(widget.settings.timezone ?? "")
        .format("ll");
    } else {
      return dayjs(value).format("ll");
    }
  };

  const settingsTitle = widget.settings.title;
  const titleOrTimezone = settingsTitle?.trim().length > 0 ? settingsTitle : widget.settings.timezone;

  return (
    <S.ClockContainer>
      {titleOrTimezone && (
        <S.TimezoneContainer>
          <S.Timezone>{titleOrTimezone}</S.Timezone>
        </S.TimezoneContainer>
      )}
      <S.TimeContainer>{getFormattedTime()}</S.TimeContainer>
      {widget.settings.size?.height! > 2 && <S.Date>{getFormattedDate()}</S.Date>}
    </S.ClockContainer>
  );
};

export default observer(ClockWidget);

const S = {
  ClockContainer: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 1.21;

    font-size: ${fallbackResponsiveUnit(FontSize.Large)};
    @supports (container-type: size) {
      font-size: ${responsiveUnit(FontSize.Large, FontSizeMultiplier.Small)};
    }

    ${"container-type"}: size;
  `,
  TimezoneContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    font-size: ${fallbackResponsiveUnit(FontSize.Small)};
    @supports (container-type: size) {
      font-size: ${responsiveUnit(FontSize.Small, FontSizeMultiplier.Small)};
    }
  `,
  Timezone: styled.p`
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  TimeContainer: styled.div`
    font-weight: bold;

    font-size: ${fallbackResponsiveUnit(FontSize.ExtraExtraLarge)};
    @supports (container-type: size) {
      font-size: ${responsiveUnit(FontSize.ExtraExtraLarge, FontSizeMultiplier.Small)};
    }
  `,
  Date: styled.div`
    font-weight: normal;

    font-size: ${fallbackResponsiveUnit(FontSize.Small)};
    @supports (container-type: size) {
      font-size: ${responsiveUnit(FontSize.Small, FontSizeMultiplier.Small)};
    }
  `,
};
