import dayjs from "dayjs";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { DataInterval, LoadingStatus } from "constants/constants";
import { Loadable } from "models/Loading";
import { Timeseries } from "models/Timeseries";
import { fromISOString } from "utils/datetimeUtils";
import { BulkItemSource } from "./interfaces";
import { VisualisationsProxyAPIClient } from "services/api/VisualisationsProxyAPIClient";
import { TimeInterval, TimeRangeType } from "constants/widgetConstants";
import { AggregationType, DateRangeOrInterval } from "models/widgets/ChartWidget";

type UnixTime = number;

type TimeseriesKey = {
  thingId: string;
  dateRangeOrInterval: DateRangeOrInterval;
  metrics: string[];
  aggregationType: AggregationType;
};

export class TimeseriesStore implements BulkItemSource<TimeseriesKey, Timeseries, never> {
  private visualisationsProxyAPIClient = new VisualisationsProxyAPIClient();

  private timeseriesRegistry = new Map<string, Loadable<Timeseries>>();

  private visualisationsQueryMap: Record<
    AggregationType,
    (thingId: string, from: number, to: number, interval: DataInterval, metrics: string[]) => Promise<any>
  > = {
    [AggregationType.Aggregated]: (
      thingId: string,
      from: number,
      to: number,
      interval: DataInterval,
      metrics: string[]
    ) => this.visualisationsProxyAPIClient.aggregationQuery(thingId, from, to, interval, metrics),
    [AggregationType.NonAggregated]: (
      thingId: string,
      from: number,
      to: number,
      interval: DataInterval,
      metrics: string[]
    ) => this.visualisationsProxyAPIClient.nonAggregationQuery(thingId, from, to, interval, metrics),
  };

  constructor() {
    makeAutoObservable(this);
  }

  async loadTimeseriesForThing(
    thingId: string,
    dateRangeOrInterval: DateRangeOrInterval,
    metrics: string[],
    aggregationType: AggregationType
  ) {
    const index = this.getRegistryIndex(thingId, dateRangeOrInterval, metrics, aggregationType);

    const existingTimeseries = this.timeseriesRegistry.get(index);

    if (
      existingTimeseries &&
      [LoadingStatus.Loading, LoadingStatus.Reloading].includes(existingTimeseries.loadingStatus)
    ) {
      // Something else already triggered the load so no need to do it again
      return;
    }

    if (existingTimeseries && existingTimeseries.loadingStatus === LoadingStatus.Loaded) {
      runInAction(() =>
        this.timeseriesRegistry.set(index, {
          ...existingTimeseries,
          loadingStatus: LoadingStatus.Reloading,
        })
      );
    } else {
      runInAction(() =>
        this.timeseriesRegistry.set(index, {
          loadingStatus: LoadingStatus.Loading,
        })
      );
    }

    const dataInterval = this.getDataInterval(dateRangeOrInterval.interval, aggregationType);

    try {
      const visualisationsQuery = this.visualisationsQueryMap[aggregationType];

      const response = await visualisationsQuery(
        thingId,
        this.toUnixtimeRange(dateRangeOrInterval).from,
        this.toUnixtimeRange(dateRangeOrInterval).to,
        dataInterval,
        metrics
      );

      const index = this.getRegistryIndex(thingId, dateRangeOrInterval, metrics, aggregationType);

      runInAction(() =>
        this.timeseriesRegistry.set(index, {
          ...response,
          loadingStatus: LoadingStatus.Loaded,
        })
      );
    } catch (error: any) {
      runInAction(() =>
        this.timeseriesRegistry.set(index, {
          loadingStatus: LoadingStatus.Error,
          error: error.message,
        })
      );
    }
  }

  getLoadedTimeseriesForThings(
    thingIds: string[],
    dateRangeOrInterval: DateRangeOrInterval,
    metrics: string[],
    aggregationType: AggregationType
  ) {
    const timeseries = thingIds.reduce((acc: Record<string, Timeseries>, thingId) => {
      const ts = this.getTimeseriesForThing(thingId, dateRangeOrInterval, metrics, aggregationType);

      if (ts && [LoadingStatus.Loaded, LoadingStatus.Reloading].includes(ts.loadingStatus)) {
        acc[thingId] = ts as Timeseries;
      }

      return acc;
    }, {});

    return timeseries;
  }

  getTimeseriesForThing(
    thingId: string,
    dateRangeOrInterval: DateRangeOrInterval,
    metrics: string[],
    aggregationType: AggregationType
  ) {
    return toJS(
      this.timeseriesRegistry.get(this.getRegistryIndex(thingId, dateRangeOrInterval, metrics, aggregationType))
    );
  }

  getTimeseriesForThings(
    thingIds: string[],
    dateRangeOrInterval: DateRangeOrInterval,
    metrics: string[],
    aggregationType: AggregationType
  ): Record<string, Loadable<Timeseries>> {
    return thingIds.reduce((acc, thingId) => {
      const ts = this.getTimeseriesForThing(thingId, dateRangeOrInterval, metrics, aggregationType);

      acc[thingId] = ts ?? { loadingStatus: LoadingStatus.Loading };
      return acc;
    }, {} as Record<string, Loadable<Timeseries>>);
  }

  private toUnixtimeRange(dateRangeOrInterval: DateRangeOrInterval): { from: UnixTime; to: UnixTime } {
    if (dateRangeOrInterval.type === TimeRangeType.TimeInterval) {
      const numberInTimeInterval = Number.parseInt(dateRangeOrInterval.interval ?? "0");
      const from = dayjs().subtract(
        numberInTimeInterval,
        (dateRangeOrInterval.interval ?? "").replace(numberInTimeInterval.toString(), "") as dayjs.ManipulateType
      );
      const today = Date.now();

      return {
        from: from.toDate().getTime() / 1000,
        to: today / 1000,
      };
    } else {
      const parsedTo = fromISOString(dateRangeOrInterval.dateRange.to);
      const parsedFrom = fromISOString(dateRangeOrInterval.dateRange.from);

      const today = new Date();
      const weekAgo = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);

      const from = parsedFrom ? parsedFrom.getTime() / 1000 : weekAgo.getTime() / 1000;
      const to = parsedTo ? parsedTo.getTime() / 1000 : today.getTime() / 1000;

      return {
        from: from,
        to: to,
      };
    }
  }

  private getRegistryIndex(
    thingId: string,
    dateRangeOrInterval: DateRangeOrInterval,
    metrics: string[],
    aggregationType: AggregationType
  ) {
    const dataRangeId =
      dateRangeOrInterval.type === TimeRangeType.TimeInterval
        ? dateRangeOrInterval.interval
        : "" + dateRangeOrInterval.dateRange.from + dateRangeOrInterval.dateRange.to;

    return `${thingId} + ${dataRangeId} + ${metrics.join(",")} + ${aggregationType}`;
  }

  readonly keyFieldName = undefined as never;

  get(key: TimeseriesKey): Loadable<Timeseries, never, TimeseriesKey> {
    return (
      this.getTimeseriesForThing(key.thingId, key.dateRangeOrInterval, key.metrics, key.aggregationType) ?? {
        loadingStatus: LoadingStatus.Loading,
      }
    );
  }

  async loadMany(keys: TimeseriesKey[]): Promise<void> {
    for (const key of keys) {
      await this.loadTimeseriesForThing(key.thingId, key.dateRangeOrInterval, key.metrics, key.aggregationType);
    }
  }

  getDataInterval(timeInterval: TimeInterval | null, aggregationType: AggregationType) {
    let dataInterval;

    if (aggregationType === AggregationType.Aggregated) {
      switch (timeInterval) {
        case TimeInterval.TwentyFourHours:
        case TimeInterval.ThreeDays:
          dataInterval = DataInterval.OneHour;
          break;

        case TimeInterval.SevenDays:
        case TimeInterval.FourteenDays:
          dataInterval = DataInterval.TwoHours;
          break;

        case TimeInterval.ThirtyDays:
        case TimeInterval.SixtyDays:
        case TimeInterval.NinetyDays:
          dataInterval = DataInterval.SixHours;
          break;

        case TimeInterval.OneHundredEightyDays:
        case TimeInterval.ThreeHundredSixtyFiveDays:
          dataInterval = DataInterval.TwelveHours;
          break;

        default:
          dataInterval = DataInterval.OneHour;
      }
    } else {
      switch (timeInterval) {
        case TimeInterval.TwentyFourHours:
        case TimeInterval.ThreeDays:
        case TimeInterval.SevenDays:
          dataInterval = DataInterval.NoInterval;
          break;

        case TimeInterval.FourteenDays:
        case TimeInterval.ThirtyDays:
          dataInterval = DataInterval.OneHour;
          break;

        case TimeInterval.SixtyDays:
        case TimeInterval.NinetyDays:
          dataInterval = DataInterval.TwoHours;
          break;

        case TimeInterval.OneHundredEightyDays:
        case TimeInterval.ThreeHundredSixtyFiveDays:
          dataInterval = DataInterval.SixHours;
          break;

        default:
          dataInterval = DataInterval.OneHour;
      }
    }

    return dataInterval;
  }
}
