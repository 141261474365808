import { TimeInterval } from "./widgetConstants";
import { AggregationType } from "../models/widgets/ChartWidget";

/*
 * Actions are things that the parent can send to the iframe (this
 * Events are things that the iframe (this) can send to the parent
 */

export enum VisualisationIframeAction {
  SetGraphInterval = "setGraphInterval",
  SetMetrics = "setMetrics",
  SetAggregationType = "setAggregationType",
}

export enum VisualisationIframeEventType {
  NavigateTo = "navigateTo",
}

export interface VisualisationIframeCommand {
  action: VisualisationIframeAction;
  payload: any;
}

export interface VisualisationIframeSetGraphIntervalCommand extends VisualisationIframeCommand {
  action: VisualisationIframeAction.SetGraphInterval;
  payload: { interval: TimeInterval };
}

export interface VisualisationIframeSetMetricsCommand extends VisualisationIframeCommand {
  action: VisualisationIframeAction.SetMetrics;
  payload: { metrics: string[] };
}

export interface VisualisationIframeSetAggregationTypeCommand extends VisualisationIframeCommand {
  action: VisualisationIframeAction.SetAggregationType;
  payload: { aggregationType: AggregationType };
}

export interface VisualisationIframeEvent {
  action: VisualisationIframeEventType;
  payload: any;
}

export interface VisualisationIframeNavigateToEvent extends VisualisationIframeEvent {
  action: VisualisationIframeEventType.NavigateTo;
  payload: { url: string };
}
