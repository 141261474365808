// *****************************************************************************
// basePalette must implement the Palette type but DONT'T TYPE IT
// Because the actual Palette keys are inferred from this exact Palette config
//
// DON'T DO: export const basePalette: Palette = {
// *****************************************************************************
export const basePalette = {
  // To Type check uncomment the line below
  // export const BaseColorPalette: Palette = {
  primary: {
    default: "#43525A",
    200: "#B4B4B4", // Table Headers, Icons
    300: "#89949B", // Table Content text
    400: "#43525A", // Titles, Body Text
  },
  secondary: {
    default: "#007EFF",
    400: "#1976D2", // Links, Secondary Chart Lines
  },
  // Neutral is for the Background
  neutral: {
    default: "#E9F0F5", // Page Background color
    100: "#FFFFFF", // Widget Background, Button text
    300: "#E9F0F5", // Page Background
    400: "#E1E1E1", // Chart Background, Table Highlight
    700: "#959fa5", // Button Background
    "700n": "#FFFFFF", // Button Text
  },
  success: {
    default: "#7ABD7E",
    400: "#7ABD7E", // Accent buttons
  },
  warning: {
    default: "#FFB54C",
    400: "#FFB54C", // Warning Text, Warning Widgets
  },
  error: {
    default: "#FF6961",
    300: "#ffb7b4",
    400: "#FF6961", // Errors, Danger Text, Danger Buttons
  },
  chart: {
    default: "#58E166",
    high: "#FF83DC",
    medium: "#6BEBE3",
    low: "#90C3FF",
    100: "#90C3FF",
    200: "#58E166",
    300: "#6BEBE3",
    400: "#FF83DC",
    500: "#007EFF",
    // 600: "#D9534F",
    // 700: "#F0AD4E",
    // 800: "##5CB85C",
  },
};
