import { basePalette } from "./palettes/base";
import { darkPalette } from "./palettes/dark";
import { darkPalePalette } from "./palettes/darkPale";
import { polygonPalette } from "./palettes/polygon";
import type { ThemeConfig } from "./types";
import { DashboardTheme } from "constants/dashboardConstants";

/************************************
 * This is a primary theme config. It should be considered as a primary source of truth for the app styles.
 * All the theme related types are inferred from this object.
 ************************************/

// TODO:
// 1. Breakpoints
// 2. Typography: Font variant = Font size, Font family, font weight, line height, letter spacing
// 3. Shadows
// 4. Radius
// 5. ZIndex
//
// const baseTheme = {
const baseTheme: ThemeConfig = {
  spacing: 4,
  borderRadius: {
    sm: "3px",
    md: "6px",
  },
  typography: {
    default: {
      fontFamily: "Lato, Arial, Helvetica, sans-serif",
      fontSize: 16,
      lineHeight: 24,
      fontWeight: 400,
    },
    body: {
      "1": {
        fontSize: 16,
        lineHeight: 24,
        letterSpacing: "-0.02em",
      },
      "2": {
        fontSize: 14,
        lineHeight: 24,
      },
      "3": {
        fontSize: 12,
        lineHeight: 16,
      },
      "4": {
        fontSize: 10,
        lineHeight: 16,
      },
    },
    title: {
      "1": {
        fontSize: 61,
        lineHeight: 80,
        fontWeight: 700,
      },
      "2": {
        fontSize: 49,
        lineHeight: 64,
        fontWeight: 700,
      },
      "3": {
        fontSize: 39,
        lineHeight: 48,
      },
      "4": {
        fontSize: 25,
        lineHeight: 32,
      },
      "5": {
        fontSize: 20,
        lineHeight: 24,
      },
      "6": {
        fontSize: 18,
        lineHeight: 24,
        fontWeight: 700,
      },
    },
  },
  palette: basePalette,
  shadow: {
    sm: "-2px -2px 6px #C8D0D6, 2px 2px 6px #C8D0D6",
    dropdown: "-2px -2px 6px rgba(0, 0, 0, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.1)",
    focus: "0px 0px 5px #007EFF",
  },
};

const darkTheme = {
  ...baseTheme,
  palette: {
    ...darkPalette,
  },
  shadow: {
    sm: "0px 0px 3px rgba(0, 0, 0, 0.3)",
    dropdown: "-2px -2px 6px rgba(0, 0, 0, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.1)",
    focus: "0px 0px 5px #007EFF",
  },
};

const darkPaleTheme = {
  ...baseTheme,
  palette: {
    ...darkPalePalette,
  },
  shadow: {
    sm: "0px 0px 3px rgba(0, 0, 0, 0.3)",
    dropdown: "-2px -2px 6px rgba(0, 0, 0, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.1)",
    focus: "0px 0px 5px #007EFF",
  },
};

const polygonTheme = {
  ...baseTheme,
  palette: {
    ...polygonPalette,
  },
  shadow: {
    sm: "-2px -2px 6px #EAEDF6, 2px 2px 6px #EAEDF6",
    dropdown: "-2px -2px 6px rgba(0, 0, 0, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.1)",
    focus: "0px 0px 5px #009FE3",
  },
};

export const themes: Record<DashboardTheme, ThemeConfig> = {
  default: baseTheme,
  dark: darkTheme,
  darkPale: darkPaleTheme,
  polygon: polygonTheme,
};

export const theme = { ...baseTheme };
