import { FAIRTRAIL_WEB_HOST, FAIRTRAIL_REBORN_WEB_HOST } from "config/config";

const prefix = "/#/admin";
export const FAIRTRAIL_DASHBOARDS_ROUTE = `${FAIRTRAIL_WEB_HOST}${prefix}/dashboards`;
export const FAIRTRAIL_DASHBOARD_ROUTE = `${FAIRTRAIL_WEB_HOST}${prefix}/dashboards/:dashboardId`;
export const FAIRTRAIL_USER_ROUTE = `${FAIRTRAIL_WEB_HOST}${prefix}/users/:userId`;
export const FAIRTRAIL_THING_ROUTE = `${FAIRTRAIL_WEB_HOST}${prefix}/things/:thingId`;
export const FAIRTRAIL_STACK_ROUTE = `${FAIRTRAIL_WEB_HOST}${prefix}/stacks/:stackId`;
export const FAIRTRAIL_INCIDENTS_ROUTE = `${FAIRTRAIL_WEB_HOST}${prefix}/tickets`;
export const FAIRTRAIL_INCIDENT_ROUTE = `${FAIRTRAIL_WEB_HOST}${prefix}/tickets/:incidentId`;
export const FAIRTRAIL_PRODUCT_ROUTE = `${FAIRTRAIL_WEB_HOST}${prefix}/products/:productId`;
export const FAIRTRAIL_BOOKMARKS_ROUTE = `${FAIRTRAIL_WEB_HOST}${prefix}/bookmarks`;

export const FAIRTRAIL_REBORN_USER_ROUTE = `${FAIRTRAIL_REBORN_WEB_HOST}/customers/:userId`;
export const FAIRTRAIL_REBORN_THING_ROUTE = `${FAIRTRAIL_REBORN_WEB_HOST}/things/:thingId`;
export const FAIRTRAIL_REBORN_STACK_ROUTE = `${FAIRTRAIL_REBORN_WEB_HOST}/properties/:stackId`;
export const FAIRTRAIL_REBORN_INCIDENT_ROUTE = `${FAIRTRAIL_REBORN_WEB_HOST}/errands/:incidentId`;
export const FAIRTRAIL_REBORN_INCIDENTS_ROUTE = `${FAIRTRAIL_REBORN_WEB_HOST}/errands`;
export const FAIRTRAIL_REBORN_INCIDENTS_OFFLINE_ROUTE = `${FAIRTRAIL_REBORN_WEB_HOST}/errands/offline`;
export const FAIRTRAIL_REBORN_PRODUCT_ROUTE = `${FAIRTRAIL_REBORN_WEB_HOST}/products/:productId`;
export const FAIRTRAIL_REBORN_BOOKMARKS_ROUTE = `${FAIRTRAIL_REBORN_WEB_HOST}/bookmarks`;
