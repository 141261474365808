import { FairtrailHttpClient } from "../FairtrailHttpClient";
import { Incident, IncidentStats, IncidentStatus } from "../../models/IncidentStats";
import { DayRange } from "components/edit/DayRangePicker";
import { IncidentType } from "constants/widgetConstants";

export const NO_DAY_RANGE = { from: 1, to: 1 };

enum SortOrder {
  Ascending = "asc",
  Descending = "desc",
}

export type GetIncidentsQuery = {
  page?: number;
  size?: number;
  q?: string;
  sortBy?: string;
  sortOrder?: SortOrder;
  incidentType?: string;
  status?: IncidentStatus | IncidentStatus[];
  userId?: string | string[];
};

class IncidentsAPIClient extends FairtrailHttpClient {
  async getIncidentStats<T extends IncidentType>(type: T, dayRange?: DayRange): Promise<IncidentStats<T>> {
    const response = await this.get({
      path: this.pathFor(type),
      authenticated: true,
      query: {
        gte: dayRange?.to,
        lt: dayRange?.from,
        size: 1000,
        page: 0,
      },
    });

    return {
      count: parseInt(response.headers["totalcount"]!),
      items: response.data.result ?? response.data,
      type,
      dayRange: { to: dayRange?.to ?? NO_DAY_RANGE.to, from: dayRange?.from ?? NO_DAY_RANGE.from },
    };
  }

  private pathFor(type: IncidentType): string {
    switch (type) {
      case IncidentType.Offline:
        return `/incident/incidents/filter/offline`;
      case IncidentType.FailedInstallations:
        return `/incident/incidents/filter`;
      case IncidentType.Alarms:
        return `/incident/incidents/filter/alarms`;
    }
  }

  async getIncidentById(incidentId: string): Promise<Incident> {
    const response = await this.get({
      path: `/incident/incidents/${incidentId}`,
      authenticated: true,
    });

    return response.data;
  }

  async getIncidents({
    page = 0,
    size = 10,
    sortOrder = SortOrder.Descending,
    sortBy = "created",
    incidentType,
    status,
    userId,
  }: GetIncidentsQuery): Promise<{
    incidents: Incident[];
    totalCount: number;
  }> {
    const response = await this.get({
      path: "/incident/incidents",
      query: {
        sortOrder: sortOrder === SortOrder.Ascending ? "1" : "-1",
        sort: sortBy,
        size,
        page,
        incidentType,
        status: status ? (typeof status === "string" ? status : status.join(",")) : IncidentStatus.InProgress,
        userId,
      },
      authenticated: true,
    });

    return {
      incidents: response.data,
      totalCount: response.headers["totalcount"],
    };
  }

  async getIncidentsOfflineCount({
    page = 0,
    size = 1000,
    gte,
    lt,
  }: {
    page?: number;
    size?: number;
    gte: number;
    lt: number;
  }): Promise<number> {
    const response = await this.get({
      path: "/incident/incidents/filter/offline",
      query: {
        size,
        page,
        gte,
        lt,
      },
      authenticated: true,
    });

    return response.data.length;
  }
}

export default IncidentsAPIClient;
