/** @jsxImportSource @emotion/react */
import { DetailedHTMLProps, InputHTMLAttributes, useMemo } from "react";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled/macro";
import language from "translations/language";

type InputProps = Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "size"> & {
  size?: "sm" | "md" | "lg";
  imagePreview?: boolean;
  validationError?: boolean;
};

export const Input = ({
  size = "md",
  disabled,
  style,
  value,
  defaultValue,
  placeholder,
  onChange,
  imagePreview = false,
  validationError,
  ...props
}: InputProps) => {
  const theme = useTheme();

  const baseCSS = useMemo(() => {
    return css`
      box-sizing: border-box;
      width: 100%;
      margin: 0 !important;

      ${theme.typography.body2}
      ${theme.color.text.primary}
      background-color: ${theme.color.neutral100};

      height: ${theme.spacing(8)};
      ${theme.spacing.px(2)}
      padding-top: calc(${theme.spacing(2)} - 1px);
      padding-bottom: calc(${theme.spacing(2)} - 1px);
      padding-right: 2px;

      border: solid 1px ${theme.color.neutral700};
      border-radius: ${theme.borderRadius.md};

      &:focus {
        outline: none;
        border-color: ${theme.color.secondary400};
        box-shadow: ${theme.shadow.focus};
      }

      &::placeholder {
        ${theme.color.text.primary300}
      }
    `;
  }, [theme]);

  const sizeCSS = useMemo(
    () => css`
      height: ${theme.spacing(size === "sm" ? 8 : size === "md" ? 10 : 12)};
    `,
    [size, theme]
  );

  const disabledCSS = useMemo(() => {
    return (
      disabled &&
      css`
        opacity: 0.5;
        cursor: not-allowed;

        &:hover {
          opacity: 0.5;
        }
      `
    );
  }, [disabled]);

  const validationErrorCSS = useMemo(() => {
    return (
      validationError &&
      css`
        border-color: ${theme.color.error};
      `
    );
  }, [validationError, theme]);

  return (
    <>
      <input
        style={style}
        disabled={disabled}
        css={[baseCSS, sizeCSS, disabledCSS, validationErrorCSS]}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      />
      {imagePreview && (
        <>
          <S.ImageLabel>{language.PREVIEW}</S.ImageLabel>
          <S.Image src={defaultValue as string} />
        </>
      )}
    </>
  );
};

// prettier-ignore
const S = {
  ImageLabel: styled.div(({theme}) =>`
  ${theme.spacing.mt(8)}
  ${theme.typography.body2}
  ${theme.color.text.primary}
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`),
  Image: styled.img(({ theme }) => `
  ${theme.spacing.m(8)}
  max-height: 100%;
  max-width: 100%;
`),
};
