import { WidgetViewModel } from "models/widgets/Widget";
import { FontSize } from "../constants/themeConstants";

export const responsiveUnit = (value: FontSize, multiplier: number = 1, min?: string) => {
  const val = Number(value.split("px")[0]) * multiplier;
  const valStr = `${val}cqmin`;

  if (!min) {
    return valStr;
  }

  return `clamp(${min}, ${valStr}, ${valStr})`;
};

export const fallbackResponsiveUnit = (value: FontSize, multiplier: number = 1) => {
  // WidgetFrame component is observing the dimensions of its outer container
  // and updating the --widget-width and --widget-height CSS variables
  //
  // This is a hack to make the widget scale with the container
  // All modern browsers should use the `container-type` and `cq...` units
  const FIREFOX_MAGIC_NUMBER = 0.0056;
  const val = Number(value.split("px")[0]) * multiplier * FIREFOX_MAGIC_NUMBER;

  return `calc( min(var(--widget-width), var(--widget-height)) * ${val} * 1px )`;
};

type WidgetHeightToVWConfig = {
  0: string;
} & {
  [key: number]: string;
};

export const widgetHeightToVW = (widget: WidgetViewModel, config: WidgetHeightToVWConfig) => {
  const height = widget.settings.size?.height ?? widget.dimensions?.height;

  const heightThresholds = Object.keys(config)
    .map((key) => Number(key))
    .sort((a, b) => a - b);

  const heightThreshold = heightThresholds.find((key) => key >= height);
  if (!heightThreshold) {
    const last = heightThresholds[heightThresholds.length - 1];
    return config[last];
  }

  const previous = heightThresholds[heightThresholds.indexOf(heightThreshold) - 1];
  return config[previous];
};
