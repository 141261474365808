import { SettingsType } from "constants/constants";
import { titleSetting } from "data/commonSettings";
import { SettingsDescriptor } from "data/widgetData";
import { UnitsType } from "models/widgets/WeatherWidget";
import language from "translations/language";

const temperatureUnits = [
  { value: "Fahrenheit", key: UnitsType.Imperial },
  { value: "Celsius", key: UnitsType.Metric },
];

export const weatherWidgetSettings: SettingsDescriptor = {
  title: titleSetting,
  location: {
    type: SettingsType.Location,
    label: language.widgets.settings.LOCATION,
    default: null,
  },
  unit: {
    type: SettingsType.Select,
    label: language.widgets.settings.TEMPERATURE_UNIT,
    default: UnitsType.Metric,
    multiple: false,
    options: temperatureUnits,
  },
};
