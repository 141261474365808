import { AccessType, DashboardActivity, GLOBAL, WidgetActivity } from "constants/permissionConstants";
import { Dashboard } from "models/Dashboard";
import { rootStore } from "stores/RootStore";

export function hasActivity(activity: DashboardActivity | WidgetActivity) {
  const user = rootStore.authStore.authUser;
  return user?.activities.includes(GLOBAL) || user?.activities.includes(activity);
}

export function hasPermission(dashboard: Dashboard, accessType: AccessType) {
  const user = rootStore.authStore.authUser;

  if (!user || !dashboard) {
    return false;
  }

  return user?.activities.includes(GLOBAL) || dashboard.acl[accessType].includes(`u:${user.userId}`);
}

export function canUpdateDashboard(dashboardOrDashboardId: Dashboard | string) {
  const dashboard =
    typeof dashboardOrDashboardId === "string"
      ? rootStore.dashboardStore.getDashboardById(dashboardOrDashboardId)
      : dashboardOrDashboardId;

  return hasPermission(dashboard!, AccessType.Write) && hasActivity(DashboardActivity.UpdateDashboard);
}

export function canDeleteDashboard(dashboardOrDashboardId: Dashboard | string) {
  const dashboard =
    typeof dashboardOrDashboardId === "string"
      ? rootStore.dashboardStore.getDashboardById(dashboardOrDashboardId)
      : dashboardOrDashboardId;

  return hasPermission(dashboard!, AccessType.Delete) && hasActivity(DashboardActivity.DeleteDashboard);
}
