export type HealthData = {
  highRisk: HealthDataPoint[];
  mediumRisk: HealthDataPoint[];
  normal: HealthDataPoint[];
  noClassification: HealthNoDataPoint[];
};

export type HealthDataPoint = {
  stackId: string;
  value: number;
  thingId: string;
  timestamp: Date;
};

type HealthNoDataPoint = {
  stackId: string;
  reason: NoClassificationReason;
};

export enum NoClassificationReason {
  THRESHOLD = "THRESHOLD",
  NOT_ENOUGH_DATA = "NOT_ENOUGH_DATA",
  MISSING_OUTDOOR_SENSOR = "MISSING_OUTDOOR_SENSOR",
}
