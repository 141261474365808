import styled from "@emotion/styled/macro";
import HomePageDashboard from "components/homePageDashboard/HomePageDashboard";
import { observer } from "mobx-react-lite";

/** Sidebar width of admin-web-reborn */
const SIDEBAR_WIDTH = 256;

const HomePageDashboardPage = () => {
  return (
    <SContainer>
      <ContentContainer>
        <HomePageDashboard />
      </ContentContainer>
      <PagePadding />
    </SContainer>
  );
};

export default observer(HomePageDashboardPage);

const SContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PagePadding = styled.div`
  flex: 1;
  flex-shrink: 1;
  width: 100%;
  max-width: ${SIDEBAR_WIDTH}px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  overflow-y: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
