import { SettingsType } from "constants/constants";
import { PropertyMetric } from "constants/widgetConstants";
import { titleSetting } from "data/commonSettings";
import { SettingsDescriptor } from "data/widgetData";
import { HealthWidgetSettings } from "models/widgets/HealthWidget";
import language from "translations/language";

const typeUnits = (Object.keys(PropertyMetric) as Array<keyof typeof PropertyMetric>).map((metric) => ({
  value: language.propertyMetric[PropertyMetric[metric]],
  key: PropertyMetric[metric],
}));

export const HEALTH_WIDGET_HIGH_RISK_DEFAULT_MIN = 0;
export const HEALTH_WIDGET_HIGH_RISK_DEFAULT_MAX = 100;

export const HEALTH_WIDGET_MEDIUM_RISK_DEFAULT_MIN = -3;
export const HEALTH_WIDGET_MEDIUM_RISK_DEFAULT_MAX = 0;

export const HEALTH_WIDGET_NORMAL_DEFAULT_MIN = -100;
export const HEALTH_WIDGET_NORMAL_DEFAULT_MAX = -3;

export const healthWidgetSettings: SettingsDescriptor = {
  title: titleSetting,
  type: {
    type: SettingsType.Select,
    label: language.widgets.settings.PROPERTY_METRIC,
    multiple: false,
    options: typeUnits,
  },
  organizationId: {
    type: SettingsType.Organization,
    label: language.widgets.settings.ORGANIZATION_ID,
    multiple: false,
  },
  thresholds: {
    type: SettingsType.Nested,
    label: language.widgets.settings.THRESHOLDS,
    hidden: (settings: HealthWidgetSettings) => settings.type !== PropertyMetric.Condensation,
    settings: {
      highRisk: {
        type: SettingsType.Range,
        label: language.widgets.settings.RANGE_ALARM,
        default: {
          min: HEALTH_WIDGET_HIGH_RISK_DEFAULT_MIN,
          max: HEALTH_WIDGET_HIGH_RISK_DEFAULT_MAX,
        },
      },
      mediumRisk: {
        type: SettingsType.Range,
        label: language.widgets.settings.RANGE_WARNING,
        default: {
          min: HEALTH_WIDGET_MEDIUM_RISK_DEFAULT_MIN,
          max: HEALTH_WIDGET_MEDIUM_RISK_DEFAULT_MAX,
        },
      },
      normal: {
        type: SettingsType.Range,
        label: language.widgets.settings.RANGE_HEALTHY,
        default: {
          min: HEALTH_WIDGET_NORMAL_DEFAULT_MIN,
          max: HEALTH_WIDGET_NORMAL_DEFAULT_MAX,
        },
      },
    },
  },
};
