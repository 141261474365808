import { ConnectivityMessage, KnownEventType } from "../../models/Timeline";
import { LeakAlarmType, PressureAlarmType, ThingAlarmLike, ThingAlarmType, ThingStatus } from "models/Thing";
import { SettingsModalOptions } from "constants/constants";
import { DashboardPurpose, DashboardTheme } from "constants/dashboardConstants";
import {
  FilterValue,
  GaugeType,
  HealthFilterValue,
  IncidentAllTypes,
  IncidentType,
  LiveThingProperty,
  MapInitialView,
  PropertyMetric,
  SingleValueSourceTypes,
  TimeInterval,
  WidgetType,
} from "constants/widgetConstants";
import { AggregationType, ChartSourceTypes, TimeseriesType } from "models/widgets/ChartWidget";
import { MultipleValueSourceTypes } from "models/widgets/common/value";
import { GeoDataSourceTypes } from "models/widgets/MapWidget";
import {
  MultipleAlarmsValueSourceTypes,
  MultipleStacksValueSourceTypes,
  MultipleThingsValueSourceTypes,
  ThingMetricThreshold,
  ThingMetricThresholdOperator,
} from "models/widgets/TableWidget";
import { NoClassificationReason } from "models/HealthData";
import { ThresholdColor } from "models/widgets/common/common";

const sv = {
  EDIT: "Redigera",
  NEW: "Ny",
  SAVE: "Spara",
  CREATE: "Skapa",
  NAME: "Namn",
  ADD: "Lägg till",
  REMOVE: "Ta bort",
  CREATE_COPY: "Skapa en kopia",
  MODIFIED: "Redigerad",
  OWNER: "Ägare",
  ADDRESS: "Adress",
  VIEW: "Visa",
  SETTINGS: "Inställningar",
  SHARING: "Delning",
  COLOR: "Färg",
  EMPTY: "Tom",
  BACK: "Tillbaka",
  SEARCH: "Sök",
  FULLSCREEN: "Helskärm",
  RESET: "Återställa",
  RESET_THRESHOLD: "Återställ tröskelvärde",

  LOGIN: "Logga in",
  SEND: "Skicka",
  EMAIL: "E-post",
  LOGIN_CODE: "Inloggningskod",
  WARNING: "Varning",
  NEXT: ">",
  PREVIOUS: "<",

  LOADING: "Laddar...",
  MUST_BE_CONFIGURED: (widgetName: string) => `${widgetName}-widget måste konfigureras`,

  TIME_INTERVAL: "Tidsintervall",
  THINGS: "Saker",
  TYPE: "Typ",
  ACTIONS: "Åtgärder",
  DASHBOARDS: "Dashboards",
  THING: "Saker",
  PRODUCT: "Produkt",
  ERRAND: "Ärende",

  MIN: "Minimum",
  MAX: "Maximum",
  BETWEEN: "Mellan",
  BETWEEN_TO: "och",
  DAYS: "dagar",
  N_DAYS: (num: string) => `${num} dagar`,

  UNKNOWN_DEVICE: "Okänt enhet",

  CUSTOMER: "Kund",
  PROPERTY: "Fastighet",
  STATUS: "Status",

  MORE: "Mer",

  LATITUDE: "Latitude",
  LONGITUDE: "Longitud",
  ZOOM: "Zoom",
  PREVIEW: "Förhandsgranska",

  createDashboard: {
    CREATE_COPY_DASHBOARD_TITLE: (dashboardName: string) => `Skapa en kopia av dashboard "${dashboardName}"`,
  },

  dashboardPurpose: {
    [DashboardPurpose.Generic]: "Generisk dashboard",
    [DashboardPurpose.Customer]: "Dashboard för en specifik kund",
    [DashboardPurpose.Stack]: "Dashboard för en specifik fastighet",
  },

  dashboardShortPurpose: {
    [DashboardPurpose.Generic]: "Generisk",
    [DashboardPurpose.Customer]: "Kund",
    [DashboardPurpose.Stack]: "Fastighet",
  },

  dashboardSettings: {
    [SettingsModalOptions.General]: "Allmänt",
    // [SettingsModalOptions.MetricSystem]: "Metriska systemet",
    [SettingsModalOptions.ColorTheme]: "Helskärmsfärgtema",
  },

  themeNames: {
    [DashboardTheme.Default]: "Ljust",
    [DashboardTheme.DarkPale]: "Mörkt",
    [DashboardTheme.Dark]: "Neon",
    [DashboardTheme.Polygon]: "Polygon",
  },

  protectedHouseholds: {
    TOTAL: "Total number",
    HIGH: "High protection",
    MEDIUM: "Medium protection",
    LOW: "Localised protection",
  },

  signedInstallations: {
    CUSTOMER_CREATED_TO_COMPLETE: "Hela installationen",
    CUSTOMER_CREATED_TO_FIRST_SUGGESTED_INSTALLATION: "Till första installation är bokad",
    REVIEWING_IMAGES: "Granska bilder",
    SCHEDULING_INSTALLATION: "Schemaläggning av installation",
    TOTAL_COMPLETED_INSTALLATIONS: "Totalt antal färdiga installationer",
  },

  timelineEventTypes: {
    [KnownEventType.Alarms]: "Larm",
    [KnownEventType.Connectivity]: "Uppkoppling",
  },

  // TODO: DRY up the alarm names here and the first set of timelineEventSubTypes
  thingAlarms: {
    [ThingAlarmType.LargeWaterFlowAlarm]: "Stort vattenflöde",
    [ThingAlarmType.LargeWaterVolumeAlarm]: "Stor vattenmängd",
    [ThingAlarmType.LongWaterFlowAlarm]: "Långt vattenflöde",
    [ThingAlarmType.LowTempAlarm]: "Låg temperatur",
    [ThingAlarmType.SlowLeakage]: "Långsam läcka",
    [ThingAlarmType.WaterDetected]: "Vatten upptäckt",
    [ThingAlarmType.PressureAlarm]: "Trycklarm",
    [ThingAlarmType.LeakAlarm]: "Alarm för läcka",
  },

  alarmTypes: {
    [LeakAlarmType.SmallLeak]: "Liten läcka",
    [LeakAlarmType.MediumLeak]: "Medium läcka",
    [LeakAlarmType.Burst]: "Stor läcka",
    [PressureAlarmType.PressureAlarm1]: "Trycklarm1",
    [PressureAlarmType.PressureAlarm2]: "Trycklarm2",
  },

  timelineEventSubTypes: {
    [ThingAlarmType.LargeWaterFlowAlarm]: "Stort vattenflöde",
    [ThingAlarmType.LargeWaterVolumeAlarm]: "Stor vattenmängd",
    [ThingAlarmType.LongWaterFlowAlarm]: "Långt vattenflöde",
    [ThingAlarmType.LowTempAlarm]: "Låg temperatur",
    [ThingAlarmType.SlowLeakage]: "Långsam läcka",
    [ThingAlarmType.WaterDetected]: "Vatten upptäckt",
    [ThingAlarmType.PressureAlarm]: "Trycklarm 1",
    [ThingAlarmLike.PressureAlarm1]: "Trycklarm 1",
    [ThingAlarmLike.PressureAlarm2]: "Trycklarm 2",
    [ThingAlarmType.LeakAlarm]: "Alarm för läcka",
    [ConnectivityMessage.Offline]: "Offline",
    [ConnectivityMessage.Online]: "Online",
    [ConnectivityMessage.Timeout]: "Timeout",
  },

  thingProperties: {
    [LiveThingProperty.WaterPressure]: "Vattentryck",
    [LiveThingProperty.Temperature]: "Temperatur",
    [LiveThingProperty.WifiSignalStrength]: "Wifi-signalstyrka",
    [LiveThingProperty.RelativeHumidity]: "Relativ luftfuktighet",
    [LiveThingProperty.BatteryLevel]: "Batterinivå",
    [LiveThingProperty.Humidity]: "Luftfuktighet",
    [LiveThingProperty.CaptionDataAbsoluteHumidity]: "Absolut luftfuktighet",
    [LiveThingProperty.CaptionDataWME]: "WME",
  } as { [key in LiveThingProperty]: string },

  thingStatus: {
    [ThingStatus.Healthy]: "Online",
    [ThingStatus.Offline]: "Offline",
    [ThingStatus.HasAlarm]: "Har aktiva larm",
    [ThingStatus.Preregistered]: "Registrerad",
    [ThingStatus.ConfigReceived]: "Konfiguration mottagen",
    [ThingStatus.ConfigFailed]: "Konfiguration misslyckades",
  },

  sourceTypes: {
    "": "Ingen",
    [SingleValueSourceTypes.Thing]: "Sak",
    [SingleValueSourceTypes.IncidentStats]: "Incidentstatistik",
    [MultipleStacksValueSourceTypes.StackCreated]: "Projektlänge",
    [MultipleValueSourceTypes.ProtectedHouseholds]: "Skyddade hushåll",
    [MultipleValueSourceTypes.SignedInstallationStats]: "Installationer",
    [ChartSourceTypes.ThingEventTime]: "Händelsetider (sak)",
    [ChartSourceTypes.ThingsTimeSeries]: "Tidsserie (sak)",
    [GeoDataSourceTypes.ThingLocations]: "Sakplatser",
    [GeoDataSourceTypes.CustomerStackLocations]: "Fastighetsplatser",
    [MultipleThingsValueSourceTypes.ThingMetricThreshold]: "Tröskelvärde för saker",
    [MultipleAlarmsValueSourceTypes.Alarms]: "Larm",
  } as Record<string, string>,

  incidentTypes: {
    [IncidentType.Offline]: "Enhet offline",
    [IncidentType.FailedInstallations]: "Misslyckade installationer",
  } as { [key in IncidentType]: string },

  incidentAllTypes: {
    [IncidentAllTypes.ActiveLoss]: "Active loss",
    [IncidentAllTypes.LargeWaterFlowAlarm]: "Plötslig vattenförbrukning",
    [IncidentAllTypes.LargeWaterVolumeAlarm]: "Hög vattenförbrukning",
    [IncidentAllTypes.LongWaterFlowAlarm]: "Lång vattenförbrukning",
    [IncidentAllTypes.LeakAlarm]: "Läckage",
    [IncidentAllTypes.Other]: "Annat",
    [IncidentAllTypes.PressureAlarm1]: "Tryck 1",
    [IncidentAllTypes.PressureAlarm2]: "Tryck 2",
    [IncidentAllTypes.Slowleakage]: "Långsamt vattenläckage",
    [IncidentAllTypes.Waterleakage]: "Vattenläckage",
    [IncidentAllTypes.Offline]: "Offline",
    [IncidentAllTypes.Wpa2]: "Wpa2",
    [IncidentAllTypes.Wifi]: "Wifi",
    [IncidentAllTypes.WaterleakageUnknown]: "Vattenläckage okänt",
    [IncidentAllTypes.PassiveLoss]: "Passive loss",
    [IncidentAllTypes.SolvedThemselves]: "Problem löst",
    [IncidentAllTypes.WarrentyReplacement]: "Garanti ersättning",
    [IncidentAllTypes.ReturnSensor]: "Sensor tillbaka",
    [IncidentAllTypes.Test]: "Test",
    [IncidentAllTypes.FalseAlarm]: "Falsk alarm",
    [IncidentAllTypes.Followup]: "Uppföljning",
    [IncidentAllTypes.FailedInstallations]: "Misslyckade installationer",
    [IncidentAllTypes.LowBattery]: "Svag batteri varning",
  } as { [key in IncidentAllTypes]: string },

  thingMetricThreshold: {
    [ThingMetricThreshold.Pressure]: "Tryck",
    [ThingMetricThreshold.Temperature]: "Temperatur",
  } as { [key in ThingMetricThreshold]: string },

  thingMetricThresholdUnit: {
    [ThingMetricThreshold.Pressure]: "bar",
    [ThingMetricThreshold.Temperature]: "°C",
  } as { [key in ThingMetricThreshold]: string },

  thingMetricThresholdOperator: {
    [ThingMetricThresholdOperator.GreaterThan]: "Större än",
    [ThingMetricThresholdOperator.GreaterThanEqual]: "Större än eller lika med",
    [ThingMetricThresholdOperator.LessThan]: "Mindre än",
    [ThingMetricThresholdOperator.LessThanEqual]: "Mindre än eller lika med",
  } as { [key in ThingMetricThresholdOperator]: string },

  tableWidgetColumnTitles: {
    DATA_SOURCE: "Datakälla",
    UPDATED_AT: "Datum",
    STATUS: "Status",
  },

  thresholds: {
    [ThresholdColor.Green]: "Normal",
    [ThresholdColor.Yellow]: "Varning",
    [ThresholdColor.Red]: "Farligt",
  } as { [key in ThresholdColor]: string },

  gaugeType: {
    [GaugeType.Classic]: "Klassisk",
    [GaugeType.Solid]: "Solid",
  } as { [key in GaugeType]: string },

  mapInitialView: {
    [MapInitialView.Default]: "Standard",
    [MapInitialView.Custom]: "Beställnings",
  },

  colors: {
    RED: "Röd",
    YELLOW: "Gul",
  },

  charts: {
    line: "Linje",
    scatter: "Spridning",
  },

  errors: {
    PROPERTY_NOT_AVAILABLE: (propertyName: string) => `${propertyName}  är inte tillgänglig för denna sak`,
    CUSTOMER_DATA_LOAD_ERROR: "Kunde inte ladda kunddata",
    GENERIC_DATA_LOAD_ERROR: "Data kunde inte laddas",
    UNEXPECTED_WIDGET_ERROR: "Oväntat widgetfel",
    NO_METRICS_FOR_SELECTED_THINGS: "Inga mätvärden för valda saker",
    NO_DATA_TO_DISPLAY_FOR_PERIOD: (period: string) => `Det finns ingen data att visa för de senaste ${period} dagarna`,
    NO_DATA_TO_DISPLAY: "Inga data att visa",
    ERROR_LOADING_DATA: "Fel vid laddning av data",
  },

  widgets: {
    WEATHER: "Väder",
    GAUGE: "Mätare",
    CLOCK: "Klocka",
    TEST: "Testgraf",
    TEXT: "Text",
    VALUE: "Värde",
    STACKED_BAR_CHART: "Staplat stapeldiagram",
    BAR_CHART: "Stapeldiagram",
    CUSTOMER: "Kund",
    TABLE: "Tabell",
    BOOKMARKS: "Bokmärken",
    OFFLINE: "Offline",
    TIMESERIES_CHART: "Tidsseriegraf",
    GENERIC_CHART: "Graf",
    MAP_WIDGET: "Karta",
    TEMPERATURE: "Temperatur",
    WATER_CONSUMPTION: "Vattenförbrukning",
    HUMIDITY: "Luftfuktighet",
    PRESSURE: "Tryck",
    TIME: "Tid",
    PROPERTY_HEALTH: "Fastighetstillstånd",
    IMAGE: "Bild",

    settings: {
      DATA_SOURCE_TYPE: "Typ",
      DATA_SOURCE: "Mätarens datakälla",
      DAY_RANGE_FROM: "Från",
      DAY_RANGE_TO: "Till",
      DAY_RANGE: "Dagar",
      EVENT: "Händelsetyp",
      INCIDENT_TYPE: "Incidenttyp",
      LOCATION: "Plats",
      ORGANIZATION_ID: "Organisation",
      RANGE: "Intervall",
      THING_PROPERTY: "Egenskap",
      THINGID: "Sak",
      THRESHOLDS: "Tröskelvärden",
      RANGE_ALARM: "Range High Risk Level",
      RANGE_WARNING: "Range Warning Level",
      RANGE_HEALTHY: "Range Healthy",
      TIME_INTERVAL: "Tidsintervall",
      TIMEZONE: "Tidszon",
      TITLE: "Titel",
      CHART_TYPE: "Graftyp",
      LEFT_AXIS_METRICS: "Vänster axel mätvärden",
      RIGHT_AXIS_METRICS: "Höger axel mätvärden",
      DISPLAY_LEFT_AXIS: "Visa vänster axel",
      DISPLAY_RIGHT_AXIS: "Visa höger axel",
      INCIDENT_DURATION: "Incidentperiod",
      ALL_THINGS: "Alla saker",
      TEMPERATURE_UNIT: "Temperaturenhet",
      TIME_FORMAT: "Tidsformat",
      TWENTY_FOUR_HOUR_CLOCK: "24-timmarsklocka",
      TWELVE_HOUR_CLOCK: "12-timmarsklocka",
      OPERATOR: "Operator",
      VALUE: "Värde",
      FILTER_BY_PROPERTY: "Filtrera på fastighet",
      ALL_PROPERTIES: "Alla fastigheter",
      PROPERTY_METRIC: "Metrik",
      GAUGE_TYPE: "Typ",
      AGGREGATION_TYPE: "Aggregeringstyp",
      INITIAL_MAP_VIEW: "Initial kartvy",
      INITIAL_MAP_VIEW_OPTIONS: "Välj den initiala mitten av kartan och kartzoomnivån",
      IMAGE_URL: "Bild URL",
    },

    timeseriesTypes: {
      [TimeseriesType.Temperature]: "Temperatur",
      [TimeseriesType.Pressure]: "Tryck",
      [TimeseriesType.WaterConsumption]: "Vattenförbrukning",
      [TimeseriesType.TotalWaterConsumption]: "Total vattenförbrukning",
      [TimeseriesType.Humidity]: "Luftfuktighet",
      [TimeseriesType.AbsoluteHumidity]: "Absolut luftfuktighet",
      [TimeseriesType.PaaConcentrationRollingAverage15Min]: "Rullande medelvärde för PAA-koncentration (15 min)",
      [TimeseriesType.PaaConcentrationRollingAverage8hour]: "Rullande medelvärde för PAA-koncentration (8 tim)",
      [TimeseriesType.PaaConcentration]: "PAA-koncentration",
      [TimeseriesType.CaptionDataWME]: "WME",
      [TimeseriesType.CaptionDataAbsoluteHumidity]: "Absolut luftfuktighet",
      [TimeseriesType.CaptionDataExtHumidity]: "Ext. luftfuktighet",
      [TimeseriesType.CaptionDataExtTemperature]: "Ext. temperatur",
    } as { [key in TimeseriesType]: string },

    descriptions: {
      [WidgetType.ChartWidget]: {
        title: "Graf",
        text: "Visar en graf med olika sensorers mätvärden och larm",
      },
      [WidgetType.ClockWidget]: {
        title: "Klocka",
        text: "Visar aktuell tid för en vald tidszon",
      },
      [WidgetType.CustomerWidget]: {
        title: "Kundöversikt",
        text: "Visar kontaktinfo, enhetslista och enhetslarm för en specifik kund",
      },
      [WidgetType.GaugeWidget]: {
        title: "Mätare",
        text: "Visar aktuell status för olika sensorers mätvärden",
        validationErrorMessage: "Värdet måste vara större än föregående värde",
      },
      [WidgetType.MapWidget]: {
        title: "Karta",
        text: "Visar en karta med olika sensorers mätvärden och larm",
      },
      [WidgetType.StackedBarChartWidget]: {
        title: "Staplad stapelgraf",
        text: "Visar en stapelgraf med värden staplade ovanpå varandra",
      },
      [WidgetType.TextWidget]: {
        title: "Text widget",
        text: "Dynamiskt textblock",
      },
      [WidgetType.ValueWidget]: {
        title: "Värde",
        text: "Visar aktuellt värde för vald sensors mätvärden",
      },
      [WidgetType.WeatherWidget]: {
        title: "Väder",
        text: "Visar aktuellt väder för en specifik geografisk plats",
      },
      [WidgetType.BarChartWidget]: {
        title: "Stapeldiagram",
        text: "Visar ett stapeldiagram med olika sensorers mätvärden och larm",
      },
      [WidgetType.TableWidget]: {
        title: "Tabell",
        text: "Visar en tabell med olika seonsor mätvärden och alarm",
      },
      [WidgetType.BookmarksWidget]: {
        title: "Bokmärken",
        text: "Visar bokmärken för saker och incidenter",
      },
      [WidgetType.HealthWidget]: {
        title: "Fastighetshälsa",
        text: "Visar egendomens hälsoegenskaper",
      },
      [WidgetType.ImageWidget]: {
        title: "Bild",
        text: "Visar en bild från en URL",
      },
    } as { [key in WidgetType]: { title: string; text: string; validationErrorMessage?: string } },
  },

  propertyHealthWidgetInfo: {
    fuelPoverty:
      "Att övervaka bränslefattigdom är avgörande för att ta itu med överkomliga problem och komfortproblem. Genom att identifiera energiineffektivitet kan fastighetsägare hjälpa hyresgästerna att minska uppvärmnings- och energikostnaderna samtidigt som de säkerställer en bekväm boendemiljö",

    insulation:
      "Isoleringsmätning är avgörande för hyresgästens komfort, energieffektivitet och förebyggande av problem. Otillräcklig isolering leder till obehag, högre energiförbrukning och potentiell egendomsskada. Genom att mäta isoleringsnivåer kan ägare identifiera områden för förbättring, förbättra isoleringen och skapa en bekväm, hållbar livsmiljö.",

    ventilation:
      "Övervakning av ventilation i fastigheter är avgörande för hälsa, komfort och hållbarhet. Att snabbt åtgärda ventilationsproblem säkerställer bättre inomhusluftkvalitet, förhindrar fuktproblem och förbättrar energieffektiviteten.",

    condensation: `Att övervaka och hantera kondensrisker är avgörande för fastighetsunderhåll. Genom att snabbt identifiera och hantera kondensproblem kan fastighetsägare förebygga fuktrelaterade problem, bevara fastighetens strukturella integritet och upprätthålla en hälsosam livsmiljö.`,
  },

  propertyMetricMould: {
    text: "Övervakning och hantering av mögelrisker är avgörande för fastighetens välbefinnande. Genom att snabbt identifiera och åtgärda mögelproblem kan fastighetsägare förebygga hälsorisker, upprätthålla fastighetens strukturella integritet och säkerställa en säker och hälsosam livsmiljö för hyresgästerna.",
  },

  tableWidget: {
    UNKNOWN_ADDRESS: "Okänd adress",
    MORE: "Mer",
    ALARM_TABLE_TITLE: "Senaste larm",
    STACKS_CREATED_TABLE_TITLE: "Projektlängd",

    projectDurationTable: {
      tableHeaders: {
        ADDRESS: "Adress",
        CREATED: "Dagar",
      },
    },
  },

  timeIntervals: {
    [TimeInterval.TwentyFourHours]: "24 timmar",
    [TimeInterval.ThreeDays]: "3 dagar",
    [TimeInterval.SevenDays]: "7 dagar",
    [TimeInterval.FourteenDays]: "14 dagar",
    [TimeInterval.ThirtyDays]: "30 dagar",
    [TimeInterval.SixtyDays]: "60 dagar",
    [TimeInterval.NinetyDays]: "90 dagar",
    [TimeInterval.OneHundredEightyDays]: "180 dagar",
    [TimeInterval.ThreeHundredSixtyFiveDays]: "365 dagar",
    custom: "Anpassad",
  },

  dashboards: {
    ALL_DASHBOARDS: "Alla dashboards",
    CREATE_DASHBOARD: "Skapa dashboard",
    ADD_WIDGET: "Lägg till widget",
    ADD_A_WIDGET: "Lägg till en widget",
    ADD_WIDGET_PLACEHOLDER: "--- Välj widget ---",
  },

  [WidgetType.CustomerWidget]: {
    TITLE: "Contact",
    NAME: "Name",
    MOBILE: "Mobile",
    ADDRESS: "Address",
  },

  defaultTitle: {
    [WidgetType.WeatherWidget]: "Väder",
    [WidgetType.GaugeWidget]: "Ny mätare",
    [WidgetType.ClockWidget]: "Ny klocka",
    [WidgetType.TextWidget]: "Ny text",
    [WidgetType.ValueWidget]: "Nytt värde",
    [WidgetType.StackedBarChartWidget]: "Nytt staplat stapeldiagram",
    [WidgetType.CustomerWidget]: "kontakt",
    [WidgetType.MapWidget]: "Ny karta",
    [WidgetType.ChartWidget]: "Ny graf",
    [WidgetType.BarChartWidget]: "Nytt stapeldiagram",
    [WidgetType.TableWidget]: "Ny tabell",
    [WidgetType.BookmarksWidget]: "Nya bokmärken",
    [WidgetType.HealthWidget]: "Ny fastighetshälsa",
    [WidgetType.OfflineWidget]: "Ny offline-widget",
    [WidgetType.ImageWidget]: "Ny bild-widget",
  },

  customerPicker: {
    PLACEHOLDER: "Skriv namn, adress eller telefonnummer...",
    NO_RESULTS: "Inga resultat för",
  },

  bookmarks: {
    LAST_ERRAND: "Sista ärendet",
    NO_ACTIVE_ERRAND: "Inga aktiva ärenden",
    NO_BOOKMARKS: "Du har inga bokmärken",
    NOT_FOUND: "Hittades inte",
  },

  modals: {
    confirmation: {
      YES: "Ja",
      NO: "Nej",
    },
    DELETE_WIDGET_MESSAGE: "Är du säker på att du vill ta bort denna widget?",
    DELETE_DASHBOARD_MESSAGE: "Är du säker på att du vill ta bort denna dashboard?",
  },

  shareSettings: {
    TITLE: "Dela inställningar",
    DESCRIPTION: "Alla kan komma åt dashboarden via länk",
    COPY_LINK: "Kopiera länk",
    COPIED: "länken kopierad",
  },

  propertyFilters: {
    [HealthFilterValue.All || FilterValue.All]: "Alla",
    [HealthFilterValue.HighRisk]: "Hög risk",
    [HealthFilterValue.MediumRisk]: "Medelhög risk",
    [HealthFilterValue.Normal]: "Normal",
    [HealthFilterValue.NoClassification]: "Ingen klassificering",
    [FilterValue.Alarm]: "Alarm",
    [FilterValue.Warning]: "Varning",
    [FilterValue.Healthy]: "Välmående",
  },

  propertyMetric: {
    [PropertyMetric.FuelPoverty]: "Fuel poverty (presumed)",
    [PropertyMetric.Insulation]: "Isolering",
    [PropertyMetric.Ventilation]: "Ventilation",
    [PropertyMetric.InternalMoisture]: "Interna avvikande fukthändelser",
    [PropertyMetric.Condensation]: "Kondensation/Mögelrisk",
  },

  noClassificationReason: {
    [NoClassificationReason.THRESHOLD]: "felaktiga tröskelvärden",
    [NoClassificationReason.NOT_ENOUGH_DATA]: "Inte tillräckligt med data",
    [NoClassificationReason.MISSING_OUTDOOR_SENSOR]: "Saknar utomhussensor",
  },

  aggregationType: {
    [AggregationType.Aggregated]: "Aggregerad",
    [AggregationType.NonAggregated]: "Ej aggregerad",
  },
};
export default sv;
