import {
  MultipleThingsPropertiesDataSource,
  MultipleThingsValueSourceTypes,
  TableWidgetSettings,
} from "models/widgets/TableWidget";
import { WidgetSettings } from "models/widgets/Widget";

export function tableWidgetOnSave(settings: TableWidgetSettings & WidgetSettings) {
  const updatedSettings = { ...settings };

  if (settings.dataSource?.sourceType === MultipleThingsValueSourceTypes.ThingMetricThreshold) {
    (updatedSettings.dataSource as MultipleThingsPropertiesDataSource).value = Number(
      (updatedSettings.dataSource as MultipleThingsPropertiesDataSource).value
    );
  }

  return updatedSettings;
}
