import { SettingsType } from "constants/constants";
import { titleSetting } from "data/commonSettings";
import { SettingsDescriptor } from "data/widgetData";
import { UnitsType } from "models/widgets/ClockWidget";
import language from "translations/language";

const temperatureUnits = [
  { value: language.widgets.settings.TWENTY_FOUR_HOUR_CLOCK, key: UnitsType.Twentyfour },
  { value: language.widgets.settings.TWELVE_HOUR_CLOCK, key: UnitsType.Twelve },
];

export const clockWidgetSettings: SettingsDescriptor = {
  title: titleSetting,
  timezone: {
    type: SettingsType.Select,
    label: language.widgets.settings.TIMEZONE,
    default: null,
    options: [{ key: null, value: "Current timezone" }].concat(
      (Intl as any).supportedValuesOf("timeZone").map((timezone: string) => ({
        key: timezone,
        value: timezone,
      }))
    ),
  },
  timeFormat: {
    type: SettingsType.Select,
    label: language.widgets.settings.TIME_FORMAT,
    default: UnitsType.Twentyfour,
    multiple: false,
    options: temperatureUnits,
  },
};
