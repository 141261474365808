import { useEffect } from "react";
import { css, Global, useTheme } from "@emotion/react";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import { makeHighchartsTheme } from "theme/highcharts/makeTheme";
import { useStore } from "stores/RootStore";

highchartsMore(Highcharts);
solidGauge(Highcharts);

export default function HighchartsStyles() {
  const currentTheme = useTheme();
  const { themeStore } = useStore();

  useEffect(() => {
    const actualColorsOptions = makeHighchartsTheme(currentTheme);
    Highcharts.setOptions(actualColorsOptions);
    Highcharts.charts.forEach((chart) => {
      if (chart) {
        chart.update(actualColorsOptions);
      }
    });
  }, [currentTheme, themeStore.theme]);

  return (
    <Global
      styles={css`
        .highcharts-axis-labels {
          > text {
            transform: initial;
          }
        }

        .highcharts-legend-item {
          > text {
            font-weight: normal !important;
          }
        }

        .highcharts-tooltip {
          > span {
            line-height: 16px;

            > b:first-of-type {
              line-height: 36px;
            }
          }
        }

        .highcharts-tooltip-bullet {
          margin-right: 4px;
          position: relative;
          top: 2px;

          color: transparent;
          width: 12px;
          height: 12px;
          display: inline-block;
        }
      `}
    />
  );
}
