import { SimpleStore } from "./SimpleStore";
import { SignedInstallationStatsAPIClient } from "services/api/SignedInstallationStatsAPIClient";
import { SignedInstallationStats } from "models/SignedInstallationStats";

type OrganisationId = string;

export class SignedInstallationStatsStore extends SimpleStore<
  OrganisationId,
  SignedInstallationStats,
  "organisationId"
> {
  private client = new SignedInstallationStatsAPIClient();

  protected async fetchFromClientForKey(organisationId: OrganisationId) {
    return await this.client.getData(organisationId);
  }

  protected getRegistryIndex(organisationId: OrganisationId) {
    return organisationId;
  }

  public readonly keyFieldName = "organisationId" as const;
}
