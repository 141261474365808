import { observer } from "mobx-react-lite";
import styled from "@emotion/styled/macro";
import { useStore } from "stores/RootStore";
import { LoadingStatus } from "constants/constants";
import ConfigureMessage from "components/WidgetConfigureMessage";
import language from "translations/language";
import { User } from "models/User";
import WidgetMessage from "components/WidgetMessage";
import { LoadingState } from "components/widgets/loading/LoadingState";
import { addressForStack, Stack } from "models/Stack";
import { hasData, Loadable } from "models/Loading";
import { useEffect } from "react";
import FairtrailLink from "components/FairtrailLink";
import { FAIRTRAIL_REBORN_USER_ROUTE, FAIRTRAIL_USER_ROUTE } from "constants/fairtrailRoutes";
import { StackThingList, UserThingList } from "./common/ThingList";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { DashboardMetadata } from "models/Dashboard";
import { CustomerWidgetViewModel } from "models/widgets/CustomerWidget";

interface Props {
  widget: CustomerWidgetViewModel;
  dashboardMetadata: DashboardMetadata;
}

const CustomerWidget = ({ widget, dashboardMetadata }: Props) => {
  const { user, stack } = useWidgetData(widget, dashboardMetadata);

  if (
    (user && user.loadingStatus === LoadingStatus.Loading) ||
    (!user && stack && stack.loadingStatus === LoadingStatus.Loading)
  ) {
    return <LoadingState />;
  }

  if ((user && user.loadingStatus === LoadingStatus.Error) || (stack && stack.loadingStatus === LoadingStatus.Error)) {
    return <WidgetMessage>{language.errors.CUSTOMER_DATA_LOAD_ERROR} </WidgetMessage>;
  }

  if (!hasData(user)) {
    return <ConfigureMessage widgetName={language.widgets.CUSTOMER} />;
  }
  return (
    <S.Container>
      <UserContactDetails user={user} stack={stack} />
      {hasData(stack) ? <StackThingList stack={stack} /> : <UserThingList user={user} />}
    </S.Container>
  );
};

export default observer(CustomerWidget);

const UserContactDetails = observer(({ user, stack }: { user: User; stack?: Loadable<Stack> }) => {
  return (
    <S.Contact>
      <S.Heading>{language[WidgetType.CustomerWidget].TITLE}</S.Heading>
      <p>
        <b>{language[WidgetType.CustomerWidget].NAME}:</b>{" "}
        <FairtrailLink
          href={FAIRTRAIL_USER_ROUTE.replace(":userId", user.userId)}
          rebornHref={FAIRTRAIL_REBORN_USER_ROUTE.replace(":userId", user.userId)}
        >
          <em>{user.name}</em>
        </FairtrailLink>
      </p>
      {user.cellPhones.length > 0 && (
        <p>
          <b>{language[WidgetType.CustomerWidget].MOBILE}:</b> {user.cellPhones.join(",")}
        </p>
      )}
      {hasData(stack) && (
        <p>
          <b>{language[WidgetType.CustomerWidget].ADDRESS}:</b> {addressForStack(stack)}
        </p>
      )}
    </S.Contact>
  );
});

//prettier-ignore
export const S = {
  Container: styled.div(({ theme }) => `
    position: absolute;
    top: 0;
    left: 0;
    ${theme.spacing.p(6)}
    height: calc(var(--widget-height) * 1px);
    width: calc(var(--widget-width) * 1px);

    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      word-wrap: break-word;
      ${theme.spacing.mb(2)}
    }

    em {
      font-style: normal;
      font-weight: bold;
      ${theme.color.text.secondary}
    }
  `),

  Heading: styled.h2(({ theme }) => `
    ${theme.spacing.mb(3)}
  `),

  Contact: styled.div(({ theme }) => `
    ${theme.spacing.mb(9)}
  `),

};

const useWidgetData = (widget: CustomerWidgetViewModel, dashboardMetadata: DashboardMetadata) => {
  const { userStore } = useStore();

  const user = useUserForBoard(widget, dashboardMetadata);
  const stack = useStackForBoard(widget, dashboardMetadata);

  // We don't have a user being loaded, but we have a stack so we can get the user from that
  if (user == null && dashboardMetadata.dashboardPurpose === DashboardPurpose.Stack) {
    const user = hasData(stack) ? userStore.get(stack.owner.userId) : undefined;
    return { user, stack };
  }

  return { user, stack };
};

function useUserForBoard(widget: CustomerWidgetViewModel, dashboardMetadata: DashboardMetadata) {
  const { userStore } = useStore();

  useEffect(() => {
    if (widget.settings.customerId) {
      userStore.load(widget.settings.customerId);
    }
    if (dashboardMetadata.dashboardPurpose === DashboardPurpose.Customer) {
      userStore.load(dashboardMetadata.customerId);
    }
  }, [userStore, widget.settings.customerId, dashboardMetadata]);

  // The widget has been configured with a specific user ID - always use that
  if (widget.settings.customerId) {
    return userStore.get(widget.settings.customerId);
  }

  // No configuration but we are on a customer dashboard so we can default to that
  if (dashboardMetadata.dashboardPurpose === DashboardPurpose.Customer) {
    return userStore.get(dashboardMetadata.customerId);
  }

  return undefined;
}

function useStackForBoard(widget: CustomerWidgetViewModel, dashboardMetadata: DashboardMetadata) {
  const { userStore, stackStore } = useStore();

  useEffect(() => {
    if (dashboardMetadata.dashboardPurpose === DashboardPurpose.Stack) {
      stackStore.load(dashboardMetadata.stackId);
    }
  }, [dashboardMetadata, stackStore]);

  const stack =
    dashboardMetadata.dashboardPurpose === DashboardPurpose.Stack
      ? stackStore.get(dashboardMetadata.stackId)
      : undefined;

  // If a stack exists with data we should also trigger a load of the correct user as we'll need
  // it for information in the widget.
  useEffect(() => {
    if (hasData(stack)) {
      userStore.load(stack.owner.userId);
    }
  }, [stack, userStore]);

  // If the widget has a configured user we'll ignore the stack restriction so that we can have a widget
  // with all things for the user too
  if (widget.settings.customerId) {
    return undefined;
  }

  return stack;
}
