import { DEFAULT_ORG } from "../../config/config";
import { toAuthUser } from "../../models/AuthUser";
import { FairtrailHttpClient } from "../FairtrailHttpClient";

type RequestLoginCodeResponse = {
  confirmUrl: string;
  requestCode: string;
};

export class AuthAPIClient extends FairtrailHttpClient {
  async requestLoginCode(requestedTo: string): Promise<RequestLoginCodeResponse> {
    try {
      const response = await this.post({
        path: "/auth/passwordless",
        body: {
          requestedTo,
          type: "email",
          newCustomer: false,
          shareId: DEFAULT_ORG,
        },
      });

      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async validateCodeSentToUser(loginCode: string, requestedTo: string) {
    try {
      const response = await this.post({
        path: `/auth/passwordless/${requestedTo}`,
        body: { loginCode },
      });

      return toAuthUser(response.data);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
