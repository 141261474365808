import { HttpClient } from "./HttpClient";
import { FAIRTRAIL_API_KEY, FAIRTRAIL_HOST } from "../config/config";
import { rootStore } from "../stores/RootStore";
import { AxiosResponse, Method } from "axios";

export class FairtrailHttpClient extends HttpClient {
  constructor(baseURL?: string, timeout: number = 3000) {
    super(baseURL || FAIRTRAIL_HOST, timeout);
  }

  protected async doRequest(args: {
    method: Method;
    path: string;
    body?: any;
    query?: Record<string, string>;
    headers?: Record<string, string>;
    authenticated?: boolean;
    showOnScreenError?: boolean;
  }): Promise<AxiosResponse<any, any>> {
    try {
      return await super.doRequest(args);
    } catch (err: any) {
      if (err?.status === 401) {
        rootStore.authStore.clearToken();
      }
      throw err;
    }
  }

  protected getDefaultHeaders() {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      apikey: FAIRTRAIL_API_KEY,
    };
  }

  protected getAuthHeaders(): Record<string, string> {
    const token = rootStore?.authStore?.token;
    return {
      Authorization: `token ${token}`,
    };
  }
}
