import { LoadingStatus } from "constants/constants";
import {
  GeoStatus,
  GeoTaggedData,
  GeoTaggedDataPoint,
  GeoTaggedDataSource,
} from "datasources/geoTaggedData/interfaces";
import { Loadable } from "models/Loading";
import { ExternalCaptionDataThing, ThingStatus } from "models/Thing";
import { ThingLocationsSource } from "models/widgets/MapWidget";
import { RootStore } from "stores/RootStore";
import { default as language } from "translations/language";

export class ThingLocationsAdapter implements GeoTaggedDataSource<ThingLocationsSource> {
  constructor(private stores: RootStore) {}

  getData(config: ThingLocationsSource): Loadable<GeoTaggedData> {
    const things = this.stores.thingStore.loadedThings.filter((thing) =>
      config.allThings ? true : config.thingIds?.includes(thing.thingId)
    );

    return {
      loadingStatus: !!things.length ? LoadingStatus.Loaded : LoadingStatus.Loading,
      points: things
        .filter((thing) => hasLocation(thing as unknown as ExternalCaptionDataThing))
        .map((thing) => this.toDataPoint(thing as unknown as ExternalCaptionDataThing)),
    };
  }

  private toDataPoint(thing: ExternalCaptionDataThing): GeoTaggedDataPoint {
    return {
      lat: thing.state.location.latitude,
      lng: thing.state.location.longitude,
      title: thing.name,
      uniqueId: thing.thingId,
      metaData: {
        alarm: {
          title: language.STATUS,
          value: language.thingStatus[thing.status],
        },
      },
      status: this.getStatusForThing(thing),
    };
  }

  private getStatusForThing(thing: ExternalCaptionDataThing): GeoStatus {
    if (thing.status === ThingStatus.HasAlarm) {
      return GeoStatus.Error;
    }

    return GeoStatus.Ok;
  }

  async loadData(config: ThingLocationsSource) {
    if (config.allThings) {
      await this.stores.thingStore.loadThings({
        page: 0,
        size: 10000,
        expandAll: true,
        checkIfAlreadyLoaded: true,
      });
    } else {
      await this.stores.thingStore.loadThings({
        page: 0,
        size: 10000,
        thingIds: config.thingIds,
      });
    }
  }
}

function hasLocation(x: ExternalCaptionDataThing): x is ExternalCaptionDataThing {
  return !!x.state.location?.latitude && !!x.state.location?.longitude;
}
