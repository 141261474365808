import { observer } from "mobx-react-lite";
import styled from "@emotion/styled/macro";
import { useStore } from "stores/RootStore";
import { useEffect, useState } from "react";
import FairtrailLink from "components/FairtrailLink";
import {
  FAIRTRAIL_BOOKMARKS_ROUTE,
  FAIRTRAIL_INCIDENT_ROUTE,
  FAIRTRAIL_REBORN_BOOKMARKS_ROUTE,
  FAIRTRAIL_REBORN_INCIDENT_ROUTE,
  FAIRTRAIL_REBORN_STACK_ROUTE,
  FAIRTRAIL_REBORN_THING_ROUTE,
  FAIRTRAIL_REBORN_USER_ROUTE,
  FAIRTRAIL_STACK_ROUTE,
  FAIRTRAIL_THING_ROUTE,
  FAIRTRAIL_USER_ROUTE,
} from "constants/fairtrailRoutes";
import { colorForThingStatus } from "./common/ThingList";
import { DashboardMetadata } from "models/Dashboard";
import { BookmarksWidgetViewModel } from "models/widgets/BookmarksWidget";
import { Thing } from "models/Thing";
import { BookmarkIcon } from "components/icons";
import { ThemeProps } from "theme/types";
import { useTheme } from "@emotion/react";
import { BookmarkType } from "models/Bookmark";
import { Incident, IncidentStatus } from "models/IncidentStats";
import language from "translations/language";
import { hasData, Loadable } from "models/Loading";
import { Title } from "components/common/title";
import { MoreLink } from "components/common/moreLink";
import { User } from "models/User";
import IncidentsAPIClient from "services/api/IncidentsAPIClient";
import { Stack } from "models/Stack";
import StacksAPIClient from "services/api/StacksAPIClient";
import { LoadingState } from "./loading/LoadingState";
import { LoadingStatus } from "../../constants/constants";
import WidgetMessage from "../WidgetMessage";

interface Props {
  widget: BookmarksWidgetViewModel;
  dashboardMetadata: DashboardMetadata;
}

const BookmarkThing = ({ thing }: { thing: Loadable<Thing, "thingId", string> }) => {
  const theme = useTheme();

  if (hasData<Thing>(thing)) {
    return (
      <>
        <S.Row>
          <S.Label>{language.THING}:</S.Label>
          <S.Value>
            <S.Indicator color={colorForThingStatus(theme, thing)} />
            <FairtrailLink
              href={FAIRTRAIL_THING_ROUTE.replace(":thingId", thing.thingId)}
              rebornHref={FAIRTRAIL_REBORN_THING_ROUTE.replace(":thingId", thing.thingId)}
            >
              <em>{thing?.name}</em>
            </FairtrailLink>
          </S.Value>
        </S.Row>
        <S.Row>
          <S.Label>{language.CUSTOMER}:</S.Label>
          <S.Value>
            <FairtrailLink
              href={FAIRTRAIL_USER_ROUTE.replace(":userId", thing.owner.userId)}
              rebornHref={FAIRTRAIL_REBORN_USER_ROUTE.replace(":userId", thing.owner.userId)}
            >
              <em>{thing.owner.name}</em>
            </FairtrailLink>
          </S.Value>
        </S.Row>
      </>
    );
  }

  return (
    <S.Row>
      <S.Label>{language.THING}:</S.Label>
      <S.Value>{language.bookmarks.NOT_FOUND}</S.Value>
    </S.Row>
  );
};

const BookmarkIncident = ({ incident }: { incident: Incident }) => {
  if (incident.incidentId) {
    return (
      <>
        <S.Row>
          <S.Label>{language.ERRAND}:</S.Label>
          <S.Value>
            <FairtrailLink
              href={FAIRTRAIL_INCIDENT_ROUTE.replace(":incidentId", incident.incidentId)}
              rebornHref={FAIRTRAIL_REBORN_INCIDENT_ROUTE.replace(":incidentId", incident.incidentId)}
            >
              <em>{incident.title}</em>
            </FairtrailLink>
          </S.Value>
        </S.Row>
        <S.Row>
          <S.Label>{language.CUSTOMER}:</S.Label>
          <S.Value>
            <FairtrailLink
              href={FAIRTRAIL_USER_ROUTE.replace(":userId", incident.user.userId)}
              rebornHref={FAIRTRAIL_REBORN_USER_ROUTE.replace(":userId", incident.user.userId)}
            >
              <em>{incident.user.name}</em>
            </FairtrailLink>
          </S.Value>
        </S.Row>
      </>
    );
  }

  return (
    <S.Row>
      <S.Label>{language.ERRAND}:</S.Label>
      <S.Value>{language.bookmarks.NOT_FOUND}</S.Value>
    </S.Row>
  );
};

const BookmarkStack = ({ stack }: { stack: Stack }) => {
  if (stack.stackId) {
    return (
      <>
        <S.Row>
          <S.Label>{language.PROPERTY}:</S.Label>
          <S.Value>
            <FairtrailLink
              href={FAIRTRAIL_STACK_ROUTE.replace(":stackId", stack.stackId)}
              rebornHref={FAIRTRAIL_REBORN_STACK_ROUTE.replace(":stackId", stack.stackId)}
            >
              <em>{stack.name}</em>
            </FairtrailLink>
          </S.Value>
        </S.Row>
        <S.Row>
          <S.Label>{language.CUSTOMER}:</S.Label>
          <S.Value>
            <FairtrailLink
              href={FAIRTRAIL_USER_ROUTE.replace(":userId", stack.owner.userId)}
              rebornHref={FAIRTRAIL_REBORN_USER_ROUTE.replace(":userId", stack.owner.userId)}
            >
              <em>{stack.owner.name}</em>
            </FairtrailLink>
          </S.Value>
        </S.Row>
      </>
    );
  }

  return (
    <S.Row>
      <S.Label>{language.PROPERTY}:</S.Label>
      <S.Value>{language.bookmarks.NOT_FOUND}</S.Value>
    </S.Row>
  );
};

const BookmarkCustomer = ({
  customer,
  incident,
}: {
  customer: Loadable<User, "userId", string>;
  incident: Incident | null;
}) => {
  if (hasData<User>(customer)) {
    return (
      <>
        <S.Row>
          <S.Label>{language.CUSTOMER}:</S.Label>
          <S.Value>
            <FairtrailLink
              href={FAIRTRAIL_USER_ROUTE.replace(":userId", customer.userId)}
              rebornHref={FAIRTRAIL_REBORN_USER_ROUTE.replace(":userId", customer.userId)}
            >
              <em>{customer.name}</em>
            </FairtrailLink>
          </S.Value>
        </S.Row>
        <S.Row>
          <S.Label>{language.bookmarks.LAST_ERRAND}:</S.Label>
          <S.Value>
            {incident ? (
              <FairtrailLink
                href={FAIRTRAIL_INCIDENT_ROUTE.replace(":incidentId", incident.incidentId)}
                rebornHref={FAIRTRAIL_REBORN_INCIDENT_ROUTE.replace(":incidentId", incident.incidentId)}
              >
                <em>{incident.title}</em>
              </FairtrailLink>
            ) : (
              <div>{language.bookmarks.NO_ACTIVE_ERRAND}</div>
            )}
          </S.Value>
        </S.Row>
      </>
    );
  }

  return (
    <S.Row>
      <S.Label>{language.CUSTOMER}:</S.Label>
      <S.Value>{language.bookmarks.NOT_FOUND}</S.Value>
    </S.Row>
  );
};

const incidentsAPIClient = new IncidentsAPIClient();
const stackAPIClient = new StacksAPIClient();

const BookmarksWidget = ({ widget }: Props) => {
  const { bookmarkStore, thingStore, userStore } = useStore();
  const [bookmarksInfo, setBookmarksInfo] = useState<any[]>([]);

  useEffect(() => {
    bookmarkStore.loadTop3();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const displayedBookmarks = bookmarkStore.bookmarks;

      const entityIds = displayedBookmarks.reduce(
        (result, bookmark) => {
          // @ts-ignore
          result[bookmark.entityType].push(bookmark.entityId);
          return result;
        },
        {
          [BookmarkType.Thing]: [],
          [BookmarkType.Incident]: [],
          [BookmarkType.Customer]: [],
          [BookmarkType.Property]: [],
        }
      );

      const bookmarkInfo: any[] = [];

      if (entityIds[BookmarkType.Thing].length > 0) {
        await thingStore.loadMany(entityIds[BookmarkType.Thing]);
        entityIds[BookmarkType.Thing].map((thingId) =>
          bookmarkInfo.push({ entityId: thingId, thing: thingStore.get(thingId) })
        );
      }
      if (entityIds[BookmarkType.Customer].length > 0) {
        await userStore.loadUsers({ userId: entityIds[BookmarkType.Customer] });

        const lastIncidents = await Promise.all(
          entityIds[BookmarkType.Customer].map((id) =>
            incidentsAPIClient.getIncidents({
              userId: id,
              status: [
                IncidentStatus.Registered,
                IncidentStatus.InProgress,
                IncidentStatus.Contacted,
                IncidentStatus.Done,
              ],
              size: 1,
            })
          )
        );

        entityIds[BookmarkType.Customer].map((customerId, i) => {
          bookmarkInfo.push({
            entityId: customerId,
            customer: userStore.get(customerId),
            incident: lastIncidents[i]?.incidents[0],
          });
        });
      }
      if (entityIds[BookmarkType.Incident].length > 0) {
        const incidents = await Promise.all(
          entityIds[BookmarkType.Incident]
            .map((id) => incidentsAPIClient.getIncidentById(id))
            .map((p) => p.catch((e) => e))
        );

        entityIds[BookmarkType.Incident].map((incidentId, i) =>
          bookmarkInfo.push({
            entityId: incidentId,
            incident: incidents.find((incident) => incidentId === incident.incidentId),
          })
        );
      }
      if (entityIds[BookmarkType.Property].length > 0) {
        const stacks = await Promise.all(
          entityIds[BookmarkType.Property].map((id) => stackAPIClient.getStackById(id)).map((p) => p.catch((e) => e))
        );

        entityIds[BookmarkType.Property].map((stackId, i) =>
          bookmarkInfo.push({ entityId: stackId, stack: stacks.find((stack) => stackId === stack.stackId) ?? {} })
        );
      }

      const orderByEntityId = displayedBookmarks.map((b) => b.entityId);
      bookmarkInfo.sort((a, b) => orderByEntityId.indexOf(a.entityId) - orderByEntityId.indexOf(b.entityId));

      setBookmarksInfo(bookmarkInfo);
    };

    if (bookmarkStore.bookmarks.length) {
      fetchData();
    }
  }, [bookmarkStore.bookmarks]);

  if (bookmarkStore.loadingState === LoadingStatus.Loading) {
    return <LoadingState />;
  }

  if (bookmarkStore.count === 0) {
    return <WidgetMessage>{language.bookmarks.NO_BOOKMARKS}</WidgetMessage>;
  }

  return (
    <S.Container>
      <Title text={widget.settings.title} icon={<BookmarkIcon />} />

      <S.List>
        {bookmarksInfo.map((bookmark, i) => (
          <S.Item key={i}>
            {bookmark.thing && <BookmarkThing thing={bookmark.thing} />}
            {bookmark.incident && !bookmark.customer && <BookmarkIncident incident={bookmark.incident} />}
            {bookmark.customer && <BookmarkCustomer customer={bookmark.customer} incident={bookmark.incident} />}
            {bookmark.stack && <BookmarkStack stack={bookmark.stack} />}
          </S.Item>
        ))}
      </S.List>
      {bookmarkStore.count > 3 && (
        <MoreLink href={FAIRTRAIL_BOOKMARKS_ROUTE} hrefNew={FAIRTRAIL_REBORN_BOOKMARKS_ROUTE} />
      )}
    </S.Container>
  );
};

export default observer(BookmarksWidget);

//prettier-ignore
export const S = {
    Container: styled.div(({ theme }) => `
      position: absolute;
      top: 0;
      left: 0;
      ${theme.spacing.p(6)}
      height: calc(var(--widget-height) * 1px);
      width: calc(var(--widget-width) * 1px);

      ${theme.color.text.neutral700}
    `),

    List: styled.div(({ theme }) => `
        display: flex;
        gap: 24px;
        margin: 24px 0;
        ${theme.typography.body3}
        font-weight: 700;
    `),

    Item: styled.div(() => `
        flex-basis: 33%;
        max-width: 33%;
        padding: 0 14px 0 25px;
        margin-left: -25px;
        white-space: nowrap;

        & + & {
          border-left: 1px solid #E3E5E6;
        }
    `),

    Row: styled.div(() => `
        display: flex;
        margin-top: 8px;
        gap: 4px;
    `),
    Label: styled.div(() => `
        flex: 3;
        max-width: 100px;
    `),
    Value: styled.div(({ theme }) => `
        flex: 5;

        display: flex;
        align-items: center;

        overflow: hidden;

        * {
            ${theme.color.text.secondary}

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    `),

    Indicator: styled.span(({ color }: ThemeProps & { color: string }) => `
        display: inline-block;
        width: 10px;
        height: 10px;
        flex-shrink: 0;
        margin-right: 0.5rem;

        ${color}
        border-radius: 50%;
    `),

  };
