import { WidgetViewModel } from "models/widgets/Widget";

export function getWidgetWidth(widget: WidgetViewModel) {
  if (widget.settings.size?.width) {
    const width = widget.settings.size.width;

    if (widget.dimensions.maxWidth && width > widget.dimensions.maxWidth) {
      return widget.dimensions.maxWidth;
    }

    if (widget.dimensions.minWidth && width < widget.dimensions.minWidth) {
      return widget.dimensions.minWidth;
    }

    return width;
  }

  return widget.dimensions.width;
}

export function getWidgetHeight(widget: WidgetViewModel) {
  if (widget.settings.size?.height) {
    const height = widget.settings.size.height;

    if (widget.dimensions.maxHeight && height > widget.dimensions.maxHeight) {
      return widget.dimensions.maxHeight;
    }

    if (widget.dimensions.minHeight && height < widget.dimensions.minHeight) {
      return widget.dimensions.minHeight;
    }

    return height;
  }

  return widget.dimensions.height;
}
