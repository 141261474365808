import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { WidgetData } from "../widgetData";
import OfflineWidget from "components/widgets/OfflineWidget";

export const OfflineWidgetData: WidgetData<WidgetType.OfflineWidget, WidgetSettings> = {
  type: WidgetType.OfflineWidget,

  name: language.widgets.OFFLINE,

  description: {
    title: language.widgets.OFFLINE,
    text: "",
    thumbnail: "",
  },

  dashboardPurposes: [DashboardPurpose.Generic, DashboardPurpose.Customer, DashboardPurpose.Stack],

  hideTitle: true,

  component: (props) => <OfflineWidget {...props} />,
};
