import { configure as configureMobx } from "mobx";
import { configurePersistable, isHydrated } from "mobx-persist-store";
import React from "react";
import { initializeWebSocketsChannels } from "../services/WebSocketService";
import { AuthStore } from "./AuthStore";
import { DashboardStore } from "./DashboardStore";
import { StackStore } from "./StackStore";
import { ThingStore } from "./ThingStore";
import { TimeseriesStore } from "./TimeseriesStore";
import WebSocketStore from "./WebSocketStore";
import { IncidentStatsStore } from "./IncidentStatsStore";
import { ProtectedHouseholdsStore } from "./ProtectedHouseholdsStore";
import { TimelineStore } from "./TimelineStore";
import { OrganizationStore } from "./OrganizationStore";
import { UserStore } from "stores/UserStore";
import { ConfigStore } from "./ConfigStore";
import { AppStore } from "./AppStore";
import { SignedInstallationStatsStore } from "./SignedInstallationStatsStore";
import { ThemeStore } from "./ThemeStore";
import { BookmarkStore } from "./Bookmark";
import { IncidentStore } from "./IncidentStore";
import { HealthDataStore } from "./HealthDataStore";

configureMobx({ enforceActions: "observed", useProxies: "always" });

configurePersistable(
  {
    // debugMode: true,
    storage: window.localStorage,
    stringify: true,
  },
  {
    delay: 500,
  }
);

export const rootStore = Object.freeze({
  appStore: new AppStore(),
  authStore: new AuthStore(),
  thingStore: new ThingStore(),
  stackStore: new StackStore(),
  dashboardStore: new DashboardStore(),
  timeseriesStore: new TimeseriesStore(),
  webSocketStore: new WebSocketStore(),
  incidentStatsStore: new IncidentStatsStore(),
  protectedHouseholdsStore: new ProtectedHouseholdsStore(),
  timelineStore: new TimelineStore(),
  organizationStore: new OrganizationStore(),
  userStore: new UserStore(),
  configStore: new ConfigStore(),
  signedInstallationStatsStore: new SignedInstallationStatsStore(),
  themeStore: new ThemeStore(),
  bookmarkStore: new BookmarkStore(),
  incidentStore: new IncidentStore(),
  healthDataStore: new HealthDataStore(),

  isHydrated: () => isHydrated(rootStore.authStore),

  reset: async () => {},
});

export type RootStore = typeof rootStore;

const StoreContext = React.createContext(rootStore);

export const StoreProvider = ({ children, store }: any) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export const useStore = () => React.useContext(StoreContext);

/**
 * Makes sure the stores are hydrated before initializing the websocket,
 * so that it is not registered with an invalid token. Only really needed for
 * local development, as the production will always get the token from the url
 **/
const waitForHydration = async () => {
  while (!rootStore.isHydrated()) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  rootStore.authStore.loadAuthUser();

  initializeWebSocketsChannels();
};

waitForHydration();
