import { useModal } from "@ebay/nice-modal-react";
import styled from "@emotion/styled/macro";
import { Button } from "components/common";
import CreateWidgetModal from "components/modals/CreateWidgetModal";
import { WidgetType } from "constants/widgetConstants";
import { WidgetData } from "data/widgetData";
import { observer } from "mobx-react";
import { useStore } from "stores/RootStore";
import language from "translations/language";

type Props = {
  dashboardId: string;
  widgetData: WidgetData<WidgetType, any>;
  closeSidebar: () => void;
  openSidebar: () => void;
};

const AddWidgetSelectorItem = ({ dashboardId, widgetData, closeSidebar, openSidebar }: Props) => {
  const { dashboardStore } = useStore();
  const createWidgetModal = useModal(CreateWidgetModal);

  const addWidget = async () => {
    /* Add text widget straight into dashboard */
    if (widgetData.type === WidgetType.TextWidget || widgetData.type === WidgetType.BookmarksWidget) {
      dashboardStore.createWidget(dashboardId, {
        type: widgetData.type,
        settings: {
          title: language.defaultTitle[widgetData.type],
          position: { x: 0, y: 0 },
        },
      });
    } else {
      /* build empty widget */
      const emptyWidget = dashboardStore.buildEmptyWidget(
        widgetData.type,
        language.defaultTitle[widgetData.type],
        dashboardId
      );

      /* Open createWidgetModal */
      try {
        closeSidebar?.();

        await createWidgetModal.show({
          widget: emptyWidget,
          /*  Open the widgets sidebar again on modal cancel. */
          onCancel: openSidebar,
        });
      } catch (err) {}
    }
  };

  return (
    <S.Container>
      <>
        {typeof widgetData.description.thumbnail === "string" ? (
          <S.Image src={widgetData.description.thumbnail} alt={widgetData.description.title} />
        ) : (
          <S.SVGContainer>
            <widgetData.description.thumbnail />
          </S.SVGContainer>
        )}
      </>
      <S.Body>
        <S.Title>{widgetData.description.title}</S.Title>
        <S.Description>{widgetData.description.text}</S.Description>
        <Button size="sm" onClick={addWidget}>
          {language.ADD}
        </Button>
      </S.Body>
    </S.Container>
  );
};

export default observer(AddWidgetSelectorItem);

// prettier-ignore
const S = {
  Container: styled.div(({ theme }) => `
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${theme.spacing(4)};
  `),

  Body: styled.div(({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    color: ${theme.color.primary};
  `),

  Image: styled.img(({ theme }) => `
    border-radius: ${theme.borderRadius.sm};
    border: 1px solid ${theme.color.neutral400};
  `),
  SVGContainer: styled.div(({ theme }) => `
    border-radius: ${theme.borderRadius.sm};
    border: 1px solid ${theme.color.neutral400};
  `),

  Title: styled.h3(({ theme }) => `
    ${theme.typography.body3};
    font-weight: bold;
  `),

  Description: styled.p(({ theme }) => `
    margin-top: -${theme.spacing(1)};
    ${theme.typography.body4};
  `)
};
