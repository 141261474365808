import language from "../translations/language";
import WidgetMessage from "./WidgetMessage";

interface Props {
  /** Widget name from language */
  widgetName: string;
}

/** Just a simple component to display configuration messages the same way accross widgets  */
const ConfigureMessage = ({ widgetName, ...props }: Props) => (
  <WidgetMessage data-testid="configuration-required-message" {...props}>
    {language.MUST_BE_CONFIGURED(widgetName)}
  </WidgetMessage>
);

export default ConfigureMessage;
