import { WidgetType } from "constants/widgetConstants";
import { WidgetViewModel } from "./Widget";

export function isClockWidget(widget: WidgetViewModel): widget is ClockWidgetViewModel {
  return widget.type === WidgetType.ClockWidget;
}

export type ClockWidgetViewModel = WidgetViewModel<WidgetType.ClockWidget, ClockWidgetSettings>;

export type ClockWidgetSettings = {
  timezone: string | null;
  timeFormat: string | null;
};

export enum UnitsType {
  Twentyfour = "24-hour",
  Twelve = "12-hour",
}
