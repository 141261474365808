import { SettingsType } from "constants/constants";
import { organizationSettings, titleSetting } from "data/commonSettings";
import { SettingsDescriptor } from "data/widgetData";
import { MultipleValueSourceTypes } from "models/widgets/common/value";
import language from "translations/language";

export const stackedBarChartWidgetSettings: SettingsDescriptor = {
  title: titleSetting,
  dataSource: {
    label: language.widgets.settings.DATA_SOURCE,
    type: SettingsType.Switch,
    switchField: {
      type: SettingsType.Select,
      label: language.widgets.settings.DATA_SOURCE_TYPE,
      default: null,
      multiple: false,
      options: Object.entries(MultipleValueSourceTypes).map(([_key, value]) => {
        return { key: value, value: language.sourceTypes[value] };
      }),
    },
    switchKey: "sourceType",
    options: {
      [MultipleValueSourceTypes.ProtectedHouseholds]: organizationSettings,
      [MultipleValueSourceTypes.SignedInstallationStats]: organizationSettings,
    },
  },
};
