import { CAPTION_DATA_REFRESH_TIMEOUT } from "constants/captionDataConstants";
import { DEFAULT_REFRESH_TIMEOUT, LoadingStatus } from "constants/constants";
import { FAIRTRAIL_REBORN_THING_ROUTE, FAIRTRAIL_THING_ROUTE } from "constants/fairtrailRoutes";
import { Loadable, hasData } from "models/Loading";
import { CubicSecureThing, Thing } from "models/Thing";
import { stateModifiedAt } from "models/ThingDataExtraction";
import {
  MultipleThingsPropertiesDataSource,
  ThingMetricThreshold,
  ThingMetricThresholdOperator,
} from "models/widgets/TableWidget";
import { WidgetViewModel } from "models/widgets/Widget";
import { RootStore } from "stores/RootStore";
import language from "translations/language";
import { getWidgetHeight } from "utils/widgetUtils";
import { TableValues, TableValuesDataSource } from "../interfaces";

export class ThingMetricThresholdValuesAdapter implements TableValuesDataSource<MultipleThingsPropertiesDataSource> {
  refreshTimeout: number | undefined;

  constructor(private stores: RootStore) {
    this.refreshTimeout = this.stores.configStore.deptId ? CAPTION_DATA_REFRESH_TIMEOUT : DEFAULT_REFRESH_TIMEOUT;
  }

  getData(config: MultipleThingsPropertiesDataSource, widget: WidgetViewModel): Loadable<TableValues> {
    const useFairtrailRebornLinks = this.stores.configStore.useFairtrailRebornLinks;

    const things = this.stores.thingStore.getThingsByWidgetId(widget.widgetId);

    if (!things.every(hasData<Thing>)) {
      return { loadingStatus: LoadingStatus.Loading };
    }

    const values = things.map((thing) => ({
      loadingStatus: LoadingStatus.Loaded,
      value: this.getValueForThing(thing, config),
      label: thing.name!,
      units: this.getUnitForThing(thing, config),
      updatedAt: stateModifiedAt(thing) as Date,
      url: useFairtrailRebornLinks
        ? FAIRTRAIL_REBORN_THING_ROUTE.replace(":thingId", thing.thingId)
        : FAIRTRAIL_THING_ROUTE.replace(":thingId", thing.thingId),
      warningValue: config.value,
      warningOperator: config.operator,
    }));

    return {
      loadingStatus: LoadingStatus.Loaded,
      values: values as any,
      title: widget.settings.title,
    };
  }

  async loadData(
    config: MultipleThingsPropertiesDataSource,
    widget: WidgetViewModel,
    additionalData: { page: number }
  ) {
    const pageSize = getWidgetHeight(widget) * 2 - 9;

    await this.stores.thingStore.loadThingsForWidget({
      widgetId: widget.widgetId,
      page: additionalData.page,
      size: pageSize,
      thresholdMetric: config.metric,
      thresholdOperator: THING_METRIC_THRESHOLD_OPERATOR_TO_BACKEND_MAP[config.operator],
      thresholdValue: config.value,
    });
  }

  private getValueForThing(thing: Thing, config: MultipleThingsPropertiesDataSource) {
    switch (config.metric) {
      case ThingMetricThreshold.Pressure:
        return (thing as CubicSecureThing)?.state?.motor1?.lastPressureResult;
      case ThingMetricThreshold.Temperature:
        return (thing as CubicSecureThing)?.state?.motor1?.temperature;
      default:
        return 0;
    }
  }

  private getUnitForThing(thing: Thing, config: MultipleThingsPropertiesDataSource) {
    switch (config.metric) {
      case ThingMetricThreshold.Pressure:
        return language.thingMetricThresholdUnit[config.metric];
      case ThingMetricThreshold.Temperature:
        return language.thingMetricThresholdUnit[config.metric];
      default:
        return "";
    }
  }
}

const THING_METRIC_THRESHOLD_OPERATOR_TO_BACKEND_MAP = {
  [ThingMetricThresholdOperator.GreaterThan]: "gt",
  [ThingMetricThresholdOperator.GreaterThanEqual]: "gte",
  [ThingMetricThresholdOperator.LessThan]: "lt",
  [ThingMetricThresholdOperator.LessThanEqual]: "lte",
};
