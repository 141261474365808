import { Theme } from "@emotion/react";
import { Options } from "highcharts";

// Theme reference:
// https://github.com/highcharts/highcharts/blob/35a43633eeefcef7a83ba427f19077460f9e4cf9/ts/Extensions/Themes/DarkUnica.ts
export const makeHighchartsTheme = (currentTheme: Theme) => {
  const { color } = currentTheme;
  const highchartsTheme: Options = {
    colors: [
      color.chart100,
      color.chart200,
      color.chart300,
      color.chart400,
      color.chart500,
      // TODO: if we need to expand color variant, here is the reference for the variety
      // "#2b908f",
      // "#90ee7e",
      // "#f45b5b",
      // "#7798BF",
      // "#aaeeee",
      // "#ff0066",
      // "#eeaaee",
      // "#55BF3B",
      // "#DF5353",
      // "#7798BF",
      // "#aaeeee",
    ],
    chart: {
      backgroundColor: color.neutral100,
      style: {
        fontFamily: "Lato, Arial, Helvetica, sans-serif",
      },
      plotBorderColor: color.neutral700,
    },
    pane: {
      background: [
        {
          backgroundColor: color.neutral400,
        },
      ],
    },
    title: {
      style: {
        color: color.primary400,
        textTransform: "uppercase",
      },
    },
    subtitle: {
      style: {
        color: color.primary400,
        textTransform: "uppercase",
      },
    },
    xAxis: {
      gridLineColor: color.neutral400,
      labels: {
        style: {
          color: color.primary400,
        },
      },
      lineColor: color.neutral400,
      minorGridLineColor: color.neutral400,
      tickColor: color.neutral400,
      title: {
        style: {
          color: color.primary400,
        },
      },
    },
    yAxis: {
      gridLineColor: color.neutral400,
      labels: {
        style: {
          color: color.primary400,
        },
      },
      lineColor: color.neutral400,
      minorGridLineColor: color.neutral400,
      tickColor: color.neutral400,
      tickWidth: 1,
      title: {
        style: {
          color: color.primary400,
        },
      },
    },
    tooltip: {
      backgroundColor: color.neutral300,
      style: {
        color: color.primary400,
      },
    },
    plotOptions: {
      series: {
        animation: false,
        turboThreshold: 1000000, //  default is 1000
        dataLabels: {
          color: color.primary400,
        },
        marker: {
          lineColor: color.neutral300,
        },
      },
      // These left for future references if more Hightcharts will require styling
      //
      // boxplot: {
      //   fillColor: "#505053",
      // },
      // candlestick: {
      //   lineColor: "white",
      // },
      // errorbar: {
      //   color: "white",
      // },
    },
    legend: {
      itemStyle: {
        color: color.primary400,
      },
      itemHoverStyle: {
        color: color.primary200,
      },
      itemHiddenStyle: {
        color: color.neutral400,
      },
      title: {
        style: {
          color: color.primary400,
        },
      },
    },
    credits: {
      style: {
        // color: "#666",
        color: color.primary400,
      },
    },

    // Example:
    // Of the remaining chart parts to be styled:
    //
    //
    // drilldown: {
    //   activeAxisLabelStyle: {
    //     color: "#F0F0F3",
    //   },
    //   activeDataLabelStyle: {
    //     color: "#F0F0F3",
    //   },
    // },

    // navigation: {
    //   buttonOptions: {
    //     symbolStroke: "#DDDDDD",
    //     theme: {
    //       fill: "#505053",
    //     },
    //   },
    // },
    // scroll charts
    // rangeSelector: {
    //   buttonTheme: {
    //     fill: "#505053",
    //     stroke: "#000000",
    //     style: {
    //       color: "#CCC",
    //     },
    //     states: {
    //       hover: {
    //         fill: "#707073",
    //         stroke: "#000000",
    //         style: {
    //           color: "white",
    //         },
    //       },
    //       select: {
    //         fill: "#000003",
    //         stroke: "#000000",
    //         style: {
    //           color: "white",
    //         },
    //       },
    //     },
    //   },
    //   inputBoxBorderColor: "#505053",
    //   inputStyle: {
    //     backgroundColor: "#333",
    //     color: "silver",
    //   },
    //   labelStyle: {
    //     color: "silver",
    //   },
    // },

    // navigator: {
    //   handles: {
    //     backgroundColor: "#666",
    //     borderColor: "#AAA",
    //   },
    //   outlineColor: "#CCC",
    //   maskFill: "rgba(255,255,255,0.1)",
    //   series: {
    //     color: "#7798BF",
    //     lineColor: "#A6C7ED",
    //   },
    //   xAxis: {
    //     gridLineColor: "#505053",
    //   },
    // },

    // scrollbar: {
    //   barBackgroundColor: "#808083",
    //   barBorderColor: "#808083",
    //   buttonArrowColor: "#CCC",
    //   buttonBackgroundColor: "#606063",
    //   buttonBorderColor: "#606063",
    //   rifleColor: "#FFF",
    //   trackBackgroundColor: "#404043",
    //   trackBorderColor: "#404043",
    // },
  };

  return highchartsTheme;
};

export const darkHighcharts: Options = {
  colors: [
    "#2b908f",
    "#90ee7e",
    "#f45b5b",
    "#7798BF",
    "#aaeeee",
    "#ff0066",
    "#eeaaee",
    "#55BF3B",
    "#DF5353",
    "#7798BF",
    "#aaeeee",
  ],
  chart: {
    backgroundColor: "#3D3D41",
    style: {
      fontFamily: "Lato, Arial, Helvetica, sans-serif",
    },
    plotBorderColor: "#606063",
  },
  title: {
    style: {
      color: "#E0E0E3",
      textTransform: "uppercase",
      fontSize: "20px",
    },
  },
  subtitle: {
    style: {
      color: "#E0E0E3",
      textTransform: "uppercase",
    },
  },
  xAxis: {
    gridLineColor: "#707073",
    labels: {
      style: {
        color: "#E0E0E3",
      },
    },
    lineColor: "#707073",
    minorGridLineColor: "#505053",
    tickColor: "#707073",
    title: {
      style: {
        color: "#A0A0A3",
      },
    },
  },
  yAxis: {
    gridLineColor: "#707073",
    labels: {
      style: {
        color: "#E0E0E3",
      },
    },
    lineColor: "#707073",
    minorGridLineColor: "#505053",
    tickColor: "#707073",
    tickWidth: 1,
    title: {
      style: {
        color: "#A0A0A3",
      },
    },
  },
  tooltip: {
    backgroundColor: "#28272C",
    style: {
      color: "#F3F3F3",
    },
  },
  plotOptions: {
    series: {
      animation: false,
      turboThreshold: 1000000, //  default is 1000
      dataLabels: {
        color: "#F0F0F3",
        style: {
          fontSize: "13px",
        },
      },
      marker: {
        lineColor: "#333",
      },
    },
    boxplot: {
      fillColor: "#505053",
    },
    candlestick: {
      lineColor: "white",
    },
    errorbar: {
      color: "white",
    },
  },
  legend: {
    itemStyle: {
      color: "#E0E0E3",
    },
    itemHoverStyle: {
      color: "#FFF",
    },
    itemHiddenStyle: {
      color: "#606063",
    },
    title: {
      style: {
        color: "#C0C0C0",
      },
    },
  },
  credits: {
    style: {
      color: "#666",
    },
  },

  drilldown: {
    activeAxisLabelStyle: {
      color: "#F0F0F3",
    },
    activeDataLabelStyle: {
      color: "#F0F0F3",
    },
  },

  navigation: {
    buttonOptions: {
      symbolStroke: "#DDDDDD",
      theme: {
        fill: "#505053",
      },
    },
  },

  // scroll charts
  rangeSelector: {
    buttonTheme: {
      fill: "#505053",
      stroke: "#000000",
      style: {
        color: "#CCC",
      },
      states: {
        hover: {
          fill: "#707073",
          stroke: "#000000",
          style: {
            color: "white",
          },
        },
        select: {
          fill: "#000003",
          stroke: "#000000",
          style: {
            color: "white",
          },
        },
      },
    },
    inputBoxBorderColor: "#505053",
    inputStyle: {
      backgroundColor: "#333",
      color: "silver",
    },
    labelStyle: {
      color: "silver",
    },
  },

  navigator: {
    handles: {
      backgroundColor: "#666",
      borderColor: "#AAA",
    },
    outlineColor: "#CCC",
    maskFill: "rgba(255,255,255,0.1)",
    series: {
      color: "#7798BF",
      lineColor: "#A6C7ED",
    },
    xAxis: {
      gridLineColor: "#505053",
    },
  },

  scrollbar: {
    barBackgroundColor: "#808083",
    barBorderColor: "#808083",
    buttonArrowColor: "#CCC",
    buttonBackgroundColor: "#606063",
    buttonBorderColor: "#606063",
    rifleColor: "#FFF",
    trackBackgroundColor: "#404043",
    trackBorderColor: "#404043",
  },
};
