import { theme } from "../config";
import { serializeCSSObj, pxOrRaw } from "theme/utils";

import type { SpacingCSSStringFunction, SpacingHelpers, SpacingIndex } from "theme/types";

/**
 * Returns the spacing value which is measure of the `spacing` in the theme.
 * @example
 * theme.spacing = 4
 * spacing(1) // 4px
 * spacing(2) // 8px
 * spacing(3) // 12px
 * spacing(10) // 40px
 */
export const spacing = (value: SpacingIndex) => pxOrRaw(theme.spacing * value);

type CSSDescriptor = [...Record<string, string>[]];
export const themeSpacingHelpers = (baseSpacing: number) => {
  const spacing = (value: SpacingIndex) => pxOrRaw(baseSpacing * value);
  const toString = (arr: CSSDescriptor) => arr.map((item) => serializeCSSObj(item)).join(" ");

  /**
   * Returns just a formatted string of values calculated from the base spacing.
   * @example
   * theme.spacing(1) => "4px"
   * theme.spacing(2) => "8px"
   * theme.spacing([1,2,3]) => "4px 8px 12px"
   */
  let module: SpacingHelpers = ((...values) =>
    values.map((item) => pxOrRaw(item, (item) => item * baseSpacing)).join(" ")) as SpacingHelpers;

  /**
   * Returns height CSS string based on the spacing value.
   * @example
   * theme.spacing.h(1) => height: 4px;
   */
  // const h: CSSFunction = (value) => {
  const h: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    return toString([{ height: pxOrRaw(spacing(v)) }]);
  };
  module.h = h;

  /**
   * Returns width CSS string based on the spacing value.
   * @example
   * theme.spacing.w(1) => width: 4px;
   */
  const w: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    // return toString([{ width: pxOrRaw(spacing(v)) }]);
    return serializeCSSObj({
      width: pxOrRaw(spacing(v)),
    });
  };
  module.w = w;

  /**
   * Returns either a single padding value or a padding value for each side.
   * @example
   * theme.spacing.p(1) => padding: 4px;
   * theme.spacing.p([1, 2, 3, 4]) => padding-top: 4px; padding-right: 8px; padding-bottom: 12px; padding-left: 16px
   */
  const p: SpacingCSSStringFunction = (value) => {
    if (!Array.isArray(value)) {
      return toString([{ padding: pxOrRaw(spacing(value)) }]);
    }

    const [top, right, bottom, left] = value;
    const obj = {
      paddingTop: pxOrRaw(spacing(top)),
      paddingRight: pxOrRaw(spacing(right)),
      paddingBottom: pxOrRaw(spacing(bottom)),
      paddingLeft: pxOrRaw(spacing(left)),
    };
    return serializeCSSObj(obj);
  };
  module.p = p;

  /**
   * Returns horizontal paddings CSS string
   * @example
   * theme.spacing.px(1) => padding-left: 4px; padding-right: 4px;
   */
  const px: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    return serializeCSSObj({
      paddingLeft: pxOrRaw(spacing(v)),
      paddingRight: pxOrRaw(spacing(v)),
    });
  };
  module.px = px;

  /**
   * Returns vertical paddings CSS string
   * @example
   * theme.spacing.py(1) => padding-top: 4px; padding-bottom: 4px;
   */
  const py: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    return serializeCSSObj({
      paddingTop: pxOrRaw(spacing(v)),
      paddingBottom: pxOrRaw(spacing(v)),
    });
  };
  module.py = py;

  /**
   * Returns top padding CSS string
   * @example
   * theme.spacing.pt(1) => padding-top: 4px;
   */
  const pt: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    return serializeCSSObj({
      paddingTop: pxOrRaw(spacing(v)),
    });
  };
  module.pt = pt;

  /**
   * Returns bottom padding CSS string
   * @example
   * theme.spacing.pb(1) => padding-bottom: 4px;
   */
  const pb: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    return serializeCSSObj({
      paddingBottom: pxOrRaw(spacing(v)),
    });
  };
  module.pb = pb;

  /**
   * Returns left padding CSS string
   * @example
   * theme.spacing.pl(1) => padding-left: 4px;
   */
  const pl: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    return serializeCSSObj({
      paddingLeft: pxOrRaw(spacing(v)),
    });
  };
  module.pl = pl;

  /**
   * Returns right padding CSS string
   * @example
   * theme.spacing.pr(1) => padding-right: 4px;
   */
  const pr: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    return serializeCSSObj({
      paddingRight: pxOrRaw(spacing(v)),
    });
  };
  module.pr = pr;

  /**
   * Returns either a single margin value or a margin value for each side.
   * @example
   * theme.spacing.m(1) => margin: 4px;
   * theme.spacing.m([0, 2, 3, 4]) => margin-top: 0; margin-right: 8px; margin-bottom: 12px; margin-left: 16px
   */
  const m: SpacingCSSStringFunction = (value) => {
    if (!Array.isArray(value)) {
      return toString([{ padding: pxOrRaw(spacing(value)) }]);
    }

    const [top, right, bottom, left] = value;
    const obj = {
      marginTop: pxOrRaw(spacing(top)),
      marginRight: pxOrRaw(spacing(right)),
      marginBottom: pxOrRaw(spacing(bottom)),
      marginLeft: pxOrRaw(spacing(left)),
    };
    return serializeCSSObj(obj);
  };
  module.m = m;

  /**
   * Returns vertical margin CSS string
   * @example
   * theme.spacing.my(1) => margin-top: 4px; margin-bottom: 4px;
   */
  const my: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    return serializeCSSObj({
      marginTop: pxOrRaw(spacing(v)),
      marginBottom: pxOrRaw(spacing(v)),
    });
  };
  module.my = my;

  /**
   * Returns top margin CSS string
   * @example
   * theme.spacing.mt(1) => margin-top: 4px;
   */
  const mt: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    return serializeCSSObj({
      marginTop: pxOrRaw(spacing(v)),
    });
  };

  module.mt = mt;

  /**
   * Returns bottom margin CSS string
   * @example
   * theme.spacing.mb(1) => margin-bottom: 4px;
   */
  const mb: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    return serializeCSSObj({
      marginBottom: pxOrRaw(spacing(v)),
    });
  };
  module.mb = mb;

  /**
   * style
   * Returns left margin CSS string
   * @example
   * theme.spacing.ml(1) => margin-left: 4px;
   */
  const ml: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    return serializeCSSObj({
      marginLeft: pxOrRaw(spacing(v)),
    });
  };
  module.ml = ml;

  /**
   * Returns right margin CSS string
   * @example
   * theme.spacing.mr(1) => margin-right: 4px;
   */
  const mr: SpacingCSSStringFunction = (value) => {
    const v = Array.isArray(value) ? value[0] : value;
    return serializeCSSObj({
      marginRight: pxOrRaw(spacing(v)),
    });
  };
  module.mr = mr;

  return module;
};
