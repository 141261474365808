import LocalizedStrings from "localized-strings";
import { LANGUAGE_QUERY_PARAM } from "../constants/constants";

import en from "./resources/en";
import sv from "./resources/sv";
import de from "./resources/de";
import fi from "./resources/fi";
import nb from "./resources/nb";

const language = new LocalizedStrings({ en, sv, de, fi, nb });

const langQuery = new URLSearchParams(window.location.search).get(LANGUAGE_QUERY_PARAM);

if (langQuery) {
  /** localized-strings will only set languages it supports, so we don't need to do any more checks on the input */
  language.setLanguage(langQuery);
}

export default language;
