import { Palette } from "theme/types";

export const darkPalePalette: Palette = {
  primary: {
    default: "#f3f6fa",
    300: "#B4B4B4", // Table Headers, Icons
    400: "#f3f6fa", // Titles, Body Text
  },
  secondary: {
    default: "#72B2FF",
    400: "#72B2FF", // Links, Secondary Chart Lines
  },
  // Neutral is for the Background
  neutral: {
    default: "#36434f", // Page Background color
    100: "#485562", // Widget Background, Button text
    300: "#36434f", // Page Background
    400: "#546271", // Chart Background, Table Highlight
    700: "#B9B9BC", // Button Background
    "700n": "#36434f", // Button Text
  },
  success: {
    default: "#4dd497",
    400: "#4dd497", // Accent buttons
  },
  warning: {
    default: "#ffe075",
    400: "#ffe075", // Warning Text, Warning Widgets
  },
  error: {
    default: "#fe8468",
    400: "#fe8468", // Errors, Danger Text, Danger Buttons
  },
  chart: {
    default: "#BB86FC",
    high: "#FC868C",
    medium: "#00c1e2",
    low: "#BB86FC",
    100: "#BB86FC",
    200: "#72B2FF",
    300: "#FC868C",
    400: "#FCD986",
    500: "#00c1e2",
  },
};
