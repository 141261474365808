import { useModal } from "@ebay/nice-modal-react";
import styled from "@emotion/styled/macro";
import { Button } from "components/common/buttons";
import { Dropdown } from "components/common/dropdown";
import { ConfirmationModal } from "components/modals/ConfirmationModal";
import CreateDashboardModal from "components/modals/CreateDashboardModal";
import { observer } from "mobx-react-lite";
import { FiChevronDown, FiCopy, FiTrash } from "react-icons/fi";
import { useStore } from "stores/RootStore";
import language from "translations/language";
import { canDeleteDashboard } from "utils/permissionUtils";

enum DashboardAction {
  Clone = "clone",
  Delete = "delete",
}

interface Props {
  dashboardId: string;
}

const DashboardListActionsDropdown = ({ dashboardId }: Props) => {
  const { dashboardStore } = useStore();

  const deleteDashboardModal = useModal(ConfirmationModal);
  const createDashboardModal = useModal(CreateDashboardModal);

  const deleteDashboard = (dashboardId: string) => dashboardStore.deleteDashboard(dashboardId);

  const promptDeleteDashboard = async (dashboardId: string) => {
    const result = await deleteDashboardModal.show({
      message: language.modals.DELETE_DASHBOARD_MESSAGE,
    });

    if (result) {
      deleteDashboard(dashboardId);
    }
  };

  const cloneDashboard = async (dashboardId: string) => {
    await createDashboardModal.show({
      cloneDashboardId: dashboardId,
    });
  };

  const onActionDropdownItemClick = (dashboardId: string) => (actionId: string) => {
    switch (actionId) {
      case DashboardAction.Clone:
        cloneDashboard(dashboardId);
        break;
      case DashboardAction.Delete:
        promptDeleteDashboard(dashboardId);
        break;
    }
  };

  const actionOptions = (dashboardId: string) => {
    const options: { id: string; name: string; icon: JSX.Element }[] = [
      {
        id: DashboardAction.Clone,
        name: language.CREATE_COPY,
        icon: <FiCopy size={18} />,
      },
    ];

    if (canDeleteDashboard(dashboardId)) {
      options.push({
        id: DashboardAction.Delete,
        name: language.REMOVE,
        icon: <FiTrash size={18} />,
      });
    }

    return options;
  };

  return (
    <Dropdown options={actionOptions(dashboardId)} optionOnClick={onActionDropdownItemClick(dashboardId)}>
      <S.DropDownIconContainer>
        <Button size="sm">
          <FiChevronDown size="14" />
        </Button>
      </S.DropDownIconContainer>
    </Dropdown>
  );
};

export default observer(DashboardListActionsDropdown);

// prettier-ignore
const S = {
	DropDownIconContainer: styled.div(({ theme }) => `
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${theme.spacing.px(2)}
  `),
};
