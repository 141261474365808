import { TimeChartSeries, TimeChartValues } from "datasources/timeChartValuesData/interfaces";
import { floatToTimeString, toISOString } from "utils/datetimeUtils";
import { CategorisedValues } from "datasources/categoryData/interfaces";

type Row = Record<string, string>;

export type CSVData = {
  rows: Row[];
};

export const timeChartValuesToCSV = (data: TimeChartValues | TimeChartValues[]): CSVData => {
  const timeIndexValues = (Array.isArray(data) ? data.flatMap((d) => d.series) : data.series).reduce((acc, series) => {
    return series.values.reduce((innerAcc, [date, value]) => {
      const timestamp = toISOString(date);
      innerAcc[timestamp] = innerAcc[timestamp] ?? { timestamp };
      innerAcc[timestamp][buildLabel(series)] =
        series.valueType === "number" ? value.toString() : floatToTimeString(value);
      return innerAcc;
    }, acc);
  }, {} as Record<string, Row>);

  return {
    rows: Object.values(timeIndexValues),
  };
};

export const categorisedValuesToCSV = (data: CategorisedValues): CSVData => {
  const total = data.total ? { [`${data.total.label}`]: data.total.value.toString() } : undefined;
  const columns = Object.fromEntries(
    Object.values(data.categories).map((category) => [category.label, category.value.toString()])
  );
  return {
    rows: [{ ...total, ...columns }],
  };
};

function buildLabel(series: TimeChartSeries) {
  return series.units ? `${series.label} (${series.units})` : series.label;
}
