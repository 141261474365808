import { Palette } from "theme/types";

export const darkPalette: Palette = {
  primary: {
    default: "#E0E0F9",
    300: "#8787E8", // Table Headers, Icons
    400: "#E0E0F9", // Titles, Body Text
  },
  secondary: {
    default: "#29D8FF",
    400: "#29D8FF", // Links, Secondary Chart Lines
  },
  // Neutral is for the Background
  neutral: {
    default: "#12113B", // Page Background color
    100: "#2A294F", // Widget Background, Button text
    300: "#12113B", // Page Background
    400: "#3F3E61", // Chart Background, Table Highlight
    700: "#B2B1CD", // Button Background
    "700n": "#F3F3F3", // Button Text
  },
  success: {
    default: "#5FFF8F",
    400: "#5FFF8F", // Accent buttons
  },
  warning: {
    default: "#FFDF5F",
    400: "#FFDF5F", // Warning Text, Warning Widgets
  },
  error: {
    default: "#FF6F7D",
    400: "#FF6F7D", // Errors, Danger Text, Danger Buttons
  },
  chart: {
    default: "#29D8FF",
    high: "#FF6E7D",
    medium: "#5FFF8F",
    low: "#29D8FF",
    100: "#29D8FF",
    200: "#FF6F7D",
    300: "#FFDF5F",
    400: "#5FFF8F",
    500: "#BB86FC",
  },
};
