import { css, Theme } from "@emotion/react";

export const typography = ({ color, typography, shadow }: Theme) => {
  return css`
    body {
      font-family: Lato, Arial, Helvetica, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      ${color.bg.neutral300}
      ${color.text.primary}
    }

    input,
    button,
    textarea,
    select,
    a {
      &:focus {
        outline: none;
        border-color: ${color.secondary400};
        box-shadow: ${shadow.focus};
      }
    }

    textarea {
      ${color.text.primary}
    }

    a {
      ${color.text.primary}
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
      font-weight: bold;
      color: inherit;
    }

    h2 {
      ${typography.title5}
      font-weight: bold;
    }

    p {
      ${typography.body2}
      margin: 0;
      word-wrap: break-word;
    }
  `;
};
