import { SimpleStore } from "./SimpleStore";
import { AccountsAPIClient } from "services/api/AccountsAPIClient";
import { User } from "models/User";
import { runInAction } from "mobx";
import { LoadingStatus } from "constants/constants";

type UserId = string;

export class UserStore extends SimpleStore<UserId, User, "userId"> {
  private client = new AccountsAPIClient();

  public readonly keyFieldName = "userId" as const;

  public async loadForQuery(query: string): Promise<UserId[]> {
    const users = await this.client.queryForUserProfiles(query);
    users.forEach((user) => this.updateEntry(user.userId, user));
    return users.map((user) => user.userId);
  }

  protected async fetchFromClientForKey(userId: UserId) {
    return await this.client.getUserProfile(userId);
  }

  protected getRegistryIndex(userId: UserId) {
    return userId;
  }

  async loadUsers({ userId }: { userId: string | string[] }) {
    const { users, totalCount } = await this.client.getUsersByQuery({
      userId,
    });

    runInAction(() => {
      this.registry.clear();

      runInAction(() => {
        users.forEach((user: any) => {
          this.registry.set(user.userId, { loadingStatus: LoadingStatus.Loaded, ...user });
        });
      });

      return { users, totalCount };
    });
  }
}
