import styled from "@emotion/styled/macro";

// prettier-ignore
export const Form = styled.form(({ theme }) => `
  input {
    margin: 0;
  }

  ${Row} {
    ${theme.spacing.my(4)}
  }
`);

// prettier-ignore
export const Row = styled.div(({ theme }) => `
`);

// prettier-ignore
export const SubmitRow = styled.div(({ theme }) => `
  ${theme.spacing.mt(8)}
  text-align: right;
`);
