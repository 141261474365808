import styled from "@emotion/styled";
import Select from "components/common/select";
import { useEffect, useMemo, useState } from "react";

interface Props {
  options: { key: string | null; value: string; color?: string; icon?: React.ReactNode }[];
  value: string | (string | null)[] | null;
  defaultValue?: string | (string | null)[] | null;
  settingsKey: string;
  onChange: (key: string, value: any) => void;
  multiple?: boolean;
  noOptionsMessage?: () => string;
  disabled?: boolean;
  className?: string;
}

export const SelectSetting = ({
  options,
  value,
  defaultValue,
  onChange,
  settingsKey,
  multiple,
  noOptionsMessage,
  disabled = false,
  className,
}: Props) => {
  const handleChange = (selected: any) => {
    if (multiple) {
      onChange(settingsKey, selected?.map((option: any) => option?.value) ?? null);
    } else {
      onChange(settingsKey, selected?.value ?? null);
    }

    setSelectedValues(selected);
  };

  const selectOptions = useMemo(
    () =>
      options.map((option) => ({
        value: option.key as string,
        label: (
          <SLabelContainer>
            {option.icon}
            {option.value}
          </SLabelContainer>
        ),
        color: option.color,
      })),
    [options]
  );

  const [selectedValues, setSelectedValues] = useState<any>();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if ((value === undefined || value === null) && defaultValue) {
      onChange(settingsKey, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (!value) {
      const selected = multiple
        ? selectOptions.filter((option) => defaultValue?.includes(option.value))
        : selectOptions.find((option) => option.value === defaultValue);

      setSelectedValues(selected);
    } else {
      const selectedValues = selectOptions.filter((option) => {
        if (multiple) {
          return value?.includes(option.value);
        } else {
          return value === option.value;
        }
      });

      setSelectedValues(selectedValues);
    }
  }, [selectOptions, value, multiple, defaultValue]);

  return (
    <>
      <Select
        value={selectedValues}
        onChange={handleChange}
        options={selectOptions}
        inputValue={searchValue}
        onInputChange={(inputValue: string) => setSearchValue(inputValue)}
        placeholder=""
        isSearchable={true}
        isMulti={multiple}
        noOptionsMessage={noOptionsMessage}
        isDisabled={disabled}
        className={className}
      />
    </>
  );
};

const SLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;
