import { LiveThingProperty } from "constants/widgetConstants";
import { Thing } from "models/Thing";
import objectPath from "object-path";
import language from "translations/language";
import { captionDataThingValue, isCaptionDataThing } from "utils/captionDataUtils";

type ThingValueData = {
  stateFields: string[];
  unit: string;
};

const thingValueDataMap: { [key in LiveThingProperty]: ThingValueData } = {
  [LiveThingProperty.Temperature]: {
    stateFields: ["motor1.temperature", "temperature"],
    unit: "°C",
  },
  [LiveThingProperty.WifiSignalStrength]: {
    stateFields: ["wifiSignalStrength"],
    unit: "dBm",
  },
  [LiveThingProperty.RelativeHumidity]: {
    stateFields: ["humidity"],
    unit: "%",
  },
  [LiveThingProperty.WaterPressure]: {
    stateFields: ["motor1.lastPressureResult"],
    unit: "bar",
  },
  [LiveThingProperty.BatteryLevel]: {
    stateFields: ["batteryLevel"],
    unit: "%",
  },
  [LiveThingProperty.Humidity]: {
    stateFields: ["humidity"],
    unit: "%",
  },
  [LiveThingProperty.CaptionDataAbsoluteHumidity]: {
    stateFields: ["absolutehumidity"],
    unit: "g/m3",
  },
  [LiveThingProperty.CaptionDataWME]: {
    stateFields: ["wmevalue"],
    unit: "W/m2",
  },
};

type NoValue = { value?: undefined; name?: undefined; units?: undefined; hasValue: false; error: string };
export type ThingValue = { value: number; name: string; units: string; hasValue: true; error?: undefined };

export function getValueForThing(thing: Thing, property: LiveThingProperty): NoValue | ThingValue {
  if (isCaptionDataThing(thing)) {
    return captionDataThingValue(thing, property);
  }

  const thingValueData = thingValueDataMap[property];

  if (!thingValueData) {
    return noValue(property);
  }

  const { stateFields, unit } = thingValueData;
  const value = getValueFromState(stateFields, thing);

  if (value == null) {
    return noValue(property);
  }

  return {
    hasValue: true,
    name: language.thingProperties[property],
    value: value,
    units: unit,
  };
}

function getValueFromState(stateFields: string[], thing: Thing) {
  for (const field of stateFields) {
    const value = objectPath.get(thing, "state." + field);

    if (value != null) {
      return value;
    }
  }
}

function noValue(property: LiveThingProperty): NoValue {
  return { hasValue: false, error: language.errors.PROPERTY_NOT_AVAILABLE(property) };
}
