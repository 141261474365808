import TableWidget from "components/widgets/TableWidget";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { TableWidgetSettings } from "models/widgets/TableWidget";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { TableWidgetThumbSVG } from "../thumbnails";
import { WidgetData } from "../widgetData";
import { tableWidgetOnSave } from "./tableWidgetOnSave";
import { tableWidgetSettings } from "./tableWidgetSettings";

export const TableWidgetData: WidgetData<WidgetType.TableWidget, TableWidgetSettings & WidgetSettings> = {
  type: WidgetType.TableWidget,

  name: language.widgets.TABLE,

  description: {
    ...language.widgets.descriptions[WidgetType.TableWidget],
    thumbnail: TableWidgetThumbSVG,
  },

  dashboardPurposes: [DashboardPurpose.Generic, DashboardPurpose.Customer, DashboardPurpose.Stack],

  settings: tableWidgetSettings,

  hideTitle: true,

  onSave: tableWidgetOnSave,

  component: (props) => <TableWidget {...props} />,
};
