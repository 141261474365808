import { TimeseriesType } from "./widgets/ChartWidget";

export type Timeseries = {
  times: Date[];
  /** This depends on the type of data the thing has */
  series: {
    [key in TimeseriesType]?: number[];
  };
};

export function toTimeseries(json: any) {
  return {
    ...json,
    times: json.times.map((time: string) => new Date(time)),
  };
}
