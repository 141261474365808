import GaugeWidget from "components/widgets/gaugeWidget/GaugeWidget";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { GaugeWidgetSettings } from "models/widgets/GaugeWidget";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { GaugeThumbSVG } from "../thumbnails";
import { WidgetData } from "../widgetData";
import { gaugeWidgetSettings } from "./gaugeWidgetSettings";

export const GaugeWidgetData: WidgetData<WidgetType.GaugeWidget, GaugeWidgetSettings & WidgetSettings> = {
  type: WidgetType.GaugeWidget,

  name: language.widgets.GAUGE,

  description: {
    ...language.widgets.descriptions[WidgetType.GaugeWidget],
    thumbnail: GaugeThumbSVG,
  },

  dashboardPurposes: [DashboardPurpose.Generic, DashboardPurpose.Customer, DashboardPurpose.Stack],

  settings: gaugeWidgetSettings,

  component: (props) => <GaugeWidget {...props} />,
};
