import { SettingsType } from "constants/constants";
import { TimeInterval } from "constants/widgetConstants";
import { titleSetting } from "data/commonSettings";
import { SettingsDescriptor, SwitchSettings } from "data/widgetData";
import { ThingAlarmLike, ThingAlarmType } from "models/Thing";
import { ConnectivityMessage, KnownEventType } from "models/Timeline";
import { AggregationType, ChartSourceTypes } from "models/widgets/ChartWidget";
import language from "translations/language";

export const chartWidgetAlarmTypes = [
  ThingAlarmType.LargeWaterFlowAlarm,
  ThingAlarmType.LongWaterFlowAlarm,
  ThingAlarmType.LargeWaterVolumeAlarm,
  ThingAlarmType.LowTempAlarm,
  ThingAlarmType.SlowLeakage,
  ThingAlarmType.WaterDetected,
  ThingAlarmLike.PressureAlarm1,
  ThingAlarmLike.PressureAlarm2,
  ThingAlarmType.LeakAlarm,
];

const eventOptionPairEntries = [
  ...Object.values(ConnectivityMessage).map((value) => [
    `${KnownEventType.Connectivity}-${value}`,
    {
      value: language.timelineEventSubTypes[value],
      update: {
        eventType: KnownEventType.Connectivity,
        eventSubType: value,
      },
    },
  ]),

  ...chartWidgetAlarmTypes.map((value) => [
    `${KnownEventType.Alarms}-${value}`,
    {
      value: language.timelineEventSubTypes[value as ThingAlarmType | ConnectivityMessage] ?? value,
      update: {
        eventType: KnownEventType.Alarms,
        eventSubType: value,
      },
    },
  ]),
];

const thingEventTimeSettings: SettingsDescriptor = {
  thingId: {
    type: SettingsType.Thing,
    label: language.widgets.settings.THINGID,
    multiple: false,
  },

  event: {
    type: SettingsType.CompositeSelect,
    label: language.widgets.settings.EVENT,
    keys: ["eventType", "eventSubType"],
    options: Object.fromEntries(eventOptionPairEntries),
    default: null,
  },

  eventType: {
    type: SettingsType.Hidden,
  },

  eventSubType: {
    type: SettingsType.Hidden,
  },
};

const thingTimeseriesSettings: SettingsDescriptor = {
  aggregationType: {
    type: SettingsType.Select,
    label: language.widgets.settings.AGGREGATION_TYPE,
    default: AggregationType.Aggregated,
    multiple: false,
    options: Object.entries(AggregationType).map(([_key, value]) => {
      return { key: value, value: language.aggregationType[value] };
    }),
  },
  thingIds: {
    type: SettingsType.Thing,
    label: language.widgets.settings.THINGID,
    multiple: true,
  },

  metrics: {
    type: SettingsType.ThingMetric,
    label: language.widgets.settings.THING_PROPERTY,
    multiple: true,
    options: "TimeseriesType",
    thingsIdField: "thingIds",
  },
};

function buildAxisMetrics(label: string): SwitchSettings {
  return {
    label: label,
    type: SettingsType.Switch,
    switchField: {
      type: SettingsType.Select,
      label: language.widgets.settings.DATA_SOURCE_TYPE,
      default: null,
      multiple: false,
      options: [["", ""], ...Object.entries(ChartSourceTypes)].map(([_key, value]) => {
        return { key: value, value: language.sourceTypes[value] };
      }),
    },
    switchKey: "sourceType",
    options: {
      [ChartSourceTypes.ThingEventTime]: thingEventTimeSettings,
      [ChartSourceTypes.ThingsTimeSeries]: thingTimeseriesSettings,
    },
  };
}

export const chartWidgetSettings: SettingsDescriptor = {
  title: titleSetting,
  timeInterval: {
    type: SettingsType.TimeInterval,
    label: language.TIME_INTERVAL,
    options: [
      ...Object.values(TimeInterval).map((timeInterval) => ({
        key: timeInterval,
        value: language.timeIntervals[timeInterval as TimeInterval],
      })),
    ],
  },
  leftAxisMetrics: buildAxisMetrics(language.widgets.settings.LEFT_AXIS_METRICS),
  rightAxisMetrics: buildAxisMetrics(language.widgets.settings.RIGHT_AXIS_METRICS),
};
