import HealthWidget from "components/widgets/HealthWidget";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { HealthWidgetSettings } from "models/widgets/HealthWidget";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { PropertyHealthSVG } from "../thumbnails";
import { WidgetData } from "../widgetData";
import { healthWidgetSettings } from "./healthSettings";

export const HealthData: WidgetData<WidgetType.HealthWidget, HealthWidgetSettings & WidgetSettings> = {
  type: WidgetType.HealthWidget,

  name: language.widgets.PROPERTY_HEALTH,

  description: {
    ...language.widgets.descriptions[WidgetType.HealthWidget],
    thumbnail: PropertyHealthSVG,
  },

  dashboardPurposes: [DashboardPurpose.Generic, DashboardPurpose.Customer, DashboardPurpose.Stack],

  settings: healthWidgetSettings,

  hideTitle: true,

  component: (props) => <HealthWidget {...props} />,
};
