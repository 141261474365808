/**
 * Data sources
 * @module datasources/hooks
 *
 * The hooks provided in this module allow a widget to load data from a data source without knowing about the specific
 * details of how to load the data.
 *
 * The loading of the data itself is handled by adapters that are defined in `datasources/index.ts`.
 *
 * See `adr/002_create_data_source_abstraction.md` for the reasoning behind this design.
 */
import {
  categorisedDataLookups,
  geoLookups,
  singleValueLookups,
  tableValueDataLookups,
  timeChartLookups,
} from "datasources";
import { CategorisedValues } from "datasources/categoryData/interfaces";
import { DataSource } from "datasources/core";
import { GeoTaggedData } from "datasources/geoTaggedData/interfaces";
import { SingleValue } from "datasources/singleValueData/interfaces";
import { DateRangeConfig, TimeChartValues } from "datasources/timeChartValuesData/interfaces";
import { Loadable } from "models/Loading";
import { ChartDataSource } from "models/widgets/ChartWidget";
import { SingleValueDataSource as SingleValueDataSourceConfig } from "models/widgets/common/common";
import { MultipleValueDataSource } from "models/widgets/common/value";
import { GeoDataSource } from "models/widgets/MapWidget";
import {
  MultipleAlarmsDataSource,
  MultipleIncidentStatsDataSource,
  MultipleStacksDataSource,
  MultipleThingPropertiesDataSource,
  MultipleThingsPropertiesDataSource,
} from "models/widgets/TableWidget";
import { WidgetViewModel } from "models/widgets/Widget";
import { useEffect, useMemo } from "react";
import { useStore } from "stores/RootStore";
import { TableValues } from "./tableData/interfaces";

export const useGeoTaggedDataFromSource = (sourceConfig: GeoDataSource | null | undefined): Loadable<GeoTaggedData> => {
  const dataSourceMap = useDataSourceMap(geoLookups);
  return useDataFromDataSource(sourceConfig, dataSourceMap);
};

export const useSingleValueFromDataSource = (
  sourceConfig: SingleValueDataSourceConfig | null | undefined
): Loadable<SingleValue> => {
  const dataSourceMap = useDataSourceMap(singleValueLookups);

  return useDataFromDataSource(sourceConfig, dataSourceMap);
};

export const useMultipleValueFromDataSource = (
  sourceConfig:
    | MultipleThingPropertiesDataSource
    | MultipleIncidentStatsDataSource
    | MultipleThingsPropertiesDataSource
    | MultipleAlarmsDataSource
    | MultipleStacksDataSource
    | null
    | undefined,
  widget: WidgetViewModel,
  additionalData: {
    page: number;
  }
): Loadable<TableValues> => {
  const dataSourceMap = useDataSourceMap(tableValueDataLookups);
  return useDataFromDataSource(sourceConfig, dataSourceMap, widget, additionalData);
};

export const useCategorisedValuesFromDataSource = (
  sourceConfig: MultipleValueDataSource | null | undefined
): Loadable<CategorisedValues> => {
  const dataSourceMap = useDataSourceMap(categorisedDataLookups);
  return useDataFromDataSource(sourceConfig, dataSourceMap);
};

export const useTimeChartValuesFromManyDataSources = (
  sourceConfigs: (ChartDataSource & DateRangeConfig)[]
): Loadable<TimeChartValues>[] => {
  const dataSourceMap = useDataSourceMap(timeChartLookups);
  return useDataFromManyDataSources(sourceConfigs, dataSourceMap);
};

/*
 * This function is not exported directly as we don't want to expose this detail to the rest of the app
 * it should be consumed via one of:
 * - useGeoTaggedDataFromSource
 * - useSingleValueFromDataSource
 * - useTimeChartValuesFromManyDataSources
 */
const useDataSourceMap = <
  TSourceTypes extends string,
  TConfig extends { sourceType: TSourceTypes },
  TSource extends DataSource<any, TConfig>
>(
  dataSourceLookupBuilder: (rootStore: any) => Record<TSourceTypes, TSource>
): Record<TSourceTypes, TSource> => {
  const rootStore = useStore();

  /*
   * The mapping between the source type and the dataloader object is cached for
   * the root store. This means that the data loader objects won't change or be
   * recreated as long as the root store is the same. This will always be the case
   * in prod but not in tests (which is good).
   */
  return useMemo(() => {
    return dataSourceLookupBuilder(rootStore);
  }, [rootStore, dataSourceLookupBuilder]);
};

/*
 * This function is not exported directly as we don't want to expose this detail to the rest of the app
 * it should be consumed via one of:
 * - useGeoTaggedDataFromSource
 * - useSingleValueFromDataSource
 * - useMultipleValueFromDataSource
 * - useTimeChartValuesFromManyDataSources
 */
const useDataFromDataSource = <
  TSourceTypes extends string,
  TSource extends DataSource<any, TConfig>,
  TConfig extends { sourceType: TSourceTypes },
  TDataType
>(
  sourceConfig: TConfig | null | undefined,
  dataSourceMap: Record<TSourceTypes, TSource>,
  widget?: WidgetViewModel,
  additionalData: any = {}
): Loadable<TDataType> => {
  const sourceConfigs = useMemo(() => {
    const fuckingHell = (_a: any) => {};
    fuckingHell(widget);

    return sourceConfig ? [sourceConfig] : [];
  }, [sourceConfig, widget]);

  return useDataFromManyDataSources(sourceConfigs, dataSourceMap, widget, additionalData)[0];
};

/*
 * This function is not exported directly as we don't want to expose this detail to the rest of the app
 * it should be consumed via one of:
 * - useGeoTaggedDataFromSource
 * - useSingleValueFromDataSource
 * - useTimeChartValuesFromManyDataSources
 */
const useDataFromManyDataSources = <
  TSourceTypes extends string,
  TSource extends DataSource<any, TConfig>,
  TConfig extends { sourceType: TSourceTypes },
  TDataType
>(
  sourceConfigs: TConfig[],
  dataSourceMap: Record<TSourceTypes, TSource>,
  widget?: WidgetViewModel,
  additionalData: any = {}
): Loadable<TDataType>[] => {
  /*
   * This hash is used to ensure that the useEffect hook is called when the source configs change.
   * The array's identity is not consistent between renders so we need to use a hash of the contents
   */
  const sourceConfigHash = useMemo(
    () => hashConfigs(sourceConfigs.concat((widget as any) ?? {}, additionalData ?? {})),
    [sourceConfigs, widget, additionalData]
  );

  useEffect(() => {
    const intervals = sourceConfigs
      .map((sourceConfig) => {
        const dataSource = dataSourceMap[sourceConfig.sourceType];

        dataSource.loadData(sourceConfig, widget, additionalData);

        if (dataSource.refreshTimeout) {
          return setInterval(
            () => dataSource.loadData(sourceConfig, widget, additionalData),
            dataSource.refreshTimeout
          );
        }

        return null;
      })
      .filter((interval) => interval != null) as unknown as number[];

    return () => intervals.forEach((interval) => clearInterval(interval));

    /* We're using a hash of the sourceConfigs array so it's not a dep itself. See the comment above. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceConfigHash, dataSourceMap]);

  return sourceConfigs.map((sourceConfig) =>
    dataSourceMap[sourceConfig.sourceType].getData(sourceConfig, widget, additionalData)
  );
};

const hashConfigs = <TConfig>(sourceConfigs: TConfig[]) => JSON.stringify(sourceConfigs);
