import { makeAutoObservable } from "mobx";

export class AppStore {
  private _isFullScreen = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isFullScreen() {
    return this._isFullScreen;
  }

  toggleFullScreen() {
    this._isFullScreen = !this._isFullScreen;
  }

  exitFullScreen() {
    this._isFullScreen = false;
  }
}
