import MapWidget from "components/widgets/MapWidget";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { MapWidgetSettings } from "models/widgets/MapWidget";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { MapThumbImg } from "../thumbnails";
import { WidgetData } from "../widgetData";
import { mapWidgetSettings } from "./mapWidgetSettings";

export const MapWidgetData: WidgetData<WidgetType.MapWidget, MapWidgetSettings & WidgetSettings> = {
  type: WidgetType.MapWidget,

  name: language.widgets.MAP_WIDGET,

  description: {
    ...language.widgets.descriptions[WidgetType.MapWidget],
    thumbnail: MapThumbImg,
  },

  dashboardPurposes: [DashboardPurpose.Generic, DashboardPurpose.Customer, DashboardPurpose.Stack],

  settings: mapWidgetSettings,

  hideTitle: true,

  component: (props) => <MapWidget {...props} />,
};
