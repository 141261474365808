import NiceModal, { antdModal, useModal } from "@ebay/nice-modal-react";
import { Button } from "components/common";
import * as Form from "components/common/form";
import Modal from "components/common/modal";
import SettingsComponents from "components/edit/SettingsComponents";
import { DashboardPurpose } from "constants/dashboardConstants";
import { getWidgetData } from "data/widgetData";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { FormEventHandler } from "react";
import { useStore } from "stores/RootStore";
import language from "translations/language";

type Props = {
  widgetId: string;
};

export const EditWidgetModal = NiceModal.create(({ widgetId }: Props) => {
  const { dashboardStore } = useStore();
  const modal = useModal();

  const widget = dashboardStore.getWidgetById(widgetId);

  if (!widget) {
    modal.reject("Can't find the widget with id " + widgetId);
    return null;
  }

  const settingsComponentState = observable(toJS(widget ?? {}).settings);

  const widgetData = getWidgetData(widget);
  const onSave = widgetData?.onSave;

  const settingsComponentSettings = (widgetData ?? {}).settings;

  const updateWidget: FormEventHandler = async (e) => {
    e.preventDefault();

    let updatedSettings = {
      ...settingsComponentSettings,
      ...settingsComponentState,
    };

    try {
      if (onSave) {
        updatedSettings = onSave(updatedSettings);
      }

      const res = await dashboardStore.updateWidget(widget.dashboardId, widgetId!, {
        ...widget,
        settings: updatedSettings,
      });

      modal.resolve(res);
      modal.hide();
    } catch (err) {
      console.error("Update widget error:", err);
      modal.reject(err);
    }
  };

  if (!settingsComponentSettings) {
    return <></>;
  }

  return (
    // antdModal helpers is the easiest way to standardize the modal props
    <Modal {...antdModal(modal)}>
      <Form.Form onSubmit={updateWidget}>
        <SettingsComponents
          settingsDescriptor={settingsComponentSettings}
          state={settingsComponentState}
          dashboardMeta={dashboardStore.currentDashboard.metadata ?? { dashboardPurpose: DashboardPurpose.Generic }}
        />
        <Form.SubmitRow>
          <Button size="lg">{language.SAVE}</Button>
        </Form.SubmitRow>
      </Form.Form>
    </Modal>
  );
});

export default observer(EditWidgetModal);
