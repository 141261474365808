import StackedBarChartWidget from "components/widgets/StackedBarChartWidget";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { StackedBarChartWidgetSettings } from "models/widgets/StackedBarChartWidget";
import { WidgetSettings } from "models/widgets/Widget";
import language from "translations/language";
import { StackedBarchartThumbSVG } from "../thumbnails";
import { WidgetData } from "../widgetData";
import { stackedBarChartWidgetSettings } from "./stackedBarChartWidgetSettings";

export const StakedBarChartWidgetData: WidgetData<
  WidgetType.StackedBarChartWidget,
  StackedBarChartWidgetSettings & WidgetSettings
> = {
  type: WidgetType.StackedBarChartWidget,

  name: language.widgets.STACKED_BAR_CHART,

  description: {
    ...language.widgets.descriptions[WidgetType.StackedBarChartWidget],
    thumbnail: StackedBarchartThumbSVG,
  },

  dashboardPurposes: [DashboardPurpose.Generic],

  settings: stackedBarChartWidgetSettings,

  component: (props) => <StackedBarChartWidget {...props} />,
};
