import { runInAction } from "mobx";
import { Incident, IncidentId } from "models/IncidentStats";
import IncidentsAPIClient, { GetIncidentsQuery } from "services/api/IncidentsAPIClient";
import { SingleItemSource } from "./interfaces";
import { SimpleStore } from "./SimpleStore";
import { LoadingStatus } from "constants/constants";
import { LoadedEntity, ReloadingEntity, hasData } from "models/Loading";

type LoadedIncident = LoadedEntity<Incident, "incidentId", string>;

type ReloadingIncident = ReloadingEntity<Incident, "incidentId", string>;

export class IncidentStore
  extends SimpleStore<IncidentId, Incident, "incidentId">
  implements SingleItemSource<IncidentId, Incident, "incidentId">
{
  private incidentsAPIClient = new IncidentsAPIClient();

  protected async fetchFromClientForKey(incidentId: IncidentId): Promise<Incident> {
    return await this.incidentsAPIClient.getIncidentById(incidentId);
  }

  protected getRegistryIndex(incidentId: IncidentId): string {
    return incidentId;
  }

  public readonly keyFieldName = "incidentId" as const;

  get loadedIncidents(): (LoadedIncident | ReloadingIncident)[] {
    return Array.from(this.registry.values()).filter(hasData<Incident>);
  }

  async loadIncidents({ page = 0, size = 10, sortBy = "name", sortOrder, incidentType, status }: GetIncidentsQuery) {
    const { incidents, totalCount } = await this.incidentsAPIClient.getIncidents({
      size,
      page,
      sortOrder,
      sortBy,
      incidentType,
      status,
    });

    runInAction(() => {
      incidents.forEach((incident) => {
        this.registry.set(incident.incidentId, { loadingStatus: LoadingStatus.Loaded, ...incident });
      });
    });

    return { incidents, totalCount };
  }
}
