import { Owner } from "./Owner";
import { Acl } from "./Acl";
import { ThingAlarmType } from "./Thing";

// This list is not comprehensive, but we can add more as we need them.
// Technically the field is completely free text. but I think it makes more sense for us not to expose that. We
// can then provide a dropdown/select type thing to users.
export enum KnownEventType {
  Alarms = "alarms",
  Connectivity = "connectivity",
}

export type Timeline<EventType extends KnownEventType | string = string> = {
  timelineId: string;
  createdBy: string;
  modified: Date;
  created: Date;
  acl: Acl;
  correlationId: string;
  owner: Owner;
  eventType: EventType;
  message: string;
  moreInfo: any;
  signedInstallationId: string | null;
  thingId: string;
};

export type TimeLineEventCollection<EventType extends string = string> = {
  events: Timeline<EventType>[];
  earliestDate: Date;
};

export const mergeTimelineEventCollections = <EventType extends string, T extends TimeLineEventCollection<EventType>>(
  a: T,
  b: T
): T => {
  const events = uniqEvents([...a.events, ...b.events]).sort(
    (a, b) => a.created.getTime() - b.created.getTime() || a.created - b.created
  );
  return {
    events: events,
    earliestDate: events[0]?.created ?? new Date(),
  } as T;
};

const uniqEvents = (events: Timeline[]) => {
  const map = new Map();
  events.forEach((event) => {
    map.set(event.timelineId, event);
  });
  return Array.from(map.values());
};

export type AlarmTimeline = Timeline<KnownEventType.Alarms> & {
  // plain string is still here because the values could be anything, but we have a few known messages
  message: string | ThingAlarmType;
};

export type ConnectivityTimeline = Timeline<KnownEventType.Connectivity> & {
  // plain string is still here because the values could be anything, but we have a few known messages
  message: string | ConnectivityMessage;
};

export enum ConnectivityMessage {
  Timeout = "connectivity_timedout",
  Online = "connectivity_online",
  Offline = "connectivity_offline",
}

export function toTimeline(input: any): Timeline {
  return {
    ...input,
    modified: new Date(input.modified),
    created: new Date(input.created),
  };
}
