import styled from "@emotion/styled";

type TitleProps = {
  text: string;
  icon: React.ReactNode;
};

export const Title = ({ text, icon }: TitleProps) => {
  return (
    <S.Title>
      {icon}
      <div>{text}</div>
    </S.Title>
  );
};

// prettier-ignore
const S = {
    Title: styled.div(({theme}) => `
        display: flex;
        align-items: center;
        gap: 8px;

        font-size: 20px;
        font-style: normal;
        font-weight: 700;

        color: ${theme.color.primary300};
    `)
};
