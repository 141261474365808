import { runInAction, makeAutoObservable } from "mobx";
import { Organization } from "models/Organization";
import { AccountsAPIClient } from "services/api/AccountsAPIClient";

type OrganizationId = string;

export class OrganizationStore {
  private accountsClient = new AccountsAPIClient();

  private registry = new Map<OrganizationId, Organization>();

  constructor() {
    makeAutoObservable(this);
  }

  async loadAll(): Promise<void> {
    const organizations = await this.accountsClient.getOrganizations();

    runInAction(() =>
      organizations.forEach((organization) => this.registry.set(organization.organizationId, organization))
    );
  }

  get organizations() {
    return Array.from(this.registry.values());
  }
}
