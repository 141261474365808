import { makeObservable, observable, runInAction } from "mobx";
import { LoadingStatus } from "../constants/constants";
import { Loadable } from "../models/Loading";
import { BulkItemSource, SingleItemSource } from "./interfaces";

export abstract class SimpleStore<TKey, TItem, TKeyFieldName extends string>
  implements SingleItemSource<TKey, TItem, TKeyFieldName>, BulkItemSource<TKey, TItem, TKeyFieldName>
{
  protected registry = new Map<string, Loadable<TItem, TKeyFieldName, TKey>>();

  constructor() {
    // @ts-ignore - A little concerned about this but typescript thinks than registry doesn't exist in this class
    makeObservable(this, { registry: observable });
  }

  async load(key: TKey) {
    const existingStats = this.registry.get(this.getRegistryIndex(key));

    if (existingStats && [LoadingStatus.Loading, LoadingStatus.Reloading].includes(existingStats.loadingStatus)) {
      // Something else already triggered the load so no need to do it again
      return;
    }

    if (existingStats && existingStats.loadingStatus === LoadingStatus.Loaded) {
      runInAction(() =>
        this.registry.set(this.getRegistryIndex(key), {
          ...existingStats,
          [this.keyFieldName]: key,
          loadingStatus: LoadingStatus.Reloading,
        })
      );
    } else {
      runInAction(() =>
        this.registry.set(this.getRegistryIndex(key), {
          [this.keyFieldName]: key,
          loadingStatus: LoadingStatus.Loading,
        })
      );
    }

    try {
      const data = await this.fetchFromClientForKey(key);

      runInAction(() => this.updateEntry(key, data));
    } catch (error: any) {
      runInAction(() => {
        const errorMessage = error instanceof Error ? error.message : "unknown error";

        this.registry.set(this.getRegistryIndex(key), {
          loadingStatus: LoadingStatus.Error,
          [this.keyFieldName]: key,
          error: errorMessage,
        });
      });
    }
  }

  protected updateEntry(key: TKey, data: TItem) {
    runInAction(() => {
      return this.registry.set(this.getRegistryIndex(key), {
        ...data,
        [this.keyFieldName]: key,
        loadingStatus: LoadingStatus.Loaded,
      });
    });
  }

  async loadMany(keys: TKey[]) {
    keys.forEach((key) => this.load(key));
  }

  get(key: TKey): Loadable<TItem, TKeyFieldName, TKey> {
    return (
      this.registry.get(this.getRegistryIndex(key)) ?? {
        [this.keyFieldName]: key,
        loadingStatus: LoadingStatus.Loading,
      }
    );
  }

  protected abstract fetchFromClientForKey(key: TKey): Promise<TItem>;

  protected abstract getRegistryIndex(key: TKey): string;

  public abstract readonly keyFieldName: TKeyFieldName;
}
