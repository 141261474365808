import { Palette } from "theme/types";

export const polygonPalette: Palette = {
  primary: {
    default: "#1F6381",
    200: "#46A8D3", // Table Headers, Icons
    300: "#1F6381", // Table Content text
    400: "#1F6381", // Titles, Body Text
  },
  secondary: {
    default: "#009FE3",
    400: "#009FE3", // Links, Secondary Chart Lines
  },
  // Neutral is for the Background
  neutral: {
    default: "#F8F9FC", // Page Background color
    100: "#FFFFFF", // Widget Background, Button text
    300: "#F8F9FC", // Page Background
    400: "#F4F4F2", // Chart Background, Table Highlight
    700: "#1F6381", // Button Background
    "700n": "#FFFFFF", // Button Text
  },
  success: {
    default: "#6A971F",
    400: "#6A971F", // Accent buttons
  },
  warning: {
    default: "#DD7500",
    400: "#DD7500", // Warning Text, Warning Widgets
  },
  error: {
    default: "#A8334C",
    400: "#A8334C", // Errors, Danger Text, Danger Buttons
  },
  // TODO:
  chart: {
    default: "#58E166",
    high: "#FF9CE3",
    medium: "#76F6EE",
    low: "#8CC1FF",
    100: "#8CC1FF",
    200: "#58E166",
    300: "#76F6EE",
    400: "#FF9CE3",
    500: "#009FE3",
  },
};
