import NiceModal, { antdModal, useModal } from "@ebay/nice-modal-react";
import styled from "@emotion/styled";
import Modal from "components/common/modal";
import FairtrailLink from "components/FairtrailLink";
import Filters from "components/widgets/common/Filters";
import { LoadingState } from "components/widgets/loading/LoadingState";
import { FAIRTRAIL_REBORN_STACK_ROUTE, FAIRTRAIL_STACK_ROUTE } from "constants/fairtrailRoutes";
import { HealthFilterValue, PropertyMetric } from "constants/widgetConstants";
import { observer } from "mobx-react";
import { HealthData, NoClassificationReason } from "models/HealthData";
import { useEffect, useState } from "react";
import { useStore } from "stores/RootStore";
import language from "translations/language";

type PropertyInfo = { id: string; name: string; reason?: string | NoClassificationReason };

export type Categories = { [key in HealthFilterValue]: PropertyInfo[] };

type Props = {
  type: PropertyMetric;
  data: HealthData;
  widgetId: string;
  selectedFilter: HealthFilterValue;
};

export const HealthDetailsModal = NiceModal.create(({ type, data, widgetId, selectedFilter }: Props) => {
  const { stackStore } = useStore();

  const modal = useModal();

  const [allDataPoints, setAllDataPoints] = useState<PropertyInfo[]>([]);
  const [currentFilterDataPoints, setCurrentFilterDataPoints] = useState<PropertyInfo[]>([]);
  const [localFilter, setLocalFilter] = useState<HealthFilterValue>(selectedFilter);

  const loadData = async () => {
    const stackIds = [
      ...data.highRisk.map((stack) => stack.stackId),
      ...data.mediumRisk.map((stack) => stack.stackId),
      ...data.normal.map((stack) => stack.stackId),
      ...data.noClassification.map((stack) => stack.stackId),
    ];

    await stackStore.loadStacksForWidget(widgetId, 0, 1000, undefined, "1", `${stackIds}`);

    const stacks = stackStore.getStacksByWidgetId(widgetId);

    const dataPoints = stacks.map((stack) => ({
      id: stack.stackId,
      name: stack.name,
    }));

    setAllDataPoints(dataPoints);

    setCurrentFilterDataPoints(dataPoints);

    setLocalFilter(selectedFilter);
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadData();
    };

    fetchData();
  }, []);

  useEffect(() => {
    setLocalFilter(selectedFilter);
  }, [selectedFilter]);

  useEffect(() => {
    if (allDataPoints.length > 0 && localFilter) {
      onFilter(localFilter);
    }
  }, [allDataPoints, localFilter]);

  const filters = Object.values(HealthFilterValue).map((filter) => {
    if (filter === HealthFilterValue.All) return { id: filter, count: allDataPoints.length };

    const count = data[filter].length;
    return { id: filter, count };
  });

  const onFilter = (filterValue: HealthFilterValue) => {
    if (filterValue === HealthFilterValue.All) {
      setCurrentFilterDataPoints(allDataPoints);
      setLocalFilter(filterValue);
    } else {
      setLocalFilter(filterValue);
      const filteredData = data[filterValue]
        .map((d) => allDataPoints.find((dP) => dP.id === d.stackId))
        .filter(Boolean);
      setCurrentFilterDataPoints(filteredData as PropertyInfo[]);
    }
  };

  return (
    <Modal
      {...antdModal(modal)}
      afterClose={() => {
        setLocalFilter(selectedFilter);
        modal.hide();
      }}
    >
      <S.Title>{language.propertyMetric[type]}</S.Title>
      {allDataPoints.length > 0 ? (
        <>
          <Filters filters={filters} onApplyFilter={onFilter} selectedFilter={selectedFilter} />
          <S.List>
            {currentFilterDataPoints.length > 0 && localFilter !== HealthFilterValue.NoClassification ? (
              currentFilterDataPoints.map((point, i) => (
                <div key={i}>
                  <FairtrailLink
                    href={FAIRTRAIL_STACK_ROUTE.replace(":stackId", point.id)}
                    rebornHref={FAIRTRAIL_REBORN_STACK_ROUTE.replace(":stackId", point.id)}
                  >
                    {point.name}
                  </FairtrailLink>
                </div>
              ))
            ) : (
              <NoClassification currentFilterDataPoints={currentFilterDataPoints} data={data} />
            )}
          </S.List>
        </>
      ) : (
        <LoadingState />
      )}
    </Modal>
  );
});

export default observer(HealthDetailsModal);

interface ReasonProps {
  currentFilterDataPoints: PropertyInfo[];
  data: HealthData;
}

const NoClassification = ({ currentFilterDataPoints, data }: ReasonProps) => {
  const groupedData: { [key: string]: PropertyInfo[] } = {};

  currentFilterDataPoints.forEach((point) => {
    const reason = data.noClassification.find((d) => d.stackId === point.id)?.reason;
    if (reason) {
      if (!groupedData[reason]) {
        groupedData[reason] = [];
      }
      groupedData[reason].push(point);
    }
  });

  return (
    <>
      {Object.entries(groupedData).map(([reason, points], i) => (
        <div key={i}>
          <S.Title>{language.noClassificationReason[reason as keyof typeof language.noClassificationReason]}</S.Title>
          {points.map((point, j) => (
            <S.PointContainer key={j}>
              <FairtrailLink
                href={FAIRTRAIL_STACK_ROUTE.replace(":stackId", point.id)}
                rebornHref={FAIRTRAIL_REBORN_STACK_ROUTE.replace(":stackId", point.id)}
              >
                {point.name}
              </FairtrailLink>
            </S.PointContainer>
          ))}
        </div>
      ))}
    </>
  );
};

// prettier-ignore
const S = {
  Title: styled.div(({ theme }) => `
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;  
    font-weight: 700;
    ${theme.color.bg.neutral100}
  `),
  List: styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  PointContainer: styled.div`
    margin-bottom: 15px;
  `,
};
