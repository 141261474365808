import { fromTimeline } from "components/widgets/genericData/eventTimes";
import { CAPTION_DATA_REFRESH_TIMEOUT } from "constants/captionDataConstants";
import { DEFAULT_REFRESH_TIMEOUT, LoadingStatus } from "constants/constants";
import {
  DateRangeConfig,
  TimeChartValues,
  TimeChartValuesDataSource,
} from "datasources/timeChartValuesData/interfaces";
import dayjs from "dayjs";
import { hasData, Loadable } from "models/Loading";
import { PressureAlarmType, SlowLeakageLevel, Thing, ThingAlarmLike, ThingAlarmType } from "models/Thing";
import { ConnectivityMessage, KnownEventType } from "models/Timeline";
import { ThingEventTimeDataSource } from "models/widgets/ChartWidget";
import { RootStore } from "stores/RootStore";
import { TimelineKey } from "stores/TimelineStore";
import language from "translations/language";
import { intervalToDateFrom, withinRange } from "utils/datetimeUtils";

// For this we need to know when to load the data for as well
type Source = ThingEventTimeDataSource & DateRangeConfig;

export class ThingEventTimeTimeChartValuesAdapter implements TimeChartValuesDataSource<Source> {
  refreshTimeout: number;

  constructor(private stores: RootStore) {
    this.refreshTimeout = this.stores.configStore.deptId ? CAPTION_DATA_REFRESH_TIMEOUT : DEFAULT_REFRESH_TIMEOUT;
  }

  getData(config: Source): Loadable<TimeChartValues> {
    const data = this.stores.timelineStore.get(this.timelineKey(config));

    if (!hasData(data)) {
      return data;
    }

    const usersTimezone = dayjs.tz.guess();
    const now = dayjs().tz(usersTimezone);
    const filteredEvents = fromTimeline(
      data.events.filter((e) => withinRange(e.created, config.dateRangeOrInterval, now)),
      usersTimezone
    );

    return {
      loadingStatus: LoadingStatus.Loaded,
      series: [
        {
          values: filteredEvents.map((event) => [event.date, event.alarmTime]),
          label: this.seriesLabel(config),
          plotType: "discrete",
          valueType: "timeOfDay",
        },
      ],
    };
  }

  async loadData(config: Source) {
    await this.stores.thingStore.load(config.thingId);
    await this.stores.timelineStore.load(this.timelineKey(config));
  }

  private seriesLabel(config: Source) {
    const eventLabel = language.timelineEventTypes[config.eventType as KnownEventType] ?? "?";
    const subEventLabel = this.labelForEventMessage(config.eventSubType);

    if (this.stores.configStore.showOnlyProperties) {
      return `${subEventLabel}`;
    }

    const thing = this.stores.thingStore.get(config.thingId);
    const thingName = hasData<Thing>(thing) ? thing.name : language.UNKNOWN_DEVICE;

    return `${thingName ?? language.UNKNOWN_DEVICE} (${eventLabel} - ${subEventLabel})`;
  }

  private labelForEventMessage(message: string): string {
    return language.timelineEventSubTypes[message as ThingAlarmType | ConnectivityMessage] ?? "?";
  }

  private timelineKey(config: Source): TimelineKey {
    const { eventType, eventSubType, moreInfo } = this.handleEventTypes(config);

    return {
      eventType,
      eventSubType,
      thingId: config.thingId,
      dateFrom: intervalToDateFrom(config.dateRangeOrInterval),
      moreInfo,
    };
  }

  private handleEventTypes(config: Source): {
    eventType: string;
    eventSubType: string[];
    moreInfo?: {
      level: string;
    };
  } {
    if (config.eventType === KnownEventType.Alarms) {
      switch (config.eventSubType) {
        case ThingAlarmType.SlowLeakage:
          return {
            eventType: "slowleakage.flat,slowleakage.rise,slowleakage.fall,slowleakage.undefined",
            eventSubType: [
              SlowLeakageLevel.HighFall,
              SlowLeakageLevel.HighFlat,
              SlowLeakageLevel.HighRise,
              SlowLeakageLevel.LowFall,
              SlowLeakageLevel.LowFlat,
              SlowLeakageLevel.LowRise,
              SlowLeakageLevel.LegacyLowFall,
              SlowLeakageLevel.LegacyLowFlat,
              SlowLeakageLevel.LegacyLowRise,
            ],
          };

        case ThingAlarmType.PressureAlarm:
        case ThingAlarmLike.PressureAlarm1:
          return {
            eventType: KnownEventType.Alarms,
            eventSubType: [ThingAlarmType.PressureAlarm],
            moreInfo: { level: PressureAlarmType.PressureAlarm1 },
          };
        case ThingAlarmLike.PressureAlarm2:
          return {
            eventType: KnownEventType.Alarms,
            eventSubType: [ThingAlarmType.PressureAlarm],
            moreInfo: { level: PressureAlarmType.PressureAlarm2 },
          };
      }
    }

    return {
      eventType: config.eventType,
      eventSubType: [config.eventSubType],
    };
  }

  private handleEventType(config: Source) {
    if (config.eventType === KnownEventType.Alarms) {
      switch (config.eventSubType) {
        case ThingAlarmType.SlowLeakage:
          return "slowleakage.flat,slowleakage.rise,slowleakage.fall,slowleakage.undefined";
      }
    }

    return config.eventType;
  }

  private handleSubEventType(config: Source) {
    if (config.eventType === KnownEventType.Alarms) {
      switch (config.eventSubType) {
        case ThingAlarmType.PressureAlarm:
        case ThingAlarmLike.PressureAlarm1:
          return PressureAlarmType.PressureAlarm1;
        case ThingAlarmLike.PressureAlarm2:
          return PressureAlarmType.PressureAlarm2;
      }
    }

    return config.eventSubType;
  }
}
