import { CAPTION_DATA_REFRESH_TIMEOUT } from "constants/captionDataConstants";
import { DEFAULT_REFRESH_TIMEOUT, LoadingStatus } from "constants/constants";
import { SingleValue, SingleValueDataSource } from "datasources/singleValueData/interfaces";
import { hasData, Loadable } from "models/Loading";
import { IncidentStatsDataSource } from "models/widgets/common/common";
import { RootStore } from "stores/RootStore";

export class IncidentStatsSingleValueAdapter implements SingleValueDataSource<IncidentStatsDataSource> {
  refreshTimeout: number;

  constructor(private stores: RootStore) {
    this.refreshTimeout = this.stores.configStore.deptId ? CAPTION_DATA_REFRESH_TIMEOUT : DEFAULT_REFRESH_TIMEOUT;
  }

  getData(config: IncidentStatsDataSource): Loadable<SingleValue> {
    const incidentStats = this.stores.incidentStatsStore.get({ type: config.incidentType, range: config.dayRange });

    if (!hasData(incidentStats)) {
      return incidentStats;
    }

    return {
      loadingStatus: LoadingStatus.Loaded,
      value: incidentStats.count,
      label: "", // TODO: generate a generically useful label for this,
    };
  }

  async loadData(config: IncidentStatsDataSource) {
    await this.stores.incidentStatsStore.load({ type: config.incidentType, range: config.dayRange });
  }
}
