import { useEffect } from "react";
import { Loadable } from "../../models/Loading";
import { LoadingStatus } from "../../constants/constants";
import { BulkItemSource, SingleItemSource } from "../interfaces";
import { LooseEqualityMap } from "../../utils/common";

const fifteenMinutes = 1000 * 60 * 15;
const defaultDataRefresh = fifteenMinutes;

type Options = {
  disableAutoRefresh?: boolean;
};

export function useStoreItem<TKey, TItem, TKeyFieldName extends string>(
  store: SingleItemSource<TKey, TItem, TKeyFieldName>,
  itemKey: TKey,
  options?: Options
): Loadable<TItem, TKeyFieldName, TKey> {
  useEffect(() => {
    if (itemKey == null) {
      return;
    }

    const loadData = async () => {
      await store.load(itemKey);
    };

    loadData();

    if (!options?.disableAutoRefresh) {
      const interval = setInterval(loadData, defaultDataRefresh);
      return () => clearInterval(interval);
    }
    // We don't need the deps here because this is a hook it would also cause an infinite loop if we did
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (itemKey == null) {
    return {
      loadingStatus: LoadingStatus.Error,
      error: "Item id not specified",
    };
  }

  return store.get(itemKey);
}

export function useManyStoreItems<TKey, TItem, TKeyFieldName extends string>(
  store: BulkItemSource<TKey, TItem, TKeyFieldName>,
  itemKeys: TKey[],
  options?: Options
): LooseEqualityMap<TKey, Loadable<TItem, TKeyFieldName, TKey>> {
  useEffect(() => {
    const loadData = async () => {
      await store.loadMany(itemKeys);
    };

    loadData();

    if (!options?.disableAutoRefresh) {
      const interval = setInterval(loadData, defaultDataRefresh);
      return () => clearInterval(interval);
    }
    // We don't need the deps here because this is a hook it would also cause an infinite loop if we did
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return new LooseEqualityMap(itemKeys.map((itemKey) => [itemKey, store.get(itemKey)]));
}
