import { computed, makeObservable, observable, runInAction } from "mobx";
import { VisualisationsProxyAPIClient } from "services/api/VisualisationsProxyAPIClient";
import { areCaptionDataThings } from "utils/captionDataUtils";
import { LoadingStatus } from "../constants/constants";
import { hasData, LoadedEntity, ReloadingEntity } from "../models/Loading";
import { Thing } from "../models/Thing";
import { BulkItemSource, SingleItemSource } from "./interfaces";
import { SimpleStore } from "./SimpleStore";

type LoadedThing = LoadedEntity<Thing, "thingId", string>;

type ReloadingThing = ReloadingEntity<Thing, "thingId", string>;

type ThingId = string;

const FIVE_MINUTES = 5 * 60 * 1000;

type LoadThingsQuery = {
  page: number;
  size: number;
  q?: string;
  thingIds?: string[];
  thresholdMetric?: string;
  thresholdValue?: number;
  thresholdOperator?: string;
  category?: string;
  expandAll?: boolean;
  checkIfAlreadyLoaded?: boolean;
};

export class ThingStore
  extends SimpleStore<ThingId, Thing, "thingId">
  implements SingleItemSource<ThingId, Thing, "thingId">, BulkItemSource<ThingId, Thing, "thingId">
{
  private visualisationsProxyAPIClient = new VisualisationsProxyAPIClient();

  thingsByWidgetId = new Map<
    string,
    {
      thingIds: string[];
      totalCount: number;
      pageSize: number;
      page: number;
    }
  >();

  constructor() {
    super();
    makeObservable(this, {
      loadedThings: computed,
      things: computed,
      thingsByWidgetId: observable,
    });
  }

  private lastLoadThings: { [query: string]: Date } = {};

  // TODO: make one query param instead
  /**
   * @param expandAll caption data specific param, if true, all systems will be expanded
   * @param checkIfAlreadyLoaded if true, will check if a call with the the same query params was made in the last 5 minutes and if so, will not make the call again
   */
  async loadThings({
    page = 0,
    size = 10,
    q,
    thingIds,
    thresholdMetric,
    thresholdValue,
    thresholdOperator,
    category,
    expandAll,
    checkIfAlreadyLoaded,
  }: LoadThingsQuery) {
    if (checkIfAlreadyLoaded) {
      const query = JSON.stringify({ page, size, q, thingIds, expandAll });

      if (this.lastLoadThings[query] && new Date().getTime() - this.lastLoadThings[query].getTime() < FIVE_MINUTES) {
        return;
      }

      this.lastLoadThings[query] = new Date();
    }

    const { things, totalCount } = await this.visualisationsProxyAPIClient.getThings({
      page,
      size,
      q,
      thingIds,
      thresholdMetric,
      thresholdValue,
      thresholdOperator,
      category,
      expandAll,
    });

    if (areCaptionDataThings(things)) {
      this.keepDetectedCapabilities(things);
      this.mergeState(things);
    }

    runInAction(() =>
      things.forEach((thing) => this.registry.set(thing.thingId, { loadingStatus: LoadingStatus.Loaded, ...thing }))
    );

    return { things, totalCount };
  }

  async loadThingsForWidget({
    widgetId,
    page = 0,
    size = 10,
    q,
    thingIds,
    thresholdMetric,
    thresholdValue,
    thresholdOperator,
    category,
  }: {
    widgetId: string;
  } & Omit<LoadThingsQuery, "checkIfAlreadyLoaded">) {
    const resp = await this.loadThings({
      page,
      size,
      q,
      thingIds,
      thresholdMetric,
      thresholdValue,
      thresholdOperator,
      category,
    });

    if (!resp) {
      return;
    }

    const { things, totalCount } = resp;

    runInAction(() =>
      this.thingsByWidgetId.set(widgetId, {
        thingIds: things.map((thing) => thing.thingId),
        totalCount,
        pageSize: size,
        page,
      })
    );
  }

  async loadAllThingsForUser(userId: string) {
    await this.loadThings({
      page: 0,
      size: 10000,
      q: userId,
    });
  }

  async loadAll() {
    await this.loadThings({
      page: 0,
      size: 10000,
    });
  }

  getThingsByWidgetId(widgetId: string) {
    const thingsByWidgetId = this.thingsByWidgetId.get(widgetId);

    if (thingsByWidgetId) {
      return thingsByWidgetId.thingIds.map((thingId) => this.registry.get(thingId));
    } else {
      return [];
    }
  }

  async loadThingsById(thingIds: string[]) {
    const thingsToFetch: string[] = [];

    thingIds.forEach((thingId) => {
      const existingThing = this.registry.get(thingId);

      if (existingThing && [LoadingStatus.Loading, LoadingStatus.Reloading].includes(existingThing.loadingStatus)) {
        /** Something else already triggered the load so no need to do it again, ignore the thing for this load */
        return;
      } else {
        /** Otherwise set loading status accordingly  */
        if (existingThing && existingThing.loadingStatus === LoadingStatus.Loaded) {
          runInAction(() => this.registry.set(thingId, { ...existingThing, loadingStatus: LoadingStatus.Reloading }));
        } else {
          runInAction(() => this.registry.set(thingId, { loadingStatus: LoadingStatus.Loading, thingId }));
        }

        /** And include thingId in array of things to fetch */
        thingsToFetch.push(thingId);
      }
    });

    if (thingsToFetch.length === 0) {
      return;
    }

    try {
      const { things } = await this.visualisationsProxyAPIClient.getThings({
        page: 0,
        size: 100,
        thingIds: thingsToFetch,
      });

      if (areCaptionDataThings(things)) {
        this.keepDetectedCapabilities(things);
        this.mergeState(things);
      }

      runInAction(() =>
        things.forEach((thing) => this.registry.set(thing.thingId, { ...thing, loadingStatus: LoadingStatus.Loaded }))
      );
    } catch (error: any) {
      const errorMessage = error instanceof Error ? error.message : "unknown error";

      runInAction(() =>
        thingIds.forEach((thingId) =>
          this.registry.set(thingId, { loadingStatus: LoadingStatus.Error, thingId, error: errorMessage })
        )
      );
    }
  }

  async loadMany(keys: ThingId[]) {
    await this.loadThingsById(keys);
  }

  get things() {
    return Array.from(this.registry.values());
  }

  get loadedThings(): (LoadedThing | ReloadingThing)[] {
    return Array.from(this.registry.values()).filter(hasData<Thing>);
  }

  thingUpdated(thing: Thing) {
    const { thingId } = thing;
    const existingThing = this.get(thingId) ?? ({} as Thing);
    const merged = { ...existingThing, ...thing, loadingStatus: LoadingStatus.Loaded } as LoadedThing;

    runInAction(() => this.registry.set(thingId, merged));
  }

  protected async fetchFromClientForKey(thingId: ThingId): Promise<Thing> {
    return await this.visualisationsProxyAPIClient.getThingById(thingId);
  }

  protected getRegistryIndex(thingId: ThingId): string {
    return thingId;
  }

  /**
   * If the thing has no detected capabilities, we need to keep the existing ones
   * This is needed for the caption data as the detected capabilities are not returned in the bulk endpoint
   * (caption data api weakness)
   */
  private keepDetectedCapabilities(things: Thing[]) {
    things.forEach((thing) => {
      if (!thing.productType.detectedCapabilities?.length) {
        const existingThing = this.registry.get(thing.thingId);

        if (hasData<Thing>(existingThing)) {
          thing.productType.detectedCapabilities = existingThing?.productType.detectedCapabilities;
        }
      }
    });
  }

  /**
   * We merge states as the caption data bulk endpoint does not return the state
   * (caption data api weakness)
   */
  private mergeState(things: Thing[]) {
    things.forEach((thing) => {
      const existingThing = this.registry.get(thing.thingId);

      if (hasData<Thing>(existingThing)) {
        thing.state = { ...existingThing?.state, ...thing.state } as any;
      }
    });
  }

  /** Adds thing to state. Primarily used by other stores when responses include things, such as when getting stacks. */
  addThing(thing: Thing) {
    runInAction(() => this.registry.set(thing.thingId, { ...thing, loadingStatus: LoadingStatus.Loaded }));
  }

  public readonly keyFieldName = "thingId" as const;
}
