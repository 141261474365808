import { useModal } from "@ebay/nice-modal-react";
import styled from "@emotion/styled/macro";
import { IconButton } from "components/common";
import { ExportDropdown } from "components/dropdowns/ExportDropdown";
import { ConfirmationModal } from "components/modals/ConfirmationModal";
import EditWidgetModal from "components/modals/EditWidgetModal";
import { ErrorState } from "components/widgets/loading/ErrorState";
import { WidgetActivity } from "constants/permissionConstants";
import { WidgetType } from "constants/widgetConstants";
import { getWidgetColor, getWidgetData } from "data/widgetData";
import { observer } from "mobx-react-lite";
import React, { LegacyRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FiSettings, FiX } from "react-icons/fi";
import { useMeasure } from "react-use";
import { useStore } from "stores/RootStore";
import language from "translations/language";
import { canUpdateDashboard, hasActivity } from "utils/permissionUtils";

interface Props {
  children: any;
  widgetId: string;
  hideTitle?: boolean;
  hideable?: boolean;
  title: string | undefined;
  readonly?: boolean;
}

const availableForExportCharts = [
  WidgetType.ChartWidget,
  WidgetType.BarChartWidget,
  WidgetType.GaugeWidget,
  WidgetType.StackedBarChartWidget,
  WidgetType.TableWidget,
];

export type WidgetFrameOptions = {
  color: string | undefined;
  setColor: (color: string | undefined) => void;
};

const WidgetFrameContext = React.createContext({} as WidgetFrameOptions);

export const WidgetFrameProvider = ({
  children,
  options,
}: {
  children: React.ReactNode;
  options: WidgetFrameOptions;
}) => {
  return <WidgetFrameContext.Provider value={options}>{children}</WidgetFrameContext.Provider>;
};

export const useWidgetFrame = () => React.useContext(WidgetFrameContext);

const WidgetFrame = ({ children, widgetId, hideTitle, hideable, readonly }: Props) => {
  const { dashboardStore } = useStore();
  const [ref, { width, height }] = useMeasure();

  const editWidgetModal = useModal(EditWidgetModal);
  const deleteDashboardModal = useModal(ConfirmationModal);

  const widget = dashboardStore.getWidgetById(widgetId);
  const [color, setColor] = React.useState<string | undefined>(undefined);

  if (!widget) {
    return null;
  }

  const widgetData = getWidgetData(widget!);

  const deleteWidget = () => {
    dashboardStore.deleteWidget(widget?.dashboardId!, widgetId);
  };

  const promptDeleteWidget = async () => {
    const result = await deleteDashboardModal.show({
      message: language.modals.DELETE_WIDGET_MESSAGE,
    });

    if (result) {
      deleteWidget();
    }
  };

  const Container = readonly && hideable ? S.HiddenContainer : hideable ? S.TextContainer : S.Container;
  const HeaderContainer = readonly ? S.HeaderContainer : S.DragHandle;

  const widgetColor = color ? color : getWidgetColor(widget);

  /** For widgets that hide the title we hide the whole header part when in readonly and don't have actions buttons */
  const shouldShowWidgetHeader = !(readonly && hideTitle) || availableForExportCharts.indexOf(widget.type) >= 0;

  return (
    <WidgetFrameProvider options={{ color, setColor }}>
      <Container
        className="widget"
        width={width}
        height={height}
        color={widgetColor}
        ref={ref as LegacyRef<HTMLDivElement>}
      >
        {shouldShowWidgetHeader && (
          <S.Header>
            <HeaderContainer className="dragHandle">
              {!hideTitle && <S.WidgetTitle color={widgetColor}>{widget?.settings.title}</S.WidgetTitle>}
            </HeaderContainer>

            {availableForExportCharts.indexOf(widget.type) > -1 && (
              <S.Actions className="widget-actions">
                <ExportDropdown widget={widget} />
              </S.Actions>
            )}

            {!readonly && (
              <S.Actions className="widget-actions">
                {hasActivity(WidgetActivity.UpdateWidget) &&
                  canUpdateDashboard(widget?.dashboardId!) &&
                  widgetData?.settings &&
                  widget?.settings && (
                    <IconButton onClick={() => editWidgetModal.show({ widgetId })}>
                      <FiSettings />
                    </IconButton>
                  )}

                {hasActivity(WidgetActivity.DeleteWidget) && canUpdateDashboard(widget?.dashboardId!) && (
                  <IconButton onClick={promptDeleteWidget} style={{ fontSize: 21 }}>
                    <FiX />
                  </IconButton>
                )}
              </S.Actions>
            )}
          </S.Header>
        )}

        <ErrorBoundary FallbackComponent={() => <ErrorState>{language.errors.UNEXPECTED_WIDGET_ERROR}</ErrorState>}>
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, { widget });
          })}
        </ErrorBoundary>
      </Container>
    </WidgetFrameProvider>
  );
};

type DimensionProps = {
  width: number;
  height: number;
};

// prettier-ignore
const S = {
  Container: styled.div<DimensionProps>(({ theme, width, height, color }, ) =>`
    --widget-width: ${width};
    --widget-height: ${height};
    ${color ? "color: " + color + ";": ""}
    ${theme.color.bg.neutral100}
    border-radius: 5px;
    height: 100%;
    box-shadow: ${theme.shadow.sm}
    ${color ? "border: " + color + " 4px solid;": ""}
    overflow: hidden;
    container-type: size;
    .widget-actions {
      transition: all 0.3s ease;
      opacity: 0;
      ${theme.color.bg.neutral100}
    }
    &:hover {
      .widget-actions {
        opacity: 1;
      }
    }
  `),

  HeaderContainer: styled.span`
    user-select: none;
    border-radius: 5px;
    width: 100%;
    height: 24px;
    display: inline-block;
  `,

  Header: styled.div(({ theme }) => `
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${theme.typography.body1}
    ${theme.spacing.h(12)}
    ${theme.spacing.pl(4)}
    ${theme.spacing.mb(4)}
    > * {
      z-index: 1;
    }
  `),

  DragHandle: styled.div`
    user-select: none;
    cursor: move;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
  `,

  Actions: styled.div`
    display: flex;
    align-self: stretch;
    button {
      height: 100%;
    }
  `,

  WidgetTitle: styled.h3(({ color }) => `
    margin: 0;
    font-size: 1em;
    ${color ? "color: " + color + ";" : ""}
    font-weight: bold;
  `),

  TextContainer: styled.div<{ height: number, width: number }>(({ theme, width, height }) => `
    --widget-width: ${width};
    --widget-height: ${height};
    border-radius: 5px;
    width: inherit;
    height: inherit;
    box-shadow: ${theme.shadow.sm};
    ${theme.color.bg.neutral100}
    overflow-y: hidden;
    overflow-x: hidden;
    transition: all 0.15s ease-in-out;

    .widget-actions {
      transition: all 0.3s ease;
      opacity: 0;
    }

    &:hover {
      .widget-actions {
        opacity: 1;
      }
    }

    .dragHandle {
      height: 50%;
    }
  `),

  HiddenContainer: styled.div`
    background-color: transparent;
    overflow: hidden;
    width: inherit;
    height: inherit;
  `,
};

export default observer(WidgetFrame);
