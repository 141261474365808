import { DashboardPurpose, DashboardTheme } from "constants/dashboardConstants";

export type Dashboard = {
  dashboardId: string;
  name: string;
  owner: {
    userId: string;
    name: string;
  };
  created: Date;
  modified: Date;
  metadata: DashboardMetadata;
  acl: {
    read: string[];
    write: string[];
    delete: string[];
  };
  settings: DashboardSettings;
};

export function toDashboard(json: any): Dashboard {
  return {
    ...json,
    created: new Date(json.created),
    modified: new Date(json.modified),
  };
}

export type DashboardMetadata = GenericDashboardMetadata | CustomerDashboardMetadata | StackDashboardMetadata;

export type CustomerDashboardMetadata = {
  dashboardPurpose: DashboardPurpose.Customer;
  customerId: string;
};

export type StackDashboardMetadata = {
  dashboardPurpose: DashboardPurpose.Stack;
  stackId: string;
};

export type GenericDashboardMetadata = {
  dashboardPurpose: DashboardPurpose.Generic;
};

export type DashboardSettings = {
  theme: DashboardTheme;
  isPublic: boolean;
};
