import { SimpleStore } from "./SimpleStore";
import { HealthData } from "models/HealthData";
import { HealthWidgetAPIClient } from "services/api/HealthWidgetAPIClient";

type OrganisationId = string;

export class HealthDataStore extends SimpleStore<OrganisationId, HealthData, "widgetId"> {
  private client = new HealthWidgetAPIClient();

  protected async fetchFromClientForKey(widgetId: string) {
    return (await this.client.getData(widgetId)) as HealthData;
  }

  protected getRegistryIndex(widgetId: string) {
    return widgetId;
  }

  public readonly keyFieldName = "widgetId" as const;
}
