/**
 * Data sources
 * @module datasources
 *
 * This module contains the data sources that are used to load data for widgets. All data is exposed via generic
 * interfaces described by the interface in core.ts.
 *
 * The mappings below map between a data source configuration type and the adapter that provides the data. The
 * actual loading of the data itself is handled by some react hooks in `hooks.ts`.
 *
 * See `adr/002_create_data_source_abstraction.md` for the reasoning behind this design.
 */
import { SingleValueSourceTypes } from "constants/widgetConstants";
import { ProtectedHouseholdsCategoryDataAdapter } from "datasources/categoryData/adapters/ProtectedHouseholdsCategoryDataAdapter";
import { CustomerStackLocationAdapter } from "datasources/geoTaggedData/adapters/CustomerStackLocationAdapter";
import { IncidentStatsSingleValueAdapter } from "datasources/singleValueData/adapters/IncidentStatsSingleValueAdapter";
import { ThingAdapterSingleValueAdapter } from "datasources/singleValueData/adapters/ThingAdapterSingleValueAdapter";
import { ThingEventTimeTimeChartValuesAdapter } from "datasources/timeChartValuesData/adapters/ThingEventTimeTimeChartValuesAdapter";
import { ThingTimeSeriesTimeChartValuesAdapter } from "datasources/timeChartValuesData/adapters/ThingTimeSeriesTimeChartValuesAdapter";
import { ChartSourceTypes } from "models/widgets/ChartWidget";
import { MultipleValueSourceTypes } from "models/widgets/common/value";
import { GeoDataSourceTypes } from "models/widgets/MapWidget";
import {
  MultipleAlarmsValueSourceTypes,
  MultiplePropertyValueSourceTypes,
  MultipleStacksValueSourceTypes,
  MultipleThingsValueSourceTypes,
} from "models/widgets/TableWidget";
import { RootStore } from "stores/RootStore";
import { ThingLocationsAdapter } from "./geoTaggedData/adapters/ThingLocationsAdapter";
import { SignedInstallationStatsCategoryDataAdapter } from "./categoryData/adapters/SignedInstallationStatsCategoryDataAdapter";
import { IncidentStatsMultipleValueAdapter } from "./tableData/adapters/IncidentStatsMultipleValueAdapter";
import { ThingMetricThresholdValuesAdapter } from "./tableData/adapters/ThingMetricThresholdValuesAdapter";
import { ThingTableAdapter } from "./tableData/adapters/ThingTableAdapter";
import { AlarmStatsMultipleValueAdapter } from "./tableData/adapters/AlarmStatsMultipleValueAdapter";
import { MultipleStacksValueAdapter } from "./tableData/adapters/MultipleStacksValueAdapter";

export const singleValueLookups = (rootStore: RootStore) => ({
  [SingleValueSourceTypes.IncidentStats]: new IncidentStatsSingleValueAdapter(rootStore),
  [SingleValueSourceTypes.Thing]: new ThingAdapterSingleValueAdapter(rootStore),
});

export const timeChartLookups = (rootStore: RootStore) => ({
  [ChartSourceTypes.ThingsTimeSeries]: new ThingTimeSeriesTimeChartValuesAdapter(rootStore),
  [ChartSourceTypes.ThingEventTime]: new ThingEventTimeTimeChartValuesAdapter(rootStore),
});

export const geoLookups = (rootStore: RootStore) => ({
  [GeoDataSourceTypes.CustomerStackLocations]: new CustomerStackLocationAdapter(rootStore),
  [GeoDataSourceTypes.ThingLocations]: new ThingLocationsAdapter(rootStore),
});

export const categorisedDataLookups = (rootStore: RootStore) => ({
  [MultipleValueSourceTypes.ProtectedHouseholds]: new ProtectedHouseholdsCategoryDataAdapter(rootStore),
  [MultipleValueSourceTypes.SignedInstallationStats]: new SignedInstallationStatsCategoryDataAdapter(rootStore),
});

export const tableValueDataLookups = (rootStore: RootStore) => ({
  [MultiplePropertyValueSourceTypes.Thing]: new ThingTableAdapter(rootStore),
  [MultiplePropertyValueSourceTypes.IncidentStats]: new IncidentStatsMultipleValueAdapter(rootStore),
  [MultipleThingsValueSourceTypes.ThingMetricThreshold]: new ThingMetricThresholdValuesAdapter(rootStore),
  [MultipleAlarmsValueSourceTypes.Alarms]: new AlarmStatsMultipleValueAdapter(rootStore),
  [MultipleStacksValueSourceTypes.StackCreated]: new MultipleStacksValueAdapter(rootStore),
});
