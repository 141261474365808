import { useTheme } from "@emotion/react";
import GridLayout, { Layout } from "components/common/GridLayout";
import { DashboardPurpose } from "constants/dashboardConstants";
import { WidgetType } from "constants/widgetConstants";
import { HomeDashboardWidgets, HomeDashboardWidgetsForTablet, MARGIN_PX, ROW_H_PX } from "data/HomeDashboardData";
import { getWidgetData } from "data/widgetData";
import Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import highchartsExportData from "highcharts/modules/export-data";
import highchartsExporting from "highcharts/modules/exporting";
import { observer } from "mobx-react-lite";
import { useLayoutEffect, useMemo, useState } from "react";
import { useWindowSize } from "react-use";
import HomePageWidgetFrame from "./HomePageWidgetFrame";

highchartsAccessibility(Highcharts);
highchartsExporting(Highcharts);
highchartsExportData(Highcharts);

export const TABLET_TRHESHOLD = 1280;

const HomePageDashboard = () => {
  const theme = useTheme();
  const windowSize = useWindowSize();

  const [size, setSize] = useState({ width: 0, height: 0 });

  const handleSize = () => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useLayoutEffect(() => {
    handleSize();

    window.addEventListener("resize", handleSize);

    return () => window.removeEventListener("resize", handleSize);
  }, []);

  const { layouts, items } = useMemo(() => {
    return (size.width <= TABLET_TRHESHOLD ? HomeDashboardWidgetsForTablet : HomeDashboardWidgets)(size.height)
      .map((widget): { layout: Layout; item: JSX.Element } => {
        const widgetData = getWidgetData(widget);

        return {
          layout: {
            i: widget.widgetId,
            x: widget.settings.position.x,
            y: widget.settings.position.y,
            w: widget.settings.size?.width!,
            h: widget.settings.size?.height!,
            maxH: widget.settings.size?.height,
            maxW: widget.settings.size?.width,
            minH: widget.settings.size?.height,
            minW: widget.settings.size?.width,
            static: true,
          },
          item: (
            <HomePageWidgetFrame widget={widget} hideable={widgetData?.type === WidgetType.TextWidget}>
              {widgetData?.component({
                widget,
                readonly: true,
                dashboardMetadata: { dashboardPurpose: DashboardPurpose.Generic },
              })}
            </HomePageWidgetFrame>
          ),
        };
      })
      .reduce(
        (acc: { layouts: Layout[]; items: JSX.Element[] }, { layout, item }) => ({
          layouts: [...acc.layouts, layout],
          items: [...acc.items, <div key={layout.i}>{item}</div>],
        }),
        { layouts: [], items: [] }
      );
  }, [size.height, size.width]);

  return (
    <GridLayout
      className="layout"
      layout={layouts}
      cols={12}
      rowHeight={ROW_H_PX}
      width={Math.min(windowSize.width, 1440) - theme.spacingValue * 6}
      onLayoutChange={undefined}
      draggableHandle=".dragHandle"
      isResizable={false}
      resizeHandles={[]}
      useCSSTransforms={false}
      margin={[MARGIN_PX, MARGIN_PX]}
    >
      {items}
    </GridLayout>
  );
};

export default observer(HomePageDashboard);
