import styled from "@emotion/styled/macro";
import { useMemo } from "react";
import { createUltimatePagination, ITEM_TYPES } from "react-ultimate-pagination";
import { useSize } from "react-use";
import language from "translations/language";
import { Button } from "./common";

interface Props {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  onChange: (page: number) => void;
}

const Pagination = ({ currentPage = 0, pageSize = 1, totalCount = 0, onChange }: Props) => {
  const numberOfPages = Math.ceil(totalCount / pageSize);
  const [sizeMeasure, { width }] = useSize(() => <S.SizeMeasure />, { width: 100, height: 100 });

  const siblingPagesRange = useMemo(() => {
    /* breakpoints are the widths of the component, the index is the number of sibling pages to show */
    const breakpoints = [400, 520, 700, 900, 1200];
    const index = breakpoints.findIndex((breakpoint) => width <= breakpoint);

    return index !== -1 ? index : breakpoints.length;
  }, [width]);

  if (numberOfPages <= 1) {
    return null;
  }

  return (
    <>
      {sizeMeasure}
      <PaginationComp
        currentPage={currentPage + 1}
        totalPages={numberOfPages}
        onChange={(a) => onChange(a - 1)}
        hideFirstAndLastPageLinks
        hideEllipsis={false}
        boundaryPagesRange={1}
        siblingPagesRange={siblingPagesRange}
        hidePreviousAndNextPageLinks={width <= 350}
      />
    </>
  );
};

export default Pagination;

const Page = ({ isActive, onClick, isDisabled, value }: any) => (
  <Button ghost size="sm" type={isActive ? "default" : "text"} onClick={onClick} disabled={isDisabled}>
    {value}
  </Button>
);

const Ellipsis = ({ isActive, onClick, isDisabled }: any) => (
  <Button ghost size="sm" type={isActive ? "default" : "text"} onClick={onClick} disabled={isDisabled}>
    ...
  </Button>
);

const PreviousPageLink = ({ onClick, isActive }: any) => (
  <Button size="sm" disabled={isActive} onClick={onClick}>
    {language.PREVIOUS}
  </Button>
);

const NextPageLink = ({ onClick, isActive, value }: any) => (
  <Button size="sm" disabled={isActive} onClick={onClick}>
    {language.NEXT}
  </Button>
);

const Wrapper = (props: any) => <S.PaginationContainer>{props.children}</S.PaginationContainer>;

const itemTypeToComponent = {
  [ITEM_TYPES.PAGE]: Page,
  [ITEM_TYPES.ELLIPSIS]: Ellipsis,
  [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
  [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
  [ITEM_TYPES.FIRST_PAGE_LINK]: () => <></>,
  [ITEM_TYPES.LAST_PAGE_LINK]: () => <></>,
};

const PaginationComp = createUltimatePagination({ itemTypeToComponent, WrapperComponent: Wrapper });

// prettier-ignore
const S = {
  PaginationContainer : styled.div(({theme})=>`
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: center;
    ${theme.color.bg.neutral100}

    button {
      ${theme.spacing.mr(4)}
    }
    
    button:last-child {
      margin-right: 0;
    }
  `),

  SizeMeasure: styled.div(({ theme }) => `
    position: absolute;
    left: 0;
    width: 100%;
  `),
}
