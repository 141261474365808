import styled from "@emotion/styled/macro";
import { IconButton, Input } from "components/common";
import { WidgetType } from "constants/widgetConstants";
import { getWidgets, WidgetData } from "data/widgetData";
import { observer } from "mobx-react-lite";
import { useMemo, useRef } from "react";
import { FiX } from "react-icons/fi";
import { useHoverDirty, useLockBodyScroll } from "react-use";
import { useStore } from "stores/RootStore";
import language from "translations/language";
import AddWidgetSelectorItem from "./AddWidgetSelectorItem";
import { useState } from "react";

type Props = {
  dashboardId: string;
  isOpen: boolean;
  closeSidebar?: () => void;
  openSidebar?: () => void;
};

const AddWidgetSelector = ({ dashboardId, isOpen, closeSidebar, openSidebar }: Props) => {
  const { dashboardStore } = useStore();
  const [searchQuery, setSearchQuery] = useState("");

  /* Block main page scroll when hovering this component */
  const ref = useRef(null);
  const isHovering = useHoverDirty(ref);

  useLockBodyScroll(isHovering);

  const widgetsList = useMemo(() => {
    const dashboard = dashboardStore.getDashboardById(dashboardId);

    const sortedWidgets = getWidgets(dashboard!);

    return Object.values(sortedWidgets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container isOpen={isOpen} ref={ref}>
      <S.CloseContainer>
        <IconButton onClick={closeSidebar} style={{ fontSize: 21 }}>
          <FiX />
        </IconButton>
      </S.CloseContainer>
      <S.Header>
        <>
          <S.Title>{language.dashboards.ADD_A_WIDGET}</S.Title>
        </>
      </S.Header>
      <S.Input placeholder={language.SEARCH} onChange={(e) => setSearchQuery(e.target.value)} />
      <S.Content>
        {widgetsList
          .filter((widgetData) => widgetData.name.toLowerCase().includes(searchQuery.toLowerCase()))
          .map((widgetData) => (
            <AddWidgetSelectorItem
              key={widgetData.type}
              widgetData={widgetData as WidgetData<WidgetType, any>}
              dashboardId={dashboardId}
              closeSidebar={closeSidebar!}
              openSidebar={openSidebar!}
            />
          ))}
      </S.Content>
    </S.Container>
  );
};

export default observer(AddWidgetSelector);

// prettier-ignore
const S = {
  Container: styled.div<{ isOpen: boolean}>(({ isOpen, theme }) => `
    position: fixed;
    overflow: clip;
    top: ${theme.spacing(8)};
    right: ${theme.spacing(8)};
    bottom: ${theme.spacing(8)};

    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(7)};

    ${theme.color.bg.neutral100};
    border-radius: ${theme.borderRadius.md};
    box-shadow: ${theme.shadow.sm};

    /* min-width: ${theme.spacing(73)}; */
    width: ${theme.spacing(73)};
    ${theme.spacing.px(5)}
    ${theme.spacing.py(4)}

    /* TODO: extract to theme layer */
    z-index: 10;

    transform: translateX(${isOpen ? 0 : 150}%);
    transition: transform 0.2s ease-in-out;
  `),

  Header: styled.div(({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
  `),

  Content: styled.div(({ theme }) => `
    margin-right: -${theme.spacing(5)};
    ${theme.spacing.pr(5)}
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
  `),

  CloseContainer: styled.div(({ theme }) => `
    position: absolute;
    top: ${theme.spacing(3)};
    right: ${theme.spacing(2)};
  `),

  Title: styled.h2(({ theme }) => `
    ${theme.typography.body1}
    font-weight: bold;
    margin-right: auto;
  `),

  Input: styled(Input)(({ theme }) => `
    border: 1px solid ${theme.color.neutral400};
  `),
}
