import { CAPTION_DATA_REFRESH_TIMEOUT } from "constants/captionDataConstants";
import { DEFAULT_REFRESH_TIMEOUT, LoadingStatus } from "constants/constants";
import { IncidentType } from "constants/widgetConstants";
import { Loadable, hasData } from "models/Loading";
import { MultipleIncidentStatsDataSource } from "models/widgets/TableWidget";
import { WidgetViewModel } from "models/widgets/Widget";
import { RootStore } from "stores/RootStore";
import language from "translations/language";
import { TableValues, TableValuesDataSource } from "../interfaces";

export class IncidentStatsMultipleValueAdapter implements TableValuesDataSource<MultipleIncidentStatsDataSource> {
  refreshTimeout: number | undefined;

  constructor(private stores: RootStore) {
    /* If deptId is used, refreshes every CAPTION_DATA_REFRESH_TIMEOUT, otherwise we rely on the incoming websocket messages */
    this.refreshTimeout = this.stores.configStore.deptId ? CAPTION_DATA_REFRESH_TIMEOUT : DEFAULT_REFRESH_TIMEOUT;
  }

  getData(config: MultipleIncidentStatsDataSource, widget: WidgetViewModel): Loadable<TableValues> {
    const incidentStats = config.incidentType.map((type) =>
      this.stores.incidentStatsStore.get({ type, range: config.dayRange })
    );

    const values = incidentStats.map((incidentStats) => {
      if (!hasData(incidentStats)) {
        return {
          loadingStatus: incidentStats.loadingStatus,
          value: 0,
          label: "",
          updatedAt: new Date(),
        };
      }

      return {
        loadingStatus: LoadingStatus.Loaded,
        value: incidentStats.type === IncidentType.Alarms ? incidentStats.items : incidentStats.count,
        label: language.incidentTypes[incidentStats.type as IncidentType],
        updatedAt: new Date(incidentStats.items.find((item) => item.created)?.created ?? new Date()),
        // @ts-ignore
      } as TableValues["values"][0];
    });

    return {
      loadingStatus: values.every((value) => value?.loadingStatus === LoadingStatus.Loaded)
        ? LoadingStatus.Loaded
        : LoadingStatus.Loading,
      values,
      title: widget.settings.title,
    };
  }

  async loadData(config: MultipleIncidentStatsDataSource) {
    await this.stores.incidentStatsStore.loadMany(
      config.incidentType.map((type) => ({ type, range: config.dayRange }))
    );
  }
}
