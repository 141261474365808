import { MapInitialView, WidgetType } from "constants/widgetConstants";
import { WidgetViewModel } from "./Widget";
import { MapOptions } from "data/widgetData";

export type MapWidgetViewModel = WidgetViewModel<WidgetType.MapWidget, MapWidgetSettings>;

export type MapWidgetSettings = {
  dataSource: GeoDataSource | null;
};

export type GeoDataSource = CustomerStackLocationSource | ThingLocationsSource;

export type CustomerStackLocationSource = {
  sourceType: GeoDataSourceTypes.CustomerStackLocations;
  /** One of allProperties and customerId has to be used */
  allProperties?: boolean;
  customerId: string;
  mapInitialView: MapInitialView;
  mapInitialViewOptions: MapOptions;
};

export type ThingLocationsSource = {
  sourceType: GeoDataSourceTypes.ThingLocations;
  /** One of allThings and thingIds has to be used */
  allThings?: boolean;
  thingIds?: string[];
  mapInitialView: MapInitialView;
  mapInitialViewOptions: MapOptions;
};

export enum GeoDataSourceTypes {
  CustomerStackLocations = "customerStackLocations",
  ThingLocations = "thingLocations",
}

export function isMapWidget(widget: WidgetViewModel): widget is MapWidgetViewModel {
  return widget.type === WidgetType.MapWidget;
}
