import { ChangeEvent, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import { observer } from "mobx-react-lite";
import language from "translations/language";

import { Input, Select } from "components/common";
import { isNumeric } from "utils/numberUtils";

export interface DayRange {
  from: number;
  to: number;
}

interface Props {
  onChange: (range: DayRange) => void;
  value: DayRange | null;
}

const validateInput = (e: ChangeEvent<HTMLInputElement>, min = 0) => {
  const { value } = e.target;
  const newValue = value.replace(/[^0-9]/g, "");
  const intValue = parseInt(newValue, 10);
  const finalValue = intValue >= min ? intValue.toString() : "";
  e.target.value = finalValue;
};

const intervalOptions = [
  ...[
    [0, 2],
    [3, 7],
    [8, 14],
    [15, 21],
    [22, 29],
    [30, "+"],
  ].map((option) => ({
    value: { from: option[0], to: option[1] },
    label: language.N_DAYS(option.join(option[1] === "+" ? "" : "-")),
  })),
  { value: "custom", label: language.timeIntervals.custom },
];

const DayRangePicker = ({ onChange, value }: Props) => {
  const [from, setFrom] = useState<string>(value?.from.toString() || "");
  const [to, setTo] = useState<string>(value?.to.toString() || "");

  const selectedIntervalOption = useMemo(() => {
    return (
      intervalOptions.find((option) => {
        if (typeof option.value === "string") {
          return false;
        } else {
          return option.value.from === value?.from && option.value.to === value?.to;
        }
      }) || intervalOptions.find((option) => option.value === "custom")
    );
  }, [value]);

  const [intervalOption, setIntervalOption] = useState<string>(
    selectedIntervalOption?.value === "custom" ? "custom" : ""
  );
  const isCustom = useMemo(() => intervalOption === "custom", [intervalOption]);

  const changeFrom = (e: ChangeEvent<HTMLInputElement>) => {
    validateInput(e);

    const newFrom = e.target.value;

    handleRangeUpdate(newFrom, to);
    setFrom(newFrom);
  };

  const changeTo = (e: ChangeEvent<HTMLInputElement>) => {
    validateInput(e);

    const newTo = e.target.value;

    handleRangeUpdate(from, newTo);
    setTo(newTo);
  };

  const handleIntervalChange = (selected: any) => {
    if (!selected.value) return;

    if (selected.value === "custom") {
      setIntervalOption("custom");
      handleRangeUpdate(from, to);
    } else {
      setIntervalOption([selected.value.from, selected.value.to].join("_"));

      const newFrom = selected.value.from.toString();
      const newTo = selected.value.to === "+" ? "9999" : selected.value.to;

      handleRangeUpdate(newFrom, newTo);

      setFrom(newFrom);
      setTo(newTo);
    }
  };

  const handleRangeUpdate = (from: string, to: string) => {
    if (isNumeric(from) && isNumeric(to)) {
      const fromNum = parseFloat(from);
      const toNum = parseFloat(to);

      if (fromNum < toNum) {
        onChange({ from: fromNum, to: toNum });
      }
    }
  };

  return (
    <S.RangePickerRow>
      <S.RangePickerRowCell>
        <Select
          style={{ width: "100%" }}
          options={intervalOptions}
          onChange={handleIntervalChange}
          defaultValue={selectedIntervalOption}
        />
      </S.RangePickerRowCell>
      <S.RangePickerRowCell disabled={!isCustom}>
        <label htmlFor="dayrange-from">{language.BETWEEN}</label>
        <Input disabled={!isCustom} type="text" id="dayrange-from" value={from} onChange={changeFrom} />
        <label htmlFor="dayrange-to">{language.BETWEEN_TO}</label>
        <Input disabled={!isCustom} type="text" id="dayrange-to" value={to} onChange={changeTo} />{" "}
        <label>{language.DAYS}</label>
      </S.RangePickerRowCell>
    </S.RangePickerRow>
  );
};

// prettier-ignore
const S = {
  RangePickerRow: styled.div(({ theme }) => `
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: ${theme.spacing(8)};

    ${theme.typography.body2}

    label {
      font-weight: bold;
      ${theme.spacing.mr(2)}
      ${theme.spacing.ml(2)}
    }
  `),

  RangePickerRowCell: styled.div<{disabled?: boolean}>(({ disabled = false }) => `
    display: flex;
    align-items: center;
    ${disabled ? "opacity: 0.5;" : ""}
  `),
}

export default observer(DayRangePicker);
